import React, { useContext } from "react";
import ThemeContext from "../../../contexts/theme";

import { ListWrapper, ListItem } from "./styled";

const List = ({ children, margin, style, listType = "disc" }) => {
  return (
    <ListWrapper $listType={listType} $styled={{ margin, ...style }}>
      {children}
    </ListWrapper>
  );
};

List.Item = ({
  fontSize = 14,
  fontWeight = 400,
  lineHeight = "28px",
  height = "initial",
  padding,
  margin,
  bulletSize,
  style,
  bulletColor = "secondary200",
  children
}) => {
  const theme = useContext(ThemeContext);

  return (
    <ListItem
      $styled={{ fontSize, fontWeight, lineHeight, padding, margin, height, ...style }}
      $bulletSize={bulletSize}
      $bulletColor={theme[bulletColor]}
    >
      {children}
    </ListItem>
  );
};

export default List;
