import React, { useState, useEffect, useContext } from "react";
import ThemeContext from "../../../contexts/theme";
import Text from "../Text";
import { SwitchWrapper, SwitchControl, SwitchLabel, SwitchThumb } from "./styled";

const SwitchInput = ({
  id = "id",
  margin = "10px 0",
  width,
  label,
  style = {},
  disabled,
  tabIndex = 0,
  baseColor = "black100",
  checkedColor = "secondary200",
  errorMessage,
  errorColor = "accent300",
  display = "block",
  checked = false,
  onChange,
  isNotChangeByDefault,
  ...props
}) => {
  const [isChecked, setChecked] = useState(checked);
  const [isFocused, setFocus] = useState(false);

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  const onChangeHandler = () => {
    if (!isNotChangeByDefault) {
      setChecked(!isChecked);
    }

    onChange && onChange();
  };

  const onFocusHandler = () => {
    setFocus(true);
  };

  const onBlurHandler = () => {
    setFocus(false);
  };

  const Theme = useContext(ThemeContext);
  const uid = `uid-${id}`;

  return (
    <SwitchWrapper styled={{ width, margin, display, ...style }}>
      <SwitchControl
        id={uid}
        type="checkbox"
        hasError={!!errorMessage}
        aria-invalid={!!errorMessage}
        tabIndex={tabIndex}
        checked={isChecked}
        disabled={disabled}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        onChange={onChangeHandler}
        role="switch"
        aria-checked={isChecked}
        {...props}
      />
      <SwitchLabel
        htmlFor={uid}
        checked={isChecked}
        isFocused={isFocused}
        baseColor={disabled ? Theme.grey200 : Theme[baseColor]}
        checkedColor={disabled ? Theme.grey200 : Theme[checkedColor]}
      >
        <SwitchThumb checked={isChecked} isFocused={isFocused} />
      </SwitchLabel>
      {errorMessage && (
        <Text color={errorColor} margin="4px 0 0" loaded={!!errorMessage} aria-describedby={uid}>
          {errorMessage}
        </Text>
      )}
    </SwitchWrapper>
  );
};

export default SwitchInput;
