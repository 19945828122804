import axios from "axios";

const course_path = "/api/v1/browse/courses";

const affiliate_path = "/api/v1/browse/affiliate_courses";

// Courses

export const getLearningActivity = () => axios.get(`${course_path}/activity`);

export const getUnfinishedCourses = () => axios.get(`${course_path}/unfinished`);

export const getFinishedCourses = () => axios.get(`${course_path}/finished`);

export const getSavedCourses = () => axios.get(`${course_path}/saved`);

// Course details

export const getCourse = id => axios.get(`${course_path}/${id}`);

export const getCourseReviews = id => axios.get(`${course_path}/${id}/reviews`);

export const startCourse = id => axios.post(`${course_path}/${id}/start`);

export const cancelCourse = id => axios.post(`${course_path}/${id}/cancel`);

export const getModule = (courseId, moduleId) => axios.get(`${course_path}/${courseId}/modules/${moduleId}`);

export const completeModule = (courseId, moduleId) =>
  axios.post(`${course_path}/${courseId}/modules/${moduleId}/complete`);

export const getTestResult = id => axios.get(`/api/v1/browse/forms/${id}/result`);

export const postponeModule = (courseId, moduleId) =>
  axios.post(`${course_path}/${courseId}/modules/${moduleId}/postpone`);

export const addCourseReview = (course_id, grade, comment) =>
  axios.post(`${course_path}/${course_id}/reviews`, { course_id, review: { grade, comment } });

export const startAffiliate = id => axios.post(`${affiliate_path}/${id}/start`);

export const cancelAffiliate = id => axios.post(`${affiliate_path}/${id}/cancel`);

export const finishAffiliate = id => axios.post(`${affiliate_path}/${id}/finish`);

export const toggleCourseLike = id => axios.post(`${course_path}/${id}/toggle_like`);

// Comments

export const getCourseComments = id => axios.get(`${course_path}/${id}/comments`);

export const addCourseComment = (id, body, parent_id = null) =>
  axios.post(`${course_path}/${id}/comments`, { comment: { body, parent_id } });

export const updateCourseComment = (id, body, comment_id) =>
  axios.patch(`${course_path}/${id}/comments/${comment_id}`, { body });

export const removeCourseComment = (id, comment_id) => axios.delete(`${course_path}/${id}/comments/${comment_id}`);
// Search

export const getCourseCategories = () => axios.get(`${course_path}/categories`);

export const searchCourses = query =>
  axios.get(`${course_path}?${query}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });

export const getCourseFilters = query =>
  axios.get(`${course_path}/filters?${query}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
