import axios from "axios";

// CV upload
const profile_path = "/api/v1/browse/profile";

export const uploadCv = form =>
  axios.post(`${profile_path}/cv/upload`, form, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });

export const uploadCvAndPopulate = form =>
  axios.post(`${profile_path}/cv/upload_and_populate`, form, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
