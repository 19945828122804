import styled from "styled-components";

export const StyledRow = styled.div`
  ${({ theme, padding, disabled, $prevDisabled, $nextDisabled }) => `
        display: flex;
        width: 100%;
        padding: ${padding};
        ${
          disabled
            ? `
        pointer-events: none;
        opacity: 0.3;
        `
            : ""
        }
        justify-content: center;
        ${$prevDisabled ? "padding-left: 72px;" : ""}
        ${$nextDisabled ? "padding-right: 72px;" : ""}

        > div {
            height: 35px;
        }

        .b2c-text {
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        .nav-prev, .nav-next {
            display: flex;
            svg {
                margin: auto 8px;
                fill: ${theme.primary300};
            }

            &:hover > svg {
                fill: ${theme.primary};
            }
        }
    `}
`;
