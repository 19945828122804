export const validateNonempty = input => (input ? { test: true } : { test: false, error: "Field is required" });

export const validateFullName = input => {
  if (input) {
    return !/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u.test(
      input
    )
      ? { test: false, error: "Invalid name" }
      : { test: true };
  }
  return { test: false, error: "Name is required" };
};

export const validateNumber = input => {
  if (input) {
    return isNaN(input) ? { test: false, error: "Invalid phone number" } : { test: true };
  }
  return { test: false, error: "Field is required" };
};

export const validateEmail = input => {
  if (input) {
    return !/^\w+([\w\S]?\w+)*@\w+([.-]?\w+)*(\.\w{2,9})+$/.test(input)
      ? { test: false, error: "Invalid email" }
      : { test: true };
  }
  return { test: false, error: "Email address is required" };
};

export const validatePassword = input => {
  if (!input) return { test: false, error: "Password is required" };
  if (input.length < 8) return { test: false, error: "Password should be at least 8 characters long" };
  return { test: true };
};

export const validateForm = ({ first_name, email, password, confirmation, terms, fuzu_country_id }) => {
  const validation = {
    first_name: validateFullName(first_name),
    email: validateEmail(email),
    password: validatePassword(password),
    confirmation: {
      test: confirmation && password === confirmation,
      error: confirmation && password === confirmation ? "" : "Passwords do not match"
    },
    terms: { test: terms, error: !terms ? "Required to proceed" : "" },
    fuzu_country_id: { test: !!fuzu_country_id, error: !fuzu_country_id ? "Required to proceed" : "" }
  };

  const valid = Object.keys(validation).every(key => validation[key].test);

  return { validation, valid };
};

export const validateLogin = ({ email, password }) => {
  const validation = {
    email: validateEmail(email),
    password: password.length >= 8 ? { test: true } : { test: false, error: "Invalid password" }
  };

  const valid = Object.keys(validation).every(key => validation[key].test);

  return { validation, valid };
};
