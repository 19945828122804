import styled from "styled-components";

export const NotificationItemContent = styled.div`
  display: flex;
  align-items: center;
  padding: 17px 10px;
  margin: 0 -13px;
  border-bottom: 1px solid #f5f5f5;
  overflow: hidden;
  height: 100%;
`;

export const titleStyle = {
  fontWeight: "600",
  fontSize: "12px",
  lineHeight: "100%",
  color: "#292929",
  margin: "0 0 5px 7px"
};

export const textStyles = {
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "100%",
  color: "#292929",
  margin: "0 0 0 7px"
};

export const switchStyle = {
  margin: "0 0 0 auto"
};
