import React from "react";

import { Title, Text, Button, Image } from "../Core";
import {
  StyledModalContent,
  titleStyles,
  textStyles,
  buttonStyles,
  buttonCancelStyles,
  imageStyle,
  linkStyles
} from "./styled";

const NotificationModal = ({
  close,
  isLink,
  title,
  imgUrl,
  text,
  onClick,
  buttonText = "Yes, turn on notifications",
  closeButtonText = "No thanks",
  linkUrl = "/job/settings",
  onClose
}) => {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    close();
  };

  return (
    <StyledModalContent>
      <Image src={imgUrl} style={imageStyle} />
      <Title level="4" style={titleStyles}>
        {title}
      </Title>

      {text && <Text style={textStyles}>{text}</Text>}

      {isLink ? (
        <Button.Link style={linkStyles} to={linkUrl} onClick={close}>
          {buttonText}
        </Button.Link>
      ) : (
        <Button style={buttonStyles} onClick={onClick}>
          {buttonText}
        </Button>
      )}

      <Button style={buttonCancelStyles} theme="transparent" onClick={handleClose}>
        {closeButtonText}
      </Button>
    </StyledModalContent>
  );
};

export default NotificationModal;
