import React, { createContext, createRef, useContext, useEffect } from "react";
import { createPortal } from "react-dom";

import { ScreenClassContext } from "react-grid-system";

const DropdownContainerContext = createContext({});

export class DropdownContainer extends React.Component {
  constructor(props) {
    super(props);
    this.container = createRef();
  }

  state = { ref: null };

  componentDidMount() {
    this.setState({ ref: this.container?.current });
  }

  render() {
    return (
      <DropdownContainerContext.Provider value={this.state.ref}>
        {this.props.children}
        <div id="dropdown-menu-container" className="dropdown-container" ref={this.container} />
      </DropdownContainerContext.Provider>
    );
  }
}

export const useDropdownContainer = () => {
  const ref = useContext(DropdownContainerContext);
  return ref;
};

const DropdownPortal = ({ children }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = /xs|sm/.test(screen);
  const container = useDropdownContainer();

  useEffect(() => {
    if (document?.body && isSm) document.body.style.overflow = "hidden";

    return () => {
      if (document?.body) document.body.style.overflow = "unset";
    };
  }, []);

  return isSm && Object.keys(container).length ? createPortal(children, container) : children;
};

export default DropdownPortal;
