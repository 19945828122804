import React, { useState, useEffect, useContext, useRef, forwardRef } from "react";
import { ShowAlt } from "@styled-icons/boxicons-regular";
import ThemeContext from "../../../contexts/theme";
import Text from "../Text";
import IconComponent from "../Icon";

import {
  InputWrapper,
  InputHolder,
  PrependedText,
  InputLabel,
  InputControl,
  InputControlInputMask,
  RequiredText
} from "./styled";

const Input = forwardRef(
  (
    {
      id,
      inputColor = "black500",
      inputHeight = "48px",
      name,
      width,
      height,
      margin,
      display = "block",
      label,
      ariaLabel,
      disabled,
      prependedText,
      labelColor = "black500",
      type = "text",
      placeholder,
      errorMessage = "",
      errorColor = "accent300",
      Icon = <></>,
      fieldRequired = false,
      fieldRequiredText,
      autoComplete,
      labelStyle = {},
      style,
      inputMask,
      dataCy,
      ...props
    },
    ref
  ) => {
    const control = ref || useRef();
    const [displayError, setErrorDisplay] = useState(true);
    const [inputType, setInputType] = useState(type);
    const Theme = useContext(ThemeContext);

    useEffect(() => {
      if (displayError) setErrorDisplay(false);
    }, [control.current?.value]);

    useEffect(() => {
      setErrorDisplay(true);
    }, [errorMessage]);

    const idString = new Date().getTime() + Math.random().toString(36).substr(2, 5);

    const uid = id || `uid_${idString}`;

    const eid = `eid_${id || idString}`;

    const lid = `lid_${id || idString}`;

    const MainInput = inputMask ? InputControlInputMask : InputControl;

    return (
      <InputWrapper
        className="input-wrapper"
        $hasError={!!errorMessage && displayError}
        styled={{ width, height, margin, display }}
      >
        {label && (
          <InputLabel id={lid} htmlFor={uid} styled={{ color: Theme[labelColor], ...labelStyle }}>
            {label}
            {fieldRequired && (fieldRequiredText ? <RequiredText> {fieldRequiredText}</RequiredText> : "*")}
          </InputLabel>
        )}
        {Icon}
        <InputHolder>
          {prependedText && (
            <PrependedText
              fontWeight={500}
              margin="auto 5px auto 0"
              htmlFor={uid}
              styled={{
                color: Theme[inputColor],
                height: inputHeight
              }}
              $hasError={!!errorMessage && displayError}
              theme={Theme}
            >
              {prependedText}
            </PrependedText>
          )}
          <MainInput
            data-cy={dataCy}
            mask={inputMask}
            maskChar={null}
            id={uid}
            type={inputType}
            ref={control}
            autoComplete={autoComplete}
            aria-label={ariaLabel}
            aria-labelledby={lid}
            aria-required={fieldRequired ? "true" : "false"}
            aria-invalid={!!errorMessage && displayError}
            aria-describedby={eid}
            aria-disabled={disabled ? "true" : "false"}
            color={Theme[inputColor]}
            height={inputHeight}
            styled={style}
            placeholder={placeholder}
            $hasError={!!errorMessage && displayError}
            name={name}
            $withPrependedText={!!prependedText}
            theme={Theme}
            disabled={disabled}
            {...props}
          />
          {type === "password" && (
            <IconComponent
              as={ShowAlt}
              onClick={() => {
                setInputType(inputType === "password" ? "text" : "password");
              }}
              fill={inputType === "password" ? "grey300" : "black300"}
            />
          )}
        </InputHolder>
        {!!errorMessage && displayError && (
          <Text id={eid} color={errorColor} fontSize="12px" margin="2px 0" loaded={!!errorMessage && displayError}>
            {errorMessage}
          </Text>
        )}
      </InputWrapper>
    );
  }
);

Input.File = ({ Component, dataCy, ...rest }) => {
  const uploader = useRef();

  const simulateClick = e => {
    e.persist();
    uploader.current.click();
  };

  return (
    <>
      <Component onClick={simulateClick} />
      <input ref={uploader} type="file" data-cy={dataCy} style={{ display: "none" }} {...rest} />
    </>
  );
};

export default Input;
