import { makeAutoObservable } from "mobx";
import * as State from "utils/storeHelpers";
import API from "../../utils/api";

class Companies {
  state = {
    loading: false,
    processing: false,
    type: "",
    message: ""
  };

  companies;

  company;

  constructor(setAppState, companies, current_company = {}) {
    makeAutoObservable(this);

    this.companies = companies;
    this.company = current_company;
  }

  getCompanies = (filter, company_identifier, limit) => {
    const company_identifiers = [this.company?.id, this.company?.slug];
    if (company_identifier && company_identifiers.includes(company_identifier)) return Promise.resolve();

    this.state = State.setLoading(this.state);

    return API.getCompaniesFeed(filter, company_identifier, limit)
      .then(res => {
        this.companies = res?.data?.companies;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to get companies", () =>
          this.getCompanies(filter, company_identifier, limit)
        );
      });
  };

  resetCompanies = () => {
    this.companies = null;
  };
}

export default Companies;
