import { makeAutoObservable } from "mobx";
import * as API from "utils/api";
import { getPhoneCodes, getEmployerSizes, getCountries } from "b2b/utils/api";
import * as State from "utils/storeHelpers";

class ContactUsStore {
  appointments;

  codes;

  employerSizes;

  countries;

  defaultCountry = {};

  selectedProductType = "";

  constructor() {
    makeAutoObservable(this);
  }

  getAppointments = countryId => {
    return API.getAppointments(countryId)
      .then(response => {
        this.appointments = response.data?.data || [];
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed get appointments", () => this.getAppointments(countryId));
      });
  };

  getDefaultCountry = () => {
    return API.getDefaultCountry()
      .then(res => {
        this.defaultCountry = res?.data?.default_country;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed get default country", () => this.getDefaultCountry());
      });
  };

  getPhoneCodes = () => {
    return getPhoneCodes().then(response => {
      const { priorities, common } = response.data.codes;

      const sortedCommon = [];
      common.filter(item => {
        const i = sortedCommon.findIndex(x => x.phone_code === item.phone_code);
        if (i <= -1 && item.phone_code !== "+") {
          sortedCommon.push(item);
        }

        return null;
      });

      sortedCommon.sort((a, b) => (parseInt(a.phone_code) > parseInt(b.phone_code) ? 1 : -1));
      this.codes = priorities.concat(sortedCommon);
    });
  };

  getEmployerSizes = () => {
    return getEmployerSizes().then(response => {
      this.employerSizes = response.data.employer_sizes;
    });
  };

  getCountries = () => {
    const sortCountries = countries => {
      return countries.sort((a, b) => a.name - b.name);
    };

    const prioritizeCountries = countries => {
      const mainCountriesList = countries.slice(0, 3);
      const countriesList = sortCountries(countries.slice(3));

      return [...mainCountriesList, ...countriesList];
    };

    return getCountries().then(response => {
      this.countries = prioritizeCountries(response.data.countries);
    });
  };

  addTimeSlots = (form, id) => {
    return API.addTimeSlots(form, id);
  };

  savePotentialEmployers = token => {
    return API.savePotentialEmployers(token);
  };

  setSelectedProductType = id => {
    this.selectedProductType = id;
  };
}

export default ContactUsStore;
