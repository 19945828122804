const populateFormData = (formData, data, parentKey = null) => {
  if (data && Array.isArray(data)) {
    data.forEach(item => {
      populateFormData(formData, item, `${parentKey}[]`);
    });
  } else if (data && typeof data === "object" && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      populateFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else if (data !== null && data !== undefined) {
    formData.append(parentKey, data);
  }
};

export { populateFormData };
