import { makeAutoObservable, reaction } from "mobx";

import * as State from "utils/storeHelpers";
import API from "../../utils/api";

import Courses from "./Courses";
import Jobs from "./Jobs";
import Articles from "./Articles";
import Leaders from "./Leaders";
import Unfinished from "./Unfinished";
import Companies from "./Companies";

class Layout {
  setAppState;

  setTutorialCompleted;

  resolveStep;

  hero = {};

  header = {};

  sections = [];

  isBaronaState;

  constructor(userStore, setAppState, layout, isBarona) {
    makeAutoObservable(this);

    this.setAppState = setAppState;
    this.setTutorialCompleted = userStore?.setTutorialCompleted;
    this.isBaronaState = isBarona;

    this.userStore = userStore;
    if (layout.hero?.hasOwnProperty("hero_article")) {
      this.hero = {
        ...layout.hero.hero_article,
        type: "carousel"
      };
    } else if (layout.hero?.hasOwnProperty("onboarding_cta")) {
      this.hero = {
        ...layout.hero.onboarding_cta,
        type: "onboarding"
      };
    }
    this.header = layout.general || {};
    this.sections = layout.common?.length ? layout.common : [];

    reaction(
      () => !!this.userStore.user?.onboarding_steps_completed,
      authorized => {
        if (authorized) {
          this.getHomepageLayout("home_page");
        }
      },
      { fireImmediately: true }
    );
  }

  state = {
    loading: false,
    type: "",
    message: ""
  };

  headerType = true;

  highlight = [];

  describe = {};

  highlightedRegions = [];

  getHomepageLayout = slug => {
    this.state = State.setLoading(this.state);
    return API.getHomepageLayout(slug)
      .then(res => {
        this.sections = res?.data?.common;
        this.header = res?.data?.general;

        if (res?.data?.common.some(({ type }) => type === "search")) {
          this.header.search = true;
        }
        if (res?.data?.hero.hasOwnProperty("hero_article")) {
          this.hero = {
            ...res?.data?.hero.hero_article,
            type: "carousel"
          };
        } else if (res?.data?.hero.hasOwnProperty("onboarding_cta")) {
          this.hero = {
            ...res?.data?.hero.onboarding_cta,
            type: "onboarding"
          };
        }

        this.state = State.setNeutral(this.state);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve layout structure for current user", () =>
          this.getHomepageLayout(slug)
        );
      });
  };

  highlightRegion = (id, region) => {
    const tempRegions = this.highlightedRegions;
    if (tempRegions.some(item => item.id == id)) {
      const tempItem = tempRegions.find(item => item.id == id);
      tempItem.region = region;
    } else {
      tempRegions.push({ id, region });
    }
    this.highlightedRegions = [...tempRegions];
  };

  initiateIntroduction = () => {
    this.highlight = ["homepage"];
    this.describe = {
      id: "homepage",
      title: "tour.home.title",
      paragraphs: ["tour.home.description_1", "tour.home.description_2"],
      step: 1
    };

    let resolveHandler;

    this.resolveStep = () => {
      resolveHandler();
    };

    const firstStep = new Promise(resolve => {
      resolveHandler = resolve;
    });
    firstStep
      .then(() => {
        this.highlightedRegions = [];
        this.highlight = ["learn"];
        this.describe = {
          id: "learn",
          title: "tour.learn.title",
          paragraphs: ["tour.learn.description_1"],
          step: 2,
          alt: "article"
        };

        return new Promise(resolve => {
          resolveHandler = resolve;
        });
      })
      .then(() => {
        this.highlightedRegions = [];
        this.highlight = ["menu"];
        this.describe = {
          id: "menu",
          title: "tour.menu.title",
          paragraphs: ["tour.menu.description_1"],
          step: 3
        };

        return new Promise(resolve => {
          resolveHandler = resolve;
        });
      })
      .then(() => {
        this.highlightedRegions = [];
        this.highlight = ["messages"];
        this.describe = {
          id: "messages",
          title: "tour.messages.title",
          paragraphs: ["tour.messages.description_1"],
          step: 4
        };

        return new Promise(resolve => {
          resolveHandler = resolve;
        });
      })
      .then(() => {
        this.highlightedRegions = [];
        this.highlight = ["notifications"];
        this.describe = {
          id: "notifications",
          title: "? ? ?",
          paragraphs: ["? ? ? "],
          step: 5
        };

        return new Promise(resolve => {
          resolveHandler = resolve;
        });
      })
      .then(() => {
        this.highlightedRegions = [];
        this.highlight = [];
        this.describe = {};
        this.resolveStep = null;
        this.setTutorialCompleted();
      });
  };

  switchHeaderType = type => {
    this.headerType = type || !this.headerType;
  };
}

class Home {
  constructor(userStore, setAppState, context = {}, layout = {}, location, isBarona) {
    this.HomeLayout = new Layout(userStore, setAppState, layout, isBarona);
    this.Courses = new Courses(setAppState);
    this.Jobs = new Jobs(setAppState, context.similar_jobs);
    this.Articles = new Articles(setAppState, context, location);
    this.Leaders = new Leaders(setAppState);
    this.Unfinished = new Unfinished(setAppState);
    this.Companies = new Companies(setAppState, context.similar_company, context.company_details);
  }
}

export default Home;
