import axios from "axios";

// Common

export const getLocations = term => axios.get(`/api/v1/browse/countries/with_towns${term ? `?term=${term}` : ""}`);

export const getTowns = term => axios.get(`/api/v1/browse/towns${term ? `?term=${term}` : ""}`);

export const getCompanies = term => axios.get(`/api/v1/browse/companies${term ? `?term=${term}` : ""}`);

export const pingServer = () => axios.get("/api/v1/browse/ping");

export const getCountries = notDefault => axios.get(`/api/v1/browse/countries${notDefault ? "?non_default=true" : ""}`);
