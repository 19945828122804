import axios from "axios";

// User profile

const profile_path = "/api/v1/browse/profile";

export const getGeneralInfo = () => axios.get(`${profile_path}/general_info`);

export const getPersonalInfo = () => axios.get(`${profile_path}/personal_info`);

export const updatePersonalInfo = personal_info => axios.patch(`${profile_path}/personal_info`, { personal_info });

export const addPhoneNumber = phone => axios.patch(`${profile_path}/personal_info/phone_number`, { phone });

export const getPersonality = () => axios.get("/api/v1/browse/profile/personality_results");

export const getPersonalInfoOptions = () => axios.get(`${profile_path}/personal_info/available_options`);

export const updateUserAvatar = data =>
  axios.patch(`${profile_path}/avatar`, data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });

export const deleteUserAvatar = () => axios.delete(`${profile_path}/avatar`);

export const getBasicInfo = () => axios.get(`${profile_path}/basic_info`);

export const updateBasicInfo = basic_info => axios.patch(`${profile_path}/basic_info`, { basic_info });

export const getWorkExperienceDetails = id => axios.get(`${profile_path}/work_experiences/${id}`);

export const getWorkExperienceOptions = () => axios.get(`${profile_path}/work_experiences/available_options`);

export const addWorkExperience = work_experience_info =>
  axios.post(`${profile_path}/work_experiences`, { work_experience_info });

export const updateWorkExperience = (id, work_experience_info) =>
  axios.patch(`${profile_path}/work_experiences/${id}`, { work_experience_info });

export const updateWorkExperienceSkills = (id, form) =>
  axios.patch(`${profile_path}/work_experiences/${id}/skills`, form);

export const removeWorkExperience = id => axios.delete(`${profile_path}/work_experiences/${id}`);

export const getEducationDetails = id => axios.get(`${profile_path}/educations/${id}`);

export const getEducationOptions = () => axios.get(`${profile_path}/educations/available_options`);

export const addEducation = education_info => axios.post(`${profile_path}/educations`, { education_info });

export const updateEducation = (id, education_info) =>
  axios.patch(`${profile_path}/educations/${id}`, { education_info });

export const removeEducation = id => axios.delete(`${profile_path}/educations/${id}`);

export const getSkillOptions = work_experience_id =>
  axios.get(`${profile_path}/skills${work_experience_id ? `?work_experience_id=${work_experience_id}` : ""}`);

export const getCategorySkills = id => axios.get(`${profile_path}/skills/by_category?category_id=${id}`);

export const getSkillsByTerm = term =>
  axios.get(`${profile_path}/skills/search?term=${encodeURI(term)}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });

export const updateSkills = form => axios.patch(`${profile_path}/skills`, form);

export const removeSkill = id => axios.delete(`${profile_path}/skills/${id}`);

export const getLanguageOptions = () => axios.get(`${profile_path}/languages/available_options`);

export const getLanguages = () => axios.get(`${profile_path}/languages`);

export const updateLanguages = form => axios.patch(`${profile_path}/languages`, form);

export const removeLanguage = id => axios.delete(`${profile_path}/languages/${id}`);

export const getCertificateOptions = () => axios.get(`${profile_path}/certificate_items/available_options`);

export const getCertificates = () => axios.get(`${profile_path}/certificate_items`);

export const updateCertificates = form =>
  axios.patch(`${profile_path}/certificate_items`, form, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });

export const removeCertificate = id => axios.delete(`${profile_path}/certificate_items/${id}`);

export const getAssociations = () => axios.get(`${profile_path}/association_items`);

export const updateAssociations = form => axios.patch(`${profile_path}/association_items`, form);

export const removeAssociation = id => axios.delete(`${profile_path}/association_items/${id}`);

export const getHobbies = () => axios.get(`${profile_path}/hobbies`);

export const updateHobbies = form => axios.patch(`${profile_path}/hobbies`, form);

export const removeHobby = id => axios.delete(`${profile_path}/hobbies/${id}`);

export const getGeneratedCv = () => axios.get("/api/v1/browse/profile/cv/generate");

export const getReferenceDetails = id => axios.get(`${profile_path}/references/${id}`);

export const getReferenceOptions = () => axios.get(`${profile_path}/references/available_options`);

export const addReference = reference_info => axios.post(`${profile_path}/references`, { reference_info });

export const updateReference = (id, reference_info) =>
  axios.patch(`${profile_path}/references/${id}`, { reference_info });

export const removeReference = id => axios.delete(`${profile_path}/references/${id}`);

export const getNotificationSettings = () => axios.get("/api/v1/browse/profile/notification_settings");

export const updateNotificationSettings = notificationSettings =>
  axios.post("/api/v1/browse/profile/notification_settings", {
    notification_settings: { ...notificationSettings }
  });

export const getUserDevice = deviceId => axios.get(`/api/v1/browse/profile/user_devices/${deviceId}`);

export const updateUserDevice = (deviceId, userDevice) =>
  axios.patch(`/api/v1/browse/profile/user_devices/${deviceId}`, { user_device: userDevice });
