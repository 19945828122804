import React from "react";
import { MdClose } from "react-icons/md";

import Fuzuotification from "images/fuzuNotification.svg";
import { Title, Button } from "../Core";
import { StyledModalContent, titleStyles, buttonCancelStyles, imageStyle, buttonStyles, ButtonClose } from "./styled";
import OfferListAddToHomeScreen from "../OfferListAddToHomeScreen";
import useStore from "../../contexts/store";

const NotificationAddToHomeScreen = ({ close, handleOpenPrompt }) => {
  const {
    Profile: { updateUserDevice, userDevice }
  } = useStore("User");

  const handleClose = () => {
    updateUserDevice(userDevice.deviceId, { installation_declined: true, app_installed: false });
    close();
  };

  const handleOpen = () => {
    handleOpenPrompt();
    updateUserDevice(userDevice.deviceId, { app_installed: true, installation_declined: false });
    close();
  };

  return (
    <StyledModalContent>
      <ButtonClose onClick={handleClose}>
        <MdClose />
      </ButtonClose>
      <Fuzuotification style={imageStyle} />
      <Title level="4" style={titleStyles}>
        Get Fuzu app for Android
      </Title>
      <OfferListAddToHomeScreen />

      <Button style={buttonStyles} onClick={handleOpen}>
        Add Fuzu to Home screen
      </Button>
      <Button style={buttonCancelStyles} theme="transparent" onClick={handleClose}>
        Maybe later
      </Button>
    </StyledModalContent>
  );
};

export default NotificationAddToHomeScreen;
