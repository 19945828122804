import React, { useEffect } from "react";
import { useTranslation } from "b2c/hooks/useTranslation";

import { Row, Col } from "react-grid-system";
import { Text, Button } from "../Core";

const Confirmation = ({ resolve, reject, message = "Are you sure?", cta, buttonProps = {}, close }) => {
  useEffect(() => reject);
  const { t } = useTranslation();

  const resolveHandler = () => {
    resolve();
    close();
  };

  return (
    <>
      <Text fontWeight={600} margin="0 0 16px">
        {message}
      </Text>
      <Row>
        <Col>
          <Button theme="grey200" width="100%" onClick={close}>
            {t("global.cancel")}
          </Button>
        </Col>
        <Col>
          <Button width="100%" onClick={resolveHandler} {...buttonProps}>
            {cta}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Confirmation;
