import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import Backend from "i18next-http-backend";

import en from "./lang/en/translation.json";
import fi from "./lang/fi/translation.json";

/*
i18n
  // .use(Backend)
  .use(initReactI18next)
  .init({
    /!*   backend: {
      loadPath: "/api/v1/common/translations?locale={{lng}}"
    },*!/
    resources: {
      en: { translation: en },
      fi: { translation: fi }
    },
    lng: sessionStorage.getItem("language") || "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
    // react: {
    // useSuspense: true
    // }
  });

*/

export const initI18n = locale => {
  const language = locale || "en";
  i18n.use(initReactI18next).init({
    resources: {
      en: { translation: en },
      fi: { translation: fi }
    },
    lng: language,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });
};
