import { makeAutoObservable, reaction } from "mobx";

import { applicationType } from "b2c/constants/main";
import * as State from "utils/storeHelpers";
import API from "../../utils/api";

class Application {
  setAppState;

  getUserStatus;

  toggleNavigation;

  removeApplication;

  constructor(setAppState, getUserStatus, toggleNavigation, removeApplication) {
    makeAutoObservable(this);

    this.setAppState = setAppState;
    this.getUserStatus = getUserStatus;
    this.toggleNavigation = toggleNavigation;
    this.removeApplication = removeApplication;

    reaction(
      () => this.state,
      localState => {
        this.setAppState(localState);
      }
    );
  }

  state = {
    loading: false,
    processing: false,
    error: false,
    message: ""
  };

  steps = [];

  application;

  job_details = {};

  educationsItems = [];

  professionalCertificates = [];

  questions = [];

  questionsErrors = [];

  questionAnswers = [];

  attachments;

  validation_errors = {};

  modalType = "leave";

  submitted = false;

  isInviteToApplyJob = false;

  applicationAttachmentsErrors = {};

  getApplicationFlow = async id => {
    this.state = State.setLoading(this.state);
    this.job_details.id = id;

    this.application = undefined;
    this.submitted = false;

    return API.getApplicationFlow(id)
      .then(res => {
        this.submitted = res.data.meta.application?.submit_step?.completed;
        this.steps = res.data.steps;
        const completed = res.data.meta.application?.submit_step?.completed;
        this.toggleNavigation({ desktop: completed, mobile: completed });
        this.application = res.data.meta.application;

        this.state = State.setNeutral(this.state);
      })
      .catch(err => {
        this.steps = [];
        this.application = null;
        this.job_details = {};

        this.state = State.setError(this.state, err, "Could not retrieve application data", () =>
          this.getApplicationFlow(id)
        );
      });
  };

  initializeApplication = async (id, force = false, isRemoveUploadCv = true) => {
    if ((this.job_details.id != id && this.job_details.slug != id) || force) {
      this.state = State.setLoading(this.state);
      this.sections = [];
      this.job_details.id = id;
      const uploadCv = this.attachments?.uploaded_cv;

      this.application = undefined;
      this.attachments = undefined;
      if (!isRemoveUploadCv && uploadCv) {
        this.attachments = {};
        this.attachments.uploaded_cv = uploadCv;
      }
      this.submitted = false;

      return Promise.all([API.getApplicationFlow(id), API.getJobDetails(id)])
        .then(res => {
          this.submitted = res[0].data.meta.application?.submit_step?.completed;
          this.steps = res[0].data.steps;
          this.job_details = res[1].data.job;
          const completed = res[0].data.meta.application?.submit_step?.completed;
          this.toggleNavigation({ desktop: completed, mobile: completed });
          this.application = res[0].data.meta.application;

          this.state = State.setNeutral(this.state);
        })
        .catch(err => {
          this.steps = [];
          this.application = null;
          this.job_details = {};

          this.state = State.setError(this.state, err, "Could not retrieve application data", () =>
            this.initializeApplication(id)
          );
        });
    }
    return Promise.resolve();
  };

  updateCoverLetter = letter => {
    this.state = State.setProcessing(this.state);
    return API.updateCoverLetter(this.job_details.id, this.application.id, letter)
      .then(res => {
        this.state = State.setSuccess(this.state, res, "Successfully updated cover letter");
        this.steps = res?.data?.steps;
        this.application.cover_letter = letter;
        this.completeStep("cover_letter");

        return true;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to update cover letter", () =>
          this.updateCoverLetter(letter)
        );
      });
  };

  getAttachments = async id => {
    if (!this.application) await this.initializeApplication(id);
    return API.getAttachments(id || this.job_details.id)
      .then(res => {
        this.validation_errors = {};
        this.attachments = res?.data?.attachments;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve application attachments", () =>
          this.getAttachments()
        );
      });
  };

  getApplicationFlowQuestions = id => {
    this.state = State.setProcessing(this.state);

    return API.getApplicationFlowQuestions(id || this.job_details.id)
      .then(res => {
        this.questionsErrors = [];
        this.questions = res.data.questions;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve application attachments", () =>
          this.getAttachments()
        );
      });
  };

  postApplicationFlowQuestions = (id, query) => {
    this.state = State.setProcessing(this.state);

    return API.postApplicationFlowQuestions(id || this.job_details.id, query)
      .then(res => {
        this.questionsErrors = [];
        this.state = State.setNeutral(this.state, res);
        this.getApplicationFlow(id || this.job_details.id);
        return res.data;
      })
      .catch(err => {
        this.questionsErrors = err.response.data.errors.answers.options;

        this.state = State.setError(this.state, err, "Failed to retrieve application attachments", () =>
          this.postApplicationFlowQuestions(id || this.job_details.id, query)
        );
        return err.response.data;
      });
  };

  getApplicationFlowAnswers = id => {
    this.state = State.setProcessing(this.state);

    return API.getApplicationFlowAnswers(id || this.job_details.id)
      .then(result => {
        this.questionAnswers = result.data.answers?.length
          ? result.data.answers.reduce((acc, current) => {
              acc[current.question_id] = current;
              return acc;
            }, {})
          : {};

        this.state = State.setNeutral(this.state, result);
        return result.data;
      })
      .catch(err => {
        this.questionsErrors = err.response.data.errors.answers.options;

        this.state = State.setError(this.state, err, "Failed to retrieve application attachments", () =>
          this.getApplicationFlowAnswers(id || this.job_details.id)
        );
        return err.response.data;
      });
  };

  cleanApplicationFlowQuestions = () => {
    this.questions = [];
  };

  getAttachmentsEducationItems = id => {
    return API.getAttachmentsEducationItems(id || this.job_details.id)
      .then(res => {
        this.educationsItems = res.data.education_items;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve application attachments", () =>
          this.getAttachmentsEducationItems(id)
        );
      });
  };

  getAttachmentsProfessionalCertificates = id => {
    return API.getAttachmentsProfessionalCertificates(id || this.job_details.id)
      .then(res => {
        this.professionalCertificates = res.data.professional_certificates;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve application attachments", () =>
          this.getAttachmentsProfessionalCertificates(id)
        );
      });
  };

  updateAttachments = ({ uploaded_cv, education_certificates, professional_certificates }) => {
    if (uploaded_cv?.name || education_certificates?.length || professional_certificates?.length) {
      const formData = new FormData();
      if (uploaded_cv?.name) {
        formData.append("attachments[uploaded_cv]", uploaded_cv);
      }

      if (education_certificates?.length) {
        education_certificates.map(item => {
          for (const key in item)
            if (item[key] && !item[key].url) {
              formData.append(`attachments[education_certificates][][${key}]`, item[key]);
            }
        });
      }

      if (professional_certificates?.length) {
        professional_certificates.map(item => {
          for (const key in item)
            if (item[key] && !item[key].url) {
              formData.append(`attachments[professional_certificates][][${key}]`, item[key]);
            }
        });
      }

      this.state = State.setProcessing(this.state);
      this.state = State.setLoading(this.state);

      return API.updateAttachments(this.job_details.id, formData)
        .then(res => {
          this.steps = res?.data?.steps;
          this.validation_errors = {};
          this.state = State.setSuccess(this.state, res, "Successfully updated application attachments");
          this.completeStep("attachments");
          this.applicationAttachmentsErrors = {};

          return true;
        })
        .catch(err => {
          if (err.response?.status === 422 && err.response.data?.errors?.attachments) {
            this.applicationAttachmentsErrors = err.response.data?.errors?.attachments;
          } else {
            this.applicationAttachmentsErrors = {};
          }

          this.validation_errors = err?.response?.data?.errors;
          this.state = State.setError(this.state, err, "Failed to update application attachments", () =>
            this.updateAttachments({ uploaded_cv, education_certificates })
          );
        });
    }
    return Promise.resolve(true);
  };

  deleteEducationCertificate = certficateId => {
    this.state = State.setProcessing(this.state);
    this.state = State.setLoading(this.state);

    return API.deleteEducationCertificate(certficateId)
      .then(res => {
        this.getAttachmentsEducationItems(this.job_details.id);
        this.state = State.setSuccess(this.state, res, "Successfully removed attachment");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to update application attachments", () =>
          this.deleteEducationCertificate(certficateId)
        );
      });
  };

  cancelApplication = (job_id, app_id) => {
    this.state = State.setProcessing(this.state);
    return API.cancelApplication(job_id || this.job_details?.id, app_id || this.application?.id)
      .then(res => {
        this.clearApplication();
        this.removeApplication(job_id || this.job_details.id);
        this.state = State.setSuccess(this.state, res, "Application was cancelled successfully");

        return true;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to cancel application", () =>
          this.cancelApplication(job_id, app_id)
        );
      });
  };

  submitApplication = (jobId, appId, isInviteToApply, isExternal) => {
    if (!this.submitted || isExternal) {
      this.state = State.setLoading(this.state);
      const inviteToApplyParams = isInviteToApply ? { from_invite_message: true } : {};
      return API.submitApplication(this.job_details.id, this.application.id, inviteToApplyParams)
        .then(res => {
          this.state = State.setNeutral(this.state, res);

          return true;
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Failed to submit application", () => this.submitApplication());
        });
    }
    return Promise.resolve(false);
  };

  clearApplication = () => {
    this.application = undefined;
    this.job_details = {};
    this.validation_errors = {};
    this.toggleNavigation({ desktop: true, mobile: true });

    return Promise.resolve(true);
  };

  cancelSubmission = () => {
    this.submitted = false;
    return Promise.resolve();
  };

  completeStep = step => {
    const tempSteps = [...this.steps];
    const found = tempSteps.find(({ variety }) => variety == step);
    if (found) {
      const foundIndex = tempSteps.indexOf(found);
      tempSteps[foundIndex] = { ...found, completed: true };

      this.steps = tempSteps;
    }
  };

  switchModalType = type => {
    if (type === applicationType.submit) {
      this.submitted = false;
    }

    this.modalType = type;
  };

  setIsInviteToApplyJob = isActive => {
    this.isInviteToApplyJob = isActive;
  };
}

export default Application;
