import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import { Row, Col } from "react-grid-system";
import styled from "styled-components";

import Diamond from "images/b2c/icons/diamond.svg";
import ProfileCVIco from "images/b2c/icons/profileCVIco.svg";
import DeskIco from "images/b2c/icons/deskIco.svg";
import BrainIco from "images/b2c/icons/brainIco.svg";
import promoWoman from "images/b2c/landing-page/promoWoman.png";
import { Image, Label, Button, Title, Text, List, Card } from "../Core";
import Product from "../Product";
import useStore from "../../contexts/store";
import { useTheme } from "../../contexts/theme";

const GetInstanceFeedbackContent = ({ position_id, onClose, close }) => {
  const { productDetails } = useStore("Payment");
  const { pathname } = useLocation();
  const product =
    productDetails[position_id ? "position-specific-application-booster" : "global-application-booster-30-days"];
  const theme = useTheme();

  useEffect(() => {
    return () => {
      onClose && onClose();
    };
  }, []);

  return (
    <StyledContent>
      <Card
        style={{
          backgroundImage: `linear-gradient(180deg, ${theme.primary} 0%, #F3A900 100%)`,
          marginBottom: 15
        }}
        wideOnMobile
      >
        <Card.Content padding="16px">
          <Row>
            <Col>
              <Label
                theme="primary300"
                color="black500"
                fill="black500"
                fontWeight={500}
                margin="0 0 10px"
                Icon={<Diamond width={16} />}
              >
                Premium
              </Label>
            </Col>
            <Col xs="content">
              <Title level="5" fontWeight={700}>
                Instant Feedback gives you guidance you deserve
              </Title>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Image src={promoWoman} />
            </Col>
            <Col sm={8}>
              <List>
                <List.Item bulletColor="black500">
                  You will receive detailed feedback on your Fuzu profile and how it matches with the requirements of
                  the employer.
                </List.Item>
                <List.Item bulletColor="black500">
                  You will also be able to see how your profile compares with those of the top candidates.
                </List.Item>
              </List>
              <Button.Link
                to={{
                  pathname: `/jobs/${position_id}/product/position-specific-application-booster`,
                  state: { discounted: true, from: pathname }
                }}
                theme="accent300"
                width="100%"
                margin="25px 0"
                processing={!product}
                onClick={close}
              >
                From {product?.currency} {product?.discounted_price}
              </Button.Link>
            </Col>
          </Row>
        </Card.Content>
      </Card>

      <Card theme="grey100" style={{ marginBottom: 15 }}>
        <Card.Content padding="16px">
          <Row align="center">
            <Col xs="content">
              <DeskIco />
            </Col>
            <Col>
              <Text>
                You will get guidance on how to improve your chances and what gaps your Fuzu profile may have.
              </Text>
            </Col>
          </Row>
        </Card.Content>
      </Card>
      <Card theme="grey100" style={{ marginBottom: 15 }}>
        <Card.Content padding="16px">
          <Row align="center">
            <Col xs="content">
              <BrainIco />
            </Col>
            <Col>
              <Text>
                You will be able to compare your professional personality and mindset to that of other top candidates.
              </Text>
            </Col>
          </Row>
        </Card.Content>
      </Card>
      <Card theme="grey100" style={{ marginBottom: 15 }}>
        <Card.Content padding="16px">
          <Row align="center">
            <Col xs="content">
              <ProfileCVIco />
            </Col>
            <Col>
              <Text>
                You will be able to compare your Fuzu Profile to those of the top candidates and get valuable insights
                and ideas for the future.
              </Text>
            </Col>
          </Row>
        </Card.Content>
      </Card>

      <Row>
        {position_id && (
          <Col md={6}>
            <Product
              slug="position-specific-application-booster"
              position_id={position_id}
              onClick={close}
              style={{ marginBottom: 15 }}
            />
          </Col>
        )}
        <Col md={6}>
          <Product slug="global-application-booster-30-days" pro onClick={close} style={{ marginBottom: 15 }} />
        </Col>
      </Row>
    </StyledContent>
  );
};

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 690px;
`;

export default observer(GetInstanceFeedbackContent);
