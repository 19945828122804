import React, { createContext } from "react";

const NotificationContext = createContext({});

export class NotificationProvider extends React.Component {
  setNotification = (content, color = "primary200", onClose) => {
    this.setState({ ...this.state, content, color, onClose });
  };

  state = {
    content: null,
    color: "primary200",
    onClose: null,
    setNotification: this.setNotification
  };

  render() {
    return <NotificationContext.Provider value={this.state}>{this.props.children}</NotificationContext.Provider>;
  }
}

export default NotificationContext;
