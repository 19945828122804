import React from "react";

import notification from "images/notification.png";
import Notification from "../NotificationModal";
import useStore from "../../contexts/store";
import { useModal } from "../../../utils/hooks";
import NotificationModalAllowNotifySuccess from "../NotificationModalAllowNotifySuccess";
import { notificationRequestPermission } from "../../utils/helpers";

const NotificationModalAllowNotify = ({ close }) => {
  const openNotificationModalAllowNotifySuccess = useModal(<NotificationModalAllowNotifySuccess />, "");

  const {
    Profile: { userDevice, updateUserDevice },
    setNotificationPrompt
  } = useStore("User");

  const callback = () => {
    updateUserDevice(userDevice.deviceId, { allow_push_notifications: !userDevice.allowPushNotifications });
    setNotificationPrompt(openNotificationModalAllowNotifySuccess);
  };

  const onSetNotificationPrompt = () => {
    notificationRequestPermission(callback);
  };

  const onClose = () => {
    setNotificationPrompt();
  };

  return (
    <Notification
      close={close}
      imgUrl={notification}
      title="Would you like to get alerted about suitable jobs or messages from employers?"
      onClick={onSetNotificationPrompt}
      onClose={onClose}
    />
  );
};

export default NotificationModalAllowNotify;
