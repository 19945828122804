import { makeAutoObservable, reaction } from "mobx";
import * as State from "utils/storeHelpers";
import API from "../../utils/api";

class LandingStore {
  setAppState;

  state = {
    loading: false,
    type: "",
    message: ""
  };

  jobCategories;

  popularArticles;

  popularCourses;

  filters;

  trendingCompanies;

  towns;

  stats;

  guestLayouts = {};

  constructor(
    { categories, companies, filters, popular_articles, popular_courses, towns, stats, seo_categories } = {},
    setAppState
  ) {
    makeAutoObservable(this);

    this.setAppState = setAppState;
    this.jobCategories = categories;
    this.filters = filters;
    this.popularArticles = popular_articles;
    this.trendingCompanies = companies;
    this.popularCourses = popular_courses;
    this.seoCategories = seo_categories;
    this.towns = towns;
    this.stats = stats;

    reaction(
      () => this.state,
      localState => {
        this.setAppState(localState);
      }
    );
  }

  initializeEmployerLanding = id => {
    this.state = State.setLoading(this.state);

    return Promise.all([!this.trendingCompanies ? API.getTrendingCompanies(id) : Promise.resolve()])
      .then(res => {
        this.trendingCompanies = res[0].data.companies;
        this.state = State.setNeutral(this.state, res[0]);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not retrieve page data");
      });
  };

  // remove after test and realise
  initializeLanding = id => {
    this.state = State.setLoading(this.state);
    return Promise.all([
      !this.filters ? API.getJobFilters(id) : Promise.resolve(),
      !this.trendingCompanies ? API.getTrendingCompanies(id) : Promise.resolve(),
      !this.towns ? API.getCountryTowns(id) : Promise.resolve(),
      !this.stats ? API.getLandingStats() : Promise.resolve(),
      !this.seoCategories ? API.getSEOCategories(id) : Promise.resolve(),
      !this.guestLayouts ? API.getSEOCategories(id) : Promise.resolve()
    ])
      .then(res => {
        this.filters = res[0].data.filters;
        this.trendingCompanies = res[1].data.companies;
        this.towns = res[2].data.towns;
        this.stats = res[3]?.data?.stats;
        this.seoCategories = res[4]?.data?.seo_categories || [];

        this.state = State.setNeutral(this.state, res[1]);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not retrieve page data");
      });
  };

  getGuestLandingPage = () => {
    this.state = State.setLoading(this.state);

    return API.getGuestLandingPage()
      .then(res => {
        this.guestLayouts = res?.data;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not retrieve trending companies");
      });
  };

  getPageInfo = () => {
    this.state = State.setLoading(this.state);

    return API.getPageInfoLanding()
      .then(res => {
        this.filters = res.data.page_info.filters;
        this.setTrendingCompanies(res.data.page_info.companies);
        this.seoCategories = res.data.page_info.seo_categories;
        this.towns = res.data.page_info.towns;
        this.stats = res.data.page_info.stats;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not retrieve trending companies");
      });
  };

  getTrendingCompanies = id => {
    this.state = State.setLoading(this.state);

    return API.getTrendingCompanies(id)
      .then(res => {
        this.setTrendingCompanies(res.data.companies);

        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not retrieve trending companies");
      });
  };

  setTrendingCompanies = companies => {
    this.trendingCompanies = companies;
  };
}

export default LandingStore;
