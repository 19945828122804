import React from "react";
import { StyledLink } from "./styled";

const OptionAsLink = ({ href, children, ...rest }) => {
  if (!href) return children;

  return (
    <StyledLink href={href} {...rest}>
      {children}
    </StyledLink>
  );
};

export default OptionAsLink;
