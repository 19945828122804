const APP_UTM_PATTERN = /utm_source=pwa_app_homescreen/;

export const isStandaloneMode = () => {
  /* We do not support iPhones, but we will try to keep the consistency of the logic */
  const isiOSStandalone = window.navigator.standalone === true;
  const isChromeStandalone = window.matchMedia("(display-mode: standalone)").matches;

  return isiOSStandalone || isChromeStandalone;
};

/* Just in case, if we want to distinguish the behavior of a standard PWA from a PWA launched through Google Play */
export const isStandaloneAppFlow = () => {
  const storedFlag = window?.localStorage.getItem("isStandaloneAppFlow") === "true";

  if (!storedFlag && APP_UTM_PATTERN.test(window.location.search)) {
    window?.localStorage.setItem("isStandaloneAppFlow", "true");

    return true;
  }

  return storedFlag;
};
