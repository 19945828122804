import { FacebookSquare, InstagramAlt, LinkedinSquare, Twitter, Youtube } from "@styled-icons/boxicons-logos";

export const notificationPermissionTypes = {
  granted: "granted",
  denied: "denied",
  default: "default"
};

export const list = ["Fast job alerts", "Chat with employers", "Use Fuzu offline"];

export const socialGlobal = [
  {
    icon: FacebookSquare,
    href: "https://www.facebook.com/fuzultd",
    "aria-label": "Facebook"
  },
  {
    icon: Twitter,
    href: "https://twitter.com/fuzukenya",
    "aria-label": "Twitter"
  },
  {
    icon: LinkedinSquare,
    href: "https://www.linkedin.com/company/fuzu-oy/",
    "aria-label": "Linkedin"
  },
  {
    icon: Youtube,
    href: "https://www.youtube.com/channel/UCDXBg1wJ5uPCVq_qpNSxaqQ",
    "aria-label": "Youtube"
  }
];

export const socialBarona = [
  {
    icon: FacebookSquare,
    href: "https://www.facebook.com/barona.international",
    "aria-label": "Facebook"
  },
  {
    icon: InstagramAlt,
    href: "https://www.instagram.com/baronasuomi",
    "aria-label": "Instagram"
  },
  {
    icon: Twitter,
    href: "https://twitter.com/baronasuomi",
    "aria-label": "Twitter"
  },
  {
    icon: LinkedinSquare,
    href: "https://www.linkedin.com/company/244117",
    "aria-label": "Linkedin"
  },
  {
    icon: Youtube,
    href: "https://www.youtube.com/@baronasuomi/featured",
    "aria-label": "Youtube"
  }
];

export const socialKenya = [
  {
    icon: FacebookSquare,
    href: "https://www.facebook.com/fuzultd",
    "aria-label": "Facebook"
  },
  {
    icon: InstagramAlt,
    href: "https://www.instagram.com/fuzukenya",
    "aria-label": "Instagram"
  },
  {
    icon: Twitter,
    href: "https://twitter.com/fuzukenya",
    "aria-label": "Twitter"
  },
  {
    icon: LinkedinSquare,
    href: "https://www.linkedin.com/company/fuzu-oy/",
    "aria-label": "Linkedin"
  },
  {
    icon: Youtube,
    href: "https://www.youtube.com/channel/UCDXBg1wJ5uPCVq_qpNSxaqQ",
    "aria-label": "Youtube"
  }
];

export const socialNigeria = [
  {
    icon: FacebookSquare,
    href: "https://www.facebook.com/fuzunigeria/",
    "aria-label": "Facebook"
  },
  {
    icon: InstagramAlt,
    href: "https://www.instagram.com/fuzunigeria/",
    "aria-label": "Instagram"
  },
  {
    icon: Twitter,
    href: "https://twitter.com/fuzunigeria",
    "aria-label": "Twitter"
  },
  {
    icon: LinkedinSquare,
    href: "https://www.linkedin.com/company/fuzu-nigeria/",
    "aria-label": "Linkedin"
  },
  {
    icon: Youtube,
    href: "https://www.youtube.com/channel/UCDXBg1wJ5uPCVq_qpNSxaqQ",
    "aria-label": "Youtube"
  }
];

export const socialUganda = [
  {
    icon: FacebookSquare,
    href: "https://www.facebook.com/fuzultd",
    "aria-label": "Facebook"
  },
  {
    icon: InstagramAlt,
    href: "https://www.instagram.com/fuzu_uganda/",
    "aria-label": "Instagram"
  },
  {
    icon: Twitter,
    href: "https://twitter.com/FuzuUganda",
    "aria-label": "Twitter"
  },
  {
    icon: LinkedinSquare,
    href: "https://www.linkedin.com/company/fuzu-oy/",
    "aria-label": "Linkedin"
  },
  {
    icon: Youtube,
    href: "https://www.youtube.com/channel/UCDXBg1wJ5uPCVq_qpNSxaqQ",
    "aria-label": "Youtube"
  }
];

export const countryKeys = {
  kenya: "kenya",
  nigeria: "nigeria",
  uganda: "uganda"
};

export const applicationType = {
  submit: "submit"
};

export const inputTypes = {
  text: "text",
  password: "password"
};

export const languageFormat = {
  en: "en-US",
  fi: "fi-Fi"
};

export const baronaEventName = {
  viewItem: "view_item",
  addToCard: "add_to_card",
  purchase: "purchase"
};
