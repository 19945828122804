import qs from "qs";
import { COMMON_STRINGIFY_PARAMS } from "../../constants/commonStringifyParams";

export const stringify = (data, params = {}) => qs.stringify(data, { ...COMMON_STRINGIFY_PARAMS, ...params });

export const generate = (filters, params) => {
  const filterString = stringify({ filters });
  const paramString = stringify(params);
  return `${filterString ? `${filterString}&` : ""}${paramString}`;
};
