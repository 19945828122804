import axios from "axios";

// Cv builder

const builder_path = "/api/v1/browse/cvs";

export const getBuilderTemplates = () => axios.get(`${builder_path}/templates`);

export const getBuilderOptions = template_id => axios.get(`${builder_path}/options?template_id=${template_id}`);

export const applyBuilderOptions = body => axios.post(`${builder_path}/apply`, body);

export const loadCv = () => axios.get(`${builder_path}/load`);
