import { makeAutoObservable } from "mobx";

import API from "../../utils/api";
import { brandName } from "../../constants/brand";
import CSRFManager from "../../../utils/defaults/csrfToken";

class AuthorizationStore {
  appState;

  track;

  constructor(userStore, authenticationChannel, appState, track) {
    makeAutoObservable(this);

    this.appState = appState;
    this.userStore = userStore;
    this.track = track;
    this.authenticationChannel = authenticationChannel;
  }

  validation_errors = {};

  interest_areas;

  seniority_levels;

  career_interests;

  countries;

  userLogin = (valid, user, context) => {
    this.track("login_trial", { method: "email", ...context });
    if (valid) {
      this.validation_errors = {};
      this.userStore.setState({ processing: true });

      return API.userLogin(user, this.authenticationChannel.slug)
        .then(res => {
          if (res?.data?.csrf_meta_tags?.csrf_token) {
            CSRFManager.setCsrfToken(res.data.csrf_meta_tags.csrf_token);
          }

          this.userStore.resetState();
          this.userStore.updateUser(res?.data?.user);
          this.resetValidationErrors();
          this.track("login", { method: "email", ...context });

          return res?.data?.user;
        })
        .catch(err => {
          this.userStore.setState({
            loading: false,
            processing: false,
            type: "error",
            status: err?.response ? err?.response.status : 500,
            action: () => this.userLogin(valid, user, context),
            message: err?.response?.data?.error || "Failed to log in"
          });

          if (err?.response?.data?.errors) {
            this.validation_errors = err?.response?.data?.errors;
          }

          return false;
        });
    }
    return Promise.resolve();
  };

  userLogout = () => {
    return API.userLogout()
      .then(() => {
        this.userStore.resetState();
        this.resetValidationErrors();
        this.userStore.resetUser();
      })
      .catch(err => {
        this.userStore.setState({
          loading: false,
          type: "error",
          status: err?.response ? err?.response.status : 500,
          action: () => this.userLogout(),
          message: err?.response ? err?.response?.data?.error : "Failed to log out"
        });
      });
  };

  resetValidationErrors = () => {
    this.validation_errors = {};
  };

  fetchRegistrationSteps = () =>
    Promise.all([
      !this.seniority_levels ? this.getSeniorityLevels() : Promise.resolve(),
      !this.career_interests ? this.getCareerInterests() : Promise.resolve(),
      !this.interest_areas ? this.getInterestAreas() : Promise.resolve()
    ]);

  userRegister = (
    valid,
    {
      first_name: firstname,
      last_name: lastName,
      email,
      password,
      phone_number,
      passport_number,
      confirmation: password_confirmation,
      fuzu_country_id,
      visible: talent_pool_visibility,
      terms: terms_privacy_consent
    },
    ncpwd,
    refer_code,
    isJkuat
  ) => {
    this.track("sign_up_trial", { flow: "old", method: "email" });
    if (valid) {
      this.validation_errors = {};
      this.userStore.setState({ processing: true });
      const lastNameValue = isJkuat ? { lastname: lastName } : { lastName };

      return API.userRegister({
        user: {
          firstname,
          email,
          ...lastNameValue,
          password,
          password_confirmation,
          phone_number,
          fuzu_country_id,
          passport_number
        },
        talent_pool_visibility,
        terms_privacy_consent,
        refer_code,
        ncpwd,
        authentication_channel: this.authenticationChannel.slug
      })
        .then(res => {
          this.userStore.resetState();
          this.resetValidationErrors();
          this.userStore.updateUser(res?.data?.user);
          this.track("sign_up", { flow: "old", method: "email" });

          if (res?.data?.user?.brand?.slug === brandName.jkuat) {
            setTimeout(() => {
              window.location.reload();
            }, 10);
          }

          return true;
        })
        .catch(err => {
          this.userStore.setState({
            loading: false,
            processing: false,
            type: "error",
            status: err?.response ? err?.response.status : 500,
            action: () =>
              this.userRegister({
                user: { firstname, email, password, password_confirmation, phone_number, fuzu_country_id },
                talent_pool_visibility,
                terms_privacy_consent,
                refer_code,
                ncpwd
              }),
            message: err?.response ? err?.response?.data?.error : "Failed to sign up"
          });

          if (err?.response?.data?.errors) this.validation_errors = err?.response?.data?.errors;
        });
    }
    return Promise.resolve();
  };

  userDetails = form => {
    // this.userStore.setState({ loading: true, message: '' });
    this.validation_errors = {};
    this.userStore.setState({ processing: true });

    return API.userDetails(form)
      .then(res => {
        this.userStore.resetState();
        this.userStore.updateUser(res?.data?.user);
        this.resetValidationErrors();

        return true;
      })
      .catch(err => {
        this.userStore.setState({
          loading: false,
          processing: false,
          type: "error",
          status: err?.response ? err?.response.status : 500,
          action: () => this.userDetails(form),
          message: err?.response ? err?.response?.data?.error : "Failed to retrieve user details"
        });

        if (err?.response?.data?.errors) this.validation_errors = err?.response?.data?.errors;

        return false;
      });
  };

  ncpwdValidateUser = form => {
    this.userStore.setState({ processing: true, message: "" });
    return API.ncpwdValidateUser(form)
      .then(res => {
        this.userStore.resetState();
        this.userStore.updateUser(res?.data?.user);

        return true;
      })
      .catch(err => {
        this.userStore.setState({
          loading: false,
          processing: false,
          type: "error",
          status: err?.response ? err?.response.status : 500,
          action: () => this.ncpwdValidateUser(form),
          message: err?.response ? err?.response?.data?.error : "Failed to validate user"
        });

        return false;
      });
  };

  userResetPassword = email => {
    this.userStore.setState({ processing: true });

    return API.userResetPassword(email)
      .then(res => {
        this.userStore.setState({
          loading: false,
          processing: false,
          type: "success",
          message: res?.data?.success
        });
        this.resetValidationErrors();

        return true;
      })
      .catch(err => {
        this.userStore.setState({
          loading: false,
          processing: false,
          type: "error",
          status: err?.response ? err?.response.status : 500,
          action: () => this.userResetPassword(email),
          message: err?.response ? err?.response?.data?.error : "Failed to reset user password"
        });

        return false;
      });
  };

  userUpdatePassword = form => {
    this.validation_errors = {};
    this.userStore.setState({ processing: true });

    API.userUpdatePassword(form)
      .then(res => {
        this.userStore.updateUser(res?.data?.user);
        this.resetValidationErrors();

        this.userStore.setState({
          loading: false,
          processing: false,
          type: "success",
          status: res.status,
          message: res?.data?.success || "Password updated successfully"
        });
      })
      .catch(err => {
        if (err?.response?.data?.error)
          this.userStore.setState({ message: err?.response?.data?.error, processing: false });
        if (err?.response?.data?.errors) this.validation_errors = err?.response?.data?.errors;
      });
  };

  responseOAuth = (provider, code, { source, channel, details, referCode }) => {
    const callback = (() => {
      switch (provider) {
        default:
        case "facebook":
          return API.facebookCallback;
        case "google":
          return API.googleCallback;
        case "linkedin":
          return API.linkedinCallback;
      }
    })();
    this.track("login_trial", { flow: "old", method: provider, context: source });

    return callback(code, channel, details, referCode)
      .then(res => {
        this.getCountries(channel);
        this.userStore.updateUser(res?.data?.user);
        this.track("login", { method: provider, context: source });

        return res?.data;
      })
      .catch(err => {
        const data = err?.response?.data;
        this.userStore.setState({
          loading: false,
          type: "error",
          status: err?.response?.status || 500,
          action: () => this?.responseOAuth(provider, code, { referCode }),
          message: data.error || "Could not retrieve user data"
        });
        if (data.state) throw err;
      });
  };

  responseOAuthError = res => {
    console.error("Error", res);
  };

  getCountries = channel => {
    return API.getCountries(channel)
      .then(res => {
        this.countries = res?.data?.countries;
      })
      .catch(err => {
        this.userStore.setState({
          loading: false,
          type: "error",
          status: err?.response ? err?.response.status : 500,
          action: () => this.getCountries(),
          message: "Could not retrieve country list"
        });
      });
  };

  getInterestAreas = () => {
    return API.getInterestAreas()
      .then(res => {
        this.interest_areas = res?.data?.interest_areas;
      })
      .catch(err => {
        this.userStore.setState({
          loading: false,
          type: "error",
          status: err?.response ? err?.response.status : 500,
          action: () => this.getInterestAreas(),
          message: "Could not retrieve interest areas"
        });
      });
  };

  getCareerInterests = () => {
    return API.getCareerInterests()
      .then(res => {
        this.career_interests = res?.data?.career_interests;
      })
      .catch(err => {
        this.userStore.setState({
          loading: false,
          type: "error",
          status: err?.response ? err?.response.status : 500,
          action: () => this.getCareerInterests(),
          message: "Could not retrieve career interests"
        });
      });
  };

  getSeniorityLevels = () => {
    return API.getSeniorityLevels()
      .then(res => {
        this.seniority_levels = res?.data?.seniority_levels;
      })
      .catch(err => {
        this.userStore.setState({
          loading: false,
          type: "error",
          status: err?.response ? err?.response.status : 500,
          action: () => this.getSeniorityLevels(),
          message: "Could not retrieve seniority levels"
        });
      });
  };

  updateUserSteps = form => {
    this.userStore.setState({ loading: true, processing: true });

    return API.updateUserSteps(form)
      .then(res => {
        this.userStore.updateUser(res?.data?.user);
        this.userStore.resetState();
        this.resetValidationErrors();
        this.track("sign_up_onboarding_complete", { flow: "old" });
      })
      .catch(err => {
        this.userStore.setState({
          loading: false,
          processing: false,
          type: "error",
          status: err?.response ? err?.response.status : 500,
          action: () => this.updateUserSteps(form),
          message: "Failed to update user information"
        });
      });
  };

  checkEmail = email => {
    this.userStore.setState({ processing: true });

    return API.checkEmail(email)
      .then(res => {
        this.userStore.resetState();
        return res?.data?.exists;
      })
      .catch(err => {
        this.userStore.setState({
          loading: false,
          processing: false,
          type: "error",
          status: err?.response ? err?.response.status : 500,
          action: () => this.checkEmail(email),
          message: err?.response ? err.response.data.error : "Failed to check email"
        });
      });
  };

  ncpwdLogin = user => {
    this.userStore.setState({ processing: true });
    this.track("login_trial", { method: "email" });

    return API.ncpwdLogin(user)
      .then(res => {
        this.userStore.updateUser(res?.data?.user);
        this.userStore.setState({
          loading: false,
          processing: false,
          type: "success",
          message: res?.data?.success || "Login successful"
        });
        this.resetValidationErrors();
        this.track("login", { method: "email" });
      })
      .catch(err => {
        this.userStore.setState({
          loading: false,
          processing: false,
          type: "error",
          status: err?.response ? err?.response.status : 500,
          action: () => this.ncpwdLogin(user),
          message: err?.response?.data?.error
        });
      });
  };
}

export default AuthorizationStore;
