export const brandTypes = {
  whitelabelled: "whitelabelled",
  customPlatform: "custom_platform",
  normal: "normal"
};

export const brandName = {
  jkuat: "jkuates",
  ncpwd: "ncpwd",
  barona: "Barona",
  kepsa: "KEPSA Ajira"
};
