import { Buildings } from "@styled-icons/boxicons-solid";

const importAll = require =>
  require.keys().reduce((acc, next) => {
    const cut = next.replace("./", "");

    acc[cut.slice(0, cut.indexOf("."))] = require(next);
    return acc;
  }, {});

const importAllIcons = require =>
  require.keys().reduce((acc, next) => {
    const cut = next.replace("./", "");
    const Icon = require(next).default;
    acc[cut.slice(0, cut.indexOf("."))] = Icon;
    return acc;
  }, {});

const icons = importAllIcons(require.context("@images/industries-icons", false, /\.svg$/));

const backgrounds = importAll(require.context("@images/industries", false, /\.(png|jpe?g|svg)$/));

const categories = importAll(require.context("@images/categories", false, /\.(png|jpe?g|svg)$/));

const getIndustryStyles = industry => {
  switch (industry) {
    default:
      return { Icon: Buildings, background: backgrounds.financial_services };
    case "Energy, utilities, environment":
      return { Icon: icons.energy_utilities, background: backgrounds.energy_utilities };
    case "Health care, medical":
      return { Icon: icons.health_care, background: backgrounds.health_care };
    case "Manufacturing":
      return { Icon: icons.manufacturing, background: backgrounds.manufacturing };
    case "Education, academic":
      return { Icon: icons.education_academic, background: backgrounds.education_academic };
    case "Banking, insurance":
      return { Icon: icons.banking_insurance, background: backgrounds.banking_insurance };
    case "Non-profit, social work":
      return { Icon: icons.non_profit_social, background: backgrounds.non_profit_social };
    case "Financial Services":
      return { Icon: icons.financial_services, background: backgrounds.financial_services };
    case "Fitness, well-being and lifestyle":
      return { Icon: icons.fitness, background: backgrounds.fitness };
    case "Automotive":
      return { Icon: icons.automotive, background: backgrounds.automotive };
    case "Arts, design":
      return { Icon: icons.arts_design, background: backgrounds.arts_design };
    case "Outsourcing, leasing":
      return { Icon: icons.outsourcing_leasing, background: backgrounds.outsourcing_leasing };
    case "Computers, software development and services":
      return { Icon: icons.computers_software, background: backgrounds.computers_software };
    case "Construction, renovation, maintenance":
      return { Icon: icons.construction_renovation, background: backgrounds.construction_renovation };
    case "Communications, media, radio, tv":
      return { Icon: icons.communications_media, background: backgrounds.communications_media };
    case "Human resources, talent development, recruiting":
      return { Icon: icons.human_resources, background: backgrounds.human_resources };
    case "Import, export":
      return { Icon: icons.import_export, background: backgrounds.import_export };
    case "Agriculture, fishing, forestry":
      return { Icon: icons.agriculture_fishing, background: backgrounds.agriculture_fishing };
    case "Aeronautics":
      return { Icon: icons.aeronautics, background: backgrounds.aeronautics };
    case "Legal, accounting":
      return { Icon: icons.legal_accounting, background: backgrounds.legal_accounting };
    case "Entertainment, events":
      return { Icon: icons.entertainment_events, background: backgrounds.entertainment_events };
    case "Marketing, advertising":
      return { Icon: icons.marketing_advertising, background: backgrounds.marketing_advertising };
    case "Textile, fashion":
      return { Icon: icons.textile_fashion, background: backgrounds.textile_fashion };
    case "Telecommunications":
      return { Icon: icons.telecommunications, background: backgrounds.telecommunications };
    case "Security":
      return { Icon: icons.security, background: backgrounds.security };
    case "Restaurant, hospitality, travel":
      return { Icon: icons.restaurant_hospitality, background: backgrounds.restaurant_hospitality };
    case "Real estate":
      return { Icon: icons.real_estate, background: backgrounds.real_estate };
    case "Raw materials, oil, chemicals":
      return { Icon: icons.raw_materials, background: backgrounds.raw_materials };
    case "Housekeeping, maintenance":
      return { Icon: icons.housekeeping_maintenance, background: backgrounds.housekeeping_maintenance };
    case "Handicraft":
      return { Icon: icons.handicraft, background: backgrounds.handicraft };
    case "Governmental":
      return { Icon: icons.governmental, background: backgrounds.governmental };
    case "Engineering, architecture":
      return { Icon: icons.engineering_architecture, background: backgrounds.engineering_architecture };
    case "Electronics":
      return { Icon: icons.electronics, background: backgrounds.electronics };
    case "Beauty, cosmetics":
      return { Icon: icons.beauty_cosmetics, background: backgrounds.beauty_cosmetics };
    case "Transportation, logistics, storage":
      return { Icon: icons.transportation_logistics, background: backgrounds.transportation_logistics };
    case "Consulting, business support, auditing":
      return { Icon: icons.consulting_business, background: backgrounds.consulting_business };
    case "Retail, wholesale, FMCG":
      return { Icon: icons.retail_wholesale, background: backgrounds.retail_wholesale };
  }
};

export const getCategoryBackground = slug => categories[slug];

export default getIndustryStyles;
