import React, { useContext } from "react";

import { ScreenClassContext } from "react-grid-system";
import PropTypes from "prop-types";

import styled from "styled-components";
import cn from "classnames";
import ThemeContext from "../../../contexts/theme";

import GuestModeContext from "../../../contexts/guest";
import useStore from "../../../contexts/store";

const StyledTitle = styled.h1`
  ${props => props.styled}
`;

const Title = ({
  height,
  width,
  maxWidth,
  inline,
  color,
  margin,
  fontSize,
  fontWeight,
  lineHeight,
  textAlign,
  loading,
  loaded,
  truncatedLine,
  leftBordered,
  borderWidth = "5px",
  borderColor = "primary300",
  maxLength,
  children,
  level,
  scales = false,
  style,
  testScope,
  h1ForGuests = false,
  ...props
}) => {
  const Theme = useContext(ThemeContext);
  const { active: guest_mode_active } = useContext(GuestModeContext);
  const screen = useContext(ScreenClassContext);

  const useH1 = h1ForGuests && guest_mode_active;

  const isSm = ["sm", "xs"].includes(screen);

  const tagLevel = useH1 ? 1 : isSm && scales ? +level + 1 : level;
  const initialState = useStore("initialState");
  const globalBrandName = initialState?.global_brand?.name?.toLowerCase();

  return !loading ? (
    <StyledTitle
      className={`title ${loaded ? " loaded" : ""}`}
      data-test-scope={testScope}
      as={`h${tagLevel}`}
      style={{
        margin,
        height,
        width,
        maxWidth,
        fontSize,
        fontWeight,
        lineHeight,
        textAlign,
        color: Theme[color],
        ...(inline ? { display: "inline-flex" } : {}),
        ...(truncatedLine
          ? {
              display: "-webkit-box",
              "-webkit-line-clamp": truncatedLine.toString(),
              "-webkit-box-orient": "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }
          : {}),
        ...(leftBordered
          ? {
              borderLeft: `${borderWidth} solid ${Theme[borderColor]}`,
              padding: "0 0 0 12px"
            }
          : {}),
        ...style
      }}
      {...props}
    >
      {typeof children === "string" && maxLength
        ? children.length <= maxLength
          ? children
          : `${children.slice(0, maxLength)}...`
        : children}
    </StyledTitle>
  ) : (
    <StyledTitle
      className={cn("title placeholder", `placeholder_${globalBrandName}`)}
      as={`h${tagLevel}`}
      styled={{
        margin,
        width,
        ...(inline ? { display: "inline-flex" } : {}),
        ...props.style
      }}
    >
      ____________
    </StyledTitle>
  );
};

Title.defaultProps = {
  color: "black500",
  margin: "auto 0",
  fontWeight: 400,
  loading: false,
  loaded: false,
  level: 1
};

Title.propTypes = {
  color: PropTypes.string,
  margin: PropTypes.string,
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  level: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.oneOf(["1", "2", "3", "4", "5", "6"])])
};

export default Title;
