import styled from "styled-components";

export const PriceBadge = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -1px;
  top: -1px;
  width: 73px;
  height: 73px;
  font-size: 12px;
  color: white;
  border-radius: 5px 0 0 0;
  text-align: left;
  z-index: 1;
  overflow: hidden;
  padding: 5px;

  &:after {
    transform: skewx(135deg) translateX(-50%);
    content: "";
    background-color: #4690ff;
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: block;
  }
`;
