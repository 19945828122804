import styled from "styled-components";

export const StyledProgress = styled.progress`
  appearance: none;
  height: 5px;
  width: 100%;
  border-radius: 5px;
  border: none;
  background-color: ${({ $barColor }) => $barColor};
  ${({ $styled }) => $styled};
  &::-webkit-progress-bar {
    background-color: ${({ $barColor }) => $barColor};
    border-radius: 5px;
  }
  &::-webkit-progress-value {
    background-color: ${({ $filledColor }) => $filledColor};
    border-radius: 5px;
  }
  &::-moz-progress-bar {
    background-color: ${({ $filledColor }) => $filledColor};
    border-radius: 5px;
    border: none;
  }
`;
