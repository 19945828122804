import axios from "axios";

// Onboarding/Registartion flow

const onboarding_path = "/api/v1/browse/onboarding";

export const getInterestedJobs = (term, limit) =>
  axios.get(`${onboarding_path}/interested_jobs?term=${term}&limit=${limit}`);

export const updateInterestedJob = interested_job =>
  axios.post(`${onboarding_path}/interested_jobs`, { interested_job });

export const onboardingSignUp = (user, referCode, candidate) => {
  const params = {};
  if (referCode) {
    params.refer_code = referCode;
  }

  if (candidate) {
    params.candidate = candidate;
  }

  return axios.post(`${onboarding_path}/registration/union`, user, { params });
};

export const onboardingSignUpDetails = (user, referCode) => {
  const params = {};
  if (referCode) {
    params.refer_code = referCode;
  }

  return axios.post(`${onboarding_path}/registration/details`, user, { params });
};

export const confirmEmailRequest = body => {
  return axios.post(`${onboarding_path}/registration/email_confirmation`, body);
};

export const updatePreferredCountries = preferred_country_ids =>
  axios.post(`${onboarding_path}/countries/preferred`, { preferred_country_ids });

export const getInterests = () => axios.get(`${onboarding_path}/interests`);

export const getBlueCollarJobTitles = () => axios.get(`${onboarding_path}/interested_jobs/blue_collar`);

export const getInterestAreas = () => axios.get(`${onboarding_path}/interest_areas`);

export const getSkills = params => axios.get(`/api/v1/browse/profile/skills/by_interest_areas?${params}`);

export const getSeniorityLevels = () => axios.get(`${onboarding_path}/seniority_levels`);

export const updateInterests = interest_ids => axios.post(`${onboarding_path}/interests`, { interest_ids });

export const updateContactDetails = contact_details =>
  axios.post(`${onboarding_path}/contact_details`, { contact_details });

export const debounceValidateEmail = email => axios.get(`/api/v1/debounce/validate?email=${email}`);
