import React, { useContext } from "react";
import styled from "styled-components";

import cn from "classnames";
import ThemeContext from "../../../contexts/theme";
import { useIsBarona } from "../../../hooks";

const StyledText = styled.p(({ $styled }) => $styled);

const Text = ({
  fontSize = 14,
  as = "p",
  height,
  inline,
  color = "black500",
  backgroundColor,
  margin = "auto 0",
  lineHeight,
  fontWeight = 400,
  textAlign,
  loading = false,
  loaded = false,
  maxLength,
  truncated,
  label,
  children,
  width = "fit-content",
  style,
  ...props
}) => {
  const Theme = useContext(ThemeContext);
  const isBarona = useIsBarona();

  return !loading ? (
    <StyledText
      as={as}
      className={`b2c-text${loaded ? " loaded" : ""}`}
      $styled={{
        fontSize,
        lineHeight,
        fontWeight,
        textAlign,
        margin,
        height,
        color: Theme[color],
        width,
        ...(inline ? { display: "inline-flex" } : {}),
        ...(backgroundColor ? { backgroundColor: Theme[backgroundColor] } : {}),
        ...(truncated
          ? {
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "100%",
              textOverflow: "ellipsis"
            }
          : {}),
        ...style
      }}
      {...props}
    >
      {typeof children === "string" && maxLength
        ? children.length <= maxLength
          ? children
          : `${children.slice(0, maxLength)}...`
        : children}
      {label}
    </StyledText>
  ) : (
    <StyledText
      className={cn("b2c-text placeholder", isBarona ? "placeholder_barona" : "")}
      $styled={{
        fontSize,
        textAlign,
        margin,
        width,
        ...(inline ? { display: "inline-flex" } : {}),
        ...style
      }}
    >
      _________
    </StyledText>
  );
};

export default Text;
