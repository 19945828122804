import React, { useState } from "react";
import styled from "styled-components";

const Img = styled.img(item => item.styled);

const Image = ({
  className = "",
  width,
  height,
  baseWidth,
  baseHeight,
  margin,
  padding,
  display,
  backgroundColor,
  objectFit = "contain",
  objectPosition,
  borderRadius = "4px",
  maxWidth = "100%",
  maxHeight,
  src = null,
  placeholder,
  loadingType = "lazy",
  loading = false,
  style = {},
  srcSet,
  ...props
}) => {
  const [error, setError] = useState(false);

  return (
    <Img
      className={className}
      onError={() => setError(true)}
      styled={{
        width,
        height,
        objectFit,
        objectPosition,
        padding,
        borderRadius,
        margin,
        display,
        backgroundColor,
        maxWidth,
        maxHeight,
        ...style
      }}
      src={loading || error || !src ? placeholder : src}
      loading={loadingType}
      width={baseWidth || width}
      height={baseHeight || height}
      {...props}
      srcSet={srcSet}
    />
  );
};

export default Image;
