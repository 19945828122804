import React, { useEffect, useRef } from "react";
import { MdClose } from "react-icons/md";
import cn from "classnames";

import ModalConsumer from "contexts/modal";
import { useFocusTrap } from "utils/hooks";

const Modal = ({ children, ...rest }) => {
  return (
    <ModalConsumer>
      {({ content: Inner, title, closable, toggle, aggressive, isDisableWindowScroll, isOverflowVisible }) =>
        Inner ? (
          <Content
            onClose={toggle}
            closable={closable}
            title={title}
            aggressive={aggressive}
            isDisableWindowScroll={isDisableWindowScroll}
            isOverflowVisible={isOverflowVisible}
            {...rest}
          >
            {Inner}
          </Content>
        ) : null
      }
    </ModalConsumer>
  );
};

// TODO remove isDisableWindowScroll in the future 12.07.2022
const Content = ({
  children,
  onClose,
  title,
  closable = true,
  aggressive,
  isDisableWindowScroll = true,
  isOverflowVisible
}) => {
  const contentRef = useFocusTrap(onClose, aggressive);
  const containerRef = useRef();
  const closeButtonRef = useRef();

  useEffect(() => {
    if (closeButtonRef?.current) {
      closeButtonRef.current.focus();
    } else if (contentRef?.current) {
      contentRef.current.focus();
    }
    if (document?.body) document.body.style.overflow = "hidden";

    return () => {
      if (document?.body) document.body.style.overflow = "unset";
    };
  }, [contentRef, closeButtonRef]);

  useEffect(() => {
    if (!isDisableWindowScroll) {
      window.scrollTo(0, containerRef.current?.scrollHeight);
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    }
  }, []);

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <div className="fz-modal" aria-modal="true" role="dialog" tabIndex="-1" aria-labelledby="modal-label">
        <div
          className={cn(
            "fz-modal__container fadeInFromBottom",
            isOverflowVisible ? "fz-modal__container_overflow-visible" : ""
          )}
          ref={containerRef}
          style={aggressive ? { padding: 0 } : {}}
        >
          <div className="fz-modal__header" style={aggressive ? { padding: 0 } : {}}>
            <h4 className="fz-modal__title" id="modal-label">
              {title}
            </h4>
            {closable ? (
              <div
                ref={closeButtonRef}
                tabIndex="0"
                role="button"
                className="fz-modal__close"
                onClick={handleClose}
                aria-label="Close"
              >
                <MdClose />
              </div>
            ) : null}
          </div>
          <div className="fz-modal__content" ref={contentRef}>
            {children}
          </div>
        </div>
      </div>

      <div
        className="fz-modal__overlay fadeInSimple"
        role="button"
        tabIndex={-1}
        aria-label="close"
        onClick={() => (typeof closable === "number" || closable ? handleClose() : null)}
      />
    </>
  );
};

export default Modal;
