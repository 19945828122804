import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { ScreenClassContext } from "react-grid-system";
import signUpClose from "images/signup-close.png";
import styled from "styled-components";

import { Text, Button, Image } from "../../Core";

const ModalContent = ({ close, title: mainTitle, image, list, from }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  return (
    <div>
      <OnboardingModalWrapper>
        <Image
          src={signUpClose}
          onClick={() => {
            localStorage.removeItem("onboardingForm");
            localStorage.removeItem("onboardingCurrentStep");
            localStorage.removeItem("onboardingFlow");
            localStorage.removeItem("onboardingContext");
            close();
          }}
          alt="close"
          style={{
            position: "absolute",
            cursor: "pointer",
            top: "16px",
            right: "16px"
          }}
        />

        <Image src={image} height={isSm ? "100%" : 333} alt="Frame" />

        <div style={{ padding: isSm ? "0px 20px 20px 20px" : "20px" }}>
          <div>
            <Text
              fontSize={isSm ? 20 : 32}
              fontWeight={600}
              width="100%"
              textAlign="center"
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                textAlign: "start"
              }}
            >
              {mainTitle}
            </Text>
          </div>
          {list?.map(({ icon, title }) => {
            return (
              <div
                key={title}
                id={title}
                style={{
                  margin: "10px 0"
                }}
              >
                <Image
                  src={icon}
                  alt={title}
                  style={{
                    position: "relative",
                    top: "5px"
                  }}
                />
                <Text
                  fontSize={16}
                  width="100%"
                  margin="0 0 0 8px"
                  textAlign="start"
                  style={{
                    display: "inline",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    textAlign: "start"
                  }}
                >
                  {title}
                </Text>
              </div>
            );
          })}
          <Button
            arrow
            width="100%"
            margin="18px 0"
            onClick={() => {
              const onboardingContext = localStorage.getItem("onboardingContext");
              if (onboardingContext !== "job") {
                localStorage.setItem("onboardingCurrentStep", "1");
              }
              close();
              window.location.href = `/onboarding${from ? `?return_to=${from}` : ""}`;
            }}
          >
            Get Started
          </Button>
          <Button
            as={Link}
            to={`/login${from ? `?return_to=${from}` : ""}`}
            width="100%"
            onClick={close}
            margin="0 0 auto"
            style={{ background: "#f3f3f3" }}
          >
            I already have a Fuzu account
          </Button>
        </div>
      </OnboardingModalWrapper>
    </div>
  );
};

export const OnboardingModalWrapper = styled.div`
  width: 500px;
  height: 750px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    width: auto;
    height: auto;
  }
`;

export default observer(ModalContent);
