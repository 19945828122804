import { makeAutoObservable, reaction } from "mobx";

import * as State from "utils/storeHelpers";
import API from "../../utils/api";

class CommonStore {
  setAppState;

  constructor(setAppState) {
    makeAutoObservable(this);

    this.setAppState = setAppState;

    reaction(
      () => this.state,
      localState => {
        this.setAppState(localState);
      }
    );
  }

  state = {
    loading: false,
    type: "",
    message: ""
  };

  countries = [];

  towns = [];

  employers = [];

  allCountries = [];

  getLocations = term => {
    this.state = State.setLoading(this.state);
    return API.getLocations(term)
      .then(res => {
        this.countries = res?.data?.countries;
        this.state = State.setNeutral(this.state, res);

        return res?.data?.countries;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve a list of locations", () =>
          this.getLocations()
        );
      });
  };

  getTowns = term => {
    this.state = State.setLoading(this.state);
    return API.getTowns(term)
      .then(res => {
        this.towns = res?.data?.towns;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve a list of towns", () => this.getTowns());
      });
  };

  getCountries = notDefault => {
    this.state = State.setLoading(this.state);
    return API.getCountries(notDefault)
      .then(res => {
        this.allCountries = res?.data?.countries;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve a list of towns", () => this.getCountries());
      });
  };

  getCompanies = term => {
    this.state = State.setLoading(this.state);
    return API.getCompanies(term)
      .then(res => {
        this.employers = res?.data?.companies;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve a list of companies", () =>
          this.getCompanies()
        );
      });
  };
}

export default CommonStore;
