import styled, { css } from "styled-components";

export const StyledLink = styled.a`
  ${({ theme, color = "black300" }) => css`
    color: ${theme[color]};
  `}
`;

export const RequiredText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #666666;

  @media screen and (min-width: 992px) {
    font-size: 16px;
  }
`;

export const StyledItem = styled.div`
  ${({
    theme,
    color = "black300",
    activeColor = "white",
    activeBackground = "secondary200",
    selectedColor = "black500",
    fontWeight = 500,
    fontSize = 14
  }) => css`
    display: inline-block;
    align-items: center;
    width: 100%;
    padding: 10px 12px;
    cursor: pointer;
    color: ${theme[color]};
    transition: 0.2s ease;
    font-size: ${fontSize}px;
    font-weight: ${fontWeight};

    > span {
      display: inline-flex;
      color: ${theme[selectedColor]};
      background-color: ${theme.primary100};
    }

    &.selected {
      color: ${theme[selectedColor]};
    }

    &:hover,
    &:focus {
      background-color: ${theme[activeBackground]};
      color: ${theme[activeColor]};
    }

    &:focus {
      background-color: ${theme[activeBackground]};
      outline: none;
    }

    @media screen and (max-width: 767px) {
      font-weight: 400;
      padding: 16px;

      &.selected {
        font-weight: 600;
      }

      :not(:last-child) {
        border-bottom: 1px solid ${theme.grey300};
      }
    }
  `}
`;

const StyledDiv = styled.div`
  ${({ theme, wrapperStyle, $triggerStyle, disabled, $hasError, $borderColor }) => css`
    position: relative;
    ${!$hasError ? "margin-bottom: 20px;" : ""}
    ${wrapperStyle}

    > input {
      display: none;
    }

    .dropdown-label {
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      margin: 0 0 8px;
    }

    .default-trigger {
      display: flex;
      position: relative;
      justify-content: space-between;
      padding: 10px 12px;
      border: 1px solid ${$borderColor ? theme[$borderColor] : theme.grey200};
      background-color: ${theme.white};
      border-radius: 5px;
      height: 48px;
      width: 100%;
      color: ${theme.black500};
      font-size: 14px;
      appearance: none;
      outline: none;
      transition:
        background-color 0.2s ease,
        border-color 0.2s ease,
        color 0.2s ease;
      cursor: pointer;
      align-items: center;
      ${$triggerStyle}

      ${disabled
        ? `
            border-color: ${theme.grey300};
            background-color: ${theme.grey200};
            pointer-events: none;
        `
        : ""}

        &.error {
        border-color: ${theme.accent300};
        background-color: ${theme.accent200};

        svg {
          fill: ${theme.accent300};
        }
      }

      svg {
        fill: ${theme.grey300};
        position: absolute;
        right: 0;
        transform: translateX(-50%);
      }

      &:hover,
      &:focus {
        border-color: ${theme.secondary200};
        background-color: #f9fafc;

        svg {
          fill: ${theme.secondary200};
        }
      }

      &:focus {
        border-color: ${theme.secondary200};
        background-color: #fff;

        svg {
          fill: ${theme.secondary300};
        }
      }
    }
  `}
`;

export const StyledMenu = styled.div`
  ${({ theme, menuStyle, reverseMode, searchEnabled }) => `
        position: absolute;
        z-index: 101;
        border: 1px solid ${theme.grey200};
        background-color: ${theme.white};
        border-radius: 5px;
        font-size: 14px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        width: 100%;
        ${
          reverseMode
            ? `
        top: 0;
        transform: translateY(calc(-100% + 42px));
        `
            : ""
        }
        ${menuStyle};
        
        &.left {
            right: 0;
        }
        
        .dropdown-search {
            padding: 8px;
            display: flex;
        }
        
        .dropdown-list {
            overflow: hidden;
            max-height: ${searchEnabled ? "calc(40vh - 56px)" : "40vh"};
            width: 100%;
            overflow-y: auto;
            height: fit-content;
        }

        .dropdown-footer {
            display: flex;
            border-top: 1px solid ${theme.grey200};
            padding: 10px 12px;
        }

        @media screen and (max-width: 767px) {
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100vw;
            max-width: 100%;
            border-radius: 0px;
            z-index: 999;
            transform: none;
            flex-direction: column;

            .dropdown-search input {
                width: 100%;
            }

            .dropdown-list {
                max-height: inherit;
            }
        }
`}
`;

export const DropdownInactive = styled.div`
  display: none;
`;

export default StyledDiv;
