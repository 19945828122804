import React, { useContext, Children } from "react";

import Label from "../Core/Label";
import Layout from "../Layout";
import { NavWrapper, NavContent, NavLeftContent, NavRightContent, NavScrollContent, NavItem } from "./styled";

import ThemeContext from "../../contexts/theme";

const Navigation = ({
  children,
  NavRight,
  style,
  theme = "white",
  margin = "0 0 10px",
  padding = "0",
  innerLayoutSize,
  ...props
}) => {
  const Theme = useContext(ThemeContext);

  return (
    <>
      <NavWrapper
        $styled={{
          backgroundColor: Theme[theme],
          margin,
          padding,
          ...style
        }}
        {...props}
      >
        <Layout.Content tag="div" size={innerLayoutSize}>
          <NavContent>
            <NavLeftContent>
              <NavScrollContent $theme={Theme[theme]}>{children}</NavScrollContent>
            </NavLeftContent>
            {NavRight && <NavRightContent>{NavRight}</NavRightContent>}
          </NavContent>
        </Layout.Content>
      </NavWrapper>
    </>
  );
};

Navigation.Tab = ({
  color = "black200",
  selectedColor = "secondary300",
  focusColor = "secondary100",
  theme = "white",
  children,
  selected,
  style,
  count = 0,
  $isNewSelectedColor,
  ...props
}) => {
  const Theme = useContext(ThemeContext);

  return (
    <NavItem
      $color={Theme[color]}
      $altcolor={Theme[selectedColor]}
      $focusColor={Theme[focusColor]}
      $theme={Theme[theme]}
      selected={selected}
      $styled={style}
      $isNewSelectedColor={$isNewSelectedColor}
      onClick={e => {
        if (selected) e.preventDefault();
      }}
      {...props}
    >
      {children}
      {count > 0 && <Label theme="secondary300">{count}</Label>}
    </NavItem>
  );
};

Navigation.Content = ({ loading, currentTab, tab, LoadingState = () => null, EmptyState = () => null, children }) => {
  if (currentTab == tab || tab?.includes(currentTab)) {
    if (loading) return <LoadingState />;
    if (Children.count(children > 0)) return children;
    return <EmptyState />;
  }
  return null;
};

export default Navigation;
