import styled from "styled-components";
import { theme } from "../../contexts/theme";

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 520px;

  > .share-item {
    justify-content: start;
    height: 42px;
    text-align: start;
    opacity: 0.7;
    transition: opacity 0.15s ease;
    width: 100%;
    outline: none;
    font-weight: 500;

    svg {
      margin-right: 12px;
    }

    &:hover {
      opacity: 1;
    }

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
`;

export const LoginModalWrapper = styled.div`
  display: flex;
  width: 730px;
  height: 434px;
  margin: -72px -20px -32px -20px;
  overflow: hidden;

  > .menu-side {
    flex: 1 0 50%;
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
    height: 100%;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    width: auto;
    height: auto;

    > .menu-side {
      padding: 16px 24px;
    }
  }
`;

export const AggressiveLoginModalWrapper = styled.div`
  width: 500px;
  height: 742px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    width: auto;
    height: auto;
  }
`;

export const BrandSideWrapper = styled.div`
  ${({ backgroundImage, isonboarding }) => `
        flex: 0 0 50%;
        display: flex;
        flex-direction: column;
        background: ${
          backgroundImage
            ? `url(${backgroundImage})`
            : `radial-gradient(116.4% 116.4% at 50.14% 100%, ${theme.primary} 38.02%, #F3A900 100%)`
        };
        background-size: cover;
        background-position: center;
        padding: 0px 16px;
        height: 100%;
        min-height: 300px; 
        overflow: hidden;

        > img {
            width: 220px;
            width: ${isonboarding ? "100%" : "220px"};
        }

        @media screen and (max-width: 767px) {
            max-height: 40vh;
            min-height: 24vh; 
            padding: 0px 16px;

            > img {
                width: ${isonboarding ? "60%" : "32%"};
            }
        }
    `}
`;
