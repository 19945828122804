import React from "react";
import { Text } from "../Core";

const ToastMessage = ({ text }) => {
  return (
    <Text color="white" margin="10px auto">
      {text}
    </Text>
  );
};

export default ToastMessage;
