import React from "react";

import { ConversationItem, ParticipantImage } from "../../styled";

const Channel = ({ userId, loading = false, selected, id, conversation, description, logo, name, ...props }) => {
  const { created_at, body = "" } = conversation?.last_message || {};

  const convertedDate = created_at
    ? new Date(created_at).toLocaleDateString("en-US", { month: "short", day: "numeric" })
    : "";

  return (
    <ConversationItem isLoading={loading} selected={selected} {...props}>
      <div className="conversation-participants">
        {!loading ? (
          <ParticipantImage key={`channel_${id}`} alt={name} src={logo?.small?.url} index={0} participantCount={1} />
        ) : (
          <div className="photo-loading" />
        )}
      </div>
      <div className="conversation-info">
        <div className="participant-name">{name}</div>
        <div className="last-message">{body}</div>
      </div>
      <div className="conversation-additional">
        <div className="message-date">{convertedDate}</div>
        {conversation?.unread_count > 0 && !selected && (
          <div className="unread-count">{conversation?.unread_count}</div>
        )}
      </div>
    </ConversationItem>
  );
};

export default Channel;
