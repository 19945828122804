import axios from "axios";

// Feed sections

export const getJobs = (filter, limit = 4, employer_id, job_id, brandSlug) =>
  axios.get(
    `/api/v1/home/jobs?${filter ? `filter=${filter}` : ""}${employer_id ? `&employer_id=${employer_id}` : ""}${
      brandSlug ? `&brand=${brandSlug}` : ""
    }${job_id ? `&job_id=${job_id}` : ""}&limit=${limit}`
  );

export const getFuzuJobs = () => axios.get("/api/v1/browse/jobs/fuzu");

export const getCourses = (filter = "similar", type = "all", limit) =>
  axios.get(`/api/v1/home/courses?filter=${filter}&limit=${limit}&type=${type}`);

export const getSimilarCourses = slug => axios.get(`/api/v1/browse/courses/${slug}/similar`);

export const getArticles = (filter, limit, page = 0, article_id, countryId, authorId) =>
  axios.get(
    `/api/v1/home/articles?${filter ? `filter=${filter}` : ""}${limit ? `&limit=${limit}` : ""}${
      article_id ? `&article_id=${article_id}` : ""
    }&page=${page}${countryId ? `&country_id=${countryId}` : ""}${authorId ? `&author_id=${authorId}` : ""}`
  );

export const getUnfinishedItems = limit => axios.get(`/api/v1/home/unfinished?limit=${limit}`);

export const getCompaniesFeed = (filter = "trending", company_id, limit = 4) =>
  axios.get(`/api/v1/home/companies?filter=${filter}${company_id ? `&company_id=${company_id}` : ""}&limit=${limit}`);

export const getSimilarUsers = (filter = "like_me", limit = 4) =>
  axios.get(`/api/v1/home/users?filter=${filter}&limit=${limit}`);
