import axios from "axios";

import { isStandaloneMode, isStandaloneAppFlow } from "../pwa";

import * as application from "./applications";
import * as authorization from "./authorization";
import * as blog from "./blog";
import * as builder from "./builder";
import * as cv from "./cv";
import * as common from "./common";
import * as feed from "./feed";
import * as landing from "./landing";
import * as layout from "./layout";
import * as learning from "./learning";
import * as onboarding from "./onboading";
import * as profile from "./profile";
import * as saving from "./saving";
import * as search from "./search";
import * as seo from "./seo";
import * as settings from "./settings";
import * as survey from "./survey";
import * as payments from "./payments";
import * as premium from "./premium";
import * as transactions from "./transactions";
import * as notifications from "./notifications";

axios.defaults.headers.common = {
  ...axios.defaults.headers.common,
  "Content-Type": "application/json",
  Accept: "application/json",
  pwa: isStandaloneMode() || isStandaloneAppFlow()
};

export default {
  ...application,
  ...authorization,
  ...blog,
  ...builder,
  ...cv,
  ...common,
  ...feed,
  ...landing,
  ...layout,
  ...learning,
  ...onboarding,
  ...payments,
  ...premium,
  ...profile,
  ...saving,
  ...search,
  ...seo,
  ...settings,
  ...survey,
  ...transactions,
  ...notifications
};
