import { makeAutoObservable } from "mobx";
import * as State from "utils/storeHelpers";
import API from "../../utils/api";

class Article {
  state = {
    loading: false,
    processing: false,
    error: false,
    message: ""
  };

  article;

  constructor(article) {
    makeAutoObservable(this);

    this.article = article;
  }

  getArticle = (id, isForceLoad) => {
    if (!this.article || (this.article?.id != id && this.article?.slug != id) || isForceLoad) {
      this.state = State.setLoading(this.state);
      return API.getArticle(id)
        .then(res => {
          this.state = State.setNeutral(this.state, res);

          this.article = {
            ...res.data.article_details
          };

          this.getArticleComments(id);
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Could not retrieve article contents", () =>
            this.getArticle(id)
          );
        });
    }
  };

  getArticleComments = id => {
    return API.getArticleComments(id)
      .then(res => {
        this.article = {
          ...this.article,
          ...res.data
        };
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not retrieve article comments", () =>
          this.getArticleComments(id)
        );
      });
  };

  addComment = (id, body, parent_id) => {
    return API.addArticleComment(id, body, parent_id).then(res => {
      if (parent_id) {
        const index = this.article.comments.findIndex(comment => {
          return comment.id === parent_id;
        });
        this.article.comments[index].children.push(res?.data?.comment);
      } else {
        this.article.comments.unshift(res?.data?.comment);
      }
    });
  };

  updateComment = (articleId, body, commentId, parentId) => {
    return API.updateComment(articleId, body, commentId).then(() => {
      if (parentId) {
        const index = this.article.comments.findIndex(comment => {
          return comment.id === parentId;
        });
        this.article.comments[index].children = this.article.comments[index].children.map(c =>
          c.id === commentId ? { ...c, body } : c
        );
      } else {
        this.article.comments = this.article.comments.map(c => (c.id === commentId ? { ...c, body } : c));
      }
    });
  };

  removeComment = (articleId, commentId, parentId) => {
    return API.removeComment(articleId, commentId)
      .then(res => {
        this.state = State.setNeutral(this.state, res);

        if (parentId) {
          const updatedComments = this.article.comments.map(item => {
            if (item.id === parentId) {
              const updatedChildren = item.children.filter(
                childrenItem => String(childrenItem.id) !== String(commentId)
              );

              return {
                ...item,
                children: updatedChildren
              };
            }

            return item;
          });

          this.article = {
            ...this.article,
            comments: updatedComments
          };
        } else {
          this.article.comments = this.article.comments.map(c =>
            c.id === commentId ? { ...c, status: "deleted" } : c
          );
        }
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to remove message", () =>
          this.removeComment(articleId, commentId)
        );
      });
  };

  likeArticle = id => {
    return API.likeArticle(id).then(() => {
      this.article = {
        ...this.article,
        likes: this.article.likes + 1,
        liked_by_user: true
      };
    });
  };

  removeLike = id => {
    return API.removeLike(id).then(() => {
      this.article = {
        ...this.article,
        likes: this.article.likes - 1,
        liked_by_user: false
      };
    });
  };
}

export default Article;
