export const nameImages = {
  avatarEmpty: "avatarEmpty.avif",
  wizardIntro: "wizardIntro.avif",
  learnMain: "learnMain.avif",
  jobsEmpty: "jobsEmpty.svg",
  supportChat: "supportChat.avif",
  stepCycle: "stepCycle.svg",
  courseSuccess: "courseSuccess.svg",
  alert: "alert.avif",
  applicationEmpty: "applicationEmpty.svg",
  savedJobsEmpty: "savedJobsEmpty.svg",
  languageEmpty: "languageEmpty.svg",
  educationEmpty: "educationEmpty.svg",
  surveyPlaceholder: "surveyPlaceholder.avif",
  personalityEmpty: "personalityEmpty.avif",
  points: "points.svg",
  applicationSuccess: "applicationSuccess.avif",
  faq: "faq.svg",
  testSuccess: "testSuccess.avif",
  logoPrimary: "logoPrimary.svg",
  fastApply: "fastApply.svg"
};
