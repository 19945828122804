import React from "react";
import cn from "classnames";
import Popup from "reactjs-popup";

const Tooltip = ({
  className,
  classNamePopup,
  tooltipPosition = ["top center", "bottom center"],
  tooltipContent,
  children,
  contentStyle = {}
}) => {
  return tooltipContent ? (
    <Popup
      className={cn("fz-tooltip", classNamePopup || "")}
      on="hover"
      trigger={
        <div className={className} style={contentStyle}>
          {children}
        </div>
      }
      position={tooltipPosition}
      mouseEnterDelay={100}
      mouseLeaveDelay={100}
      contentStyle={{ zIndex: 10 }}
      keepTooltipInside
      closeOnDocumentClick
    >
      {tooltipContent}
    </Popup>
  ) : (
    children
  );
};

export const withTooltip = Component =>
  class extends React.Component {
    render() {
      return (
        <Tooltip tooltipPoisition={this.props.tooltipPoisition} tooltipContent={this.props.tooltipContent}>
          <Component {...this.props} />
        </Tooltip>
      );
    }
  };

export default Tooltip;
