import { makeObservable, observable, action, reaction } from "mobx";

import * as State from "utils/storeHelpers";
import API from "../../utils/api";

class PremiumStore {
  setAppState;

  constructor(userStore, setAppState) {
    makeObservable(this, {
      state: observable,
      IFScores: observable,
      features: observable,
      applications: observable,
      getFeatureStatus: action,
      getIFScore: action,
      getApplicationList: action
    });

    this.setAppState = setAppState;

    reaction(
      () => this.state,
      localState => {
        this.setAppState(localState);
      }
    );

    reaction(
      () => !!userStore.user?.id,
      authorized => {
        if (authorized) this.getFeatureStatus();
      },
      { fireImmediately: true }
    );
  }

  state = {
    loading: false,
    processing: false,
    type: "",
    message: ""
  };

  IFScores = [];

  features;

  applications;

  getFeatureStatus = () => {
    return API.getFeatureStatus()
      .then(res => {
        this.features = res.data;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not retrieve feature status", () =>
          this.getFeatureStatus()
        );
      });
  };

  getIFScore = id => {
    if (!this.IFScores.some(score => score.id == id)) {
      return API.getIFScores(id)
        .then(res => {
          this.IFScores = this.IFScores.concat({ id, ...res.data });
          this.state = State.setNeutral(this.state, res);
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Could not retrieve instant feedback scores", () =>
            this.getIFScore(id)
          );
        });
    }
    return Promise.resolve();
  };

  getApplicationList = () => {
    if (!this.applications) {
      this.state = State.setProcessing(this.state);
      return API.getApplications("page=1&per_page=100")
        .then(res => {
          this.applications = res?.data?.jobs;
          this.state = State.setNeutral(this.state, res);
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Could not retireve application list", () =>
            this.getApplicationList()
          );
        });
    }
  };
}

export default PremiumStore;
