import React, { forwardRef } from "react";
import styled from "styled-components";

const CarouselHorizontal = forwardRef(
  ({ margin, marginWrapper = "0 auto", padding = "20px", style, children, ...rest }, ref) => {
    return (
      <CarouselHorizontalScrollBox ref={ref} $styled={{ margin, ...style }} {...rest}>
        <CarouselHorizontalWrapper padding={padding} margin={marginWrapper}>
          {children}
        </CarouselHorizontalWrapper>
      </CarouselHorizontalScrollBox>
    );
  }
);

CarouselHorizontal.Slide = ({ width = "220px", style, children, ...rest }) => {
  return (
    <CarouselHorizontalSlide $styled={style} $width={width} {...rest}>
      {children}
    </CarouselHorizontalSlide>
  );
};

const CarouselHorizontalScrollBox = styled.div`
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  &::-webkit-scrollbar {
    display: none;
  }
  ${({ $styled }) => $styled}
`;
const CarouselHorizontalWrapper = styled.div`
  ${({ padding, margin }) => `
    display: flex;
    padding: ${padding};
    margin: ${margin};
  `}
`;
const CarouselHorizontalSlide = styled.div`
  scroll-snap-align: center;
  width: ${({ $width }) => $width};
  max-width: calc(100vw - 60px);
  flex-shrink: 0;
  &:not(:last-child) {
    margin-right: 10px;
  }
  &:last-child {
    padding-right: 10px;
  }
  ${({ $styled }) => $styled}
`;

export default CarouselHorizontal;
