import React from "react";

import { useTranslation } from "b2c/hooks/useTranslation";
import NoMessages from "images/b2c/NoMessages.svg";
import EmptyMessagesBarona from "images/b2c/barona/emptyMessagingBarona.svg";
import { StyledEmptyList } from "./styled";

const EmptyList = ({ isBarona }) => {
  const { t } = useTranslation();
  return (
    <StyledEmptyList>
      {isBarona ? <EmptyMessagesBarona /> : <NoMessages />}

      <h2 className="empty-title">{t("messages.empty.title", { name: isBarona ? "Barona" : "Fuzu" })}</h2>
      <p className="empty-text">{t("messages.empty.description_1")}</p>
      <br />
      <p className="empty-text">{t("messages.empty.description_2", { name: isBarona ? "Barona" : "Fuzu" })}</p>
    </StyledEmptyList>
  );
};

export default EmptyList;
