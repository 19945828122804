import { makeAutoObservable, reaction } from "mobx";

import * as State from "utils/storeHelpers";
import { copyCodeToClipboard } from "utils/helpers";
import API from "../../utils/api";

class SavedContent {
  setAppState;

  constructor(setAppState) {
    makeAutoObservable(this);

    this.setAppState = setAppState;

    reaction(
      () => this.state,
      localState => {
        this.setAppState(localState);
      }
    );
  }

  state = {
    loading: false,
    type: "",
    message: ""
  };

  saved = {};

  handleSaving = (id, type, saved) => {
    switch (type) {
      default:
      case "job":
        return saved ? this.removeJob(id) : this.saveJob(id);
      case "course":
        return saved ? this.removeCourse(id) : this.saveCourse(id);
      case "article":
        return saved ? this.removeArticle(id) : this.saveArticle(id);
    }
  };

  saveJob = id => {
    return API.saveJob(id)
      .then(res => {
        this.state = State.setSuccess(this.state, res, "Job saved successfully");

        return true;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to save job", () => this.saveJob(id));
      });
  };

  removeJob = id => {
    return API.removeJob(id)
      .then(res => {
        this.state = State.setSuccess(this.state, res, "Job removed from saved");

        return false;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to remove job from saved", () => this.removeJob(id));
      });
  };

  saveCourse = id => {
    return API.saveCourse(id)
      .then(res => {
        this.state = State.setSuccess(this.state, res, "Course saved successfully");

        return true;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to save course", () => this.saveCourse(id));
      });
  };

  removeCourse = id => {
    return API.removeCourse(id)
      .then(res => {
        this.state = State.setSuccess(this.state, res, "Course removed from saved");

        return false;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to remove course from saved", () => this.removeCourse(id));
      });
  };

  saveArticle = id => {
    return API.saveArticle(id)
      .then(res => {
        this.state = State.setSuccess(this.state, res, "Article saved successfully");

        return true;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to save article", () => this.saveArticle(id));
      });
  };

  removeArticle = id => {
    return API.removeArticle(id)
      .then(res => {
        this.state = State.setSuccess(this.state, res, "Article removed from saved");

        return false;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to remove article from saved", () =>
          this.removeArticle(id)
        );
      });
  };

  copyToClipboard = url => {
    copyCodeToClipboard(url, () => {
      this.state = {
        loading: false,
        type: "success",
        status: 200,
        message: "Link copied successfully!"
      };
    });
  };
}

export default SavedContent;
