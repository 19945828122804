import React, { useState, useLayoutEffect, forwardRef, useEffect } from "react";

import { useQueryParams } from "utils/hooks";
import { Pin, MessageX } from "@styled-icons/boxicons-solid";
import { highlightField } from "utils/helpers";
import { ConversationItem, ParticipantImage } from "../../styled";

const Conversation = forwardRef(
  (
    {
      userId,
      loading = false,
      selected,
      searchTerm = "",
      id,
      participants = [],
      last_message = {},
      unread_count = 0,
      pinned,
      read_only,
      anonymous,
      onClick,
      ...props
    },
    ref
  ) => {
    const { created_at, body = "" } = last_message || {};
    const [filteredParticipants, setParticipants] = useState([]);
    const { conversation_id: conversationId } = useQueryParams();

    useLayoutEffect(() => {
      if (participants.length > 1 && participants.some(p => p.id != userId)) {
        setParticipants(participants.filter(p => p.id != userId));
      }
    }, [userId, participants]);

    useEffect(() => {
      if (conversationId && String(conversationId) === String(id)) {
        onClick();
      }
    }, []);

    const convertedDate =
      last_message && created_at
        ? new Date(created_at).toLocaleDateString("en-US", { month: "short", day: "numeric" })
        : "";

    const participantCount = Math.max(filteredParticipants.length, 1);

    const conversationName = highlightField(
      filteredParticipants.map(participant => `${participant?.first_name} ${participant?.last_name || ""}`).join(", "),
      searchTerm
    );

    return (
      <ConversationItem
        isLoading={loading}
        selected={selected}
        ref={ref}
        tabIndex="0"
        role="tab"
        onClick={onClick}
        {...props}
      >
        <div className="status-icons">
          {read_only && <MessageX />}
          {pinned && <Pin />}
        </div>
        <div className="conversation-participants">
          {!loading ? (
            filteredParticipants.map((participant, index) => (
              <ParticipantImage
                key={`participant_${id}_${index + 1}`}
                alt={`${participant.first_name} ${participant.last_name}`}
                src={participant.avatar}
                index={index}
                participantCount={participantCount}
              />
            ))
          ) : (
            <div className="photo-loading" />
          )}
        </div>
        <div className="conversation-info">
          <div className="participant-name">{conversationName}</div>
          <div className="last-message">{body}</div>
        </div>
        <div className="conversation-additional">
          <div className="message-date">{convertedDate}</div>
          {unread_count > 0 && !selected && <div className="unread-count">{unread_count}</div>}
        </div>
      </ConversationItem>
    );
  }
);

export default Conversation;
