import axios from "axios";

// User unlocking

export const unlockUser = (userId, candidateId, employerId) =>
  axios.post(`/api/v1/employers/candidates/${userId}/unlock?application_id=${candidateId}&employer_id=${employerId}`);

// Account settings

const settings_path = "/api/v1/employers/settings";

export const getIndustries = () => axios.get("/api/v1/common/industries");

export const getEmployerSizes = () => axios.get("/api/v1/common/employer_sizes");

export const getSocialLinkTypes = () => axios.get("/api/v1/common/social_link_types");

export const getCountries = () => axios.get("/api/v1/browse/countries/with_towns");

export const getPhoneCodes = () => axios.get("/api/v1/common/phones/codes");

export const updateEmployerLogo = logo => axios.patch(`${settings_path}/profile/logo`, logo);

export const deleteEmployerLogo = () => axios.delete(`${settings_path}/profile/logo`);

export const updateEmployerDescription = description =>
  axios.patch(`${settings_path}/profile/description`, { description });

export const updateEmployerIndustries = industry_ids =>
  axios.patch(`${settings_path}/profile/industries`, { industry_ids });

export const updateEmployerContactDetails = contact_details =>
  axios.patch(`${settings_path}/profile/contact_details`, { contact_details });

export const updateEmployerSocialLink = social_link =>
  axios.patch(`${settings_path}/profile/social_link`, { social_link });

export const deleteEmployerSocialLink = id => axios.delete(`${settings_path}/profile/social_link/${id}`);

export const updateEmployerBanner = banner => axios.patch(`${settings_path}/profile/banner`, banner);

export const deleteEmployerBanner = () => axios.delete(`${settings_path}/profile/banner`);

export const getUserList = page => axios.get(`${settings_path}/users?page=${page || 1}&per_page=10`);

export const inviteUsers = invites => axios.post(`${settings_path}/users/invite`, { invites });

export const resendUserInvite = id => axios.post(`${settings_path}/users/${id}/resend_invitation`);

export const grantAdmin = id => axios.post(`${settings_path}/users/${id}/make_admin`);

export const revokeAdmin = id => axios.post(`${settings_path}/users/${id}/revoke_admin`);

export const removeUser = id => axios.delete(`${settings_path}/users/${id}`);

export const getBrandDetails = () => axios.get(`${settings_path}/brand`);

export const updateBrandSlug = slug => axios.post(`${settings_path}/brand/slug`, { slug });
export const updateBrandParams = params => axios.patch(`${settings_path}/brand`, { brand: params });

export const toggleCareerPortal = enabled => axios.post(`${settings_path}/brand/enabled`, { enabled });

export const getOrganizations = () => axios.get(`${settings_path}/organizations`);

export const inviteOrganization = slug => axios.post(`${settings_path}/organizations/invite`, { slug });

export const removeOrganization = id => axios.delete(`${settings_path}/organizations/${id}`);

export const approveOrganizationInvite = token => axios.post(`${settings_path}/organizations/${token}/approve`);

export const resendOrganizationInvite = id => axios.post(`${settings_path}/organizations/${id}/resend`);

export const getApplicationSettings = () => axios.get(`${settings_path}/applications`);

export const toggleExternalAts = () => axios.post(`${settings_path}/applications/toggle_ats_applications`);

export const toggleOpenApplications = () => axios.post(`${settings_path}/applications/toggle_open_applications`);

export const getUserInfo = () => axios.get(`${settings_path}/personal_info`);

export const updateUserName = user => axios.patch(`${settings_path}/personal_info/name`, { user });

export const updateUserEmail = user => axios.patch(`${settings_path}/personal_info/email`, { user });

export const updateUserAvatar = body => axios.patch(`${settings_path}/personal_info/avatar`, body);

export const updateUserPassword = body => axios.patch(`${settings_path}/personal_info/password`, body);

export const getAccessibilityOptions = () => axios.get(`${settings_path}/accessibilities`);

export const updateAccessibities = body => axios.patch(`${settings_path}/accessibilities`, body);

export const getApiKeys = () => axios.get(`${settings_path}/api_keys`);

export const getActiveGlobalBrands = () => axios.get(`${settings_path}/api_keys/global_brands`);

export const createApiKeys = params => axios.post(`${settings_path}/api_keys`, params);

export const regenerateApiToken = id => axios.put(`${settings_path}/api/regenerate`, { token_id: id });

export const deleteApiKeys = id => axios.delete(`${settings_path}/api_keys/${id}`);

export const generateApiToken = () => axios.patch(`${settings_path}/api/generate`);

export const createAccountRequest = body => axios.post("/api/v1/employers/signup", body);

export const loginUserRequest = body => axios.post("/login", body);

export const getEmployersListRequest = () => axios.get("/api/v1/employers/manage/list");

export const selectEmployerRequest = body => axios.post("/api/v1/employers/manage/select", body);

export const forgotPasswordRequest = body => axios.post("/api/v1/browse/password/reset", body);

export const updatePasswordRequest = body => axios.post("/api/v1/browse/password", body);

export const acceptInvitationRequest = body => axios.post("/api/v1/employers/invitations/accept", body);
