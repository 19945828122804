import React, { useLayoutEffect, forwardRef } from "react";
import { observer } from "mobx-react";
import { Link, useLocation } from "react-router-dom";
import parse from "html-react-parser";

import { useTranslation } from "b2c/hooks/useTranslation";
import FuzuCoin from "images/b2c/fuzu_coin.svg";
import useStore from "../../contexts/store";
import { Label, Button, Text, Card } from "../Core";
import { LoadingContent } from "../Core/Spinner";
import ProductButton from "../ProductButton";
import { PriceBadge } from "./styled";

const ProductCore = ({ slug, position_id, pro, onClick, disabled, style, ...rest }, ref) => {
  const { productDetails, getProductDetails } = useStore("Payment");
  const { pathname } = useLocation();
  const { t } = useTranslation();

  useLayoutEffect(() => {
    getProductDetails(slug);
  }, [slug]);

  const { name, description, currency, price, discounted_price, fuzu_points_needed_for_discount } =
    productDetails[slug] || {};

  const path = (discounted = false) => ({
    pathname: position_id ? `/jobs/${position_id}/product/${slug}` : `/product/${slug}`,
    state: { discounted, from: pathname }
  });

  const handleClick = e => {
    if (disabled) e.preventDefault();
    else if (onClick) onClick(e);
  };

  return (
    <Card
      ref={ref}
      bordered
      borderColor={pro ? "secondary200" : "grey200"}
      theme={pro ? "grey100" : "white"}
      style={{ maxWidth: "100%", ...style }}
      {...rest}
    >
      <Card.Content padding="16px" style={{ alignItems: "center", minHeight: 280 }}>
        {pro && <PriceBadge>{parse(t("instant_feedback.products.pro_badge"))}</PriceBadge>}
        <LoadingContent loading={!name} size="50px" margin="86px 0">
          <Text
            fontWeight={600}
            fontSize={20}
            color="secondary300"
            width="100%"
            textAlign="center"
            height={description ? "auto" : "53px"}
            margin={description ? "0" : "0 0 12px"}
          >
            {name}
          </Text>
          <Text color="secondary300" fontWeight={500} fontSize={14} width="100%" textAlign="center" margin="0 0 12px">
            {description}
          </Text>
          {fuzu_points_needed_for_discount > 0 && (
            <Label
              fontSize={12}
              fontWeight={500}
              lineHeight="140%"
              textAlign="left"
              margin="0 0 10px"
              color="black500"
              noBackground
              Icon={<FuzuCoin />}
            >
              <span>
                {parse(t("instant_feedback.products.use_points", { points: fuzu_points_needed_for_discount }))}
              </span>
            </Label>
          )}
          <Text fontSize={22} fontWeight={600}>
            {currency} {discounted_price}
          </Text>
          <Button.Link width="100%" margin="10px 0 20px" to={path(true)} disabled={disabled} onClick={handleClick}>
            {t("career_coaching.buy_now")}
          </Button.Link>
          <Text margin="4px auto">
            {t("instant_feedback.products.normal_price")} {currency} {price}
          </Text>
          <Link to={path(false)} onClick={handleClick} className="link underlined">
            {t("instant_feedback.products.buy_without_points")}
          </Link>
        </LoadingContent>
      </Card.Content>
    </Card>
  );
};

const Product = observer(forwardRef(ProductCore));
Product.Button = ProductButton;

export default Product;
