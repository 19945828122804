import React, { Suspense } from "react";
import { observer } from "mobx-react";
import { Redirect, useLocation, useHistory } from "react-router-dom";

import { isWhitelabelled } from "b2c/contexts/branded";
import useStore from "b2c/contexts/store";
import { Button } from "../Core";

const LoginButton = React.lazy(() => import("../LoginButton"));

export const withAuthorizationRedirect = Component => {
  return observer(props => {
    const { user } = useStore("User");
    const { pathname } = useLocation();
    const whitelabelled = isWhitelabelled();

    return user?.id || whitelabelled ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: pathname }
        }}
      />
    );
  });
};

export const AuthorizationButton = props => {
  const {
    push,
    location: { pathname }
  } = useHistory();

  const { isonboarding, ...buttonProps } = props;

  return (
    <Suspense fallback={<Button.Link {...buttonProps} to={`${props.to}?return_to=${props.from || pathname}`} />}>
      <LoginButton push={push} pathname={pathname} {...buttonProps} />
    </Suspense>
  );
};

export const withGuestVersion = (MainPage, GuestPage) => {
  return observer(props => {
    const { user } = useStore("User");
    return user ? <MainPage {...props} /> : <GuestPage {...props} />;
  });
};

export const PromotionCountryVisible = observer(({ countryCodes = [], children }) => {
  const { user } = useStore("User");
  return countryCodes.length === 0 || countryCodes.includes(user?.promotion_country?.country_code) ? children : null;
});

export const CountryContainer = ({ children, ...rest }) => {
  const { user } = useStore("User");

  return user?.country?.country_code && rest[user?.country?.country_code]
    ? children(rest[user?.country?.country_code], user?.country)
    : null;
};

export const GuestHidden = observer(({ children }) => {
  const { user } = useStore("User");
  return user?.id ? children : null;
});

export const GuestVisible = observer(({ children }) => {
  const { user } = useStore("User");
  return user?.id ? null : children;
});
