import styled from "styled-components";
import { theme } from "../../../contexts/theme";

const TextareaWrapper = styled.div`
  ${props => props.styled};
`;
const TextareaLabel = styled.label`
  display: block;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin: 0 0 8px;
  ${props => props.styled};
`;
const TextareaControl = styled.textarea`
  padding: 12px;
  border: 1px solid ${props => (props.hasError ? theme.accent300 : theme.grey200)};
  background-color: ${props => (props.hasError ? theme.accent200 : theme.white)};
  width: 100%;
  color: ${theme.black500};
  font-size: 14px;
  appearance: none;
  outline: none;
  resize: none;
  transition: 0.2s ease;
  ${props => props.styled};

  &:focus {
    border-color: ${props => (props.hasError ? theme.accent300 : theme.primary)};
    background-color: #fff;
  }
  &::-webkit-input-placeholder {
    color: #9e9e9e;
  }
`;

export { TextareaWrapper, TextareaLabel, TextareaControl };
