import styled from "styled-components";

export const StyledEmptyList = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 478px;

  > svg {
    margin: 0 auto;
  }

  .empty-title,
  .empty-text {
    font-size: 16px;
    text-align: center;
  }

  .empty-title {
    font-weight: 600;
    margin: 24px auto;
  }
`;
