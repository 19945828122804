import { makeAutoObservable, reaction, toJS } from "mobx";

import * as State from "utils/storeHelpers";
import { addQueryParams } from "utils/hooks";
import API from "../../utils/api";

const categoryMap = {
  jobs: "campaigns",
  articles: "articles",
  courses: "courses",
  companies: "companies",
  universal: "universal"
};

class UniversalSearch {
  setAppState;

  constructor(setAppState) {
    makeAutoObservable(this);

    this.setAppState = setAppState;

    reaction(
      () => ({
        term: this.params.term,
        page: this.params.page,
        filter: this.filter
      }),
      params => {
        this.state = State.setLoading(this.state);
        API.getSearchResults(categoryMap[this.type], params.term, params.page, params.filter)
          .then(res => {
            addQueryParams(this.params, toJS(this.browserHistory));
            if (this.type === "universal") this.results = { ...this.results, ...res.data };
            else
              this.results[this.type]
                ? (this.results[this.type].items = res.data[this.type])
                : (this.results[this.type] = { items: res.data[this.type] });

            this.state = State.setNeutral(this.state, res);
          })
          .catch(err => {
            this.state = State.setError(this.state, err, "Failed to retrieve search results");
          });
      }
    );

    reaction(
      () => this.state,
      localState => {
        this.setAppState(localState);
      }
    );
  }

  browserHistory;

  state = {
    loading: false,
    type: "",
    message: ""
  };

  results = {};

  type = "universal";

  filter = "";

  params = {
    term: null,
    page: 1
  };

  setType = (type = "universal") => {
    if (this.type != type) {
      this.type = type;
    }
  };

  setTerm = (term = "") => {
    if (term.length > 2) this.params.term = term;
  };

  setPage = (page = 1) => {
    if (page != this.params.page) this.params.page = page;
  };

  initializeSearch = ({ term, page = 1 }, history) => {
    this.browserHistory = history;
    if (term != this.params.term || page != this.params.page) {
      this.params = {
        page: +page,
        term
      };
    }
    this.filter = /saved/.test(this.browserHistory.location.pathname) ? "saved" : "";
  };
}

export default UniversalSearch;
