import styled from "styled-components";
import { theme } from "../../../contexts/theme";

export const FindJobContent = styled.div`
  position: absolute;
  bottom: 60px;
  left: 50%;
  margin-left: -180px;
  width: 360px;
  z-index: 1;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row-reverse;
    width: 635px;
    bottom: auto;
    top: calc(100% + 10px);
    margin-left: 0;
  }
`;

export const FindJobInfo = styled.div`
  background-color: #fff;
  padding: 16px;
  border-radius: 0 0 12px 12px;
  border: 1px solid #eeeeee;
  border-top: none;
  box-shadow: 0px 5px 15px 3px rgba(33, 33, 33, 0.05);
  @media screen and (min-width: 768px) {
    width: 405px;
    flex: none;
    padding: 24px 22px 24px 16px;
    border-radius: 12px 0px 0px 12px;
    border-top: 1px solid #eeeeee;
    border-right: none;
  }
`;

export const FindJobImage = styled.div`
  background: radial-gradient(125.93% 125.93% at 50% 100%, ${theme.primary} 10.6%, #f3a900 100%);
  padding-top: 20px;
  height: 140px;
  border-radius: 12px 12px 0 0;
  border: 1px solid #eeeeee;
  border-bottom: none;
  box-shadow: 0px 5px 15px 3px rgba(33, 33, 33, 0.05);
  text-align: center;

  @media screen and (min-width: 768px) {
    height: auto;
    width: 230px;
    flex: none;
    border-radius: 0px 12px 12px 0px;
    border-bottom: 1px solid #eeeeee;
    border-left: none;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: calc(100% - 60px);
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);

  @media screen and (min-width: 768px) {
    display: none;
  }
`;
