import { observer } from "mobx-react";
import React from "react";
import { useModal } from "utils/hooks";
import findJobOnboarding from "images/b2c/onboarding/findJobOnboarding.png";
import learnOnboarding from "images/b2c/onboarding/learnOnboarding.png";
import forumOnboarding from "images/b2c/onboarding/ForumOnboarding.png";
import onBoardingJobIcon from "images/b2c/onboarding/onBoardingJobIcon.png";
import onboardingLike from "images/b2c/onboarding/onboardingLike.png";
import searchAggressive from "images/search-aggressive.png";
import onboardingModalBuilding from "images/b2c/onboarding/onboarding-modal-building.png";
import onboardingModalFire from "images/b2c/onboarding/onboarding-modal-fire.png";
import onboardingModalLightBulb from "images/b2c/onboarding/onboarding-modal-light-bulb.png";
import onboardingModalLine from "images/b2c/onboarding/onboarding-modal-line.png";
import onboardingModalPeople from "images/b2c/onboarding/onboarding-modal-people.png";
import onboardingModalWin from "images/b2c/onboarding/onboarding-modal-win.png";

import { Button } from "../Core";
import LoginModal from "../ModalContents/LoginModal";
import useStore from "../../contexts/store";
import LoginModalContent from "../ModalContents/SignUpModalContent";

let resolveHandler;

const modalMapper = {
  job: {
    title: "Fuzu is much more than a job board!",
    image: findJobOnboarding,
    list: [
      { icon: searchAggressive, title: "Receive personalized job alerts" },
      { icon: onboardingLike, title: "Get feedback on your applications" },
      { icon: onBoardingJobIcon, title: "Access free advice and courses" }
    ]
  },
  learn: {
    title: "Build the skills employers are after!",
    image: learnOnboarding,
    list: [
      { icon: onboardingModalLightBulb, title: "Free and premium online courses" },
      { icon: onboardingModalWin, title: "Build and certify your skills" },
      { icon: onboardingModalBuilding, title: "Get expert advice from career coaches" }
    ]
  },
  join: {
    title: "Get inspired by the community",
    image: forumOnboarding,
    list: [
      { icon: onboardingModalFire, title: "Articles, podcasts, videos & discussions" },
      { icon: onboardingModalLine, title: "The Africa’s fastest growing career community" },
      { icon: onboardingModalPeople, title: "Connect with industry professionals" }
    ]
  }
};

export const withAuthorizationModal = Component => {
  return observer(({ push, pathname, isonboarding, jobContextInfo, isNewModalFlow, ...props }) => {
    const { user } = useStore("User");
    const pendingConfirmation = new Promise(resolve => {
      resolveHandler = resolve;
    });

    const openNewLoginModal = useModal(<LoginModalContent type={props.type} />, null, () => {}, 0);

    const openLoginModal = useModal(
      <LoginModal
        from={props.from}
        type={props.type}
        {...modalMapper[props.type || "job"]}
        onAuthorization={resolveHandler}
      />,
      null,
      () => {},
      0
    );

    const clickHandler = e => {
      e.persist();
      e.preventDefault();

      if (jobContextInfo) {
        const onboardingForm = {
          applied_job_id: jobContextInfo?.id
        };

        localStorage.setItem("onboardingContext", "job");
        localStorage.setItem("onboardingFlow", "ic_ec");
        localStorage.setItem("onboardingForm", JSON.stringify(onboardingForm));
      } else {
        localStorage.removeItem("onboardingForm");
        localStorage.removeItem("onboardingCurrentStep");
        localStorage.removeItem("onboardingContext");
        localStorage.removeItem("onboardingFlow");
      }

      if (isNewModalFlow) {
        openNewLoginModal();
      } else {
        openLoginModal({ aggressive: true, isDisableWindowScroll: true });
      }

      pendingConfirmation
        .then(() => {
          if (props.onClick) props.onClick();
          if (props.from) push(props.from, { from: pathname });
        })
        .catch();
    };

    return <Component {...props} {...(!user?.id ? { onClick: clickHandler } : {})} />;
  });
};

const LoginButton = withAuthorizationModal(Button);

export default LoginButton;
