import { configure } from "mobx";

import MessagesStore from "stores/MessagesStore";
import TrackingStore from "./Tracking";
import CareerHeroStore from "./CareerHero";
import ContactUsStore from "./ContactUs";
import User from "./User";
import Common from "./Common";
import CvBuilderStore from "./CvBuilder";
import Home from "./Home";
import Blog from "./Blog";
import Search from "./Search";
import SavedContent from "./SavedContent";
import Application from "./Application";
import SurveyStore from "./Survey";
import PaymentStore from "./Payment";
import PremiumStore from "./Premium";
import LandingStore from "./Landing";
import OnboardingStore from "./Onboarding";
import LearningStore from "./Learning";
import StateManager from "./StateManager";

configure({
  enforceActions: "never",
  reactionScheduler: reaction => {
    if (!window.ssr) reaction();
  }
});

class RootStore {
  initialState;

  isBarona = false;

  constructor(initialState) {
    this.initialState = initialState;
    this.isBarona = initialState?.global_brand?.name?.includes("Barona");

    this.AppState = new StateManager();

    this.Tracking = new TrackingStore(initialState.brand, this.isBarona);

    this.User = new User(
      initialState.user,
      initialState.authentication_channel,
      this.AppState.setAppState,
      this.Tracking.track,
      initialState.feature_toggles
    );

    this.Common = new Common(this.AppState.setAppState);

    this.Home = new Home(
      this.User,
      this.AppState.setAppState,
      initialState.context,
      initialState.layout,
      initialState.location,
      this.isBarona
    );

    this.Blog = new Blog(this.User, this.AppState.setAppState, initialState.context);

    this.Search = new Search(this.AppState.setAppState, this.Common, initialState.context, initialState.user);

    this.Saved = new SavedContent(this.AppState.setAppState);

    this.Application = new Application(
      this.AppState.setAppState,
      this.User.getUserStatus,
      this.User.toggleNavigation,
      this.Search.JobSearch.removeApplication
    );

    this.Survey = new SurveyStore(this.AppState.setAppState);

    this.Payment = new PaymentStore(this.AppState.setAppState, this.Tracking.track);

    this.Premium = new PremiumStore(this.User, this.AppState.setAppState);

    this.Learning = new LearningStore(this.AppState.setAppState, initialState.context, initialState.layout);

    this.Messages = new MessagesStore(this.User);

    this.CareerHero = new CareerHeroStore(initialState.context);

    this.ContactUs = new ContactUsStore(initialState.context);

    this.Landing = new LandingStore(initialState.context, this.AppState.setAppState);

    this.CvBuilder = new CvBuilderStore(this.AppState.setAppState);

    this.Onboarding = new OnboardingStore(
      this.AppState.setAppState,
      this.User.updateUser,
      this.User.Profile,
      this.Tracking.track,
      initialState.global_brand
    );
  }
}

export default RootStore;
