import React from "react";

import UnderDevelopment from "@images/b2c/fuzuIcons/development.svg";
import { StyledContent } from "./styled";

import { Text, Icon } from "../Core";

const UnderDevelopmentContent = () => {
  return (
    <StyledContent>
      <Icon as={UnderDevelopment} width={80} margin="0 auto" />
      <Text fontWeight={600} margin="24px auto">
        Under Construction
      </Text>
      <Text margin="auto auto 24px" lineHeight="21px">
        Sorry! We are working hard to make this feature available to you. Please check back soon!
      </Text>
    </StyledContent>
  );
};

export default UnderDevelopmentContent;
