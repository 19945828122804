import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

const IconedButton = ({
  onClick,
  color,
  children,
  disabled,
  isActive,
  style,
  className,
  tag,
  overwriteClass = false,
  ...props
}) => {
  const buttonClasses = !overwriteClass
    ? cn(
        `fz-iconed-flat-btn ${className}`,
        {
          [`fz-iconed-flat-btn_${color}`]: color
        },
        {
          "fz-iconed-flat-btn_active": isActive
        }
      )
    : className;

  const TagName = tag;

  return (
    <TagName onClick={onClick} className={buttonClasses} disabled={disabled} style={style} {...props}>
      {children}
    </TagName>
  );
};

IconedButton.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.oneOf(["gray", "blue", "transparent"]),
  isActive: PropTypes.bool,
  disabled: PropTypes.bool
};

IconedButton.defaultProps = {
  isActive: false,
  disabled: false,
  tag: "button"
};

export default IconedButton;
