import React, { useState, useEffect, useLayoutEffect, useRef, useMemo } from "react";
import { observer, inject } from "mobx-react";
import { Send, CheckSquare } from "@styled-icons/boxicons-solid";
import { RightArrowAlt, X } from "@styled-icons/boxicons-regular";
import cn from "classnames";

import { useTranslation } from "b2c/hooks/useTranslation";
import { usePrev } from "utils/hooks";
import { Text, Title, Button } from "b2c/components/Core";
import { useShowModalAllowNotify } from "b2c/hooks";
import MessageItem from "../MessageItem";
import EmptyList from "../EmptyList";
import HistoryListener from "../../../../b2c/components/HistoryListener";
import Files from "../Files";
import { MessageSide } from "../../styled";
import { Job, Cycle, linkStyles } from "./styled";
import { useConfirmation } from "../../../../b2c/hooks";
import { fastApplyStatuses } from "../../../../b2c/constants/messages";
import { getTimeSince } from "../../../../utils/helpers";

const Content = ({
  User: {
    user: { id, roles },
    adminAccess
  },
  id: conversation_id,
  channel_id,
  channel_name,
  channel_description,
  title,
  owner = {},
  participants,
  messages,
  draftMessage,
  setDraftMessage,
  addMessage,
  addChannelConversation,
  updateMessage,
  removeMessage,
  isMobile,
  deselectConversation,
  clearMessages,
  uploadAttachment,
  read_only,
  UserCvButton,
  getContactDetails,
  application,
  isBarona,
  campaignEmployerId
}) => {
  const { t } = useTranslation();
  const textArea = useRef();
  const submitButton = useRef();
  const messageList = useRef();
  const [messageBody, setBody] = useState(draftMessage);
  const [grouped, setGrouped] = useState(null);
  const [filteredParticipants, setParticipants] = useState([]);
  const [editId, setEditId] = useState(null);
  const prevLength = usePrev(grouped?.map(item => item.messages).flat().length);
  const handleRemoval = useConfirmation(removeMessage, t("messages.delete_title"), t("global.delete"), {
    theme: "accent300"
  });
  const isFastApply = application?.fast_apply_status === fastApplyStatuses.fastApplyAssignmentsRequested;
  const messagesLength = grouped?.map(item => item.messages.length)?.reduce((a, b) => a + b, 0);
  const remainingTime = getTimeSince(application?.complete_due_date);
  const daysLeft = Math.ceil((application?.complete_due_date - Date.now() / 1000) / (3600 * 24));
  const expired = daysLeft < 0;
  const expiredDateText = !expired && daysLeft === 0 && remainingTime === "1 day ago" ? "Closing today" : remainingTime;
  const { isShowNotificationModalAllowNotify, openNotificationModalAllowNotify } = useShowModalAllowNotify();

  useEffect(() => {
    const incomingLength = messages?.map(item => item.messages).flat().length;
    if (messages) setGrouped(messages);
    if (messageList.current && incomingLength != prevLength)
      messageList.current.scrollTop = messageList.current.scrollHeight;
  }, [messages]);

  useLayoutEffect(() => {
    if (participants?.length > 1 && participants?.some(p => p.id != id)) {
      setParticipants(participants?.filter(p => p.id != id));
    } else if (channel_id) setParticipants([]);
  }, [id, channel_id, participants]);

  const areaHeight = useMemo(() => {
    const element = textArea.current;
    if (element) {
      element.scrollTop = element.scrollHeight;
      if (messageBody) {
        const offset = element.offsetHeight - element.clientHeight;
        return `${Math.min(element.scrollHeight + offset, 250)}px`;
      }
    }
  }, [messageBody, textArea.current]);

  useEffect(() => {
    if (!isMobile) textArea.current?.focus();
    setBody(draftMessage);
  }, [conversation_id, channel_id, draftMessage]);

  useEffect(() => {
    return () => {
      setGrouped(null);
      clearMessages();
    };
  }, [conversation_id, channel_id]);

  useEffect(() => {
    textArea.current?.select();
  }, [editId]);

  useEffect(() => {
    window?.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (messageList.current) {
      setTimeout(() => {
        if (messageList && messageList.current) {
          const heightOfElement = messageList.current.scrollHeight;
          messageList.current.scrollTop = heightOfElement;
          window?.scrollTo(0, heightOfElement);
        }
      }, 400);
    }
  }, [messageList.current, prevLength, messagesLength]);

  const handleSubmission = (...args) => {
    if (channel_id && !conversation_id) createChannelConversation(...args);
    else if (editId) {
      handleUpdate(...args);
    } else handleAddition(...args);
  };

  const handleAddition = (message, attachmentIds) => {
    if (!textArea.current.disabled && (message || messageBody)) {
      textArea.current.disabled = true;
      submitButton.current.disabled = true;
      addMessage(conversation_id, message || messageBody, attachmentIds).then(() => {
        setEditId(null);
        setBody("");
        setDraftMessage(conversation_id, "");

        if (!adminAccess && isShowNotificationModalAllowNotify) {
          openNotificationModalAllowNotify();
        }

        if (textArea.current) {
          textArea.current.disabled = false;
          textArea.current.focus();
        }
      });
    }
  };

  const handleUpdate = (message, attachment_ids) => {
    textArea.current.disabled = true;
    submitButton.current.disabled = true;
    updateMessage(conversation_id, editId, message || messageBody, attachment_ids).then(() => {
      setEditId(null);
      setBody("");
      setDraftMessage(conversation_id, "");
      textArea.current.disabled = false;
      textArea.current.focus();
    });
  };

  const createChannelConversation = (message, attachmentIds) => {
    textArea.current.disabled = true;
    submitButton.current.disabled = true;
    addChannelConversation(channel_id, message || messageBody, attachmentIds).then(() => {
      setEditId(null);
      setBody("");
      setDraftMessage(conversation_id, "");
      textArea.current.disabled = false;
      textArea.current.focus();
    });
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      handleSubmission();
    }
  };

  const handleMessageEdit = (ids, body) => {
    setEditId(ids);
    setBody(body);
  };

  const handleChange = value => {
    if (value.length === 0) {
      setDraftMessage(conversation_id, "");
    }
    setBody(value);
  };
  const closeConversation = () => {
    setGrouped(null);
    deselectConversation();
    window?.scrollTo(0, 0);
  };

  const handleBackwards = (_, action) => {
    if (action === "POP") closeConversation();
  };

  const readOnly = read_only && id != owner.id;

  const onBlur = () => {
    if (!editId) {
      setDraftMessage(conversation_id, messageBody);
    }
  };

  return (
    <>
      {isMobile && <HistoryListener callback={handleBackwards} prevent />}
      <MessageSide areaHeight={areaHeight}>
        {conversation_id || channel_id ? (
          <>
            {(filteredParticipants.length > 0 || channel_id) && (
              <div className="messages-header">
                <div className="conversation-title">
                  {filteredParticipants.length === 0 && channel_name && (
                    <p className="participant-name">{channel_name}</p>
                  )}
                  {filteredParticipants.length > 0 && (
                    <>
                      <p className="participant-name">
                        {channel_name ||
                          [
                            ...new Set(
                              filteredParticipants.map(
                                participant => `${participant.first_name} ${participant.last_name}`
                              )
                            )
                          ].join(", ")}
                      </p>
                      {title && <p className="participant-position">{title}</p>}
                    </>
                  )}
                </div>
                {isMobile && (
                  <button type="button" onClick={closeConversation}>
                    <X />
                  </button>
                )}
              </div>
            )}
            {!grouped && channel_description && <div className="channel-description">{channel_description}</div>}
            <div
              className="messages-list"
              id="chat-content"
              aria-live="polite"
              aria-label="Message List"
              ref={messageList}
            >
              {grouped?.length > 0 &&
                grouped?.map(({ date, messages: messagesList }, index) => (
                  <React.Fragment key={date}>
                    <div className="date-divider">{date}</div>
                    {messagesList.map((message, messageIndex) => (
                      <>
                        <MessageItem
                          key={message.id}
                          editing={message.id === editId}
                          removable={!(index === 0 && messagesList.length === 1)}
                          deleted={message.deleted}
                          editMessage={handleMessageEdit}
                          removeMessage={contactDetailsId => handleRemoval(conversation_id, contactDetailsId)}
                          processing={message.processing}
                          owned={id === message.sender.id}
                          adminAccess={adminAccess}
                          UserCvButton={UserCvButton}
                          getContactDetails={contactDetailsId =>
                            getContactDetails(contactDetailsId, channel_id, campaignEmployerId)
                          }
                          roles={roles}
                          {...message}
                        />

                        {messageIndex === 0 && index === 0 && isFastApply ? (
                          <Job isAdmin={adminAccess} key="job-banner">
                            <Cycle>
                              <CheckSquare width="18px" color="#fff" />
                            </Cycle>
                            <div>
                              <Title
                                fontWeight="600"
                                fontSize="16px"
                                lineHeight="140%"
                                color="#0B0A0A"
                                style={{ display: "block" }}
                                margin="0 0 4px 0"
                              >
                                {application?.job.title}
                              </Title>
                              <Text fontWeight="400" fontSize="14px" lineHeight="140%" color="#000000">
                                {t("messages.assignments_required")} •{" "}
                                {expiredDateText.replace("remaining", ` ${t("messages.left_to_complete")}`)}
                              </Text>
                              <Button.Link
                                to={`/job/applications?filters[job_id]=${application?.job.id}`}
                                theme="transparent"
                                height="auto"
                                style={{ ...linkStyles, pointerEvents: adminAccess ? "none" : "all" }}
                              >
                                {t("messages.complete_assignments_now")}
                                <RightArrowAlt
                                  width="14px"
                                  color="#408BFC"
                                  style={{ display: "block", flex: "none", marginLeft: "5px" }}
                                />
                              </Button.Link>
                            </div>
                          </Job>
                        ) : null}
                      </>
                    ))}
                  </React.Fragment>
                ))}
            </div>

            <div
              className={cn(
                "messages-textarea messages-textarea-content",
                isBarona ? "messages-textarea-content_barona" : ""
              )}
            >
              <textarea
                ref={textArea}
                placeholder={readOnly ? t("messages.message_placeholder_1") : t("messages.message_placeholder_2")}
                onChange={({ target: { value } }) => handleChange(value)}
                onBlur={onBlur}
                onKeyDown={handleKeyDown}
                value={messageBody}
                disabled={readOnly}
              />
              <Files.Button
                submit={handleSubmission}
                uploadAttachment={uploadAttachment}
                defaultMessageValue={messageBody}
              />
              <button
                type="button"
                ref={submitButton}
                disabled={!messageBody || readOnly}
                onClick={() => {
                  handleSubmission();
                }}
              >
                <Send />
              </button>
            </div>
          </>
        ) : (
          <EmptyList isBarona={isBarona} />
        )}
      </MessageSide>
    </>
  );
};

export default inject("User")(observer(Content));
