import { makeAutoObservable, reaction } from "mobx";
import axios from "axios";

import { TABS } from "constants/tabs";
import { stringify } from "utils/qsUtil";
import * as API from "../utils/headhuntApi";
import { unlockUser } from "../utils/api";
import * as State from "../../utils/storeHelpers";
import { getUrlPath } from "../utils/helpers";

class Store {
  constructor() {
    makeAutoObservable(this);

    reaction(
      () => ({
        ...(this.appliedFilterCount > 0 ||
        this.currentFolder.length > 0 ||
        this.searchTerm ||
        this.appliedFilters.sort_by
          ? {
              filters: {
                ...this.appliedFilters,
                term: this.searchTerm,
                headhunting_folder_ids: this.currentFolder
              }
            }
          : null),
        ...this.params
      }),
      imposed => {
        if (Object.keys(imposed).length > 0) {
          this.state.type = "";
          this.state.message = "Loading talents...";
          if (!this.state.loading)
            this.state = {
              ...this.state,
              error: false,
              loading: true
            };

          Promise.all([this.getTalentList(imposed), this.getFilters(imposed)])
            .then(() => {
              this.state.loading = false;

              return this.state.loading;
            })
            .catch(err => {
              this.state = {
                loading: false,
                error: true,
                type: "talents_failure",
                message: err.message
              };
            });
        }
      }
    );
  }

  state = {
    loading: false,
    error: false,
    type: "",
    message: "Loading..."
  };

  talents = [];

  folders = [];

  searches = [];

  commonCountries = [];

  campaigns = [];

  candidateSearchFilters = {};

  candidateSearchAppliedFilters = {};

  companies = [];

  currentFolder = [];

  availableJobsForCandidate = [];

  selectedAvailableJobForCandidate = {};

  applicationHistory = [];

  applicationHistoryMeta = {};

  currentTalent = {};

  talentFilters = {};

  appliedFilters = {};

  currentEmployerId = "";

  selectedEmployer = {};

  searchTerm;

  searchName = "Saved search";

  params = {};

  currentTab = "";

  talentCount = 0;

  folderStages = {};

  searchCandidates = [];
  searchCandidatesMeta = {};

  isStopUpdatePage = false;

  personality = {
    meta: {},
    personalityResults: {}
  };

  talentResults = {
    meta: {},
    results: []
  };

  get appliedFilterCount() {
    return Object.values({ ...this.appliedFilters, sort_by: null }).filter(value => {
      return (
        value && (value.length > 0 || value.ids?.length > 0 || value.operator?.length > 0 || Number.isInteger(value))
      );
    }).length;
  }

  get paramsCount() {
    return Object.keys(this.params).length;
  }

  get folderName() {
    const folder = this.folders && this.folders.find(item => item.id == this.currentFolder[0]);
    return folder ? folder.name : "Folder";
  }

  get searchesCount() {
    return this.searches.length;
  }

  onSectionMount = async employerId => {
    this.state = {
      loading: true,
      error: false,
      type: "",
      message: "Loading..."
    };
    return API.getInitalState()
      .then(res => {
        this.currentEmployerId = employerId;
        this.state.loading = false;
        this.getSearches();
        if (this.folders.length === 0) this.getFolders();
        if (this.campaigns.length === 0) this.getActiveCampaigns(employerId);
        if (this.companies.length === 0) this.getActiveCompanies();
        return res.data;
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          type: "hh_init_failure",
          message: "Failed to retrieve initial data",
          callback: () => this.onSectionMount(employerId)
        };
      });
  };

  applyFilter = filter => {
    Object.keys(filter).map(key => {
      if (!filter[key] && filter[key] !== 0) delete filter[key];
    });

    if (filter.term) {
      this.searchTerm = filter.term;
      delete filter.term;
    }

    this.appliedFilters = { ...this.appliedFilters, ...filter };
    if (this.paramsCount === 0) this.applyParams({ page: 1, per_page: 10 });
  };

  resetFilters = (resetParams = true, resetFolder, resetTerm) => {
    this.appliedFilters = {};
    if (resetParams) this.params = {};
    if (resetTerm) this.searchTerm = undefined;
    if (resetFolder) this.currentFolder = [];
    this.talents = [];
  };

  applyParams = param => {
    if (Object.keys(param).some(key => param[key] != this.params[key])) this.params = { ...this.params, ...param };
  };

  applyTerm = term => {
    this.searchTerm = term;
    if (this.paramsCount === 0) this.applyParams({ page: 1, per_page: 10 });
  };

  setFolder = id => {
    this.currentFolder = [id];
    if (this.paramsCount === 0) this.applyParams({ page: 1, per_page: 10 });
  };

  setCurrentTab = tab => {
    if (tab !== this.currentTab) {
      this.currentTab = tab;
    }
    if (tab === TABS.OPEN) {
      this.applyParams({ page: 1, per_page: 10 });
    }
  };

  setSearchName = name => {
    this.searchName = name;

    return this.searchName;
  };

  getFolders = () => {
    API.getFolderList()
      .then(res => {
        this.folders = res.data.folders;
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          type: "hh_folders_failure",
          message: "Failed to retrieve saved folders",
          callback: () => this.getFolders()
        };
      });
  };

  createFolder = name => {
    return API.createFolder(name)
      .then(res => res.data.folder)
      .catch(err => {
        this.state = {
          loading: false,
          error: true,
          type: `${name}_create_failure`,
          message: err.message
        };
      });
  };

  deleteFolder = id => {
    return API.deleteFolder(id)
      .then(res => {
        this.state = {
          loading: false,
          error: false,
          type: `folder_${id}_delete_success`,
          message: res.data.success
        };
      })
      .then(() => {
        this.getFolders();
      })
      .catch(err => {
        this.state = {
          loading: false,
          error: true,
          type: `folder_${id}_delete_failure`,
          message: err.message,
          callback: () => this.deleteFolder(id)
        };
      });
  };

  addToFolder = (folder, candidateIds, stageId) => {
    return API.addToFolder(folder, candidateIds, stageId)
      .then(() => {
        this.state = {
          loading: false,
          error: false,
          type: `${folder}_${candidateIds}_success`,
          message: "Successfully added to folder"
        };
      })
      .catch(err => {
        this.state = {
          loading: false,
          error: true,
          type: `${folder}_${candidateIds}_failure`,
          message: err.message,
          callback: () => this.addToFolder(folder, candidateIds, stageId)
        };
      });
  };

  moveToStage = (stageId, candidateIds, folderId) => {
    return API.moveToStage(stageId, candidateIds)
      .then(() => {
        if (folderId) {
          window.setTimeout(() => {
            this.getFolderStages(folderId);
          }, 500);
        }

        this.talents = this.talents.filter(item => !candidateIds.includes(item.id));
        this.state = {
          loading: false,
          error: false,
          type: `${stageId}_${candidateIds}_success`,
          message: "Successfully moved to folder stage"
        };
      })
      .catch(err => {
        this.state = {
          loading: false,
          error: true,
          type: `${stageId}_${candidateIds}_failure`,
          message: err.message,
          callback: () => this.moveToStage(stageId, candidateIds)
        };
      });
  };

  renameFolder = (folderId, name) => {
    return API.renameFolder(folderId, name)
      .then(() => {
        this.folders = this.folders.map(item => {
          if (String(item.id) === String(folderId)) {
            return { ...item, name };
          }
          return item;
        });

        this.state = {
          loading: false,
          error: false,
          type: `${folderId}_${name}_success`,
          message: "Successfully renamed folder"
        };
      })
      .catch(err => {
        this.state = {
          loading: false,
          error: true,
          type: `${folderId}_${name}_failure`,
          message: err.message,
          callback: () => this.moveToStage(folderId, name)
        };
      });
  };

  getFolderStages = (folderId, isSelectFirst) => {
    return API.getFolderStages(folderId)
      .then(result => {
        const normalizeStages = result.data.stages.reduce((acc, current) => {
          acc[current.id] = current;
          return acc;
        }, {});

        normalizeStages.all = result.data.meta;

        this.folderStages = normalizeStages;

        if (isSelectFirst && result.data.stages.length) {
          this.applyFilter({ headhunting_folder_stage_relation_ids: [result.data.stages[0].id] });
        }
      })
      .catch(err => {
        this.state = {
          loading: false,
          error: true,
          type: `${folderId}_failure`,
          message: err.message,
          callback: () => this.getFolderStages(folderId)
        };
      });
  };

  removeFromFolder = (folder, candidateIds) => {
    return API.removeFromFolder(folder, candidateIds)
      .then(res => {
        this.state = {
          loading: false,
          error: false,
          type: `${folder}_${candidateIds}_remove_success`,
          message: res.data.success
        };
        this.talentCount -= candidateIds.length;
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          type: `${folder}_${candidateIds}_remove_failure`,
          message: "Failed to remove talents",
          callback: () => this.removeFromFolder(folder, candidateIds)
        };
      });
  };

  getAvailableJobForCandidate = candidateIds => {
    return API.getAvailableJobForCandidate(stringify({ candidates: candidateIds }))
      .then(res => {
        this.availableJobsForCandidate = res.data.campaigns;
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          message: "Failed to remove get available job for candidate",
          callback: () => this.getAvailableJobForCandidate(stringify({ candidates: candidateIds }))
        };
      });
  };

  inviteCandidate = (campaignId, query) => {
    return API.inviteCandidate(campaignId, query)
      .then(response => {
        if (response.data?.invitations?.length) {
          this.handleSelectedAvailableJobForCandidate({});
          this.state = {
            loading: false,
            error: false,
            type: `${campaignId}_talent_invite_success`,
            message: "Successfully invited candidate"
          };
        } else {
          this.state = {
            loading: false,
            error: true,
            message: "Failed to invite candidate",
            callback: () => this.inviteCandidate(campaignId, query)
          };
        }
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          message: "Failed to invite candidate",
          callback: () => this.inviteCandidate(campaignId, query)
        };
      });
  };

  getSearches = () => {
    API.getSearchList()
      .then(res => {
        this.searches = res.data.saved_searches;
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          type: "hh_searches_failure",
          message: "Failed to retrieve saved searches",
          callback: () => this.getSearches()
        };
      });
  };

  getSearch = id => {
    API.getSearch(id)
      .then(res => {
        this.searchName = res.data.meta.saved_search.name;
        this.applyFilter(res.data.meta.filters);
        if (res.data.meta.filters.term) this.applyTerm(res.data.meta.filters.term);
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          type: "hh_saved_search_failure",
          message: "Failed to retrieve search results",
          callback: () => this.getSearch(id)
        };
      });
  };

  saveSearch = (name, filters) => {
    if (this.searchTerm) filters.term = this.searchTerm;
    return API.saveSearch(name, filters)
      .then(() => {
        this.state = {
          loading: false,
          error: false,
          type: `${name}_search_save_success`,
          message: "Successfully saved search parameters"
        };
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          type: `${name}_search_save_failure`,
          message: "Failed to save search results",
          callback: () => this.saveSearch(name, filters)
        };
      });
  };

  deleteSearch = id => {
    return API.deleteSearch(id)
      .then(() => {
        this.searches = this.searches.filter(search => search.id !== +id);
        this.state = {
          loading: false,
          error: false,
          type: `${id}_search_delete_success`,
          message: "Successfully deleted search"
        };
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          type: `${id}_search_delete_failure`,
          message: "Failed to delete search",
          callback: () => this.deleteSearch(id)
        };
      });
  };

  getTalent = (id, force, employerId) => {
    if (String(id) !== String(this.currentTalent.id) || force) {
      this.state.loading = true;
      if (this.state.type) this.state.type = "";
      if (this.currentTab === "open") {
        return API.getOpenApplicationDetails(id)
          .then(async res => {
            const talent = {
              ...res.data.open_application_details,
              ...res.data.open_application_details.user,
              ...res.data.open_application_details.user_details,
              user: null,
              user_details: null
            };
            delete talent.details;
            if (this.folders.length === 0) this.getFolders();
            if (this.campaigns.length === 0) this.getActiveCampaigns(employerId);
            if (this.companies.length === 0) this.getActiveCompanies();

            if (this.talents.length === 0) {
              await this.getTalentList({ page: 1, per_page: 10 }, talent);
            }

            this.currentTalent = talent;

            this.state.loading = false;

            return res.data.open_application_details.user.user_id;
          })
          .then(userId => {
            this.getComments(userId).then(comments => {
              this.currentTalent.comments = comments;

              return this.currentTalent.comments;
            });
          })
          .catch(err => {
            this.state = {
              loading: false,
              error: true,
              type: err.message,
              message: err.message,
              callback: () => this.getTalent(id, force)
            };
          });
      }
      return Promise.all([API.getTalentDetails(id), this.getComments(id)])
        .then(async res => {
          const talent = {
            ...res[0].data.talent,
            ...res[0].data.talent.details,
            comments: res[1]
          };
          delete talent.details;
          if (this.folders.length === 0) this.getFolders();
          if (this.campaigns.length === 0) this.getActiveCampaigns(employerId);
          if (this.companies.length === 0) this.getActiveCompanies();

          if (this.talents.length === 0) {
            await this.getTalentList({ page: 1, per_page: 10 }, talent);
          }
          this.currentTalent = talent;

          this.state.loading = false;
        })
        .catch(err => {
          this.state = {
            loading: false,
            error: true,
            type: err.message,
            message: err.message,
            callback: () => this.getTalent(id)
          };
        });
    }
  };

  resetTalent = () => {
    this.currentTalent = {};
  };

  shortlistTalents = (id, talents) => {
    return API.shortlistTalents(id, talents)
      .then(() => {
        this.state = {
          loading: false,
          error: false,
          type: `${id}_talent_shortlist_success`,
          message: "Successfully added to campaign",
          callback: id
        };
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          type: `${id}_talent_shortlist_failure`,
          message: "Could not add talents to campaign",
          callback: () => this.shortlistTalents(id, talents)
        };
      });
  };

  getTalentActivity = (id, page = 1, isShowOnlyNew, employer) => {
    const query = employer ? stringify({ filters: { employer } }) : "";

    return API.getTalentActivity(id, page, query)
      .then(res => {
        const newCandidates = this.applicationHistory.length
          ? [...this.applicationHistory, ...res.data.candidates]
          : res.data.candidates;

        this.applicationHistory = isShowOnlyNew ? res.data.candidates : newCandidates;
        this.applicationHistoryMeta = res.data.meta;
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          type: `${id}_talent_activity_failure`,
          message: "Could not get talent activity",
          callback: () => this.getTalentActivity(id, (page = 1), isShowOnlyNew, employer)
        };
      });
  };

  getTalentList = (applied = { page: 1, per_page: 10 }, talent) => {
    const query = stringify(applied);
    let tempTalents = [...this.talents];
    if (this.currentTab === "open") {
      return API.getOpenApplications(query).then(res => {
        tempTalents = res.data.open_applications.map(item => ({ ...item, ...item.user }));
        if (!!talent && this.talents.length == 0 && !tempTalents.some(user => user.id == talent?.id))
          tempTalents.unshift(talent);
        this.talents = tempTalents;
        this.talentCount = res.data.meta.total_count;
      });
    }
    return API.getHeadhuntTalents(query).then(res => {
      tempTalents = [...res.data.talents];

      if (talent && this.talents.length === 0 && !tempTalents.some(user => String(user.id) === String(talent?.id))) {
        tempTalents.unshift(talent);
      }

      this.talents = tempTalents;
      this.talentCount = res.data.meta.total_count;
    });
  };

  getFilters = (applied = { page: 1, per_page: 10 }) => {
    const query = stringify(applied);
    return (this.currentTab === "open" ? API.getOpenApplicationFilters(query) : API.getHeadhuntFilters(query)).then(
      res => {
        this.talentFilters = res.data.filters;
      }
    );
  };

  getActiveCampaigns = (employerId, employer, candidateIds) => {
    return API.getActiveCampaigns(employerId, stringify({ candidates: candidateIds })).then(res => {
      this.campaigns = res.data.campaigns;
      this.availableJobsForCandidate = res.data.campaigns;
      this.selectedEmployer = employer || this.selectedEmployer;
      this.selectedAvailableJobForCandidate = {};
    });
  };

  getActiveCompanies = () => {
    return API.getActiveCompanies().then(res => {
      this.companies = res.data.companies;
    });
  };

  getCampaignFilters = id => {
    return API.getCamapignFilters(id)
      .then(res => {
        this.applyFilter(res.data.filters);
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          type: `${id}_campaign_filters_failure`,
          message: "Could not retrieve filters",
          callback: () => this.getCampaignFilters(id)
        };

        return this.state;
      });
  };

  getComments = id => {
    return API.getComments(id)
      .then(res => {
        this.currentTalent.comments = res.data.comments;

        return res.data.comments;
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          type: `${id}_comments_failure`,
          message: "Could not retrieve comments",
          callback: () => this.getComments(id)
        };
      });
  };

  addComment = (id, body, visibility) => {
    return API.addComment(id, body, visibility)
      .then(res => res.data.comment)
      .catch(error => {
        this.state = {
          loading: false,
          error: true,
          type: `${id}_add_comment_failure`,
          message: error.response.data.error || "Could not add comment",
          callback: () => this.addComment(id, body, visibility)
        };
      });
  };

  removeComment = (talent_id, comment_id) => {
    return API.removeComment(talent_id, comment_id)
      .then(res => {
        this.state = {
          loading: false,
          error: false,
          type: `${talent_id}_remove_comment_success`,
          message: res.data.success
        };
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          type: `${talent_id}_remove_comment_failure`,
          message: "Could not remove comment",
          callback: () => this.removeComment(talent_id, comment_id)
        };
      });
  };

  getUserPersonality = userId => {
    return API.getUserPersonality(userId)
      .then(res => {
        this.personality.meta = res.data.meta;
        this.personality.personalityResults = res.data.personality_results;

        this.state = {
          loading: false,
          error: false,
          type: `${userId}_get_user_personality`,
          message: res.data.success
        };
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          type: `${userId}_get_user_personality_failure`,
          message: "Could not get user personality",
          callback: () => this.getUserPersonality(userId)
        };
      });
  };

  exportCvProfile = (userId, isIncognito) => {
    this.state = {
      loading: true,
      error: false,
      type: `${userId}_export_cv_profile`,
      message: "fsadfasdfasdfLoafasdfasdfding..sadfasdf."
    };

    return API.exportCvProfile(userId, isIncognito)
      .then(res => {
        window.open(res.data.url);

        this.state = {
          loading: false,
          error: false,
          type: `${userId}_export_cv_profile`,
          message: res.data.success
        };
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          type: `${userId}_export_cv_profile_failure`,
          message: "Could not export cv profile",
          callback: () => this.exportCvProfile(userId, isIncognito)
        };
      });
  };

  exportBatchCvProfile = (userIds, isIncognito) => {
    this.state = {
      loading: true,
      error: false,
      type: "",
      message: "Loading..."
    };

    return API.exportBatchCvProfile(userIds, isIncognito)
      .then(() => {
        this.state = State.setSuccess(
          this.state,
          {},
          "Exported profiles are being processed. You will receive an email once they are ready",
          false,
          true
        );
      })
      .catch(() => {
        this.state = {
          loading: true,
          error: true,
          type: `${userIds}_export_batch_cv_profiles`,
          message: "Could not export cv batch profiles",
          callback: () => this.exportCvProfile(userIds, isIncognito)
        };
      });
  };

  getUserTalents = userId => {
    return API.getUserTalents(userId)
      .then(res => {
        this.talentResults.results = res.data.talent_results;

        this.state = {
          loading: false,
          error: false,
          type: `${userId}_get_user_talents`,
          message: res.data.success
        };
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          type: `${userId}_get_user_talents_failure`,
          message: "Could not get user talents",
          callback: () => this.getUserTalents(userId)
        };
      });
  };

  addTag = (id, tag) => {
    return API.addTag(id, tag).catch(() => {
      this.state = {
        loading: false,
        error: true,
        type: `${id}_add_tag_failure`,
        message: "Could not add tag",
        callback: () => this.addTag(id, tag)
      };
    });
  };

  removeTag = (id, tag) => {
    return API.removeTag(id, tag).catch(() => {
      this.state = {
        loading: false,
        error: true,
        type: `${id}_remove_tag_failure`,
        message: "Could not add tag",
        callback: () => this.removeTag(id, tag)
      };
    });
  };

  handleSelectedAvailableJobForCandidate = availableJob => {
    this.selectedAvailableJobForCandidate = availableJob;
  };

  resetSelectedEmployer = () => {
    this.selectedEmployer = {};
  };

  handleStopUpdatePage = () => {
    this.isStopUpdatePage = true;
  };

  // User unlocking
  unlockUser = (user_id, candidateId, isReloadPage) => {
    return unlockUser(user_id, candidateId)
      .then(res => {
        this.currentTalent = { ...this.currentTalent, ...res.data.candidate_details };
        this.state = {
          loading: false,
          type: `${user_id}_user_unlocked_success`,
          error: false,
          message: "User unlocked"
        };

        if (isReloadPage) {
          window.location.reload();
        }
        return res.data.meta;
      })
      .catch(err => {
        this.state = {
          loading: false,
          type: `${user_id}_user_unlocked_failure`,
          error: true,
          message: err.response.data.error || "Failed to unlock user",
          callback: () => {
            this.state.type = "";
            return this.unlockUser(user_id, candidateId, isReloadPage);
          }
        };

        return this.state;
      });
  };

  getCommonCountries = () => {
    return API.getCommonCountries()
      .then(res => {
        this.commonCountries = res.data.results;
      })
      .catch(err => {
        this.state = {
          type: "get_countries_failure",
          loading: false,
          error: true,
          message: err.response.data.error || "Failed to getting geonames countries",
          callback: () => {
            this.state.type = "";
            this.getCommonCountries();
          }
        };
      });
  };

  getSearchCandidatesDetail = (id, type) => {
    return API.getSearchCandidatesDetail(id, type)
      .then(res => {
        this.currentTalent = {
          ...res.data.candidate,
          ...res.data.candidate.details
        };
        this.state = {
          loading: false,
          error: false,
          type: "get_search_candidates_detail_success",
          message: res.data.success
        };
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          type: "get_search_candidates_detail_failure",
          message: "Could not get Search Candidates Detail",
          callback: () => this.getSearchCandidatesDetail(id, type)
        };
      });
  };

  getSearchCandidates = (filters = {}, history) => {
    const { page, per_page, term, ...filtersRest } = filters;
    const updateTerm = term ? term.replaceAll("+", "%2B") : "";
    const query = filters ? stringify({ filters: { ...filtersRest, term: updateTerm }, page, per_page }) : "";
    this.candidateSearchAppliedFilters = filters;

    if (history) {
      history.push(`/candidates?${query}`);
    }

    this.state = {
      loading: true,
      error: false,
      hidden: false,
      type: "loading",
      message: "Just a moment! We are loading your candidates..."
    };

    return API.getSearchCandidates(query)
      .then(res => {
        this.searchCandidates = res.data.candidates;
        this.searchCandidatesMeta = res.data.meta;
        this.state = {
          loading: false,
          error: false,
          hide: true,
          type: "get_search_candidates_success",
          message: ""
        };
      })

      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          type: "get_search_candidates_failure",
          message: "Could not get Search Candidates",
          callback: () => this.getSearchCandidates(query)
        };
      });
  };

  getSearchCandidatesApplications = (id, page = 1, isShowOnlyNew, employer, type) => {
    const query = employer ? stringify({ filters: { employer } }) : "";

    return API.getSearchCandidatesApplications(id, page, query, type)
      .then(res => {
        const newCandidates = this.applicationHistory.length
          ? [...this.applicationHistory, ...res.data.applications]
          : res.data.applications;

        this.applicationHistory = isShowOnlyNew ? res.data.applications : newCandidates;
        this.applicationHistoryMeta = res.data.meta;
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          type: `${id}_talent_activity_failure`,
          message: "Could not get talent activity",
          callback: () => this.getSearchCandidatesApplications(id, page, isShowOnlyNew, employer, type)
        };
      });
  };

  getSearchCandidatesUserFilter = (search, filters) => {
    const query = stringify({ filters: { ...search, ...filters } });

    return API.getSearchCandidatesUserFilter(query, filters)
      .then(res => {
        this.candidateSearchFilters = {
          ...this.candidateSearchFilters,
          ...res.data.filters
        };
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          type: "search_candidates_filters_failure",
          message: "Could not get search candidates filters",
          callback: () => this.getSearchCandidatesUserFilter(search)
        };
      });
  };

  getCVUrl = url => {
    this.state = {
      loading: true,
      error: false,
      type: `${url}_get_cv_url_loading`,
      message: "Just a moment!",
      callback: () => this.getCVUrl(url)
    };

    return axios
      .get(getUrlPath(url))
      .then(res => {
        return res.data;
      })
      .catch(() => {
        this.state = {
          loading: false,
          error: true,
          type: `${url}_get_cv_url_error`,
          message: "Failed to get CV",
          callback: () => this.getCVUrl(url)
        };
      });
  };
}

const HeadhuntingStore = new Store();

export default HeadhuntingStore;
