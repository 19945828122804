import styled from "styled-components";
import { theme } from "b2c/contexts/theme";
import { Button } from "../../Core";

export const Container = styled.div`
  width: 340px;
  overflow: hidden;
  margin: 0 auto;
  margin-top: -16px;

  @media screen and (min-width: 768px) {
    width: 465px;
    padding: 0 16px 1px 16px;
    margin-top: -24px;
  }
`;

export const LogoContent = styled.div`
  width: 48px;
  height: 48px;
  margin: 0 auto 32px auto;

  svg {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 768px) {
    width: 72px;
    height: 72px;
  }
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #333333;
  display: block;
  margin: 0 0 26px 0;
  text-align: center;

  span {
    color: #408bfc;
  }
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #333333;
  display: block;
  margin: 0 0 12px 0;
  display: flex;
  align-items: center;
  gap: 8px;

  &:last-child {
    margin: 0;
  }
`;

export const CheckContent = styled.span`
  width: 24px;
  height: 24px;
  flex: none;

  svg {
    width: 100%;
    height: 100%;
    fill: #408bfc;
  }
`;

export const LoginContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #666666;
  }
`;

export const LoginButton = styled(Button.Link)`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  border: none;
  height: auto;
  background-color: transparent;

  span {
    background-color: transparent;
    color: #408bfc;
    padding: 0;
  }
`;

export const SignUpButton = styled(Button.Link)`
  background: ${theme.primary};
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  display: block;
  margin: 32px 0 24px 0;

  span {
    color: #000000;
    padding: 0;
  }
`;
