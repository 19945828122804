import axios from "axios";

export const getActiveCampaigns = (employerId, candidateIds) =>
  axios.get(
    `/api/v1/employers/campaigns/active${employerId ? `?employer_id=${employerId}` : ""}${
      candidateIds ? `&${candidateIds}` : ""
    }`
  );

export const getActiveCompanies = () => axios.get("/api/v1/employers/companies/with_active_campaigns");

export const getAvailableJobForCandidate = candidateIds => axios.get(`/api/v1/employers/campaigns?${candidateIds}`);

export const getCamapignFilters = id => axios.get(`/api/v1/employers/campaigns/${id}/filters`);

export const getTalentActivity = (id, page = "", query) => {
  return axios.get(
    `/api/v1/employers/headhunting/talents/${id}/applications?page=${page}&per_page=5${query ? `&${query}` : ""}`
  );
};

const search_path = "/api/v1/employers/headhunting/search";

export const getInitalState = () => axios.get(`${search_path}/initial-state`);

export const getHeadhuntFilters = query => axios.get(`${search_path}/filters?${query}`);

export const getHeadhuntTalents = query => axios.get(`${search_path}/talents?${query}`);

const talent_path = "/api/v1/employers/headhunting/talents";

export const getTalentDetails = id => axios.get(`${talent_path}/${id}`);

const folder_path = "/api/v1/employers/headhunting/folders";

export const getFolderList = () => axios.get(folder_path);

export const createFolder = name =>
  axios.post(folder_path, {
    headhunting_folder: {
      name
    }
  });

export const deleteFolder = id => axios.delete(`${folder_path}/${id}`);

export const addToFolder = (folderId, candidate_ids, stageId) =>
  axios.post(`${folder_path}/${folderId}/add`, {
    candidate_ids,
    stage_relation_id: stageId
  });

export const renameFolder = (folderId, name) =>
  axios.patch(`${folder_path}/${folderId}`, {
    headhunting_folder: { name }
  });

export const moveToStage = (stageId, candidate_ids) => {
  return axios.post(`${folder_path}/move_to_stage/${stageId}`, {
    candidate_ids
  });
};

export const getFolderStages = folderId => {
  return axios.get(`${folder_path}/${folderId}/stages`);
};

export const removeFromFolder = (folderId, candidate_ids) =>
  axios.post(`${folder_path}/${folderId}/remove`, {
    candidate_ids
  });

const searches_path = "/api/v1/employers/headhunting/saved_searches";

export const getSearchList = () => axios.get(searches_path);

export const getSearch = id => axios.get(`${searches_path}/${id}`);

export const saveSearch = (name, filters) =>
  axios.post(searches_path, {
    saved_search: { name, filters }
  });

export const deleteSearch = id => axios.delete(`${searches_path}/${id}`);

export const shortlistTalents = (campaign_id, candidate_ids) =>
  axios.post(`/api/v1/employers/campaigns/${campaign_id}/candidates/headhunting_phases/shortlist`, { candidate_ids });

export const inviteCandidate = (campaignId, query) =>
  axios.post(`/api/v1/employers/campaigns/${campaignId}/invitations/invite`, query);

const comments_path = id => `/api/v1/employers/headhunting/${id}/comments`;

export const getComments = talent_id => axios.get(comments_path(talent_id));

export const getCommonCountries = () => axios.get("/api/v1/common/locations/countries");

export const addComment = (id, body, visibility) => axios.post(comments_path(id), { body, visibility });

export const removeComment = (talent_id, id) => axios.delete(`${comments_path(talent_id)}/${id}`);

export const addTag = (id, tag) =>
  axios.post(`/api/v1/employers/headhunting/talents/${id}/tags`, {
    operation: "add",
    tag
  });

export const removeTag = (id, tag) =>
  axios.post(`/api/v1/employers/headhunting/talents/${id}/tags`, {
    operation: "remove",
    tag
  });

// Open applications

const open_applications_path = "/api/v1/employers/headhunting/open_applications";

export const getOpenApplications = query => axios.get(`${open_applications_path}/search?${query}`);

export const getOpenApplicationFilters = query => axios.get(`${open_applications_path}/filters?${query}`);

export const getOpenApplicationDetails = id => axios.get(`${open_applications_path}/${id}`);

export const getTalentPersonality = id =>
  axios.get(`/api/v1/employers/headhunting_candidates/${id}/personality_results`);

export const getUserPersonality = usedId => axios.get(`/api/v1/employers/users/${usedId}/tests/personality`);
export const getUserTalents = usedId => axios.get(`/api/v1/employers/users/${usedId}/tests/talents`);
export const exportCvProfile = (userId, isIncognito) =>
  axios.get(`/api/v1/employers/users/cv/${userId}/export?incognito=${isIncognito}`);
export const exportBatchCvProfile = (userIds, isIncognito) =>
  axios.get("/api/v1/employers/users/cv/batch_export", { params: { user_ids: userIds, incognito: isIncognito } });

export const getSearchCandidates = query => axios.get(`/api/v1/employers/candidates/users${query ? `?${query}` : ""}`);

export const getSearchCandidatesDetail = (id, type = "user") =>
  axios.get(`/api/v1/employers/candidates/users/${type}_${id}`);

export const getSearchCandidatesApplications = (id, page = "", query, type = "user") => {
  return axios.get(`/api/v1/employers/${type}s/${id}/applications?page=${page}&per_page=5${query ? `&${query}` : ""}`);
};

export const getSearchCandidatesUserFilter = query => {
  return axios.get(`/api/v1/employers/candidates/users/filters${query ? `?${query}` : ""}`);
};
