import React, { useContext } from "react";
import styled from "styled-components";

import LoadingImage from "images/b2c/loading.svg";
import Text from "../Text";

import ThemeContext from "../../../contexts/theme";

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin};
  min-height: ${({ minHeight = 0 }) => minHeight}px;
`;

const StyledSpinner = styled(LoadingImage)`
  ${({ size, primary, secondary }) => `
    @keyframes loadingRotate {
      0% {
        transform: rotate(0) scale(1);
      }
      50% {
        transform: rotate(180deg) scale(1.05);
      }
      100% {
        transform: rotate(360deg) scale(1);
      }
    }
    animation: loadingRotate 1s infinite linear;
    width: ${size};
    height: ${size};
    margin: auto;

    > path {
      &:first-child {
        fill: ${secondary};
      }
      &:last-child {
        fill: ${primary};
      }
    }
  `}
`;

const Spinner = ({
  size = "20px",
  margin = "0px",
  message = "",
  primaryFill = "primary",
  secondaryFill = "grey100",
  minHeight,
  messageProps,
  ...rest
}) => {
  const theme = useContext(ThemeContext);

  return (
    <SpinnerWrapper margin={margin} minHeight={minHeight} {...rest}>
      <StyledSpinner size={size} primary={theme[primaryFill]} secondary={theme[secondaryFill]} />
      {message && (
        <Text fontSize={16} margin="8px auto 0" {...messageProps}>
          {message}
        </Text>
      )}
    </SpinnerWrapper>
  );
};

export const LoadingContent = ({ loading = false, children, ...spinnerProps }) =>
  loading ? <Spinner {...spinnerProps} /> : children;

export default Spinner;
