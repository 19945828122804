import React from "react";
import { fullPath } from "../../utils/hooks";

const LinkWithFullUrl = ({ to, children, ...rest }) => {
  return (
    <a href={fullPath(to)} {...rest}>
      {children}
    </a>
  );
};

export default LinkWithFullUrl;
