import React, { useContext } from "react";
import ThemeContext from "b2c/contexts/theme";

import { Text, Title, SwitchInput } from "b2c/components/Core";
import { NotificationItemContent, titleStyle, textStyles, switchStyle } from "./styled";

const NotificationItem = ({ Icon, title, checked, text, onChange, id, isNotChangeByDefault }) => {
  const Theme = useContext(ThemeContext);

  return (
    <NotificationItemContent>
      <Icon width={20} fill={Theme.secondary300} style={{ flex: "none" }} />
      <div>
        <Title style={titleStyle}>{title}</Title>
        {text && <Text style={textStyles}>{text}</Text>}
      </div>
      <SwitchInput
        id={id}
        checkedColor="secondary300"
        style={switchStyle}
        checked={checked}
        onChange={onChange}
        isNotChangeByDefault={isNotChangeByDefault}
      />
    </NotificationItemContent>
  );
};

export default NotificationItem;
