import styled from "styled-components";

import { Link } from "react-router-dom";

export const NavWrapper = styled.nav(props => props.$styled);

export const NavContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }

  ${props => props.$styled}
`;

export const NavLeftContent = styled.div`
  flex: 1 1 auto;
  white-space: nowrap;
  align-self: stretch;
  display: flex;
  max-width: 100%;
  min-width: 0px;
`;

export const NavRightContent = styled.div`
  flex: 1 0 40%;
  width: 100%;
  margin-left: 8px;
  max-width: 40%;

  @media screen and (max-width: 767px) {
    margin-left: 0px;
    max-width: 100%;
    flex-basis: 100%;
  }
`;
export const NavScrollContent = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &:after {
    content: "";
    width: 0;
    min-height: 50px;
    position: sticky;
    right: 0;
    background-color: ${({ $theme }) => $theme};
    display: inline-flex;
    flex: 0 0 0;
    margin-left: auto;
    box-shadow: 0px 0 10px 15px ${({ $theme }) => $theme};
  }
`;

export const NavItem = styled(Link).attrs()`
  ${({ selected, $color, $altcolor, $focusColor, $theme }) => `
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    min-height: 56px;
    padding: 0 calc(10px + 0.5%);
    color: ${selected ? $altcolor : $color};
    font-weight: 500;
    transition: .2s ease;
    user-select: none;
    background-color: ${$theme};
    box-shadow: ${selected ? `inset 0 -2px 0px 0px #408BFC, 0 0 10px 5px ${$theme};` : "none"};

    position: ${selected ? "sticky" : "static"};
    left: 0;
    right: 0;
    z-index: ${selected ? "2" : "0"};


    &:hover {
        color: ${$altcolor};
    }
    &:focus {
        background-color: ${$focusColor};
        outline: none;
        z-index: 3;
    }
  `}
  ${({ $styled }) => $styled}
`;
