import { makeAutoObservable, reaction, toJS } from "mobx";

import { MESSAGE_TAB_NAMES } from "constants/messages";
import * as State from "utils/storeHelpers";
import * as API from "utils/api";
import { stringify } from "utils/qsUtil";

const convertDate = string => {
  const date = new Date(string).toLocaleDateString("en-US", { weekday: "short", day: "numeric", month: "short" });
  const today = new Date().toLocaleDateString("en-US", { weekday: "short", day: "numeric", month: "short" });

  return date === today ? "Today" : date;
};

class MessagesStore {
  userStore;

  timer = {
    update: null,
    conversations: null,
    messageChain: null
  };

  activeSegments = {
    conversations: false,
    messageChain: false
  };

  draftMessages = [];

  activeTab = "conversations";

  filterMessages = false;

  constructor(userStore) {
    makeAutoObservable(this);

    this.userStore = userStore;

    reaction(
      () => !!(userStore?.user?.onboarding_steps_completed && !this.activeSegments?.conversations),
      authorized => {
        if (authorized) {
          this.getMessagesStatus();
          this.timer.update = setInterval(this.getMessagesStatus, 600000);
        } else {
          clearInterval(this.timer.update);
        }
      },
      { fireImmediately: true }
    );

    reaction(
      () => this.draftMessages.length,
      length => {
        if (length > 0) {
          const stringifiedDraft = JSON.stringify(toJS(this.draftMessages)) || [];
          window.localStorage.setItem("draftMessages", stringifiedDraft);
        }
      }
    );

    reaction(
      () => this.activeSegments?.conversations,
      active => {
        if (active) {
          this.timer.conversations = setInterval(() => this.getTabItems(this.activeTab, true), 30000);
        } else {
          clearInterval(this.timer.conversations);
        }
      },
      { fireImmediately: false }
    );

    reaction(
      () => this.activeSegments?.messageChain,
      active => {
        const toggle = this.userStore?.toggleNavigation;
        if (active) {
          if (toggle) toggle({ desktop: true, mobile: false });
          this.timer.messageChain = setInterval(this.refreshMessages, 15000);
        } else {
          if (toggle) toggle({ desktop: true, mobile: true });
          clearInterval(this.timer.messageChain);
        }
      },
      { fireImmediately: false }
    );

    reaction(
      () => this.status?.unread_channels_count === this.status?.unread_conversations_count,
      exceeds => {
        if (!this.activeSegments?.conversations) {
          this.setActiveTab(exceeds ? MESSAGE_TAB_NAMES.SUPPORT : MESSAGE_TAB_NAMES.CONVERSATIONS);
        }
      }
    );
  }

  state = {
    loading: true,
    processing: false,
    type: "",
    message: ""
  };

  isFirstLoading = true;

  conversations;

  channels;

  messages;

  lastAdded = {};

  currentConversationId;

  lastRetrievedPage = 1;

  retirevedConversationsCount = 12;

  status = {};

  get unreadCount() {
    return (
      this.status?.unread_channels_count +
      (this.status?.unread_inquiries_count || this.status?.unread_conversations_count)
    );
  }

  get sortedConversations() {
    return this.conversations
      ? [...this.conversations]
          .sort((a, b) => new Date(b.last_message?.created_at) - new Date(a.last_message?.created_at))
          .sort((a, b) => b.pinned - a.pinned)
      : null;
  }

  get groupedMessages() {
    if (this.messages) {
      const tempMessages = [...this.messages];
      const dates = [
        ...new Set(
          tempMessages
            ?.slice()
            .map(message => message.created_at)
            .sort((a, b) => new Date(a) - new Date(b))
            .map(date => convertDate(date))
        )
      ];
      const groupedByDate = dates?.map(date => {
        const matching = tempMessages
          .slice()
          .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
          .filter(m => convertDate(m.created_at) == date);
        return { date, messages: matching };
      });

      return groupedByDate;
    }
  }

  disableMessenger = () => {
    this.activeSegments = {
      conversations: false,
      messageChain: false
    };
  };

  disableMessageChain = () => {
    this.activeSegments = {
      ...this.activeSegments,
      messageChain: false
    };
  };

  clearMessages = () => {
    this.messages = null;
    this.disableMessageChain();
  };

  setActiveTab = tab => {
    if (this.activeTab !== tab) {
      this.activeTab = tab || "conversations";
      this.lastRetrievedPage = 1;
      this.conversations = null;
      this.channels = null;
      this.retirevedConversationsCount = 12;
      this.getTabItems(tab);
    }
  };

  initializeMessenger = () => {
    const storedDraft = window.localStorage.getItem("draftMessages");
    if (storedDraft) this.draftMessages = JSON.parse(storedDraft);
    this.activeSegments.conversations = true;

    return this.getTabItems();
  };

  getMessagesStatus = () => {
    this.state = State.setLoading(this.state);
    return API.getMessagesStatus()
      .then(res => {
        this.status = res.data;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to check messages status", () => this.getMessagesStatus());
      });
  };

  getConversations = () => {
    this.state = State.setLoading(this.state);
    const getItems = this.activeTab === "conversations" ? API.getConversations : API.getChannelConversations;
    const page = this.conversations.length ? this.lastRetrievedPage + 1 : 1;

    return getItems(page, 12, this.filterMessages)
      .then(res => {
        this.conversations = (this.conversations || []).concat(res.data.conversations);
        this.state = State.setNeutral(this.state, res);

        this.lastRetrievedPage++;
        this.retirevedConversationsCount += 12;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve conversations", () => this.getConversations());
      });
  };

  getChannelConversations = () => {
    this.state = State.setLoading(this.state);
    return API.getChannelConversations(this.lastRetrievedPage, 12, this.filterMessages)
      .then(res => {
        this.conversations = (this.conversations || []).concat(res.data.conversations);
        this.state = State.setNeutral(this.state, res);
        this.lastRetrievedPage++;
        this.retirevedConversationsCount += 12;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve conversations", () =>
          this.getChannelConversations()
        );
      });
  };

  getChannels = () => {
    this.state = State.setLoading(this.state);
    return API.getChannels()
      .then(res => {
        this.channels = res.data.channels;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve channels", () => this.getChannels());
      });
  };

  getTabItems = (tab, isRefresh) => {
    switch (tab || this.activeTab) {
      default:
      case "conversations":
        return this.refreshConversations(isRefresh);
      case "support":
        return this.getChannels();
    }
  };

  refreshConversations = isRefresh => {
    const getItems = this.activeTab === "conversations" ? API.getConversations : API.getChannelConversations;
    const page = this.conversations?.length ? this.lastRetrievedPage : 1;

    return getItems(page, 12, this.filterMessages)
      .then(res => {
        if (!isRefresh) {
          this.conversations = res.data.conversations;
        }

        if (this.isFirstLoading) {
          if (res.data.conversations.length === 0) {
            this.setActiveTab(this.userStore.adminAccess ? MESSAGE_TAB_NAMES.CHANNELS : MESSAGE_TAB_NAMES.SUPPORT);
          }

          this.isFirstLoading = false;
        }
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to refresh conversations", () =>
          this.refreshConversations()
        );
      });
  };

  refreshChannelConversations = () => {
    const page = this.conversations?.length ? this.lastRetrievedPage + 1 : 1;

    return API.getChannelConversations(page, 1, this.filterMessages)
      .then(res => {
        this.conversations = res.data.conversations;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to refresh conversations", () =>
          this.refreshChannelConversations()
        );
      });
  };

  addConversation = (ids, body = "Hello") => {
    const modifiedConversations = this.conversations;
    modifiedConversations.unshift({ loading: true, last_message: {} });
    this.conversations = modifiedConversations;
    return API.addConversation(ids, body)
      .then(res => {
        modifiedConversations[0] = res.data.conversation;
        this.conversations = [...modifiedConversations];
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to create conversation", () =>
          this.addConversation(ids, body)
        );
      });
  };

  addChannelConversation = (id, body) => {
    const modifiedChannels = this.channels;
    const currentChannel = modifiedChannels?.find(channel => channel.id == id);
    const channelIndex = modifiedChannels.map(m => m.id).indexOf(id);
    modifiedChannels[channelIndex] = { ...currentChannel, conversation: { loading: true, last_message: {} } };
    return API.addChannelConversation(id, body)
      .then(res => {
        modifiedChannels[channelIndex] = { ...currentChannel, conversation: res.data.conversation };
        this.channels = [...modifiedChannels];
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to create conversation", () =>
          this.addConversation(id, body)
        );
      });
  };

  addEmployerConversation = (id, body, settings, type, employerId) => {
    function addConversation(...args) {
      return type === "talent" ? API.addTalentConversation(...args) : API.addCandiateConversation(...args);
    }

    this.state.processing = true;

    return addConversation(id, body, settings, employerId)
      .then(res => {
        this.state = State.setSuccess(this.state, res);
        this.lastAdded = { id, conversation: res.data.conversation };

        return { type: "success", message: res.data.success, conversation: res.data.conversation };
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to create conversation", () =>
          this.addEmployerConversation(id, body, settings, type, employerId)
        );

        return { type: "error", message: err.response?.data?.error || "Failed to create conversation" };
      });
  };

  addBatchConversation = (ids, body, settings, employerId, separate) => {
    this.state.processing = true;
    return API.addBatchConversation(ids, body, settings, employerId, separate)
      .then(res => {
        this.state = State.setSuccess(this.state, res);

        return { type: "success", message: res.data.success, conversation: res.data.conversation };
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to create conversation", () =>
          this.addBatchConversation(ids, body, settings, employerId, separate)
        );

        return { type: "error", message: err.response?.data?.error || "Failed to create conversation" };
      });
  };

  getMessages = id => {
    this.messages = null;
    this.state.loading = true;
    this.currentConversationId = null;
    if (!this.activeSegments.messageChain) this.activeSegments.messageChain = true;
    return API.getConversationMessages(id)
      .then(res => {
        this.messages = res.data.messages;
        this.currentConversationId = id;
        this.state = State.setNeutral(this.state, res);
        this.activeSegments.messageChain = true;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve messages", () => this.getMessages(id));
      });
  };

  refreshMessages = () => {
    return API.getConversationMessages(this.currentConversationId)
      .then(res => {
        this.messages = res.data.messages;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to refresh messages", () => this.refreshMessages());
      });
  };

  addMessage = (id, body, attachment_ids) => {
    const tempMessage = {
      id: 0,
      body,
      processing: true,
      created_at: Date.now(),
      sender: this.userStore.user
    };
    this.messages = this.messages?.concat(tempMessage);

    return API.addMessage(id, body, attachment_ids)
      .then(res => {
        const tempMessages = this.messages;
        tempMessages[this.messages.length] = res.data.message_item;
        this.messages = tempMessages.filter(m => m.id != 0);
        this.state = State.setNeutral(this.state, res);
        const conversation =
          this.conversations?.find(conv => conv.id == id) ||
          this.channels?.find(item => item.conversation.id == id)?.conversation;
        this.updateLastMessage(conversation, res.data.message_item);

        return res.data.message_item;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to add message", () => this.addMessage(id, body));
      });
  };

  uploadAttachment = file => {
    const formData = new FormData();
    formData.append("file", file);

    return API.uploadMessageAttachment(formData)
      .then(res => {
        this.state = State.setNeutral(this.state, res);
        return res.data.attachment.id;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to upload attachment", () => this.uploadAttachment(file));
      });
  };

  updateMessage = (conversation_id, message_id, body, attachment_ids) => {
    const tempMessages = this.messages;
    const messageIndex = tempMessages.map(m => m.id).indexOf(message_id);
    tempMessages[messageIndex] = { ...tempMessages[messageIndex], body, processing: true };
    this.messages = tempMessages;

    return API.updateMessage(conversation_id, message_id, body, attachment_ids)
      .then(res => {
        tempMessages[messageIndex] = { ...res.data.message_item, edited: true };
        this.messages = tempMessages;
        this.state = State.setNeutral(this.state, res);

        const conversation =
          this.conversations?.find(conv => conv.id == conversation_id) ||
          this.channels?.find(item => item.conversation.id == conversation_id)?.conversation;
        if (conversation?.last_message && conversation.last_message.id == message_id) {
          this.updateLastMessage(conversation, res.data.message_item);
        }

        return { ...res.data.message_item, edited: true };
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to update message", () =>
          this.updateMessage(conversation_id, message_id, body)
        );
      });
  };

  removeMessage = (conversation_id, message_id) => {
    const tempMessages = [...this.messages];
    const messageIndex = tempMessages.map(m => m.id).indexOf(message_id);
    tempMessages[messageIndex] = { ...tempMessages[messageIndex], deleted: true };
    this.messages = tempMessages;

    return API.removeMessage(conversation_id, message_id)
      .then(res => {
        this.state = State.setNeutral(this.state, res);

        const conversation =
          this.conversations?.find(conv => conv.id == conversation_id) ||
          this.channels?.find(item => item.conversation.id == conversation_id)?.conversation;
        if (conversation?.last_message && conversation.last_message.id == message_id) {
          this.updateLastMessage(conversation, tempMessages[messageIndex - 1]);
        }
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to remove message", () =>
          this.removeMessage(conversation_id, message_id)
        );
      });
  };

  updateLastMessage = (conversation, message) => {
    if (conversation && message) {
      conversation.last_message = message;
      if (this.conversations.some(conv => conv.id == conversation.id)) {
        const tempConvs = [...this.conversations].filter(conv => conv.id != conversation.id);
        tempConvs.push(conversation);
        this.conversations = tempConvs;
      }
      if (this.channels.some(channel => channel.conversation?.id == conversation.id)) {
        const tempChannels = [...this.channels];
        const currentChannel = tempChannels?.find(channel => channel.conversation?.id == conversation.id);
        const channelIndex = tempChannels.map(channel => channel.conversation?.id).indexOf(conversation.id);
        tempChannels[channelIndex] = {
          ...currentChannel,
          conversation: { ...currentChannel.conversation, ...conversation }
        };
        this.channels = tempChannels;
      }
    }
  };

  setDraftMessage = (id, body) => {
    let temp = this.draftMessages;

    if (body.length === 0) {
      temp = temp.filter(item => item.id !== id);
      this.draftMessages = temp;

      return;
    }

    const item = temp.find(i => i.id === id);

    if (item) {
      item.body = body;
    } else {
      temp = temp.concat({ id, body });
    }

    this.draftMessages = temp;
  };

  markAsRead = id => {
    const conversation = this.conversations?.find(conv => conv.id === id);
    if (conversation) {
      conversation.unread_count = 0;
      const tempConvs = [...this.conversations].filter(conv => conv.id !== conversation.id);
      tempConvs.push(conversation);
      this.conversations = tempConvs;
    }
  };

  getContactDetails = (id, channel_id, employerId) => {
    const query = stringify({ channel_id, employer_id: employerId });
    return API.getContactDetails(id, query).then(res => res.data.contact_details);
  };

  toggleMessagesFilter = () => {
    this.filterMessages = !this.filterMessages;
    this.lastRetrievedPage = 1;
    this.conversations = null;
    this.channels = null;
    this.getChannelConversations();
  };
}

export default MessagesStore;
