import styled, { css, keyframes } from "styled-components";

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

export const MessageItemContent = styled.div`
  ${({ isOwned, processing, isNew, isEditing, toAnimate, isRemoved }) => css`
    animation: ${toAnimate
      ? css`
          ${isRemoved ? fadeOut : fadeIn} 0.3s ease 1
        `
      : "none"};
    animation-fill-mode: forwards;
    width: 100%;
    display: flex;
    align-items: flex-start;
    filter: ${processing ? "grayscale(0.75)" : "none"};
    flex-flow: ${isOwned ? "row-reverse" : "row"};
    transition: 0.2s ease;
    flex: 0 1 auto;

    &:not(last-child) {
      margin-bottom: 14px;
    }

    .sender-avatar {
      width: 34px;
      border-radius: 5px;
      background: #fff;
      object-fit: contain;

      &.trigger {
        cursor: pointer;
      }

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .sender-name {
      margin: 0 4px 6px;
      font-weight: 600;
      font-size: 12px;
      text-align: ${isOwned ? "right" : "left"};
    }

    .message-content {
      margin: ${isOwned ? "0 8px 0 0" : "0 0 0 8px"};
    }

    .message-body {
      width: auto;
      word-break: break-word;
      padding: 12px 16px;
      font-size: 14px;
      line-height: 20px;
      background: ${isOwned ? "rgba(223, 241, 255, 0.75)" : isNew ? "#DFF1FF" : "#F3F3F3"};
      border-radius: ${isOwned ? "12px 0 12px 12px" : "0 12px 12px 12px"};
      transition: 0.2s ease;

      > * {
        text-align: left;
      }

      > .message-additional {
        font-size: 12px;
        color: #656565;
        text-align: right;
        > button {
          cursor: pointer;
          background: transparent;
          margin-right: 6px;

          svg {
            width: 14px;

            path {
              fill: #656565;
            }
          }

          &.edit path {
            fill: ${isEditing ? "#4690FF" : "#656565"};
          }
        }
      }
    }
  `}
`;
