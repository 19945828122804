import React, { forwardRef } from "react";
import { RightArrowAlt, LeftArrowAlt } from "@styled-icons/boxicons-regular";
import styled from "styled-components";
import Slider from "react-slick";

const SamplePrevArrow = ({ currentSlide, slideCount, ...props }) => <LeftArrowAlt width="40px" {...props} />;

const SampleNextArrow = ({ currentSlide, slideCount, ...props }) => <RightArrowAlt width="40px" {...props} />;

const Carousel = forwardRef(({ children, settings, onMouseEnterArrow, onMouseLeaveArrow, ...props }, ref) => {
  const onMouseEnter = () => {
    if (onMouseEnterArrow) {
      onMouseEnterArrow();
    }
  };

  const onMouseLeave = () => {
    if (onMouseLeaveArrow) {
      onMouseLeaveArrow();
    }
  };

  const defaultSettings = {
    accessibility: true,
    adaptiveHeight: true,
    dots: true,
    infinite: true,
    speed: 400,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />,
    prevArrow: <SamplePrevArrow onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />,
    ...settings
  };
  return (
    <Slider ref={ref} {...defaultSettings} {...props}>
      {children}
    </Slider>
  );
});

const SlideCore = styled.div`
  &:focus {
    outline: none;
  }
`;

const SlideWrapper = styled(SlideCore)(({ $styled }) => $styled);

Carousel.Slide = ({ margin, padding, height, style, styled: styledProps, children, ...props }) => {
  return (
    <SlideWrapper $styled={{ margin, padding, height, ...style, ...styledProps }} {...props}>
      {children}
    </SlideWrapper>
  );
};

export default Carousel;
