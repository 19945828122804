import React, { useEffect, useRef, useContext, Children } from "react";
import { observer } from "mobx-react";

import { ScreenClassContext } from "react-grid-system";

import styled from "styled-components";

import { disableScroll, enableScroll } from "utils/helpers";
import useStore from "../../contexts/store";

const manageMask = (canvas, regions = [], isSm) => {
  const ctx = canvas.getContext("2d");
  ctx.canvas.width = window.innerWidth;
  ctx.canvas.height = window.innerHeight;

  ctx.beginPath();
  ctx.fillStyle = "rgba(243, 243, 243, 0.75)";
  ctx.fillRect(0, 0, window.innerWidth, window.innerHeight);
  regions.forEach(region => {
    ctx.clearRect(region.x, region.y, region.width + (isSm ? 0 : 10), region.height);
  });
  ctx.stroke();
};

const Highlight = ({ highlightId = "", desktopOnly = false, children }) => {
  const { highlight = [], highlightRegion } = useStore("HomeLayout");
  const highlighted = useRef();
  const screen = useContext(ScreenClassContext);
  const isSm = /sm|xs/.test(screen);
  const isActive = highlight.includes(highlightId) && (isSm ? !desktopOnly : true);

  useEffect(() => {
    const debouncedHighlight = () => {
      highlightRegion(highlightId, highlighted.current?.getBoundingClientRect());
    };

    if (highlighted.current) {
      highlightRegion(highlightId, highlighted.current?.getBoundingClientRect());

      window.addEventListener("scroll", debouncedHighlight);
      window.addEventListener("resize", debouncedHighlight);
    }

    return () => {
      window.removeEventListener("scroll", debouncedHighlight);
      window.removeEventListener("resize", debouncedHighlight);
    };
  }, [highlighted.current, isActive]);

  return isActive ? (
    <StyledWrapper ref={highlighted} $isActive={isActive}>
      {Children.only(children)}
    </StyledWrapper>
  ) : (
    Children.only(children)
  );
};

const HighlightMaskCore = () => {
  const { highlightedRegions = [] } = useStore("HomeLayout");
  const canvas = useRef();
  const screen = useContext(ScreenClassContext);
  const isSm = /sm|xs/.test(screen);

  useEffect(() => {
    if (highlightedRegions.length > 0 && canvas.current) {
      const minRegionY = !isSm ? Math.min(...highlightedRegions.map(item => item.region.y)) : 10;
      disableScroll();
      if (window.scrollY > minRegionY || window.scrollY < minRegionY - 300) scrollTo(0, minRegionY - 10);
      manageMask(
        canvas.current,
        highlightedRegions.map(item => item.region),
        isSm
      );
    }

    return () => {
      enableScroll();
    };
  }, [highlightedRegions, canvas]);

  return <StyledCanvas ref={canvas} $isActive={highlightedRegions.length > 0} />;
};

const StyledWrapper = styled.div`
  ${({ $isActive }) => `
        position: relative;
        z-index: ${$isActive ? 100 : 1};
    `}
`;

const StyledCanvas = styled.canvas`
  ${({ $isActive }) => `
        position: fixed;
        width: ${$isActive ? 100 : 0}%;
        height: ${$isActive ? 100 : 0}%;
        z-index: 100;
    `};
`;

export const HighlightMask = observer(HighlightMaskCore);

export default observer(Highlight);
