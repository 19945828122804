import React, { createContext, useContext, useRef } from "react";
import { isStandaloneAppFlow, isStandaloneMode } from "../utils/pwa";

export const StandaloneModeContext = createContext({});

export const useStandaloneMode = () => {
  const { isStandalone } = useContext(StandaloneModeContext);

  return isStandalone;
};

const StandaloneModeProvider = ({ children }) => {
  const state = useRef(null);

  state.current = state.current || {
    isStandalone: isStandaloneMode(),
    isStandaloneAppFlow: isStandaloneAppFlow()
  };

  return <StandaloneModeContext.Provider value={state.current}>{children}</StandaloneModeContext.Provider>;
};

export default StandaloneModeProvider;
