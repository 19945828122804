import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

const SimpleButton = ({ className, onClick, color, children, icon, iconAlign, disabled, tag, size, ...props }) => {
  const buttonClasses = cn(
    "fz-simple-btn",
    {
      [`fz-simple-btn_${color}`]: color,
      [`fz-simple-btn_${size}`]: size
    },
    className
  );

  const buttonIcon = () => {
    return (
      icon &&
      React.cloneElement(icon, {
        className: "fz-simple-btn__icon"
      })
    );
  };

  const TagName = tag;

  return (
    <TagName onClick={onClick} className={buttonClasses} disabled={disabled} {...props}>
      {icon && iconAlign === "left" && buttonIcon()}
      {children && <div className="fz-simple-btn__text">{children}</div>}
      {icon && iconAlign === "right" && buttonIcon()}
    </TagName>
  );
};

SimpleButton.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.oneOf(["gray", "blue", "lightgray", "gray500", "dark-gold", "red", "white"]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  icon: PropTypes.element,
  iconAlign: PropTypes.oneOf(["left", "right"]),
  disabled: PropTypes.bool
};

SimpleButton.defaultProps = {
  iconAlign: "left",
  disabled: false,
  tag: "button"
};

export default SimpleButton;
