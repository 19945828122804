import axios from "axios";

// Landing page

export const getPopularArticles = (limit = 30) => axios.get(`/api/v1/browse/articles/popular?limit=${limit}`);

export const getJobFilters = country_id =>
  axios.get(`/api/v1/browse/jobs/filters${country_id ? `?filters[country_id][0]=${country_id}` : ""}`);

export const getTrendingCompanies = country_id =>
  axios.get(`/api/v1/browse/companies/featured${country_id ? `?country_id=${country_id}` : ""}`);

export const getCountryTowns = country_id =>
  axios.get(`/api/v1/browse/towns/with_jobs${country_id ? `?country_id=${country_id}` : ""}`);

export const getPageInfoLanding = () => axios.get("/api/v1/browse/page_info?path=");

export const getSEOCategories = () => axios.get("/api/v1/browse/seo_categories");

export const getLandingStats = () => axios.get("/api/v1/browse/landing_page/stats");

export const getGuestLandingPage = () => axios.get("/api/v1/browse/layouts/guest_landing_page");
