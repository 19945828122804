import axios from "axios";

// User authorization
import { populateFormData } from "../forms";

export const userLogin = (user, authentication_channel) => axios.post("/login", { user, authentication_channel });

export const userLogout = () => axios.get("/logout");

export const userRegister = body => axios.post("/registration", body);

export const userResetPassword = email => axios.post("/api/v1/browse/password/reset", { email });

export const userUpdatePassword = user => axios.post("/api/v1/browse/password", { user });

export const facebookCallback = (code, channel, details = {}, referCode) => {
  const params = {
    channel
  };
  if (referCode) {
    params.refer_code = referCode;
  }

  return axios.post("/pro/auth/facebook/callback", { details }, { params });
};

export const googleCallback = (code, channel, details = {}, referCode) => {
  const params = {
    channel
  };
  if (referCode) {
    params.refer_code = referCode;
  }

  const data = new FormData();
  populateFormData(data, { access_token: code, details });

  return axios.post("/pro/auth/google_oauth2/callback", data, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    params
  });
};

export const linkedinCallback = (code, channel, details = {}, referCode) => {
  const params = {
    channel,
    code
  };
  if (referCode) {
    params.refer_code = referCode;
  }

  const data = new FormData();
  populateFormData(data, { code, details });

  return axios.post("/pro/auth/linkedin/callback", data, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    params
  });
};

export const userDetails = user => axios.post("/api/v1/browse/registration/details", { user });

export const userStatus = () => axios.get("/api/v1/home/user_status");

export const setNotificationPrompt = () => axios.post("/api/v1/browse/users/notifications_prompted");

export const setTutorialCompleted = () => axios.post("/api/v1/home/user_status/b2c_onboarding_tutorial_completed");

export const ncpwdValidateUser = ncpwd => axios.post("/api/v1/ncpwd/registration/validate", { ncpwd });

export const getCountries = channel => axios.get("/api/v1/browse/countries", { params: { channel } });

export const getSeniorityLevels = () => axios.get("/api/v1/browse/onboarding/seniority_levels");

export const getInterestAreas = () => axios.get("/api/v1/browse/onboarding/interest_areas");

export const getCareerInterests = () => axios.get("/api/v1/browse/onboarding/career_interests");

export const updateUserSteps = onboarding_steps =>
  axios.post("/api/v1/browse/registration/steps", { onboarding_steps });

export const checkEmail = email =>
  axios.get(`/api/v1/browse/devise/sessions/check_email?email=${encodeURIComponent(email)}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });

export const ncpwdLogin = user => axios.post("/api/v1/browse/devise/sessions", { user, ncpwd: user.ncpwd_id });
