import React, { useContext, forwardRef } from "react";
import { observer } from "mobx-react";

import styled from "styled-components";
import { HighlightMask } from "../Highlight";
import Description from "../Description";

import ThemeContext from "../../contexts/theme";
import { DropdownContainer } from "../../contexts/dropdown";

const StyledContent = styled.div(item => item.styled);
const StyledSection = styled.div(item => item.styled);

const Layout = ({ children, ...props }) => {
  return (
    <DropdownContainer>
      <div className="wrapper" {...props}>
        <HighlightMask />
        <Description />
        {children}
      </div>
    </DropdownContainer>
  );
};

const Section = ({ backgroundColor, padding, margin, style, children, ...rest }) => {
  const Theme = useContext(ThemeContext);

  return (
    <StyledSection
      styled={{
        backgroundColor: Theme[backgroundColor],
        padding,
        margin,
        ...style
      }}
      {...rest}
    >
      {children}
    </StyledSection>
  );
};

const Content = ({ children, size, tag = "main", style, ...props }, ref) => {
  const layoutSize = size ? `content content_${size}` : "content";
  const ContentTag = tag;

  return (
    <StyledContent ref={ref} as={ContentTag} className={layoutSize} styled={style} {...props}>
      {children}
    </StyledContent>
  );
};

Layout.Section = Section;
Layout.Content = forwardRef(Content);

export default observer(Layout);
