import React, { useContext } from "react";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import Button from "components/Button";
import InitContext from "contexts/init";
import i18n from "i18next";
import DOMPurify from "dompurify";

export const b2cUrl = path => {
  const { base_domain } = useContext(InitContext);
  const { protocol, port } = window.location;
  return `${protocol}//www.${base_domain}${port ? `:${port}` : ""}${path}`;
};

export const b2bUrl = path => {
  const { base_domain } = useContext(InitContext);
  const { protocol, port } = window.location;
  return `${protocol}//manage.${base_domain}${port ? `:${port}` : ""}${path}`;
};

export const capitalize = str =>
  typeof str === "string" || str instanceof String ? str.charAt(0).toUpperCase() + str.slice(1) : "";

export const convertMonths = months => {
  if (months < 12) return `${months} months`;
  return `${Math.floor(months / 12)} years ${Math.ceil(months % 12)} months`;
};

export const setHttp = link => {
  if (link.search(/^http[s]?:\/\//) === -1) {
    return `http://${link}`;
  }

  return link;
};

export const superImpose = (main, impose = []) => [
  ...new Set(
    main
      .filter(item => item)
      .map(item => {
        const obj = {
          id: item.id,
          name: item.name,
          active: impose.map(id => id.toString()).includes(item.id.toString())
        };
        if (item.job_category_ids) obj.job_category_ids = item.job_category_ids;
        return obj;
      })
  )
];

export const getPathRoot = path => {
  const endIndex = path.indexOf("/", 2) !== -1 ? path.indexOf("/", 2) : path.length;
  return path?.substring(0, endIndex);
};

export const getLastUrlLevel = path => {
  const levelCount = path.match(/\//g).length;
  return levelCount > 1 ? path?.substring(path.lastIndexOf("/"), path.length) : "";
};

const defaultMessageHandler = () => {
  toast("👍 Your link has been copied", {
    autoClose: false,
    closeButton: <Button.Simple color="gray">OK</Button.Simple>,
    position: toast.POSITION.BOTTOM_CENTER
  });
};

const fallbackCopyToClipboard = path => {
  const textArea = document.createElement("textarea");
  textArea.value = path;

  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  document.execCommand("copy");
  document.body.removeChild(textArea);
};

export const copyCodeToClipboard = (path, messageHandler = defaultMessageHandler) => {
  try {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(path);
    } else {
      fallbackCopyToClipboard(path);
    }
    messageHandler();
  } catch (err) {
    console.error("Cannot access clipboard from current environment.", err);
  }
};

export const getTimeSince = (dateString, prefix = "Closing ") => {
  if (dateString) {
    const thisDateTime = new Date(dateString).getTime();
    const todayTime = Date.now();

    return getTimeSpanString(todayTime - thisDateTime, prefix);
  }
  return i18n.t("global.no_date_specified");
};

export const calculateDaysLeft = (targetTimestamp, prefix = "Closing ") => {
  if (targetTimestamp) {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const timeDifference = targetTimestamp - currentTimestamp;
    const daysLeft = Math.ceil(timeDifference / (60 * 60 * 24));

    return getTimeSpanString(daysLeft, prefix, true, true);
  }

  return i18n.t("global.no_date_specified");
};
export const getTimeSpanString = (time, prefix = "", suffix = true, isLeftDays) => {
  const daysCount = isLeftDays ? time : Math.round(Math.abs(time) / (1000 * 3600 * 24));
  const monthsCount = Math.ceil(daysCount / 31);
  const yearsCount = Math.round(monthsCount / 12);

  return daysCount === 0
    ? `${prefix} today`
    : `${
        daysCount < 32
          ? daysCount + (daysCount > 1 ? ` ${i18n.t("global.days")}` : ` ${i18n.t("global.days")}`)
          : monthsCount < 12
            ? monthsCount + (monthsCount > 1 ? ` ${i18n.t("global.months")}` : ` ${i18n.t("global.month")}`)
            : yearsCount + (yearsCount > 1 ? ` ${i18n.t("global.years")}` : ` ${i18n.t("global.year")}`)
      }${suffix ? (!isLeftDays && time > 0 ? ` ${i18n.t("global.ago")}` : ` ${i18n.t("global.remaining")}`) : ""}`;
};

export const thousandSeparator = number => number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0;

export const thousandShortcut = number =>
  isNaN(number)
    ? 0
    : Math.abs(number) > 999
      ? `${Math.sign(number) * (Math.abs(number) / 1000).toFixed(1)}K`
      : Math.sign(number) * Math.abs(number);

export const daysSince = days => {
  if (days > 0) return `${days} ${days < 2 ? "day" : "days"} left`;
  if (days < 0) return `${-days} ${days > -2 ? "day" : "days"} since closing`;
  if (days === 0) return "Closing today";
  return "";
};

export const onEnterPress = (e, input, func) => {
  if (e.keyCode === 13 && (input.length > 0 || !isNaN(input))) func();
};

export const validationCount = () => +window.localStorage.getItem("validationCount");

export const increaseValidationCount = () => window.localStorage.setItem("validationCount", validationCount() + 1);

export const setValidationTimeout = minutes => {
  const event = new Event("validationReset");
  const timeout = setTimeout(() => {
    window.localStorage.removeItem("validationTimeout");
    window.localStorage.removeItem("validationCount");
    return document.dispatchEvent(event);
  }, minutes * 60000);
  if (!window.localStorage.getItem("validationTimeout")) window.localStorage.setItem("validationTimeout", timeout);
};

export const isValidationTimedOut = () => !!window.localStorage.getItem("validationTimeout");

export const debounce = (callback, delay) => {
  let timeout;

  return (...args) => {
    const later = () => {
      clearTimeout(timeout);
      callback(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, delay);
  };
};

export const pluralize = (count, string) => (Number(count) !== 1 ? `${count} ${string}s` : `${count} ${string}`);

export const highlightField = (name, field) => {
  const lowerField = field.toLowerCase();
  const lowerName = name.toLowerCase();
  const fieldIndex = lowerName.indexOf(lowerField);

  if (Number(fieldIndex) === -1 || !field) return name;

  const highlighted = name
    .split(" ")
    .map(word =>
      word.toLowerCase().indexOf(lowerField) === 0
        ? word.replace(capitalize(lowerField), `<span>${capitalize(lowerField)}</span>`)
        : word.replace(lowerField, `<span>${lowerField}</span>`)
    )
    .join(" ");
  return parse(highlighted);
};

const keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}
let supportsPassive = false;
try {
  window.addEventListener(
    "test",
    null,
    Object.defineProperty({}, "passive", {
      get() {
        supportsPassive = true;
      }
    })
  );
} catch (e) {
  console.error(e);
}

const wheelOpt = supportsPassive ? { passive: false } : false;
const wheelEvent = "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

export const disableScroll = () => {
  window.addEventListener("DOMMouseScroll", preventDefault, false);
  window.addEventListener(wheelEvent, preventDefault, wheelOpt);
  window.addEventListener("touchmove", preventDefault, wheelOpt);
  window.addEventListener("keydown", preventDefaultForScrollKeys, false);
};

export const enableScroll = () => {
  window.removeEventListener("DOMMouseScroll", preventDefault, false);
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
  window.removeEventListener("touchmove", preventDefault, wheelOpt);
  window.removeEventListener("keydown", preventDefaultForScrollKeys, false);
};

export const getHours = seconds => {
  const minutes = Math.round(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const rest = minutes % 60;

  return `${hours > 0 ? `${pluralize(hours, "hour")} ` : ""}${pluralize(rest, "minute")}`;
};

export const showDownloaded = (url, fileName) => {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.download = fileName;

  const anchorPromise = new Promise(resolve => {
    link.onclick = resolve;
  });

  anchorPromise.then(() => {
    URL.revokeObjectURL(url);
    link.remove();
  });

  link.click();
};

export const csvParse = e => {
  return new Promise((resolve, reject) => {
    if (e?.target?.files?.[0]) {
      const reader = new FileReader();
      reader.onloadend = event => {
        resolve(event.target.result.split("\n"));
      };

      reader.readAsBinaryString(e?.target?.files?.[0]);
    } else reject();
  });
};

export const stampToDateString = (stamp, ...rest) => new Date(stamp).toLocaleDateString(...rest);

export const pickRandom = arr => arr?.[Math.floor(Math.random() * arr?.length)];

export const lookupScreenClass = mobile => (mobile ? "xs" : "lg");

export const formatError = error => (error ? capitalize(error.join(", ")) : null);

export const testUrl = string => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return !!pattern.test(string.replace(/[[\]]/g, ""));
};

export const testEmail = string => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    string
  );
};

export const formatMessage = message => {
  const formatted = message
    .split(" ")
    .map(string => {
      const isUrl = testUrl(string);
      const isEmail = testEmail(string);

      if (isUrl) {
        return `<a className="link underlined" href="${setHttp(
          string
        )}" target="_blank" rel="noopener noreferrer">${string}</a>`;
      }

      if (isEmail) {
        return `<a className="link underlined" href="mailto:${string}">${string}</a>`;
      }
      return string;
    })
    .join(" ");

  return parse(DOMPurify.sanitize(formatted));
};

// remove fields with `null` or `undefined` values
export const cleanObject = obj => {
  return Object.entries(obj).reduce((memo, [key, value]) => {
    if (value !== null && value !== undefined) {
      memo[key] = value;
    }
    return memo;
  }, {});
};
