import JobSearch from "./JobSearch";
import UniversalSearch from "./UniversalSearch";

class Search {
  constructor(setAppState, CommonStore, context = {}, initialStateUser) {
    this.JobSearch = new JobSearch(setAppState, CommonStore, context, initialStateUser);
    this.UniversalSearch = new UniversalSearch(setAppState);
  }
}

export default Search;
