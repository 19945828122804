import React from "react";

import { Link } from "react-router-dom";

import { thousandSeparator } from "utils/helpers";
import FuzuPointsIcon from "@images/b2c/fuzuIcons/fuzu-points.svg";
import { Text, Button, Icon } from "../Core";
import Layout from "../Layout";

const ProductModalContent = ({
  product: { currency, price, slug, fuzu_points_needed_for_discount, discounted_price },
  pathname,
  close,
  locationState
}) => {
  return (
    <>
      <Layout.Section style={{ display: "flex", flexDirection: "column" }}>
        <Icon width={108} as={FuzuPointsIcon} margin="0 auto" />
        <Text fontSize={20} fontWeight={600} margin="24px auto" textAlign="center">
          You’ll get a discount with Fuzu Points!
        </Text>
        <Text margin="0 auto" textAlign="center">
          Good news! You can use your{" "}
          <Text as="b" fontWeight={600}>
            {fuzu_points_needed_for_discount} Fuzu Points
          </Text>{" "}
          to get the discounted price of {currency} {thousandSeparator(discounted_price)}.
        </Text>
        <Button.Link
          to={{ pathname: `/product/${slug}`, state: { from: pathname, discounted: true, ...locationState } }}
          margin="42px auto"
          onClick={close}
        >
          Continue to purchase - {currency} {thousandSeparator(discounted_price)}
        </Button.Link>
      </Layout.Section>
      <Layout.Section
        style={{ display: "flex", flexDirection: "column" }}
        backgroundColor="grey200"
        margin="0 -20px -32px"
      >
        <Text margin="24px auto" textAlign="center">
          Don’t want to use your Fuzu Points for some reason?
          <br />
          Normal price without Fuzu Points:{" "}
          <Text
            as={Link}
            to={{ pathname: `/product/${slug}`, state: { from: pathname, discounted: false, ...locationState } }}
            className="link underlined"
            color="secondary200"
            fontWeight={600}
            onClick={close}
          >
            Pay {currency} {thousandSeparator(price)}
          </Text>
          .
        </Text>
      </Layout.Section>
    </>
  );
};

export default ProductModalContent;
