import { makeAutoObservable } from "mobx";
import * as State from "utils/storeHelpers";
import API from "../../utils/api";

import Article from "./Article";
import Author from "./Author";

class Blog {
  constructor(userStore, setAppState, context = {}) {
    this.BlogLayout = new Layout(context);
    this.Article = new Article(context.article_details);
    this.Author = new Author(context.author_details);
  }
}

class Layout {
  sections = [];

  state = {
    loading: false
  };

  constructor(context) {
    makeAutoObservable(this);

    if (context.dynamic_filters) {
      this.sections = context.dynamic_filters;
    }
  }

  getArticleCategories = countryId => {
    this.state = State.setLoading(this.state);
    API.getArticleCategories(countryId).then(res => {
      this.sections = res?.data?.articles;
      this.state = State.setNeutral(this.state);
    });
  };
}

export default Blog;
