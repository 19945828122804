import axios from "axios";

// Application flow

const application_path = "/api/v1/browse/jobs";

export const getApplicationFlow = id => axios.get(`${application_path}/${id}/applications/flow`);

export const getApplicationFlowQuestions = id =>
  axios.get(`${application_path}/${id}/applications/prescreening/questions`);

export const postApplicationFlowQuestions = (id, query) =>
  axios.post(`${application_path}/${id}/applications/prescreening/answers`, query);

export const getApplicationFlowAnswers = id => axios.get(`${application_path}/${id}/applications/prescreening/answers`);

export const getJobDetails = id => axios.get(`${application_path}/${id}`);

export const updateCoverLetter = (jobId, applicationId, cover_letter) =>
  axios.patch(`${application_path}/${jobId}/applications/${applicationId}/cover_letter`, { cover_letter });

export const getAttachments = id => axios.get(`${application_path}/${id}/applications/attachments`);

export const getAttachmentsEducationItems = id =>
  axios.get(`${application_path}/${id}/applications/attachments/education_items`);

export const updateAttachments = (id, form) =>
  axios.post(`${application_path}/${id}/applications/attachments`, form, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });

export const getAttachmentsProfessionalCertificates = id =>
  axios.get(`${application_path}/${id}/applications/attachments/professional_certificates`);

export const deleteEducationCertificate = id => axios.delete(`/api/v1/browse/profile/academic_certificates/${id}`);

export const cancelApplication = (jobId, applicationId) =>
  axios.patch(`${application_path}/${jobId}/applications/${applicationId}/cancel`, { createdAt: new Date().getTime() });

export const submitApplication = (jobId, applicationId, inviteToApplyParams) =>
  axios.patch(`${application_path}/${jobId}/applications/${applicationId}/submit`, {
    createdAt: new Date().getTime(),
    ...inviteToApplyParams
  });
