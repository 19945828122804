import axios from "axios";

// Payment flow

const transaction_path = "/api/v1/browse/transactions";

const product_path = "/api/v1/browse/products";

export const getTransaction = id => axios.get(`${transaction_path}/${id}`);

export const initiateTransaction = (id, position_id, state) => {
  return axios.post(`${product_path}/${id}/initiate_transaction`, { position_id, ...state });
};

export const getTransactionStatus = id => axios.get(`${transaction_path}/${id}/status`);

export const cancelTransaction = id => axios.post(`${transaction_path}/${id}/cancel`);

export const getMobileOptions = id => axios.get(`${transaction_path}/${id}/mobile_payment_options`);

export const getBankOptions = id => axios.get(`${transaction_path}/${id}/bank_transfer_options`);

export const initiateMobilePayment = (id, mobile_transfer) =>
  axios.post(`${transaction_path}/${id}/mobile_transfer`, { mobile_transfer });

export const initiateCardPayment = (id, credit_card) =>
  axios.post(`${transaction_path}/${id}/credit_card_transfer`, { credit_card });

export const initiateBankTransfer = (id, body) => axios.post(`${transaction_path}/${id}/bank_transfer`, body);

export const getProductList = () => axios.get(product_path);

export const getProductDetails = id => axios.get(`${product_path}/${id}`);

export const getCoachingPackages = () => axios.get(`${product_path}/career_coaching_packages`);
