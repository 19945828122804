import React, { Suspense, useContext } from "react";

import LazyLoad from "react-lazyload";
import GuestModeContext from "../../../contexts/guest";

const LazyLoader = ({ children, ...rest }) => {
  const { active } = useContext(GuestModeContext);

  return !active ? (
    <Suspense>
      <LazyLoad {...rest}>{children}</LazyLoad>
    </Suspense>
  ) : (
    children
  );
};

export default LazyLoader;
