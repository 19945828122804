import styled, { keyframes, css } from "styled-components";

const loadingList = keyframes`
    0% {
        background-position: -200% 0%;
    }
    100% {
        background-position: 200% 0%;
    }
`;

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const MessengerPanel = styled.div`
  box-shadow: 0px 5px 15px 3px rgba(33, 33, 33, 0.05);
  border-radius: ${({ b2b }) => (b2b ? "5px" : "5px 5px 0 0")};
  display: flex;
  overflow: auto;
  width: 100%;
  height: 100%;
  flex: 1 0 0;
  background-color: #fff;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  color: #0b0a0a;
  @media screen and (max-width: 1440px) {
    min-height: calc(100vh - 130px);
  }
  @media screen and (max-width: 767px) {
    box-shadow: none;
    background: none;
    width: auto;
    min-height: calc(30vh);
    overflow: hidden;
  }

  button:focus {
    outline: none;
  }
`;

export const ConversationItem = styled.div`
    display: flex;
    position: relative;
    padding: 14px;
    background: ${({ selected, isLoading, index }) =>
      isLoading
        ? `linear-gradient(45deg, #F3F3F3 0%, #F3F3F3 ${index * 10}%, #FFF5C1 ${index * 10}%, #FFF5C1 ${
            10 + index * 10
          }%, #F3F3F3 ${10 + index * 10}%, #F3F3F3 100%)`
        : selected
          ? "#DFF1FF"
          : "#F6F7F9"};
    border: ${({ selected }) => (selected ? "2px solid #4690FF;" : "2px solid transparent;")}
    background-size: 200% 100%;
    animation: ${({ isLoading }) =>
      isLoading
        ? css`
            ${loadingList} 3s linear infinite;
          `
        : css`
            ${fadeIn} 0.3s ease;
          `};
    cursor: ${({ isLoading }) => (isLoading ? "auto" : "pointer")};
    border-radius: 4px;
    transition: .2s ease;

  .status-icons {
    width: fit-content;
    display: inline-flex;
    position: absolute;
    top: 4px;
    right: 0px;

    svg {
      width: 12px;

      &:not(:last-child) {
        margin-right: 2px;
      }

      > path {
        fill: #4690ff;
      }
    }
  }

  @media screen and (max-width: 767px) {
    background-color: #fff;
    border-radius: 0;
  }

    &:hover,
    &:focus {
        box-shadow: 0px 0 0 0 transparent, 0 0 0 1px #4690ff;
    }

  .conversation-participants {
    display: flex;
    width: 50px;
    position: relative;

    .photo-loading {
      display: block;
      border-radius: 5px;
      background: #fff;
    }
  }

  .conversation-info {
    min-width: 0px;
    flex: 1 0 0;
    padding: 0 16px;
    min-height: 50px;
  }

  .photo-loading {
    height: 100%;
  }

  .participant-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 4px;

    > span {
      background: #ffe040;
    }
  }

  .last-message {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .conversation-additional {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .message-date {
    font-weight: 500;
    font-size: 12px;
    line-height: 26px;
    color: #656565;
  }

  .unread-count {
    display: inline-flex;
    justify-content: center;
    line-height: 18px;
    padding: 2px 8px;
    min-width: 28px;
    font-size: 14px;
    background-color: #4690ff;
    color: #fff;
    font-weight: 500;
    border-radius: 12px;
  }
`;

export const ParticipantImage = styled.img`
  ${({ participantCount, index }) => `
        display: block;
        position: absolute;
        width: ${55 - participantCount * 5}px;
        height: ${55 - participantCount * 5}px;
        border-radius: 5px;
        background: #fff;
        margin: ${index * 5}px ${index * 5}px;
        object-fit: contain;
    `}
`;

export const MessageSide = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-width: 0px;

  @media screen and (max-width: 767px) {
    width: 100%;
    background-color: #fff;
    animation: ${fadeIn} 0.3s ease 1;
  }

  > .messages-header {
    padding: 15px;
    height: 80px;
    display: flex;
    justify-content: space-evenly;
    border-bottom: 1px solid #dedede;

    @media screen and (max-width: 767px) {
      position: fixed;
      top: 0;
      left: 0;
      background-color: #fff;
      width: 100%;
      z-index: 2;
    }
    .conversation-title {
      width: 100%;

      .participant-name {
        font-weight: 600;
        font-size: 18px;
        line-height: 160%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 4px;
      }

      .participant-position {
        font-size: 14px;
        line-height: 160%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 4px;
      }
    }

    button {
      display: flex;
      margin: auto 0;
      background: #fff;

      svg {
        width: 24px;
      }
    }
  }

  > .channel-description {
    margin: 16px auto;
    text-align: center;
    max-width: 80%;

    @media screen and (max-width: 767px) {
      margin-top: 100px;
    }
  }

  > .messages-list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 15px;
    overflow-y: auto;
    overscroll-behavior: contain;

    @media screen and (max-width: 767px) {
      padding: 75px 0;
    }

    .date-divider {
      animation: ${fadeIn} 0.3s ease 1;
      margin: 16px auto;
      font-weight: 600;
      font-size: 12px;
      color: #212121;
      background: rgba(223, 241, 255, 0.75);
      border-radius: 10px;
      padding: 6px 12px;
    }
  }

  > .messages-textarea {
    display: flex;
    border-top: 1px solid #dedede;
    padding: 15px;

    @media screen and (max-width: 767px) {
      &-content {
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: #fff;
      }
    }
    &.modal-textarea {
      margin: 0 -20px -32px;
      bottom: 0;
    }

    textarea {
      flex: 1;
      box-sizing: border-box;
      resize: none;
      border: none;
      padding: 10px 0;
      height: ${({ areaHeight = "44px" }) => areaHeight};

      @media screen and (max-width: 767px) {
        height: ${({ areaHeight = "44px" }) => areaHeight};
      }

      &:focus {
        outline: none;
      }
    }

    > button {
      margin: auto 8px;
      background: #ffe040;
      width: 44px;
      height: 44px;
      border-radius: 10px;
      transition: all 0.3s ease;

      &.files {
        background: none;
      }

      &:disabled {
        background: #f3f3f3;
      }

      &:not([disabled]) {
        cursor: pointer;

        :hover {
          filter: brightness(105%);
        }
      }

      > svg {
        width: 18px;
        margin: auto;
      }

      @media screen and (max-width: 767px) {
        width: 34px;
        height: 34px;
        border-radius: 6px;

        > svg {
          width: 14px;
        }
      }
    }

    input[type="file"] {
      display: none;
    }
  }

  > .messages-textarea-content_barona {
    > button {
      background: #2496f5;
    }
  }
`;

export const FileWrapper = styled.div`
  width: 100%;
  max-width: 360px;
  max-height: 42px;
  padding: 4px;

  &:not(:first-of-type),
  &:not(:last-of-type) {
    margin: 8px 0;
  }
`;
