import { makeAutoObservable } from "mobx";

import API from "../../utils/api";

class Jobs {
  state = {};

  jobs = {};

  disable = false;

  userOnBoardingCategoriesTitle = "";

  constructor(setAppState, similar_jobs) {
    makeAutoObservable(this);

    this.jobs.similar_to_job = similar_jobs;
    if (similar_jobs) {
      this.state.similar_to_job = {
        error: false,
        loading: false
      };
      this.disable = true;
    }
  }

  getJobs = (filter, limit, employer_id, job_id, brandSlug) => {
    if (!this.disable) {
      if (
        (!(this.state[filter] && this.state[filter].loading) &&
          (!this.jobs[filter] || this.jobs[filter].length < limit)) ||
        !!job_id
      ) {
        this.state[filter] = { loading: true };

        return API.getJobs(filter, limit, employer_id, job_id, brandSlug)
          .then(res => {
            this.jobs[filter] = res?.data?.jobs;
            this.userOnBoardingCategoriesTitle = res?.data?.user_onboarding_categories?.[0];
            this.state[filter] = {
              error: false,
              loading: false
            };
          })
          .catch(err => {
            this.state[filter] = {
              error: true,
              loading: false,
              message: err?.response?.data?.error
            };
          });
      }
    } else {
      this.disable = false;
    }
  };

  getFuzuJobs = limit => {
    this.state.fuzu = { loading: true };

    return API.getFuzuJobs(limit)
      .then(res => {
        this.jobs.fuzu = res?.data?.jobs;
        this.state.fuzu = {
          type: "error",
          loading: false
        };
      })
      .catch(err => {
        this.state.fuzu = {
          type: "error",
          loading: false,
          message: err?.response?.data?.error
        };
      });
  };
}

export default Jobs;
