import React, { useEffect } from "react";
import ProfileCheckImage from "@images/b2c/fuzuIcons/profileCheckImage.svg";
import { StyledContent } from "./styled";

import { Icon, Text } from "../Core";

const getContents = type => {
  switch (type) {
    default:
    case "instant-feedback":
      return `It seems that your Fuzu Profile is not compete enough for
            analysis. You need to build your profile first before we are
            able to provide you Instant Feedback.`;
    case "premium-cv":
      return `Fuzu CV converts the information on your profile into a downloadable CV file. 
            It seems that your profile is incomplete and might result in an empty-looking file. 
            We recommend you update your profile first and then come back to download the PDF file.`;
  }
};

const BuildYourProfileContent = ({ Actions, type = "instant-feedback", onClose, close, ...rest }) => {
  useEffect(() => {
    return () => {
      onClose && onClose();
    };
  }, []);

  return (
    <StyledContent>
      <Icon as={ProfileCheckImage} width={87} margin="0 auto" />
      <Text fontWeight={600} margin="24px auto">
        Build your Fuzu profile first
      </Text>
      <Text margin="auto auto 24px" lineHeight="21px">
        {getContents(type)}
      </Text>

      <Actions close={close} {...rest} />
    </StyledContent>
  );
};

export default BuildYourProfileContent;
