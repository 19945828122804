import { makeAutoObservable, reaction } from "mobx";
import * as State from "utils/storeHelpers";
import API from "../../utils/api";

class AccountSettings {
  setAppState;

  getPersonalInfo;

  constructor(setAppState, getPersonalInfo, updateUser) {
    makeAutoObservable(this);

    this.setAppState = setAppState;
    this.getPersonalInfo = getPersonalInfo;
    this.updateUser = updateUser;

    reaction(
      () => this.state,
      localState => {
        this.setAppState(localState);
      }
    );
  }

  state = {
    loading: false,
    type: "",
    message: ""
  };

  processing = {
    email: false,
    password: false,
    deleting: false
  };

  validation_errors = {};

  updatePassword = form => {
    this.processing.password = true;
    return API.updatePassword(form)
      .then(res => {
        this.validation_errors = {};
        this.processing.password = false;
        this.updateUser({ password_changed_at: "", isPasswordJustChanged: true });

        this.state = State.setSuccess(this.state, res, "Password updated");
        return res;
      })
      .catch(err => {
        this.validation_errors = err?.response?.data?.errors;
        this.processing.password = false;

        this.state = State.setError(this.state, err, "Failed to update password", () => this.updatePassword(form));
      });
  };

  updateEmail = form => {
    this.processing.email = true;
    return API.updateEmail(form)
      .then(res => {
        this.validation_errors = {};
        this.processing.email = false;
        this.updateUser({ email: form.email });

        this.state = State.setSuccess(this.state, res, "Email updated");

        return res;
      })
      .catch(err => {
        this.validation_errors.password = err?.response?.data?.error;
        this.processing.email = false;

        this.state = State.setError(this.state, err, "Failed to update email", () => this.updateEmail(form));
      });
  };

  updateLanguage = language => {
    this.processing.language = true;

    return API.updateLanguage(language)
      .then(res => {
        this.updateUser({ ui_locale: language });

        this.state = this.setState({
          loading: false,
          processing: false,
          type: "success",
          status: res.status,
          message: res?.data?.success || "Language updated successfully"
        });
      })
      .catch(err => {
        this.processing.language = false;

        if (err?.response?.data?.error) {
          this.state = State.setError(this.state, err, "Failed to update language", () =>
            this.updateLanguage(language)
          );
        }
      });
  };

  toggleVisibility = () => {
    return API.updateVisibility()
      .then(res => {
        this.state = State.setSuccess(this.state, res, "Visibility setting changed");
        this.getPersonalInfo();
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to update visibility settings", () =>
          this.toggleVisibility()
        );
      });
  };

  deleteAccount = () => {
    this.processing.deleting = true;
    return API.deleteAccount()
      .then(res => {
        this.processing.deleting = false;
        this.state = State.setSuccess(this.state, res, "Account deleted successfully");

        return res;
      })
      .catch(err => {
        this.processing.deleting = false;
        this.state = State.setError(this.state, err, "Failed to update visibility settings", () =>
          this.deleteAccount()
        );
      });
  };
}

export default AccountSettings;
