import React, { createContext } from "react";

export const ModalContext = createContext({
  content: null,
  title: "Confirmation",
  callback: null,
  closable: true,
  aggressive: false,
  text: null,
  toggle: () => null
});

export class ModalProvider extends React.Component {
  toggle = (
    content = null,
    title = "Confirmation",
    closable = true,
    callback = null,
    controlled = false,
    props = {}
  ) => {
    this.setState({
      content,
      title,
      callback,
      closable,
      controlled,
      aggressive: props.aggressive,
      text: props.text,
      isDisableWindowScroll: props.isDisableWindowScroll,
      isOverflowVisible: props.isOverflowVisible
    });
  };

  state = {
    content: null,
    title: "Confirmation",
    callback: null,
    closable: true,
    controlled: false,
    ref: null,
    aggressive: false,
    text: null,
    toggle: this.toggle
  };

  render() {
    return <ModalContext.Provider value={this.state}>{this.props.children}</ModalContext.Provider>;
  }
}

export default ModalContext.Consumer;
