import React from "react";

import { withRoute } from "utils/hocs/withRoute";
import NotFoundContent from "b2c/components/NotFoundContent";
import { Card } from "../../components/Core";
import Layout from "../../components/Layout";
import { useDocumentTitle } from "../../../utils/hooks";
import { getCompanyName } from "../../contexts/branded";

const NotFound = () => {
  useDocumentTitle(getCompanyName());

  return (
    <>
      <Layout.Content size="md" style={{ padding: "24px 0" }}>
        <Card shadow>
          <NotFoundContent />
        </Card>
      </Layout.Content>
    </>
  );
};

export default withRoute(NotFound);
