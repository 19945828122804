import React, { useContext, forwardRef, useEffect } from "react";
import styled from "styled-components";
import { Search } from "@styled-icons/boxicons-regular";
import ThemeContext from "../../../contexts/theme";

const SearchInputWrapper = styled.div(item => item.styled);
const SearchInputControl = styled.input(item => item.styled);
const SearchInputIcon = styled(Search)(item => item.styled);

const SearchInput = forwardRef(
  (
    {
      iconColor = "black100",
      iconWidth = "24px",
      textColor = "black500",
      height = "40px",
      margin,
      width,
      outline = "none",
      disabled = false,
      borderWidth = "1px",
      borderColor = "grey200",
      backgroundColor = "white",
      type = "search",
      isFocus,
      ...props
    },
    ref
  ) => {
    useEffect(() => {
      if (isFocus && ref && ref.current) {
        ref.current.focus();
      }
    }, [isFocus]);

    const theme = useContext(ThemeContext);

    return (
      <SearchInputWrapper className="form-input-search" styled={{ width, height, maxWidth: "100%", margin }}>
        <SearchInputIcon
          className="form-input-search__icon"
          styled={{
            width: iconWidth,
            color: theme[iconColor]
          }}
        />
        <SearchInputControl
          className="form-input-search__control"
          type={type}
          role="search"
          ref={ref}
          disabled={disabled}
          styled={{
            color: theme[textColor],
            outline,
            borderWidth,
            fontSize: "16px",
            borderColor: !disabled ? theme[borderColor] : theme.grey200,
            backgroundColor: !disabled ? theme[backgroundColor] : theme.grey100
          }}
          {...props}
        />
      </SearchInputWrapper>
    );
  }
);

export default SearchInput;
