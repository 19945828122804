import React, { useContext, useState } from "react";
import styled from "styled-components";
import TipIcon from "images/b2c/tip-icon.svg";
import { KeyboardArrowDown, KeyboardArrowUp } from "@styled-icons/material";
import { useTranslation } from "b2c/hooks/useTranslation";
import ThemeContext from "../../../contexts/theme";

import Title from "../Title";
import Text from "../Text";

const Tipbox = ({
  theme = "grey100",
  padding = "12px 15px",
  borderRadius = "5px",
  margin = "0",
  title,
  text,
  expandContent
}) => {
  const Theme = useContext(ThemeContext);
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const handleToggle = () => {
    setShow(prev => !prev);
  };

  return (
    <TipboxWrapper styled={{ backgroundColor: Theme[theme], borderRadius, margin }}>
      <TipboxInner styled={{ padding }} onClick={handleToggle}>
        <TipboxIcon />
        <TipboxContent>
          {title && (
            <Title
              level="6"
              color="secondary200"
              fontWeight={700}
              lineHeight="18px"
              margin="0"
              style={{ fontSize: "12px" }}
            >
              {title}
            </Title>
          )}
          {text && (
            <Text lineHeight="18px" fontSize="12px" margin="0">
              {text}
            </Text>
          )}
        </TipboxContent>
        {expandContent && (
          <TipboxTrigger>
            <Text fontSize={12}>{show ? t("global.hide") : t("global.show")}</Text>
            {show ? <KeyboardArrowUp width={16} /> : <KeyboardArrowDown width={16} />}
          </TipboxTrigger>
        )}
      </TipboxInner>
      <TipboxExpanded borderColor={Theme.grey300} style={{ height: show ? "auto" : "0", overflow: "hidden" }}>
        {expandContent}
      </TipboxExpanded>
    </TipboxWrapper>
  );
};

const TipboxWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  ${props => props.styled}
`;

const TipboxInner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ${({ isExpandable }) => (isExpandable ? "cursor: pointer;" : "")}
  ${props => props.styled}
`;

const TipboxIcon = styled(TipIcon)`
  display: block;
  margin-right: 15px;
  flex: 0 0 auto;
  @media screen and (max-width: 767px) {
    margin-right: 10px;
  }
`;

const TipboxContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const TipboxTrigger = styled.div`
  display: inline-flex;
  margin-left: auto;
  height: 48px;
  padding: 0 6px;
`;

const TipboxExpanded = styled.div`
  ${({ borderColor }) => `
    border-top: 1px solid ${borderColor};
  `}
`;

export default Tipbox;
