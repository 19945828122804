import React from "react";
import { observer } from "mobx-react";
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton
} from "react-share";

import { FacebookCircle, Telegram, Whatsapp, LinkedinSquare, Twitter } from "@styled-icons/boxicons-logos";
import { Envelope } from "@styled-icons/boxicons-solid";
import { Link, Download } from "@styled-icons/boxicons-regular";
import { useLocation } from "react-router-dom";
import { Button, Icon } from "../Core";
import { StyledContent } from "./styled";
import useStore from "../../contexts/store";
import { useTranslation } from "../../hooks/useTranslation";

const ShareModalContent = ({ url, name, close, handlePictureDownload }) => {
  const { copyToClipboard } = useStore("Saved");
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const isHeroCard = pathname.indexOf("/career-hero") !== -1;
  const handleCopy = () => {
    copyToClipboard(url);
    close();
  };

  return (
    <StyledContent>
      {isHeroCard && (
        <Button
          className="share-item"
          width="100%"
          noBackground
          color="black300"
          padding="0px"
          style={{ justifyContent: "start" }}
          onClick={handlePictureDownload}
        >
          <Icon as={Download} width={28} fill="black300" />
          {t("global.share.download_picture")}
        </Button>
      )}
      {!isHeroCard && (
        <Button
          className="share-item"
          width="100%"
          noBackground
          color="black300"
          padding="0px"
          style={{ justifyContent: "start" }}
          onClick={handleCopy}
        >
          <Icon as={Link} width={28} fill="black300" />
          {t("global.share.copy_link")}
        </Button>
      )}
      {!isHeroCard && (
        <EmailShareButton className="share-item b2c-button" url={url} subject={name} onShareWindowClose={close}>
          <Icon as={Envelope} width={28} fill="black300" />
          {`${t("global.share.share_via")} `} Email
        </EmailShareButton>
      )}
      {!isHeroCard && (
        <WhatsappShareButton className="share-item b2c-button" url={url} title={name} onShareWindowClose={close}>
          <Icon as={Whatsapp} width={28} fill="black300" />
          {`${t("global.share.share_via")} `} Whatsapp
        </WhatsappShareButton>
      )}
      {!isHeroCard && (
        <TelegramShareButton className="share-item b2c-button" url={url} title={name} onShareWindowClose={close}>
          <Icon as={Telegram} width={28} fill="black300" />
          {`${t("global.share.share_via")} `} Telegram
        </TelegramShareButton>
      )}
      <FacebookShareButton className="share-item b2c-button" url={url} quote={name} onShareWindowClose={close}>
        <Icon as={FacebookCircle} width={28} fill="black300" />
        {`${t("global.share.share_via")} `} Facebook
      </FacebookShareButton>
      <LinkedinShareButton className="share-item b2c-button" url={url} title={name} onShareWindowClose={close}>
        <Icon as={LinkedinSquare} width={28} fill="black300" />
        {`${t("global.share.share_via")} `} LinkedIn
      </LinkedinShareButton>
      <TwitterShareButton className="share-item b2c-button" url={url} title={name} onShareWindowClose={close}>
        <Icon as={Twitter} width={28} fill="black300" />
        {`${t("global.share.share_via")} `} Twitter
      </TwitterShareButton>
    </StyledContent>
  );
};

export default observer(ShareModalContent);
