import styled from "styled-components";

export const StyledRibbon = styled.div`
  ${({ backgroundColor, size, color }) => `
        width: ${size}px;
        height: ${size}px;
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;

        > div {
            position: absolute;
            left: -${size / 7.5}px;
            top: ${size / 4.2}px;
            transform: rotate(45deg);
            display: block;
            width: ${size * 1.5}px;
            padding: ${size / 20}px 0;
            background-color: ${backgroundColor};
            box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
            text-align: center;
            text-transform: uppercase;
            color: ${color};
            letter-spacing: ${size / 80}px;
            font-size: ${size / 10}px;
            font-weight: 500;
        }
    `}
`;
