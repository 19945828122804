import React from "react";

import { Row, Col, useScreenClass } from "react-grid-system";

import { StyledContent } from "./styled";

import { Image, Button, Text } from "../Core";

const StartPremiumCourseContent = ({ url, organizer, startCourse, close }) => {
  const isSm = /sm|xs/.test(useScreenClass());

  return (
    <StyledContent>
      <Image src={organizer?.logo} alt={organizer?.name} height="auto" maxHeight="125px" />
      <Text fontSize={16} fontWeight={600} margin="24px auto">
        Fuzu has partnered with {organizer?.name} to provide you the best catalogue of courses at the best possible
        price.
      </Text>
      <Text margin="auto auto 24px" lineHeight="21px">
        This course is organized by our partner {organizer?.name}. Next, you’ll be taken to {organizer?.name} website
        where you can finish the course payment and start studying. Happy learning!
      </Text>
      <Row>
        <Col lg={6} sm={12}>
          <Button width="100%" onClick={close} theme="grey200" margin="4px 0">
            Cancel
          </Button>
        </Col>
        <Col lg={6} sm={12} style={{ order: isSm ? -1 : "inherit" }}>
          <Button
            as="a"
            href={url}
            target="_blank"
            width="100%"
            theme="additional200"
            margin="4px 0"
            onClick={() => startCourse().then(close)}
          >
            Continue to {organizer?.name}
          </Button>
        </Col>
      </Row>
    </StyledContent>
  );
};

export default StartPremiumCourseContent;
