import React, { useContext } from "react";
import styled from "styled-components";
import ThemeContext from "../../../contexts/theme";

const StyledDiv = styled.div(item => item.styled);

const Badge = ({
  theme = "accent300",
  fontSize = 12,
  inside = false,
  top,
  bottom = false,
  right,
  children,
  ...props
}) => {
  const Theme = useContext(ThemeContext);

  const getAxisX = () => {
    if (top !== undefined) {
      return top;
    }
    const init = -(Math.ceil(fontSize / 2) + 1);
    return inside ? init * -1 : init;
  };

  const getAxisY = () => {
    if (right !== undefined) {
      return right;
    }
    let init = -(Math.ceil(fontSize / 2) + 1) - (typeof children === "string" ? (children.length * fontSize) / 5 : 0);
    if (inside) init = Math.ceil(fontSize / 2) + 1;
    return init;
  };

  const axisX = bottom ? { bottom: getAxisX() } : { top: getAxisX() };

  return children ? (
    <StyledDiv
      className="b2c-badge"
      styled={{
        position: "absolute",
        ...axisX,
        right: getAxisY(),
        fontSize,
        height: fontSize * 1.5,
        width: fontSize * 1.5,
        ...Theme.getColors(theme),
        ...props.style
      }}
    >
      {children}
    </StyledDiv>
  ) : null;
};

export const withBadge = WrappedComponent => {
  return class extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {
      return (
        <WrappedComponent style={{ position: "relative", ...this.props.style }} {...this.props}>
          {this.props.children}
          <Badge {...this.props.badgeProps}>{this.props.badge}</Badge>
        </WrappedComponent>
      );
    }
  };
};

export default Badge;
