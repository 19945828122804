import React, { useState, useEffect, forwardRef } from "react";

import { ChevronDown, ChevronUp } from "@styled-icons/boxicons-regular";
import Card from "../Card";
import Button from "../Button";

const CollapsibleCard = (
  { headingContent, open, openByDefault = false, controlled = false, children, ...rest },
  ref
) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (open) {
      setShow(open);
    }
  }, [open]);

  useEffect(() => {
    if (openByDefault) {
      setShow(true);
    }
  }, []);

  return (
    <Card ref={ref} {...rest}>
      <Card.Header
        padding="12px 16px"
        onClick={() => setShow(prev => !prev)}
        style={{ cursor: "pointer", outline: "none" }}
      >
        {headingContent}
        <Button.Iconed theme="transparent" margin="0 0 0 auto">
          {show ? <ChevronUp width={24} /> : <ChevronDown width={24} />}
        </Button.Iconed>
      </Card.Header>
      <div style={{ height: show ? "auto" : "0", overflow: "hidden" }}>
        <Card.Content padding="0 16px 15px">
          {controlled ? children(show, () => setShow(prev => !prev)) : children}
        </Card.Content>
      </div>
    </Card>
  );
};

export default forwardRef(CollapsibleCard);
