import React from "react";
import { Link } from "react-router-dom";
import mobileCvModal from "images/b2c/mobileCvModal.png";
import desktopCvModal from "images/b2c/desktopCvModal.png";

import Letter from "images/b2c/landing-page/bullets/letter.svg";
import Clock from "images/b2c/landing-page/bullets/clock.svg";
import Glass from "images/b2c/landing-page/bullets/glass.svg";
import { useScreenClass } from "react-grid-system";
import signUpClose from "images/signup-close.png";
import { Button, Title, Image, Text, Icon } from "../../Core";
import { Modal, ImageContent, Content } from "./styled";

const LoginModalCv = ({ close }) => {
  const isSm = /sm|xs/.test(useScreenClass());

  return (
    <Modal>
      <ImageContent backgroundImage={isSm ? mobileCvModal : desktopCvModal}>
        <Image
          src={signUpClose}
          onClick={close}
          alt="close"
          style={{
            position: "absolute",
            cursor: "pointer",
            top: "16px",
            right: "16px"
          }}
        />
      </ImageContent>

      <Content>
        <Title
          style={{
            fontWeight: "600",
            fontSize: isSm ? "24px" : "32px",
            lineHeight: "130%",
            color: "#292929",
            marginBottom: "12px"
          }}
        >
          Join Fuzu now and get a professional CV for
          <Text
            color="accent300"
            style={{
              fontWeight: "600",
              fontSize: isSm ? "24px" : "32px",
              lineHeight: "130%",
              display: "inline-block",
              margin: "0 5px"
            }}
          >
            FREE
          </Text>
          !
        </Title>
        <Text margin="4px 0" lineHeight={2} style={{ display: "flex" }}>
          <Icon as={Glass} width={20} margin="0 12px 0 0" style={{ flex: "none" }} />
          All opportunities in one place 10,000+ new open jobs every month
        </Text>
        <Text margin="4px 0" lineHeight={2} style={{ display: "flex" }}>
          <Icon as={Letter} width={20} margin="0 12px 0 0" style={{ flex: "none" }} />
          Never miss an opportunity – Get access to personalised job alerts, tools and support
        </Text>
        <Text margin="4px 0" lineHeight={2} style={{ display: "flex" }}>
          <Icon as={Clock} width={20} margin="0 12px 0 0" style={{ flex: "none" }} />
          Save time – Apply for jobs in minutes
        </Text>
        <Button arrow width="100%" margin="18px 0" as={Link} to="/onboarding" onClick={close}>
          Get Started
        </Button>
        <Button as={Link} to="/login" width="100%" margin="0 0 auto" style={{ background: "#f3f3f3" }} onClick={close}>
          I already have a Fuzu account
        </Button>
      </Content>
    </Modal>
  );
};

export default LoginModalCv;
