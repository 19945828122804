import React, { useState, useEffect, useContext, forwardRef, useMemo, Children } from "react";
import { capitalize } from "utils/helpers";
import ThemeContext from "../../../contexts/theme";
import Text from "../Text";

import { SelectInputWrapper, SelectInputLabel, SelectInputControl } from "./styled";

const SelectInput = forwardRef(
  (
    {
      id,
      options,
      inputColor = "black500",
      inputHeight = "48px",
      borderRadius = "5px",
      width,
      display = "block",
      label,
      labelColor = "black500",
      errorMessage = "",
      hasError = false,
      wrapperMargin = "0 0 20px",
      errorColor = "accent300",
      fieldRequired = false,
      labelStyle,
      formatValues = false,
      testScope,
      placeholderColor = "black200",
      controlStyles = "",
      children,
      ...props
    },
    ref
  ) => {
    const [isFocused, setFocused] = useState(false);

    const Theme = useContext(ThemeContext);

    useEffect(() => {
      setFocused(false);
    }, [errorMessage]);

    const uid = useMemo(() => id || `uid_${new Date().getTime() + Math.random().toString(36).substr(2, 5)}`, []);

    const errorState = !!errorMessage && !isFocused;

    return (
      <SelectInputWrapper
        ref={ref}
        margin={errorState ? 0 : wrapperMargin}
        styled={{ width, display }}
        onFocus={() => setFocused(true)}
      >
        {label && (
          <SelectInputLabel htmlFor={uid} styled={{ color: Theme[labelColor], ...labelStyle }}>
            {label}
            {fieldRequired && "*"}
          </SelectInputLabel>
        )}
        <InputControl
          required
          styled={`
                        height: ${inputHeight};
                        border-radius: ${borderRadius};
                        &:invalid {
                            color: ${Theme[placeholderColor]};
                        }
                        > option {
                            color: ${Theme[inputColor]};
                        }
                        ${controlStyles}
                    `}
          hasError={errorState || hasError}
          aria-invalid={errorState}
          id={uid}
          theme={Theme}
          data-test-control={testScope}
          {...props}
        >
          {children ||
            options.map((item, i) => (
              <option key={i} value={item}>
                {formatValues ? capitalize(typeof item === "string" ? item.replace(/_/g, " ") : "") : item}
              </option>
            ))}
        </InputControl>
        {errorState && (
          <Text color={errorColor} fontSize="12px" margin="2px 0" loaded={errorState} aria-describedby={uid}>
            {errorMessage}
          </Text>
        )}
      </SelectInputWrapper>
    );
  }
);

const InputControl = ({ children, placeholder, ...rest }) => {
  return Children.count(children) == 0 ? (
    <div>
      <SelectInputControl {...rest}>{placeholder && <option value="">{placeholder}</option>}</SelectInputControl>
    </div>
  ) : (
    <SelectInputControl {...rest}>
      {placeholder && <option value="">{placeholder}</option>}
      {children}
    </SelectInputControl>
  );
};

export default SelectInput;
