import React, { useContext } from "react";
import { BellRing } from "@styled-icons/boxicons-solid";
import ThemeContext from "../../../contexts/theme";
import { NotificationWrapper, NotificationIcon, NotificationContent } from "./styled";

const Notification = ({
  theme = "transparent",
  padding = "0",
  borderRadius = "0",
  Icon,
  textOnly = false,
  iconBgColor = "secondary200",
  iconColor = "white",
  iconSize = "30px",
  margin = "0",
  children,
  style
}) => {
  const Theme = useContext(ThemeContext);

  return (
    <NotificationWrapper
      $styled={{
        backgroundColor: Theme[theme],
        margin,
        padding,
        borderRadius,
        ...style
      }}
    >
      {!textOnly && (
        <NotificationIcon
          $styled={{
            backgroundColor: Theme[iconBgColor],
            color: Theme[iconColor],
            width: iconSize,
            height: iconSize
          }}
        >
          {Icon ? React.cloneElement(Icon) : <BellRing />}
        </NotificationIcon>
      )}
      <NotificationContent>{children}</NotificationContent>
    </NotificationWrapper>
  );
};

export default Notification;
