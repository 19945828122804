import React from "react";
import { Link } from "react-router-dom";

import { Card, Text, Title } from "../Core";

const NotFoundContent = () => (
  <Card.Content height={580}>
    <Title fontWeight={600} margin="0 0 16px">
      OOPS!
    </Title>
    <Text margin="0" fontSize={22}>
      Sorry...but the page you requested is no longer here. Would you like to go back to the{" "}
      <Link to="/">homepage</Link> instead?
    </Text>
  </Card.Content>
);

export default NotFoundContent;
