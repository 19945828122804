import styled from "styled-components";

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #666666;
  width: 210px;
  margin: 0 auto;
  gap: 8px;
`;
