import styled from "styled-components";

export const ListWrapper = styled.ul`
  list-style: ${({ listType }) => listType} outside;
  padding: 0 0 0 18px;
  ${({ $styled }) => $styled};

  @media screen and (max-width: 767px) {
    padding: 0 0 0 13px;
  }
`;

export const ListItem = styled.li`
  ${({ $styled }) => $styled};
  &::marker {
    font-size: ${({ $bulletSize }) => $bulletSize};
    color: ${({ $bulletColor }) => $bulletColor};
  }
`;
