import { makeAutoObservable } from "mobx";

import * as State from "utils/storeHelpers";
import API from "../../utils/api";

class Courses {
  constructor() {
    makeAutoObservable(this);
  }

  slug;

  state = {
    all: {},
    fuzu: {},
    affiliate: {},
    ncpwd: {}
  };

  courses = {
    all: {},
    fuzu: {},
    affiliate: {},
    ncpwd: {}
  };

  getCourses = (filter, type = "all", limit) => {
    if (
      !(this.state[type][filter] && this.state[type][filter].loading) &&
      (!this.courses[type][filter] || this.courses[type][filter].length < limit)
    ) {
      this.state[type][filter] = { loading: true };
      return API.getCourses(filter, type, limit)
        .then(res => {
          this.courses[type][filter] = res?.data?.courses;
          this.state[type][filter] = {
            error: false,
            loading: false
          };
        })
        .catch(err => {
          this.state[type][filter] = {
            error: true,
            loading: false,
            message: err?.response?.data?.error
          };
        });
    }
  };

  getSimilarCourses = slug => {
    this.state.all.similar = { loading: true };
    return API.getSimilarCourses(slug)
      .then(res => {
        this.courses.all.similar = res?.data?.courses;
        this.state.all.similar = State.setNeutral(this.state.all.similar, res);
      })
      .catch(err => {
        this.state.all.similar = State.setError(this.state.all.similar, err, "Could not retrieve similar courses", () =>
          this.getSimilarCourses(slug)
        );
      });
  };
}

export default Courses;
