import { makeAutoObservable, reaction } from "mobx";
import * as State from "utils/storeHelpers";
import API from "../../utils/api";

class SurveyStore {
  setAppState;

  constructor(setAppState) {
    makeAutoObservable(this);

    this.setAppState = setAppState;

    reaction(
      () => this.state,
      localState => {
        this.setAppState(localState);
        if (localState.status === 200) {
          this.question_errors = [];
        }
      }
    );
  }

  state = {
    loading: true,
    processing: false,
    type: "",
    message: ""
  };

  form_details = {};

  next_form_id = null;

  finished = false;

  currentPage = {};

  question_errors = [];

  NCPWDTestCompleted = false;

  getForm = (id, job_id) => {
    this.state = State.setLoading(this.state);
    this.currentPage = {};
    this.setFinished(false);
    const getForm = job_id ? API.getApplicationForm : API.getForm;
    return getForm(id, job_id)
      .then(res => {
        this.form_details = res?.data?.form;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to get test details", () => getForm(id, job_id));
      });
  };

  startForm = (id, job_id) => {
    this.state = State.setProcessing(this.state);
    const startForm = job_id ? API.startApplicationForm : API.startForm;
    return startForm(this.form_details.id || id, job_id)
      .then(res => {
        this.setFinished(false);
        this.currentPage = res?.data?.page;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to start the test", () => startForm(id, job_id));
      });
  };

  getFormPage = id => {
    this.state = State.setProcessing(this.state);
    return API.getFormPage(id)
      .then(res => {
        this.currentPage = res?.data?.page;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to get test page", () => this.getFormPage(id));
      });
  };

  setFinished = isFinished => {
    this.finished = isFinished;
  };

  setNCPWDTestCompleted = isCompleted => {
    this.NCPWDTestCompleted = isCompleted;
  };

  submitResponses = (responses, session_id, job_id) => {
    this.state = State.setProcessing(this.state);
    const submit = job_id ? API.submitApplicationResponses : API.submitResponses;
    return submit(responses, session_id || this.currentPage.session_id, job_id)
      .then(res => {
        this.form_details.completed = res?.data?.completed;
        this.setFinished(res?.data?.completed);
        const action = res?.data?.action;

        switch (action) {
          default:
          case "next_page":
            if (res?.data?.next_page) this.currentPage = res?.data?.next_page;
            break;
          case "invalid_response":
            if (res?.data?.erroring_questions_ids) {
              this.question_errors = res?.data?.erroring_questions_ids.map(id => ({ id, error: res?.data?.error }));
            }
            break;
          case "next_form":
            this.currentPage = {};
            break;
          case "result_page":
          case "back_to_application_edit":
          case "back_to_root":
            this.currentPage = {};
            this.form_details.test_result_id = res?.data?.test_result_id;
            break;
        }

        this.state = {
          loading: false,
          processing: false,
          status: res.status,
          type: res?.data?.completed ? "success" : "",
          message: res?.data?.completed ? `${this.form_details.name} test passed successfully` : ""
        };

        return res?.data?.completed;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to submit responses", () =>
          this.submitResponses(responses, session_id, job_id)
        );
      });
  };
}

export default SurveyStore;
