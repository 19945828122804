import React, { useContext, forwardRef } from "react";
import styled from "styled-components";

import ThemeContext from "../../../contexts/theme";

const StyledDiv = styled.div(({ $styled }) => $styled);

const CardBadge = styled.div`
  position: absolute;
  right: 0;
  bottom: 100%;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 10px 6px;
  margin-bottom: -5px;
  border-radius: 8px 8px 0 0;
  z-index: -1;
  ${({ $styled }) => $styled}
`;

const Card = forwardRef(
  (
    {
      className = "",
      theme = "white",
      bordered = false,
      borderColor = "grey200",
      borderWidth = "1px",
      borderStyle = "solid",
      backgroundImage,
      backgroundSize,
      width,
      Button,
      badge,
      badgeTheme = "primary",
      shadow = false,
      children,
      clickable,
      style,
      testScope,
      wideOnMobile,
      ...props
    },
    ref
  ) => {
    const Theme = useContext(ThemeContext);

    return (
      <StyledDiv
        ref={ref}
        className={`b2c-card${clickable ? " clickable" : ""}${wideOnMobile ? " wideOnMobile" : ""} ${className} `}
        data-test-scope={testScope}
        $styled={{
          ...{ border: bordered ? `${borderWidth} ${borderStyle} ${Theme[borderColor]}` : "none" },
          ...Theme.getColors(theme),
          ...(backgroundImage
            ? {
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize
              }
            : {}),
          ...(width ? { width } : {}),
          ...(shadow ? { boxShadow: "0px 5px 15px 3px rgba(33, 33, 33, 0.05)" } : {}),
          ...(clickable ? { cursor: "pointer" } : {}),
          ...style
        }}
        {...(clickable ? { tabIndex: 0 } : {})}
        {...props}
      >
        {Button && React.cloneElement(Button, { className: "card-button" })}
        {badge && <CardBadge $styled={Theme.getColors(badgeTheme)}>{badge}</CardBadge>}
        {children}
      </StyledDiv>
    );
  }
);

Card.Header = ({
  height = "fit-content",
  className = "",
  padding = "16px 12px 0 16px",
  background,
  backgroundImage,
  placeholder,
  backgroundSize = "cover",
  borderRadius,
  children,
  style,
  ...props
}) => (
  <StyledDiv
    className={`card-header ${className}`}
    $styled={{
      ...(background ? { background } : {}),
      ...(backgroundImage || placeholder
        ? {
            backgroundImage: `${[backgroundImage, placeholder]
              .filter(url => !!url)
              .map(url => `url(${url})`)
              .join(",")}`
          }
        : {}),
      backgroundSize,
      height,
      padding,
      borderRadius,
      ...style
    }}
    {...props}
  >
    {children}
  </StyledDiv>
);

Card.Content = ({ className = "", height, padding = "8px 16px", row, children, style, ...props }) => (
  <StyledDiv
    className={`card-content ${className}`}
    $styled={{
      display: "flex",
      flexDirection: row ? "row" : "column",
      padding,
      height,
      ...style
    }}
    {...props}
  >
    {children}
  </StyledDiv>
);

Card.Footer = ({
  theme = "transparent",
  className = "",
  display = "flex",
  padding = "8px 16px",
  height,
  separator,
  children,
  ...props
}) => {
  const Theme = useContext(ThemeContext);

  return (
    <>
      {separator && (
        <StyledDiv
          className={`card-footer-divider ${separator}`}
          aria-hidden="true"
          $styled={{
            borderBottom: `1px solid ${Theme.getBorderColor(theme)}`
          }}
        />
      )}
      <StyledDiv
        className={`card-footer ${className}`}
        $styled={{ display, height, padding, ...Theme.getColors(theme) }}
        {...props}
      >
        {children}
      </StyledDiv>
    </>
  );
};

Card.Separator = ({ margin = "10px 0", padding = "0", theme = "grey200", height = "2px", width = "100%" }) => {
  const Theme = useContext(ThemeContext);
  return (
    <StyledDiv
      $styled={{
        margin,
        padding,
        backgroundColor: Theme[theme],
        height,
        width
      }}
    />
  );
};

export default Card;
