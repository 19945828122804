import axios from "axios";

// Saving content

const save_path = "/api/v1/browse/bookmarks";

export const saveJob = id => axios.post(`${save_path}/job/${id}`);

export const savePost = id => axios.post(`${save_path}/post/${id}`);

export const saveCourse = id => axios.post(`${save_path}/course/${id}`);

export const saveArticle = id => axios.post(`${save_path}/article/${id}`);

export const removeJob = id => axios.delete(`${save_path}/job/${id}`);

export const removePost = id => axios.delete(`${save_path}/post/${id}`);

export const removeCourse = id => axios.delete(`${save_path}/course/${id}`);

export const removeArticle = id => axios.delete(`${save_path}/article/${id}`);
