import React, { useEffect, useState, useRef, useCallback, useMemo, useContext } from "react";
import qs from "qs";
import { useLocation } from "react-router-dom";
import { ScreenClassContext } from "react-grid-system";

import { ModalContext } from "contexts/modal";
import { getSearchParams } from "utils/getSearchParams";
import NotificationContext from "contexts/notification";
import { MESSAGE_TAB_NAMES } from "constants/messages";
import {
  validationCount,
  isValidationTimedOut,
  increaseValidationCount,
  setValidationTimeout,
  debounce
} from "../helpers";
import useStore from "../../b2c/contexts/store";
import { stringify } from "../qsUtil";

const initTitle = "Fuzu";

export const useOnclickOutside = (ref, handler, reset) => {
  useEffect(() => {
    const listener = event => {
      event.stopPropagation();
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", listener);

    return () => {
      if (!reset) {
        handler();
      }
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

export const useOutsideWindow = (ref, handler) => {
  useEffect(() => {
    const element = ref.current;

    const listener = () => {
      if (element) {
        const rect = element.getBoundingClientRect();
        const view = Math.max(document.documentElement.clientHeight, window.innerHeight);
        if (rect.bottom < 0 || rect.top - view >= 0) handler();
      }
    };

    window.addEventListener("scroll", listener, { passive: true });

    return () => {
      window.removeEventListener("scroll", listener, { passive: true });
    };
  }, [ref, handler]);
};

export const useScroll = (triggerHeight = 640, element) => {
  const scope = element || window;
  const [state, setState] = useState(false);

  useEffect(() => {
    const listener = () => {
      const scrollPosition = element?.scrollTop || window.pageYOffset;
      setState(scrollPosition > triggerHeight);
    };

    scope.addEventListener("scroll", listener, { passive: true });

    return () => {
      scope.removeEventListener("scroll", listener, { passive: true });
    };
  }, [triggerHeight, element]);

  return state;
};

export const usePrev = value => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current !== value) ref.current = value;
  });

  return ref.current;
};

export const OnRouteChange = ({ callback, children }) => {
  const { pathname, query } = useLocation();
  useEffect(() => {
    callback({ pathname, query });
  }, [pathname]);

  return children;
};

export const useQueryParams = () => {
  const { search } = useLocation();

  return qs.parse(decodeURI(search.substring(1, search.length)));
};

export const useQueryUtils = () => {
  const { search, pathname } = useLocation();

  const setParams = (newQueryParams, prevQueryParams) => {
    const queryParamsSource = prevQueryParams || search;
    const currentQuery = Object.fromEntries(new URLSearchParams(queryParamsSource).entries());
    const nextQueryParams = { ...currentQuery, ...newQueryParams };

    return new URLSearchParams(nextQueryParams);
  };

  const unsetParams = useCallback(
    (unsetParamsList, prevQueryParams) => {
      const queryParamsSource = prevQueryParams || search;
      const nextQueryParams = new URLSearchParams(queryParamsSource);

      nextQueryParams.forEach((value, key) => {
        if (unsetParamsList.includes(key)) {
          nextQueryParams.delete(key);
        }
      }, {});

      return nextQueryParams;
    },
    [search]
  );

  const getPathWithParams = useCallback(
    queryParamsList => {
      const queryString = new URLSearchParams(queryParamsList).toString();
      return `${pathname}?${queryString}`;
    },
    [pathname]
  );

  return {
    setParams,
    unsetParams,
    getPathWithParams
  };
};

export const fullPath = path => {
  if (process.env.RAILS_ENV === "staging") {
    return path;
  }

  return `${process.env.PROTOCOL}://${process.env.DOMAIN}${path === "/" ? "" : path}`;
};

export const buildPath = (path, query) => {
  const queryParams = Object.keys(query)?.length ? `?${stringify(query)}` : "";
  return `${path}${queryParams}`;
};

export const addQueryParams = (query, { replace, location: { pathname, state } }, alternativePath, disable) => {
  if (replace)
    replace(buildPath(alternativePath || pathname, query), {
      ...state,
      disable
    });
};

export const useValidationTimeout = limit => {
  const [validationCounter, setValidationCounter] = useState(validationCount());
  const [setValidationTimedOut] = useState(isValidationTimedOut());

  const setTimeout = () => {
    if (validationCounter < limit) increaseValidationCount();
    else {
      setValidationTimeout(5);
    }
  };

  const eventHandler = () => {
    setValidationCounter(validationCount());
    setValidationTimedOut(isValidationTimedOut());
  };

  useEffect(() => {
    document.addEventListener("validationReset", eventHandler, false);

    return () => {
      document.removeEventListener("validationReset", eventHandler, false);
    };
  }, []);

  return { isTimedOut: false, setTimeout };
};

export const useDocumentTitle = (title, extend = false) => {
  useEffect(() => {
    if (title) document.title = extend ? `${initTitle} ${title}` : title;

    return () => {
      document.title = initTitle;
    };
  }, [title]);
};

export const useErrorFocus = (formRef, validation) => {
  useEffect(() => {
    if (formRef.current) {
      const errorInput = formRef.current.querySelector("[aria-invalid='true']");
      if (errorInput) errorInput.focus();
    }
  }, [formRef, validation]);
};

export const useWindowSize = (timeout = 250) => {
  const getWindowSize = useCallback(
    () => ({
      width: window.innerWidth,
      height: window.innerHeight
    }),
    []
  );

  const [windowSize, setWindowSize] = useState(getWindowSize);

  const handleResize = useMemo(
    () =>
      debounce(() => {
        setWindowSize(getWindowSize);
      }, timeout),
    [timeout]
  );

  window.addEventListener("resize", handleResize);

  return windowSize;
};

export const useInitialFocus = ref => {
  useEffect(() => {
    if (ref?.current) {
      ref.current.focus();
    }
  }, [ref?.current]);
};

export const usePreventSubmission = toPrevent => {
  useEffect(() => {
    const prevent = e => {
      if (toPrevent && e.keyCode == 13) {
        e.preventDefault();
      }
    };

    window.addEventListener("keydown", prevent);

    return () => window.removeEventListener("keydown", prevent);
  }, [toPrevent]);
};

export const useModal = (component, title, onClose, closable) => {
  const { toggle } = useContext(ModalContext);
  const openModal = useCallback(
    props => {
      toggle(
        React.cloneElement(component, { close: () => toggle(), onClose, ...props }),
        title,
        closable,
        null,
        false,
        props
      );
    },
    [component, onClose]
  );
  return openModal;
};

export const useScrollHandler = (behavior = "smooth") => {
  const targetRef = useRef();
  const parentRef = useRef();

  const scrollHandler = () => {
    if (targetRef?.current)
      (parentRef?.current || window).scrollTo({
        top: targetRef?.current?.getBoundingClientRect().top + window.scrollY - 60,
        behavior
      });
  };

  return { scrollHandler, targetRef, parentRef };
};

export const useNotification = (content, color, onClose) => {
  const { setNotification } = useContext(NotificationContext);
  const notify = useCallback(() => {
    if (setNotification) return setNotification(content, color, onClose);
  }, [content, onClose]);
  return notify;
};

export const useFocusTrap = (onEscape, aggressive) => {
  const ref = useRef();
  const focusableElements = "button, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])";

  const handleKeyDown = e => {
    if (e.keyCode === 27 && onEscape && !aggressive) onEscape();
    if (e.key === "Tab" || e.keyCode === 9) {
      const focusableContent = ref.current?.querySelectorAll(focusableElements);
      const firstFocusableElement = focusableContent?.[0];
      const lastFocusableElement = focusableContent?.[focusableContent.length - 1];

      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          if (lastFocusableElement) {
            lastFocusableElement.focus();
          }
          e.preventDefault();
        }
      } else if (document.activeElement === lastFocusableElement) {
        if (firstFocusableElement) {
          firstFocusableElement.focus();
        }
        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);

    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, []);

  return ref;
};

export const useOpenSupportChanel = (
  channels,
  conversations,
  setActiveTab,
  search,
  setConversation,
  activeTab,
  isAdmin
) => {
  useEffect(() => {
    const channelId = getSearchParams(search).get("channel");
    const tab = isAdmin ? MESSAGE_TAB_NAMES.CHANNELS : MESSAGE_TAB_NAMES.SUPPORT;

    if (Boolean(channelId) && activeTab !== tab) {
      setActiveTab(tab);
    }
  }, [search]);

  useEffect(() => {
    const channelId = getSearchParams(search).get("channel");

    if (Boolean(channelId) && channels) {
      const findConversationOfChannel = channels?.find(({ id }) => Number(id) === Number(channelId))?.conversation;

      if (findConversationOfChannel) {
        setConversation(findConversationOfChannel);
      }
    }

    if (Boolean(channelId) && conversations && isAdmin) {
      const findConversationOfChannel = conversations?.find(
        ({ channel_id }) => Number(channel_id) === Number(channelId)
      );

      if (findConversationOfChannel) {
        setConversation(findConversationOfChannel);
      }
    }
  }, [channels, conversations, search]);

  return null;
};

export const useAddClassnameWhenOpenChat = () => {
  const userStore = useStore("User");
  const { pathname } = useLocation();
  const messagePathname = "/messages";
  const isMessagePathname = pathname === messagePathname;
  const wrapperMessage = "wrapper_message";
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);

  if (!userStore?.navigation) {
    return null;
  }

  const { navigation } = userStore;

  useEffect(() => {
    const wrapper = document.getElementsByClassName("wrapper")?.[0];

    if (isMessagePathname && !navigation.mobile && isSm) {
      wrapper.classList.add(wrapperMessage);
    }

    return () => {
      if (isMessagePathname) {
        wrapper.classList.remove(wrapperMessage);
      }
    };
  }, [navigation, navigation.mobile]);

  useEffect(() => {
    const wrapper = document.getElementsByClassName("wrapper")?.[0];

    if (isMessagePathname && navigation.mobile) {
      wrapper.classList.remove(wrapperMessage);
    }
  }, [navigation]);

  useEffect(() => {
    const wrapper = document.getElementsByClassName("wrapper")?.[0];

    return () => {
      if (isMessagePathname) {
        wrapper.classList.remove(wrapperMessage);
      }
    };
  }, []);

  return null;
};

export const useIsLoggedIn = () => {
  const { user } = useStore("User");

  return Boolean(user?.id);
};

export const useAddClassnameOnHomePage = () => {
  const { pathname } = useLocation();
  const homePathname = "/home";
  const home = "wrapper_home";
  const headerHome = "header_home";
  const isHomePathname = pathname === homePathname;

  useEffect(() => {
    const wrapper = document.getElementsByClassName("wrapper")?.[0];
    const header = document.getElementsByClassName("main-header")?.[0];

    if (isHomePathname) {
      wrapper.classList.add(home);
      header.classList.add(headerHome);
    }

    return () => {
      if (isHomePathname) {
        wrapper.classList.remove(home);
        header.classList.remove(headerHome);
      }
    };
  }, []);
};
