import { makeAutoObservable, reaction } from "mobx";
import API from "../utils/api";

let processingTimer;

let pingInterval;

class StateManager {
  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.connected,
      connected => {
        if (!connected && window?.setInterval) pingInterval = setInterval(this.pingServer, 5000);
        else if (window?.clearInterval) {
          clearInterval(pingInterval);
          if (this.state.action) this.state.action();
        }
      }
    );

    reaction(
      () => this.state.status,
      status => {
        if (!status || status === 0) {
          this.updateConnection(false);
        } else if (/404|500/.test(status)) {
          this.processingTooLong = false;
        } else if (!this.connected) {
          this.updateConnection(true);
        }
      }
    );

    reaction(
      () => this.state.processing,
      processing => {
        if (processing && window?.setTimeout) {
          processingTimer = setTimeout(() => {
            if (this.connected && !/404|500/.test(this.state.status)) this.processingTooLong = true;
          }, 15000);
        } else if (window?.clearTimeout) {
          clearTimeout(processingTimer);
          this.processingTooLong = false;
        }
      }
    );
  }

  state = {
    loading: false,
    processing: false,
    type: "",
    message: "",
    status: 200,
    action: null
  };

  connected = true;

  processingTooLong = false;

  updateConnection = connected => {
    this.connected = connected;
  };

  setAppState = state => {
    this.state = { ...this.state, ...state };
  };

  pingServer = () => {
    return API.pingServer()
      .then(() => {
        this.connected = true;
      })
      .catch();
  };
}

export default StateManager;
