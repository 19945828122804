import React, { useContext } from "react";
import { MdClose } from "react-icons/md";
import styled from "styled-components";

import { withTooltip } from "components/Tooltip";
import { onEnterPress } from "utils/helpers";
import ThemeContext from "../../../contexts/theme";

import Text from "../Text";
import Button from "../Button";

const TagWrapper = styled.div(item => item.styled);
const TagContent = styled.div(item => item.styled);
const TagControls = styled.div(item => item.styled);

const Tag = ({
  name,
  isActive,
  onRemove,
  removable = true,
  selectable = true,
  height = "auto",
  width,
  borderColor = "grey300",
  ActiveBorderColor = "primary",
  RemovableBorderColor = "grey300",
  bgColor = "white",
  bgActiveColor = "primary100",
  color = "black500",
  fontSize = "14px",
  fontWeight = 400,
  Icon,
  rightAlignIcon,
  padding = 8,
  margin = 0,
  style,
  maxLength = 48,
  onClick,
  flexDirection,
  shadow,
  ...props
}) => {
  const Theme = useContext(ThemeContext);

  return (
    <TagWrapper
      className="tag-item"
      tabIndex={onClick ? "0" : "-1"}
      data-hoverable={removable || selectable}
      onClick={onClick}
      onKeyDown={e => onEnterPress(e, 1, onClick)}
      styled={{
        border: `1px solid ${
          isActive && selectable
            ? Theme[ActiveBorderColor]
            : removable
              ? Theme[RemovableBorderColor]
              : Theme[borderColor]
        }`,
        backgroundColor: isActive && selectable ? Theme[bgActiveColor] : Theme[bgColor],
        margin,
        height,
        width,
        cursor: selectable ? "pointer" : "default",
        ...(shadow ? { boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)" } : {}),
        ...style
      }}
      data-test-control="tag"
      {...props}
    >
      <TagContent
        className="tag-item__content"
        styled={{
          fontSize,
          fontWeight,
          padding,
          width: "100%",
          justifyContent: "space-between",
          flexDirection,
          ...(removable ? { paddingRight: 0 } : {})
        }}
      >
        {Icon && !rightAlignIcon && <Icon />}
        <Text color={color} maxLength={maxLength} margin={Icon && "auto 6px"}>
          {name}
        </Text>
        {Icon && rightAlignIcon && <Icon />}
      </TagContent>

      {removable && (
        <TagControls className="tag-item__controls" style={{ marginLeft: -(padding / 2) }} onClick={onRemove}>
          <Button.Iconed type="button" noBackground theme="black500" fontSize={height / 2} title="Remove">
            <MdClose />
          </Button.Iconed>
        </TagControls>
      )}
    </TagWrapper>
  );
};

export default withTooltip(Tag);
