import React, { useContext } from "react";
import { Row, Col, ScreenClassContext } from "react-grid-system";

import { useTranslation } from "b2c/hooks/useTranslation";
import { Text, Button, Dropdown } from "../Core";
import useStore from "../../contexts/store";

const CountryNotification = ({ ssr }) => {
  const { default_country: defaultCountry, countries } = useStore("initialState");
  const employers = /employers/.test(window.location.pathname);
  const screen = useContext(ScreenClassContext);
  const isSm = /xs|sm/.test(screen);
  const findDefaultCountryInList =
    defaultCountry?.slug && countries?.length ? countries?.find(country => country.slug === defaultCountry?.slug) : "";
  const { t } = useTranslation();

  return (
    <Row style={{ width: "100%", padding: "16px 0" }} justify="center">
      <Col lg={5} sm={12} style={{ display: "flex", marginBottom: isSm ? 16 : 0 }}>
        <Text color="white">{t("landing_fuzu.select_country_title")}</Text>
      </Col>
      <Col lg={5} sm={12}>
        <Row nowrap>
          <Col lg={8} xs={8} style={{ display: "flex" }}>
            {!ssr && countries.length ? (
              <Dropdown
                defaultValue={findDefaultCountryInList?.slug || t("landing_fuzu.select_country_placeholder")}
                wrapperStyle={{ width: "100%", maxWidth: "350px", height: "42px", marginBottom: "0" }}
                $triggerStyle={{ height: "42px" }}
              >
                <Dropdown.Menu disableSorting>
                  {countries.map(({ id, name, slug }) => (
                    <Dropdown.Item key={id} value={name} style={{ textTransform: "capitalize", padding: "0" }} event>
                      <Button
                        as="a"
                        href={employers ? `/${slug}/employers` : `${slug}`}
                        width="100%"
                        height="100%"
                        theme="transparent"
                        margin="0"
                        style={{ justifyContent: "flex-start", padding: "10px" }}
                        target="_self"
                      >
                        {name}
                      </Button>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            ) : null}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CountryNotification;
