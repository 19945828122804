import styled, { css } from "styled-components";
import InputMask from "react-input-mask";

export const InputWrapper = styled.div`
  margin: ${props => (props.$hasError ? "0" : "0 0 20px")};
  position: relative;
  ${props => props.styled};

  > svg {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 1;
  }
`;

export const InputHolder = styled.div`
  display: flex;
  position: relative;

  > svg {
    position: absolute;
    z-index: 10;
    top: 50%;
    right: 0;
    transform: translateY(-50%) translateX(-50%);
    cursor: pointer;
  }
`;
export const PrependedText = styled.label`
  border-radius: 5px 0 0 5px;
  display: flex;
  align-items: center;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid ${props => (props.$hasError ? props.theme.accent300 : props.theme.grey200)};
  background-color: ${props => (props.$hasError ? props.theme.accent200 : props.theme.grey100)};
  user-select: none;
  border-right: none;
  transition: 0.2s ease;
`;

export const InputLabel = styled.label`
  display: block;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin: 0 0 8px;
  ${props => props.styled};
`;

export const RequiredText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #666666;

  @media screen and (min-width: 992px) {
    font-size: 16px;
  }
`;

const shared = () => css`
  ${({
    theme,
    $hasError,
    $withPrependedText,
    disabled,
    color,
    height,
    borderWidth = "1px",
    borderColor = "grey200",
    backgroundColor = "white"
  }) => `
    padding: 0 12px;
    border: ${borderWidth} solid
      ${$hasError ? theme.accent300 : theme[borderColor]};
    background-color: ${$hasError ? theme.accent200 : disabled ? theme.grey100 : theme[backgroundColor]};
    border-radius: ${$withPrependedText ? "0 5px 5px 0" : "5px"};
    width: 100%;
    color: ${disabled ? theme.black300 : color};
    font-size: 14px;
    appearance: none;
    outline: none;
    transition: 0.2s ease;
    height: ${height};
    ${
      !disabled &&
      `&:hover {
        background-color: ${theme[backgroundColor]};
        border-color: ${$hasError ? theme.accent300 : theme.secondary200};
      }
      &:focus {
        border-color: ${$hasError ? theme.accent300 : theme.secondary300};
        background-color: ${theme[backgroundColor]};
      }
      `
    }

    &::-webkit-input-placeholder {  
      color: #A4A4A4;
    }
  `}
  ${item => item.styled}
`;

export const InputControl = styled.input`
  ${shared}
`;

export const InputControlInputMask = styled(InputMask)`
  ${shared}
`;
