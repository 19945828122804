import React from "react";
import Popup from "reactjs-popup";

import { useTranslation } from "b2c/hooks/useTranslation";
import { useModal } from "utils/hooks";
import ContactDetails from "b2b/components/ContactDetails";
import defaultAvatar from "images/empty_avatar.png";
import Button from "components/Button";

const UserMenu = ({
  user: { id, first_name, last_name, avatar },
  isOwned,
  adminAccess,
  UserCvButton,
  getContactDetails
}) => {
  const { t } = useTranslation();

  const openContactDetails = useModal(<ContactDetails />, "messages.contact_details");

  const getDetailsAndOpen = () => {
    getContactDetails(id).then(({ email, phone_number }) => {
      openContactDetails({ email, phone: phone_number });
    });
  };

  return adminAccess && !isOwned ? (
    <Popup
      position="bottom right"
      arrow={false}
      closeOnDocumentClick
      contentStyle={{ width: "auto", display: "flex", flexDirection: "column" }}
      trigger={props => (
        <img className="sender-avatar trigger" alt="Sender's photo" src={avatar || defaultAvatar} {...props} />
      )}
    >
      {UserCvButton && <UserCvButton id={id} />}
      <Button.Simple onClick={getDetailsAndOpen} style={{ marginBottom: 10 }}>
        {t("messages.contact_details")}
      </Button.Simple>
      <Button.Simple tag="a" href={`https://www.fuzu.com/support/users/${id}/impersonate`} target="_blank">
        {t("messages.impersonate")} {first_name} {last_name}
      </Button.Simple>
    </Popup>
  ) : (
    <img className="sender-avatar" alt="Sender's photo" src={avatar || defaultAvatar} />
  );
};

export default UserMenu;
