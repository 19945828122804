import React, { useLayoutEffect, useRef } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "b2c/hooks/useTranslation";

import { getCompanyName } from "b2c/contexts/branded";
import { useInitialFocus } from "utils/hooks";
import { StyledContent, WaveHand, Heading } from "./styled";
import { Button, Text } from "../../Core";
import useStore from "../../../contexts/store";

const FirstTimeModalContent = ({ close }) => {
  const { initiateIntroduction, highlightedRegions, setTutorialCompleted } = useStore("HomeLayout");
  const { user } = useStore("User");
  const btnRef = useRef();
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (highlightedRegions.length > 0) close();

    return () => {
      setTutorialCompleted();
    };
  }, [highlightedRegions]);

  useInitialFocus(btnRef);

  return (
    <StyledContent>
      <Heading data-cy="firstTimeModalTitle">
        <Text fontWeight={500} margin="12px 0 24px">
          {t("tour.title")} {getCompanyName()}
          {user?.first_name ? `, ${user?.first_name}` : ""}!
        </Text>
        <WaveHand>👋🏿</WaveHand>
      </Heading>

      <Text margin="auto auto 24px" lineHeight="21px">
        {t("tour.description", { name: getCompanyName() })}
      </Text>
      <Button ref={btnRef} width={200} margin="0 auto" onClick={initiateIntroduction}>
        {t("tour.button")}
      </Button>
    </StyledContent>
  );
};

export default observer(FirstTimeModalContent);
