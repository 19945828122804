import styled from "styled-components";

export const ScoreRow = styled.div`
  width: 100%;
  position: relative;

  .score-titles {
    position: absolute;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    top: calc(50% - 14px);

    @media screen and (max-width: 767px) {
      top: 0px;
    }

    > p {
      width: 150px;
    }
  }
`;

export const ScoreBarWrapper = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;

  > .side-description {
    width: calc(100% - 300px);
    position: absolute;
    bottom: 0;
    left: 150px;
    display: inline-flex;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      width: 100%;
      left: 0px;
    }

    > * {
      width: 100px;
    }
  }
`;

export const ScoreBar = styled.div`
  ${({ theme, userScore, userFlip, averageScore, averageFlip, headHuntingName }) => `
        width: calc(100% - 300px);
        height: 18px;
        background-color: ${theme.grey100};
        border: 1px solid ${theme.secondary100};
        border-radius: 9px;
        margin: 24px auto;
        position: relative;

        @media screen and (max-width: 767px) {
            margin-top: 50px;
            width: 100%;
        }

        &:after {
            content: " ";
            height: 30px;
            width: 1px;
            position: absolute;
            background-color: ${theme.primary};
            left: 50%;
            top: calc(50% - 15px);
        }

        > .filled-bar {
            height: 10px;
            border-radius: 0 5px 5px 0;
            position: absolute;
            left: calc(50% - ${userFlip ? `${userScore / 2}%` : "0px"});
            top: calc(50% - 5px);
            width: ${userScore / 2}%;
            background: linear-gradient(to right, ${theme.primary100} 0%, ${theme.primary} ${100 - userScore}%, ${
              theme.primary300
            } ${200 - userScore}%);
            ${userFlip ? "transform: scaleX(-1);" : ""}
        }

        > .score-indicator {
            height: 6px;
            width: 6px;
            position: absolute;
            border-radius: 100%;
            background-color: ${theme.white};
            top: calc(50% - 3px);
            left: calc(50% ${userFlip ? "-" : "- 6px +"} (${userScore / 2}% - 2px));

            &:before {
                width: ${headHuntingName ? "auto" : "32px"};
                padding: ${headHuntingName ? "0 5px" : "0"};
                content: attr(name);
                color: ${theme.white};
                text-align: center;
                border-radius: 11px;
                font-size: 11px;
                font-weight: 500;
                left: ${userFlip ? "8px" : "-32px"};
                position: absolute;
                background-color: ${theme.black500};
                top: -24px;
                z-index: 2;
            }

            &:after {
                content: "";
                height: 24px;
                width: 1px;
                left: 50%;
                position: absolute;
                background-color: ${theme.primary300};
                top: -24px;
                z-index: 1;
            }
        }

        > .average-indicator {
            height: 10px;
            width: 10px;
            position: absolute;
            border-radius: 100%;
            background-color: ${theme.white};
            border: 2px solid ${theme.secondary300};
            top: calc(50% - 5px);
            left: calc(50% ${averageFlip ? "-" : "- 6px +"} (${averageScore / 2}% - 2px));

            &:before {
                width: 58px;
                content: "Average";
                color: ${theme.white};
                text-align: center;
                border-radius: 11px;
                font-size: 11px;
                font-weight: 500;
                left: ${averageFlip ? "8px" : "-58px"};
                position: absolute;
                background-color: ${theme.secondary300};
                top: -38px;
                z-index: 2;
            }

            &:after {
                content: "";
                height: 38px;
                width: 1px;
                left: 50%;
                position: absolute;
                background-color: ${theme.secondary300};
                top: -38px;
                z-index: 1;
            }
        }
    `}
`;
