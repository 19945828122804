import React, { useContext, forwardRef } from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";
import Btn from "components/Button";
import { useModal } from "utils/hooks";
import { RightArrowAlt } from "@styled-icons/boxicons-regular";
import onBoardingJobIcon from "images/b2c/onboarding/onBoardingJobIcon.png";
import onboardingLike from "images/b2c/onboarding/onboardingLike.png";
import searchAggressive from "images/search-aggressive.png";
import findJobOnboarding from "images/b2c/onboarding/findJobOnboarding.png";
import ThemeContext from "../../../contexts/theme";

import Icon from "../Icon";
import Spinner from "../Spinner";
import LoginModal from "../../ModalContents/LoginModal";
import { useTranslation } from "../../../hooks/useTranslation";

const StyledButton = styled(Btn)(({ $styled }) => $styled);
const StyledIconed = styled(Btn.Iconed)(({ $styled }) => $styled);
const StyledLink = styled(Link)(({ $styled }) => $styled);

export const Button = forwardRef(
  (
    {
      theme = "primary",
      fontSize,
      fontWeight,
      width,
      height = 42,
      margin,
      padding = "0 15px",
      color,
      icon,
      fill,
      style,
      noBackground = false,
      bordered = false,
      uppercase = false,
      processing = false,
      className = "",
      arrow,
      testControl,
      disabled,
      children,
      dataCy,
      ...props
    },
    ref
  ) => {
    const Theme = useContext(ThemeContext);

    return (
      <StyledButton
        className={`b2c-button${icon ? " icon" : ""} ${className}`}
        ref={ref}
        icon={icon ? React.cloneElement(icon, fill ? { fill: Theme[fill] } : {}) : null}
        tabIndex="0"
        data-cy={dataCy}
        $styled={{
          fontSize,
          fontWeight,
          width,
          height,
          margin,
          padding,
          ...(bordered ? { border: `1px solid ${Theme[theme]}` } : {}),
          ...(uppercase ? { textTransform: "uppercase" } : {}),
          ...(!noBackground ? { ...Theme.getColors(theme) } : { background: "none", color: Theme[theme] }),
          ...(color ? { color: Theme[color] } : {}),
          ...style
        }}
        data-test-control={testControl}
        disabled={disabled || processing}
        children={
          processing ? (
            <>
              <Spinner
                size="30px"
                primaryFill="secondary200"
                secondaryFill="transparent"
                style={{
                  position: "absolute",
                  right: "50%",
                  transform: "translateX(50%)"
                }}
              />
              {children}
              {arrow && (
                <Icon
                  as={RightArrowAlt}
                  width={16}
                  fill={Theme[color] || Theme.getTextColor(theme) || "black500"}
                  margin="auto 0 auto 8px"
                />
              )}
            </>
          ) : (
            <>
              {children}
              {arrow && (
                <Icon
                  as={RightArrowAlt}
                  width={16}
                  fill={Theme[color] || Theme.getTextColor(theme) || "black500"}
                  margin="auto 0 auto 8px"
                />
              )}
            </>
          )
        }
        {...props}
      />
    );
  }
);

Button.Iconed = ({
  theme = "primary",
  fontSize,
  fontWeight,
  width,
  height,
  margin,
  color,
  style,
  noBackground = false,
  bordered = false,
  className = "",
  ...props
}) => {
  const Theme = useContext(ThemeContext);

  return (
    <StyledIconed
      className={`b2c-iconed-flat-btn ${className}`}
      $styled={{
        fontSize,
        fontWeight,
        width,
        height,
        margin,
        ...(bordered ? { border: `1px solid ${Theme.getBorderColor(theme)}` } : {}),
        ...(!noBackground ? { ...Theme.getColors(theme) } : { background: "none", color: Theme[theme] }),
        ...(color ? { color: Theme[color] } : {}),
        ...style
      }}
      {...props}
    />
  );
};

Button.Link = forwardRef(
  (
    {
      to,
      as = Link,
      children,
      margin,
      width,
      height = 42,
      className,
      ariaLabel,
      style,
      state,
      event,
      theme,
      $trackParams,
      ...buttonProps
    },
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <StyledLink
        as={as}
        to={to}
        state={state}
        className={className}
        $trackParams={$trackParams}
        event={event}
        $styled={{
          display: style?.display || "inline-flex",
          alignSelf: style?.alignSelf || "inherit",
          textDecoration: "none",
          verticalAlign: "middle",
          margin,
          width,
          height
        }}
        aria-label={ariaLabel}
      >
        <Button
          ref={ref}
          theme={theme || "primary"}
          tag="span"
          width="100%"
          height="100%"
          style={style}
          {...buttonProps}
        >
          {children || t("global.back")}
        </Button>
      </StyledLink>
    );
  }
);

Button.Signup = forwardRef(({ children, fromUrl, ...rest }, ref) => {
  const { t } = useTranslation();

  const openLoginModal = useModal(
    <LoginModal
      type="job"
      from={fromUrl}
      image={findJobOnboarding}
      title={t("global.login_modal.title")}
      list={[
        { icon: searchAggressive, title: t("global.login_modal.list_1") },
        { icon: onboardingLike, title: t("global.login_modal.list_2") },
        { icon: onBoardingJobIcon, title: t("global.login_modal.list_3") }
      ]}
    />,
    null,
    () => {},
    0
  );
  return (
    <Button
      ref={ref}
      onClick={() => {
        openLoginModal({ aggressive: true, isDisableWindowScroll: true });
        const email = rest?.state?.email;
        if (email) {
          const onboardingForm = {
            email
          };
          localStorage.setItem("onboardingForm", JSON.stringify(onboardingForm));
        }
      }}
      {...rest}
    >
      {children}
    </Button>
  );
});

export default Button;
