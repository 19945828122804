import axios from "axios";

// Account settings

const settings_path = "/api/v1/browse/profile/settings";

export const updatePassword = user => axios.patch(`${settings_path}/password`, { user });

export const updateLanguage = language => axios.patch(`${settings_path}/language`, { locale: language });

export const updateEmail = user => axios.patch(`${settings_path}/email`, { user });

export const updateVisibility = () => axios.patch(`${settings_path}/visibility`);

export const deleteAccount = () => axios.delete(`${settings_path}/account`);
