import React, { useEffect, useContext, useState, useRef } from "react";

import { onEnterPress } from "utils/helpers";
import ThemeContext from "../../../contexts/theme";
import Text from "../Text";

import { CheckboxWrapper, CheckboxLabel, CheckboxMark, CheckboxControl, CheckboxMarkIcon } from "./styled";

const Checkbox = ({
  id = "id",
  margin = "10px 0",
  width,
  height,
  label,
  tabIndex = 0,
  errorMessage,
  errorColor = "accent300",
  baseColor = "black200",
  checkedColor = "primary",
  checkedBgColor = "primary100",
  borderColor = "grey300",
  markColor = "white",
  $markMargin = "auto 12px auto 0px",
  checked = false,
  boxedView = false,
  borderedView = false,
  fieldRequired = false,
  describedBy,
  style,
  labelProps = {},
  checkboxMarkProps = {},
  ...props
}) => {
  const control = useRef();
  const Theme = useContext(ThemeContext);
  const [displayError, setErrorDisplay] = useState(true);

  useEffect(() => {
    if (displayError) setErrorDisplay(false);
  }, [checked]);

  useEffect(() => {
    setErrorDisplay(true);
  }, [errorMessage]);

  const handleEnter = e => {
    e.stopPropagation();
    onEnterPress(e, 0, () => control.current.click());
  };

  const uid = `uid-${id}`;
  const eid = `eid-${id}`;
  const errorState = !!errorMessage && displayError;

  return (
    <CheckboxWrapper
      role="checkbox"
      tabIndex={tabIndex}
      aria-describedby={describedBy || `checkbox-label-${uid}`}
      aria-invalid={errorState}
      aria-checked={checked ? "true" : "false"}
      onKeyDown={handleEnter}
      styled={{ width, height, margin, ...style }}
    >
      <CheckboxControl id={uid} ref={control} type="checkbox" hasError={errorState} checked={checked} {...props} />
      <CheckboxLabel
        id={`checkbox-label-${uid}`}
        htmlFor={uid}
        boxedView={boxedView}
        borderedView={borderedView}
        checked={checked}
        checkedColor={Theme[checkedColor]}
        checkedBgColor={Theme[checkedBgColor]}
        borderColor={Theme[borderColor]}
        {...labelProps}
      >
        <CheckboxMark
          checked={checked}
          baseColor={Theme[baseColor]}
          checkedColor={Theme[checkedColor]}
          $markMargin={$markMargin}
          {...checkboxMarkProps}
        >
          <CheckboxMarkIcon markColor={Theme[markColor]} checked={checked} checkedColor={Theme[checkedColor]} />
        </CheckboxMark>
        <span>
          {label}
          {fieldRequired && "*"}
        </span>
      </CheckboxLabel>
      {errorState && (
        <Text id={eid} color={errorColor} margin="4px 0 0" loaded={errorState} aria-describedby={uid}>
          {errorMessage}
        </Text>
      )}
    </CheckboxWrapper>
  );
};

export default Checkbox;
