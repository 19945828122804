import styled from "styled-components";

export const StyledModalContent = styled.div`
  max-width: 550px;
  text-align: center;
  margin-top: -40px;
  padding: 0 8px;
  position: relative;
`;

export const ButtonClose = styled.button`
  display: inline-flex;
  height: fit-content;
  cursor: pointer;
  font-size: 20px;
  color: #707378;
  user-select: none;
  transition: 0.2s ease;
  z-index: 10;
  position: absolute;
  right: 10px;
  top: 10px;
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
`;

export const titleStyles = {
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "30px",
  color: "#0B0A0A",
  margin: "0 auto 16px auto"
};

export const buttonStyles = {
  display: "block",
  margin: "30px auto 12px auto",
  maxWidth: "350px",
  width: "100%",
  padding: "10px"
};

export const buttonCancelStyles = {
  display: "block",
  color: "#408BFC",
  margin: "0 auto",
  fontWeight: 400
};

export const imageStyle = {
  display: "block",
  width: "52px",
  height: "52px",
  margin: "20px auto 16px auto"
};
