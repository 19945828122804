import React from "react";
import { Bell, Star, Alarm, InfoCircle, Message } from "@styled-icons/boxicons-regular";

import { getCompanyName } from "b2c/contexts/branded";
import { notificationRequestPermission, getPushSubscription, getUserDeviceData } from "b2c/utils/helpers";
import { useTranslation } from "b2c/hooks/useTranslation";
import { NotificationsContent, NotificationsItems, Text } from "./styled";
import NotificationItem from "../NotificationItem";

const Notifications = ({
  updateNotificationSettings,
  notificationSettings,
  updateUserDevice,
  userDevice,
  setNotificationPrompt
}) => {
  const { top_candidate, daily_job, application_status_updates, messages } = notificationSettings;
  const { t } = useTranslation();

  const handleUpdateAllowNotifications = () => {
    const allowNotificationPermission = !userDevice.allowPushNotifications;
    const { timeZone, language, userAgent } = getUserDeviceData();

    if (allowNotificationPermission) {
      notificationRequestPermission(async () => {
        const newUserDeviceSettings = {
          allow_push_notifications: !userDevice.allowPushNotifications,
          timeZone,
          language,
          userAgent
        };
        const { subscription } = await getPushSubscription(userDevice.deviceId, setNotificationPrompt);

        if (subscription) {
          newUserDeviceSettings.push_notification_creds = subscription;
        }

        updateUserDevice(userDevice.deviceId, newUserDeviceSettings);
      });
    } else {
      updateUserDevice(userDevice.deviceId, {
        allow_push_notifications: !userDevice.allowPushNotifications,
        timeZone,
        language,
        userAgent
      });
    }
  };

  const notificationsChannels = [
    {
      icon: Bell,
      title: t("notification_settings.notifications.title_1"),
      checked: userDevice.allowPushNotifications,
      value: "allow_push_notifications"
    }
  ];

  // removed weekly_job, comment_reply, trending_article
  const notificationsTypes = [
    {
      icon: Star,
      title: t("notification_settings.notifications.title_2"),
      text: t("notification_settings.notifications.label_2"),
      value: "top_candidate",
      checked: top_candidate
    },
    {
      icon: Alarm,
      title: t("notification_settings.notifications.title_3"),
      text: t("notification_settings.notifications.label_3"),
      value: "daily_job",
      checked: daily_job
    },
    {
      icon: InfoCircle,
      title: t("notification_settings.notifications.title_4"),
      text: t("notification_settings.notifications.label_4"),
      value: "application_status_updates",
      checked: application_status_updates
    },
    {
      icon: Message,
      title: t("notification_settings.notifications.title_5"),
      text: `${t("notification_settings.notifications.label_5")} ${getCompanyName()}`,
      value: "messages",
      checked: messages
    }
  ];

  const handleChange = value => {
    const notificationSettingsParams = {
      ...notificationSettings,
      [value]: !notificationSettings[value]
    };

    updateNotificationSettings(notificationSettingsParams);
  };

  const notificationsChannelsList = notificationsChannels.map(item => (
    <NotificationItem
      id={item.value}
      key={item.value}
      Icon={item.icon}
      title={item.title}
      checked={item.checked}
      onChange={handleUpdateAllowNotifications}
      isNotChangeByDefault
    />
  ));

  const notificationsTypesList = notificationsTypes.map(item => (
    <NotificationItem
      id={item.value}
      key={item.value}
      Icon={item.icon}
      title={item.title}
      checked={item.checked}
      text={item.text}
      onChange={() => handleChange(item.value)}
    />
  ));

  return (
    <NotificationsContent>
      <NotificationsItems>
        <Text>{t("notification_settings.notification_channel_title")}</Text>
        {notificationsChannelsList}

        <Text>{t("notification_settings.notification_channel_types")}</Text>
        {notificationsTypesList}
      </NotificationsItems>
    </NotificationsContent>
  );
};

export default Notifications;
