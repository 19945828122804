import { makeAutoObservable } from "mobx";
import * as State from "utils/storeHelpers";
import API from "../../utils/api";

class Author {
  state = {
    loading: false,
    processing: false,
    error: false,
    message: ""
  };

  author;

  constructor(author) {
    makeAutoObservable(this);

    this.author = author || {};
  }

  getAuthor = id => {
    if (!this.author || (this.author?.id != id && this.author?.slug != id)) {
      this.state = State.setLoading(this.state);
      API.getAuthor(id)
        .then(res => {
          this.author = res.data;

          this.state = State.setNeutral(this.state, res);
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Could not retrieve article contents", () => this.getAuthor(id));
        });
    }
  };
}

export default Author;
