import axios from "axios";

// Blog/Article

const article_path = "/api/v1/home/articles";

export const getArticleCategories = countryId =>
  axios.get(`${article_path}/dynamic_filters${countryId ? `?country_id=${countryId}` : ""}`);

export const getAuthor = id => axios.get(`${article_path}/authors/${id}`);

export const getArticle = id => axios.get(`${article_path}/${id}`);

export const likeArticle = id => axios.post(`${article_path}/${id}/like`);

export const removeLike = id => axios.post(`${article_path}/${id}/unlike`);

export const getArticleComments = id => axios.get(`${article_path}/${id}/comments`);

export const addArticleComment = (id, body, parent_id) =>
  axios.post(`${article_path}/${id}/comments`, { body, parent_id });

export const updateComment = (article_id, body, comment_id) =>
  axios.patch(`${article_path}/${article_id}/comments/${comment_id}`, { body });

export const removeComment = (article_id, comment_id) =>
  axios.delete(`${article_path}/${article_id}/comments/${comment_id}`);

export const addArticleReply = (articleId, parent_id, body) =>
  axios.post(`${article_path}/${articleId}/comments`, {
    body,
    parent_id
  });
