import styled from "styled-components";

export const StyledWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  opacity: ${({ currentOpacity }) => currentOpacity};
  padding: ${({ padding }) => padding};

  > div {
    width: 20%;
  }
`;

export const IconWrapper = styled.div``;
