import React, { useContext } from "react";
import styled from "styled-components";

import Diamond from "images/b2c/icons/diamond.svg";
import PremiumAlternativeIco from "images/b2c/premium_alternative.svg";
import ThemeContext from "../../../contexts/theme";

const Styled = styled.span(item => item.styled);

const Label = ({
  theme = "secondary300",
  noBackground = false,
  backgroundColor,
  rect = false,
  bordered = false,
  borderColor,
  fontSize = 12,
  fontWeight = 500,
  lineHeight,
  textAlign,
  height,
  width,
  padding = "4px 8px",
  margin = "0 0 0 6px",
  fill,
  color,
  Icon,
  children,
  style,
  display = "flex"
}) => {
  const Theme = useContext(ThemeContext);

  return (
    <Styled
      className="b2c-label"
      styled={{
        fontSize,
        fontWeight,
        lineHeight,
        height,
        width,
        padding,
        margin,
        textAlign,
        display,
        ...(rect ? { borderRadius: 4 } : {}),
        ...(bordered ? { border: `1px solid ${Theme[borderColor || theme]}` } : {}),
        ...(!noBackground ? { ...Theme.getColors(theme) } : { background: "none", color: Theme[theme] }),
        ...(backgroundColor ? { backgroundColor: Theme[backgroundColor] } : {}),
        ...(color ? { color: Theme[color] } : {}),
        ...style
      }}
    >
      {Icon &&
        React.cloneElement(Icon, {
          fill: !noBackground ? (fill ? Theme[fill] : Theme.getTextColor(theme)) : Theme[fill || theme]
        })}
      {children}
    </Styled>
  );
};

export const withLabel = WrappedComponent => {
  return class extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {
      return (
        <WrappedComponent {...this.props}>
          {this.props.children}
          <Label {...this.props.labelProps}>{this.props.label}</Label>
        </WrappedComponent>
      );
    }
  };
};

Label.Premium = () => (
  <Label
    theme="primary300"
    color="black500"
    fill="black500"
    fontWeight={500}
    margin="0 0 15px"
    Icon={<Diamond width={16} />}
  >
    Premium
  </Label>
);

Label.PremiumAlternative = ({ title = "premium", iconSize, style, ...rest }) => (
  <Label
    Icon={<PremiumAlternativeIco style={{ marginRight: "10px", width: iconSize, height: iconSize }} />}
    margin="0"
    noBackground
    fill="transparent"
    fontSize={14}
    fontWeight={600}
    color="additional300"
    padding="0"
    style={{ ...style, textTransform: "uppercase", letterSpacing: "3px" }}
    {...rest}
  >
    {title}
  </Label>
);

export default Label;
