import React from "react";
import { useHistory } from "react-router-dom";
import { Check } from "@styled-icons/boxicons-regular";
import parse from "html-react-parser";

import signUpClose from "images/signup-close.png";
import LogoPwa from "images/b2c/fuzu-logo-border.svg";
import { Image } from "../../Core";
import { Container, LogoContent, Title, Text, CheckContent, LoginContent, LoginButton, SignUpButton } from "./styled";
import { useTranslation } from "../../../hooks/useTranslation";

const listOfTextArray = [
  "sign_up_modal.receive_personalized_description",
  "sign_up_modal.get_feedback_description",
  "sign_up_modal.boost_your_skills_description"
];

const getModalInfo = type => {
  if (type === "learn") {
    return {
      title: "sign_up_modal.bring_your_skills_title",
      listOfText: [
        "sign_up_modal.free_and_premium_description",
        "sign_up_modal.learn_job-ready_description",
        "sign_up_modal.get_expert_description"
      ],
      buttonText: "sign_up_modal.join_now_its_free",
      isShowArrow: false
    };
  } else if (type === "join") {
    return {
      title: "sign_up_modal.join_africa_fastest_title",
      listOfText: [
        "sign_up_modal.get_free_career_description",
        "sign_up_modal.new_articles_description",
        "sign_up_modal.connect_description"
      ],
      buttonText: "sign_up_modal.join_now_its_free",
      isShowArrow: false
    };
  } else if (type === "job") {
    return {
      listOfText: listOfTextArray,
      buttonText: "sign_up_modal.join_now_its_free",
      isShowArrow: false,
      isJobModal: true
    };
  } else {
    return {
      listOfText: listOfTextArray,
      buttonText: "sign_up_modal.get_started_for_free",
      isShowArrow: true,
      isJobModal: true
    };
  }
};

const SignupModal = ({ close, from, type, appliedJobId, isShorterFlow }) => {
  const {
    location: { pathname, search }
  } = useHistory();
  const { t } = useTranslation();

  const {
    isJobModal,
    title,
    buttonText = "sign_up_modal.get_started_for_free",
    listOfText = listOfTextArray,
    isShowArrow = true
  } = getModalInfo(type);

  const renderText = text => {
    return (
      <Text>
        <CheckContent>
          <Check />
        </CheckContent>
        {t(text)}
      </Text>
    );
  };

  const renderListOfText = listOfText.map(item => {
    return <React.Fragment key={item}>{renderText(item)}</React.Fragment>;
  });

  const shorterFlow = isShorterFlow ? "?shorterFlow=true" : "";
  const normalizedLinkToOnboarding = `/onboarding${shorterFlow}${shorterFlow ? "&" : "?"}return_to=${
    from || pathname + search
  }`.replace("?filters", "&filters");

  const linkToOnboarding = appliedJobId
    ? `${normalizedLinkToOnboarding}&application_id=${appliedJobId}`
    : normalizedLinkToOnboarding;

  return (
    <Container>
      <Image
        src={signUpClose}
        onClick={close}
        alt="close"
        data-cy="close-modal"
        style={{
          position: "absolute",
          cursor: "pointer",
          top: "16px",
          right: "16px"
        }}
      />

      <LogoContent>
        <LogoPwa />
      </LogoContent>
      {isJobModal ? <Title>{parse(t("sign_up_modal.join_over_title"))}</Title> : <Title>{title}</Title>}

      <div>{renderListOfText}</div>

      <SignUpButton
        to={linkToOnboarding}
        arrow={isShowArrow}
        onClick={() => {
          const onboardingContext = localStorage.getItem("onboardingContext");
          if (onboardingContext !== "job") {
            localStorage.setItem("onboardingCurrentStep", "1");
            localStorage.setItem("onboardingContext", "job");
          }
          close();
        }}
      >
        {t(buttonText)}
      </SignUpButton>

      <LoginContent>
        <span>{t("global.already_have_an_account")}</span>
        <LoginButton to={`/login?return_to=${from || pathname + search}`} onClick={close}>
          {t("global.sign_in")}
        </LoginButton>
      </LoginContent>
    </Container>
  );
};

export default SignupModal;
