export const TABS = {
  APPLICANTS: "applicants",
  HEADHUNT: "headhunt",
  OPEN: "open",
  DETAILS: "details",
  UPDATES: "updates",
  CONVERSATIONS: "conversations",
  SAVED: "saved",
  promotions: "promotions",
  createPromotion: "createPromotion"
};
