import axios from "axios";

// Test & Survey

const form_path = "/api/v1/browse/forms";

const job_path = "/api/v1/browse/jobs";

export const getForm = id => axios.get(`${form_path}/${id}`);

export const startForm = id => axios.post(`${form_path}/${id}/start`);

export const getFormPage = id => axios.get(`${form_path}/pages/${id}`);

export const submitResponses = (responses, id) =>
  axios.post(`${form_path}/sessions/${id}/submit_responses`, { responses });

export const getApplicationForm = (form_id, job_id) => axios.get(`${job_path}/${job_id}/applications/forms/${form_id}`);

export const startApplicationForm = (form_id, job_id) =>
  axios.post(`${job_path}/${job_id}/applications/forms/${form_id}/start`);

export const submitApplicationResponses = (responses, session_id, job_id) =>
  axios.post(`${job_path}/${job_id}/applications/forms/sessions/${session_id}/submit_responses`, { responses });
