import styled from "styled-components";

export const StyledModalContent = styled.div`
  max-width: 450px;
  text-align: center;
  margin-top: -40px;
  padding: 0 60px;
`;

export const titleStyles = {
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "24px",
  color: "#0B0A0A",
  margin: "0 0 16px 0"
};

export const textStyles = {
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "24px",
  color: "#0B0A0A",
  display: "block",
  margin: "0 0 24px 0"
};

export const buttonStyles = {
  display: "block",
  margin: "0 auto 12px auto",
  width: "100%",
  maxWidth: "300px",
  padding: "10px",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px"
};

export const linkStyles = {
  display: "block",
  margin: "0 auto 12px auto",
  width: "100%",
  maxWidth: "300px",
  padding: "10px",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px"
};

export const buttonCancelStyles = {
  display: "block",
  color: "#0E50B5",
  margin: "0 auto",
  width: "100%",
  maxWidth: "300px",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px"
};

export const imageStyle = {
  display: "block",
  maxWidth: "110px",
  margin: "0 auto 16px auto"
};
