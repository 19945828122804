import axios from "axios";

const notifications_path = "/api/v1/browse/notifications";

export const getNotificationsList = () => {
  return axios.get(notifications_path);
};

export const makeNotificationRead = notificationId => {
  axios.patch(`${notifications_path}/${notificationId}/read`, { event_type: "clicked" });
};
