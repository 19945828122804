import styled from "styled-components";

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 650px;
`;

export const WaveHand = styled.span`
  display: inline-block;
  margin-left: 4px;
  @media screen and (min-width: 768px) {
    display: block;
    font-size: 50px;
    margin-left: 0;
  }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  @media screen and (min-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;
