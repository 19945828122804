import React from "react";

import notification from "images/notification.png";
import Notification from "../NotificationModal";
import { useNotificationsSettingsNav } from "../NotificationsSettings";

const NotificationModalAllowNotify = ({ close }) => {
  const { open } = useNotificationsSettingsNav();

  const handleOpenNotification = () => {
    open();
    close();
  };

  return (
    <Notification
      close={close}
      imgUrl={notification}
      title="You can manage your notification settings under your profile - just click your profile image."
      onClick={handleOpenNotification}
      buttonText="Go to notification settings"
      closeButtonText="Close"
    />
  );
};

export default NotificationModalAllowNotify;
