import { makeAutoObservable, autorun, reaction } from "mobx";

import { handleScrollToError, generateDeviceId } from "b2c/utils/helpers";
import * as State from "utils/storeHelpers";
import API from "../../utils/api";

const pad = d => (d ? (d < 10 ? `0${d.toString()}` : d.toString()) : null);

const checkDate = date => ["year", "month"].every(prop => !!date[prop]);

class ProfileStore {
  setAppState;

  job_details;

  profile = {};

  notificationSettings = {};

  userDevice = {
    allowPushNotifications: false,
    deviceId: ""
  };

  constructor(userStore, setAppState) {
    makeAutoObservable(this);

    this.userStore = userStore;
    this.setAppState = setAppState;

    autorun(() => {
      if (!userStore.user?.id) this.profile = {};
    });

    reaction(
      () => this.state,
      localState => {
        this.setAppState(localState);
      }
    );
  }

  state = {
    processing: false,
    loading: false,
    type: "",
    message: ""
  };

  editable = false;

  personal_options;

  validation_errors = {};

  work_experience_details;

  work_experience_options;

  education_details;

  education_options;

  language_info;

  certificate_info;

  associations;

  reference_options;

  reference_details;

  hobbies;

  skills;

  initializeProfile = id => {
    this.state = State.setLoading(this.state);
    this.editable = this.userStore.user && this.userStore.user.id == id;
    this.validation_errors = {};
    this.work_experience_details = {};
    this.education_details = {};
    this.reference_details = {};
    return Promise.all([
      !this.profile.general_info ? this.getGeneralInfo() : Promise.resolve(),
      !this.profile.personal_info ? this.getPersonalInfo() : Promise.resolve(),
      !this.profile.basic_info ? this.getBasicInfo() : Promise.resolve()
    ]).then(() => {
      this.state = State.setNeutral(this.state);
    });
  };

  getPersonality = force => {
    if (!this.profile.personality || force) {
      this.state = State.setLoading(this.state);
      return API.getPersonality()
        .then(res => {
          this.profile.personality = res?.data?.personality_results;
          this.state = State.setNeutral(this.state);
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Failed to retrieve personality data", () =>
            this.getPersonality()
          );
        });
    }
  };

  initializeWizard = () => {
    this.state = State.setLoading(this.state);
    this.validation_errors = {};
    this.work_experience_details = {};
    this.education_details = {};
    this.reference_details = {};
    return Promise.all([
      !this.profile.general_info ? this.getGeneralInfo() : Promise.resolve(),
      !this.profile.personal_info ? this.getPersonalInfo() : Promise.resolve()
    ]).then(() => {
      this.state = State.setNeutral(this.state);
    });
  };

  initializePersonalInfo = isNotCallCountries => {
    this.state = State.setLoading(this.state);
    this.validation_errors = {};
    return Promise.all([
      !this.profile.general_info ? this.getGeneralInfo() : Promise.resolve(),
      !this.profile.personal_info ? this.getPersonalInfo() : Promise.resolve(),
      !this.personal_options ? this.getPersonalOptions(isNotCallCountries) : Promise.resolve()
    ]).then(() => {
      this.state = State.setNeutral(this.state);
      return this.state;
    });
  };

  initializeWorkExperience = id => {
    this.state = State.setLoading(this.state);
    this.validation_errors = {};

    return Promise.all([
      !this.work_experience_options ? this.getWorkExperienceOptions() : Promise.resolve(),
      !this.profile.general_info ? this.getGeneralInfo() : Promise.resolve(),
      id ? this.getWorkExperienceDetails(id) : (this.work_experience_details = {})
    ]).then(() => {
      this.state = State.setNeutral(this.state);
      return this.state;
    });
  };

  initializeEducation = id => {
    this.state = State.setLoading(this.state);
    this.validation_errors = {};

    return Promise.all([
      !this.education_options ? this.getEducationOptions() : Promise.resolve(),
      !this.profile.general_info ? this.getGeneralInfo() : Promise.resolve(),
      id ? this.getEducationDetails(id) : (this.education_details = {})
    ]).then(() => {
      this.state = State.setNeutral(this.state);
      return this.state;
    });
  };

  initializeSkillset = work_experience_id => {
    this.state = State.setLoading(this.state);
    this.validation_errors = {};
    return Promise.all([this.getSkillOptions(work_experience_id)]).then(() => {
      this.state = State.setNeutral(this.state);
      return this.state;
    });
  };

  initializeLanguages = () => {
    this.state = State.setLoading(this.state);
    this.validation_errors = {};
    return Promise.all([this.getLanguageOptions(), this.getLanguages()]).then(() => {
      this.state = State.setNeutral(this.state);
      return this.state;
    });
  };

  initializeCertificates = () => {
    this.state = State.setLoading(this.state);
    this.validation_errors = {};
    return Promise.all([
      !this.certificate_info ? this.getCertificateOptions() : Promise.resolve(),
      this.getCertificates()
    ]).then(() => {
      this.state = State.setNeutral(this.state);
      return this.state;
    });
  };

  initializeAssociations = () => {
    this.state = State.setLoading(this.state);
    this.validation_errors = {};
    return this.getAssociations().then(() => {
      this.state = State.setNeutral(this.state);
      return this.state;
    });
  };

  initializeHobbies = () => {
    this.state = State.setLoading(this.state);
    this.validation_errors = {};
    return this.getHobbies().then(() => {
      this.state = State.setNeutral(this.state);
      return this.state;
    });
  };

  initializeReference = (id, isApplicationAttachment) => {
    this.state = State.setLoading(this.state);
    this.validation_errors = {};

    return Promise.all([
      !this.reference_options ? this.getReferenceOptions() : Promise.resolve(),
      !this.personal_options ? this.getPersonalOptions() : Promise.resolve(),
      id ? this.getReferenceDetails(id, isApplicationAttachment) : (this.reference_details = {})
    ]).then(() => {
      this.state = State.setNeutral(this.state);
      return this.state;
    });
  };

  getGeneralInfo = () => {
    return API.getGeneralInfo()
      .then(res => {
        this.profile = { ...this.profile, ...res?.data?.profile };
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve profile data", () => this.getGeneralInfo());
      });
  };

  getPersonalInfo = () => {
    return API.getPersonalInfo()
      .then(res => {
        this.profile.personal_info = res?.data?.personal_info;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve personal data", () => this.getPersonalInfo());
      });
  };

  getBasicInfo = () => {
    return API.getBasicInfo()
      .then(res => {
        this.profile.basic_info = res?.data?.basic_info;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve basic info", () => this.getBasicInfo());
      });
  };

  getPersonalOptions = isNotCallCountries => {
    return Promise.all(
      isNotCallCountries ? [API.getPersonalInfoOptions()] : [API.getPersonalInfoOptions(), API.getCountries()]
    )
      .then(res => {
        this.personal_options = isNotCallCountries
          ? { ...res[0].data }
          : { ...res[0].data, countries: res[1].data.countries };
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve options", () => this.getPersonalOptions());
      });
  };

  updateUserAvatar = avatar => {
    const form = new FormData();
    form.append("avatar", avatar);

    return API.updateUserAvatar(form)
      .then(res => {
        this.userStore.updateAvatar(res?.data?.avatar);
        this.profile.personal_info.avatar = res?.data?.avatar;
        this.profile.general_info.avatar = res?.data?.avatar;

        this.state = {
          type: "success",
          loading: false,
          status: res.status,
          message: ""
        };
        this.state = State.setNeutral(this.state, res);

        return true;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to update profile photo", () =>
          this.updateUserAvatar(avatar)
        );

        return false;
      });
  };

  deleteUserAvatar = () => {
    return API.deleteUserAvatar()
      .then(res => {
        this.userStore.updateUser({ ...this.userStore.user, avatar: null });
        this.profile.presonal_info.avatar = null;
        this.profile.general_info.avatar = null;

        this.state = State.setSuccess(this.state, res, "Successfully deleted profile photo");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to delete profile photo", () => this.deleteUserAvatar());
      });
  };

  updatePersonalInfo = async (form, avatar, isScrollToError) => {
    const filledForm = {
      date_of_birth: form.date ? `${form.date.year}-${pad(form.date.month)}-${pad(form.date.day)}` : "",
      ...form
    };

    this.state = State.setProcessing(this.state);
    this.validation_errors = {};

    return API.updatePersonalInfo(filledForm)
      .then(res => {
        this.profile.personal_info = res?.data?.personal_info;

        this.state = State.setSuccess(this.state, res, "Successfully updated personal info");
      })
      .then(() => {
        if (avatar) return this.updateUserAvatar(avatar);
        return true;
      })
      .catch(err => {
        this.validation_errors = err?.response?.data?.errors || {};

        handleScrollToError(err?.response?.data?.errors, isScrollToError);

        this.state = State.setError(this.state, err, "Failed to update personal info", () =>
          this.updatePersonalInfo(form)
        );
      });
  };

  toggleVisibility = props => {
    this.profile.personal_info = { ...this.profile.personal_info, ...props };
  };

  updateBasicInfo = form => {
    this.validation_errors = {};
    this.state = State.setProcessing(this.state);
    return API.updateBasicInfo({ ...this.profile.basic_info, ...form })
      .then(res => {
        this.profile.basic_info = res?.data?.basic_info;

        this.state = State.setSuccess(this.state, res, "Successfully updated main info");

        return true;
      })
      .catch(err => {
        this.validation_errors = err?.response?.data?.errors || {};

        this.state = State.setError(this.state, err, "Failed to update basic info", () => this.updateBasicInfo(form));
      });
  };

  clearCurrentWorkExperience = () => {
    this.work_experience_details = {};
  };

  getWorkExperienceOptions = () => {
    this.state = State.setLoading(this.state);
    return API.getWorkExperienceOptions().then(res => {
      this.work_experience_options = res.data;
    });
  };

  getWorkExperienceDetails = id => {
    return API.getWorkExperienceDetails(id).then(res => {
      this.work_experience_details = res?.data?.work_experience;
    });
  };

  addWorkExperience = (form, inProfileWizard = false) => {
    const filledForm = {
      start_date: checkDate(form.start) ? `${form.start.year}-${pad(form.start.month)}-01` : null,
      end_date: !form.current_work && checkDate(form.end) ? `${form.end.year}-${pad(form.end.month)}-01` : null,
      ...form
    };

    this.state = State.setProcessing(this.state);
    this.validation_errors = {};

    return API.addWorkExperience(filledForm)
      .then(res => {
        this.work_experience_details = res?.data?.work_experience;

        if (inProfileWizard) {
          this.work_experience_details = {};
          this.getGeneralInfo();
        }
        this.userStore.getUserStatus();

        this.state = State.setSuccess(this.state, res, "Successfully added work experience");

        return true;
      })
      .catch(err => {
        this.validation_errors = err?.response?.data?.errors || {};

        this.state = State.setError(this.state, err, "Failed to add work experience", () =>
          this.addWorkExperience(form)
        );
      });
  };

  updateWorkExperience = (id, form, inProfileWizard = false) => {
    const filledForm = {
      start_date: checkDate(form.start) ? `${form.start.year}-${pad(form.start.month)}-01` : null,
      end_date: !form.current_work && checkDate(form.end) ? `${form.end.year}-${pad(form.end.month)}-01` : null,
      ...form
    };
    this.state = State.setProcessing(this.state);
    this.validation_errors = {};

    return API.updateWorkExperience(id, filledForm)
      .then(res => {
        this.work_experience_details = res?.data?.work_experience;

        if (inProfileWizard) {
          this.work_experience_details = {};
          this.getGeneralInfo();
        }
        this.userStore.getUserStatus();

        this.state = State.setSuccess(this.state, res, "Successfully updated work experience");

        return true;
      })
      .catch(err => {
        this.validation_errors = err?.response?.data?.errors || {};

        this.state = State.setError(this.state, err, "Failed to update work experience", () =>
          this.updateWorkExperience(id, form)
        );
      });
  };

  updateWorkExperienceSkills = (id, form) => {
    this.state = State.setProcessing(this.state);
    this.validation_errors = {};

    if (form.skill_ids.length === 0) {
      return Promise.resolve().then(res => {
        this.work_experience_details = {};

        this.getGeneralInfo();

        this.state = State.setSuccess(this.state, res, "Successfully updated work experience skills");
        return true;
      });
    }

    return API.updateWorkExperienceSkills(id, form)
      .then(res => {
        this.work_experience_details = {};

        this.profile.work_experiences = res?.data?.work_experiences;

        this.getGeneralInfo();
        this.userStore.getUserStatus();

        this.state = State.setSuccess(this.state, res, "Successfully updated work experience skills");

        return true;
      })
      .catch(err => {
        this.validation_errors = err?.response?.data?.errors || {};
        this.work_experience_details = {};

        this.state = State.setError(this.state, err, "Failed to update work experience skills", () =>
          this.updateWorkExperienceSkills(id, form)
        );
      });
  };

  removeWorkExperience = id => {
    return API.removeWorkExperience(id)
      .then(res => {
        this.profile.work_experiences = this.profile.work_experiences.filter(item => item.id != id);
        this.userStore.getUserStatus();

        this.state = State.setSuccess(this.state, res, "Successfully removed work experience");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to remove work experience", () =>
          this.removeWorkExperience(id)
        );
      });
  };

  getEducationOptions = () => {
    this.state = State.setLoading(this.state);
    return API.getEducationOptions().then(res => {
      this.education_options = res.data;
    });
  };

  getEducationDetails = id => {
    return API.getEducationDetails(id).then(res => {
      this.education_details = res?.data?.education;
    });
  };

  addEducation = form => {
    const filledForm = {
      start_date: checkDate(form.start) ? `${form.start?.year}-${pad(form.start?.month)}-01` : null,
      end_date: !form.current_education && checkDate(form.end) ? `${form.end?.year}-${pad(form.end?.month)}-01` : null,
      ...form
    };

    this.state = State.setProcessing(this.state);
    this.validation_errors = {};

    return API.addEducation(filledForm)
      .then(res => {
        this.education_details = res?.data?.education;
        this.profile.educations = this.profile.educations.concat(res?.data?.education);
        this.userStore.getUserStatus();

        this.state = State.setSuccess(this.state, res, "Successfully added education item");

        return true;
      })
      .catch(err => {
        this.validation_errors = err?.response?.data?.errors || {};

        this.state = State.setError(this.state, err, "Failed to add education item", () => this.addEducation(form));
      });
  };

  updateEducation = (id, form) => {
    const filledForm = {
      start_date: checkDate(form.start) ? `${form.start?.year}-${pad(form.start?.month)}-01` : null,
      end_date: !form.current_education && checkDate(form.end) ? `${form.end?.year}-${pad(form.end?.month)}-01` : null,
      ...form
    };

    this.state = State.setProcessing(this.state);
    this.validation_errors = {};

    return API.updateEducation(id, filledForm)
      .then(res => {
        this.education_details = res?.data?.education;
        this.profile.educations = this.profile.educations.filter(item => item.id != id).concat(res?.data?.education);
        this.userStore.getUserStatus();

        this.state = State.setSuccess(this.state, res, "Successfully updated education item");

        return true;
      })
      .catch(err => {
        this.validation_errors = err?.response?.data?.errors || {};

        this.state = State.setError(this.state, err, "Failed to update education item", () =>
          this.updateEducation(id, form)
        );
      });
  };

  removeEducation = id => {
    return API.removeEducation(id)
      .then(res => {
        this.profile.educations = this.profile.educations.filter(item => item.id != id);
        this.userStore.getUserStatus();
        this.state = State.setSuccess(this.state, res, "Removed education");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to remove education", () => this.removeEducation(id));
      });
  };

  getSkillOptions = work_experience_id => {
    return API.getSkillOptions(work_experience_id)
      .then(res => {
        this.skills = res.data;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to get skill options", () =>
          this.getSkillOptions(work_experience_id)
        );
      });
  };

  getCategorySkills = id => {
    return API.getCategorySkills(id)
      .then(res => {
        this.skills.current_category = res?.data?.skills;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve category skills", () =>
          this.getCategorySkills(id)
        );
      });
  };

  getSkillsByTerm = term => {
    this.state = State.setProcessing(this.state);
    return API.getSkillsByTerm(term)
      .then(res => {
        this.skills.search = res?.data?.skills;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve skills", () => this.getSkillsByTerm(term));
      });
  };

  updateSkills = (form, workEdited) => {
    this.state = State.setProcessing(this.state);
    this.validation_errors = {};
    return API.updateSkills(form)
      .then(res => {
        this.profile.skills = res?.data?.skills;

        if (workEdited) this.getGeneralInfo();
        this.userStore.getUserStatus();

        this.state = State.setSuccess(this.state, res, "Successfully updated your skillset");

        return true;
      })
      .catch(err => {
        this.validation_errors = err?.response?.data?.errors || {};

        this.state = State.setError(this.state, err, "Failed to update skills", () =>
          this.updateSkills(form, workEdited)
        );
      });
  };

  removeSkill = id => {
    return API.removeSkill(id)
      .then(res => {
        this.state = State.setSuccess(this.state, res, "Skill removed");

        this.profile.skills = this.profile.skills.filter(item => item.id != id);
        this.userStore.getUserStatus();
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to remove skill", () => this.removeSkill(id));
      });
  };

  getLanguageOptions = () => {
    return API.getLanguageOptions()
      .then(res => {
        this.language_info = { ...this.language_info, ...res.data };
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to get language options", () => this.getLanguageOptions());
      });
  };

  getLanguages = () => {
    return API.getLanguages()
      .then(res => {
        this.language_info = { ...this.language_info, language_items: res?.data?.languages };
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve languages", () => this.getLanguages());
      });
  };

  updateLanguages = list => {
    this.state = State.setProcessing(this.state);
    this.validation_errors = {};
    return API.updateLanguages(list)
      .then(res => {
        this.profile.languages = res?.data?.language_items;
        this.userStore.getUserStatus();
        this.language_info.languages = [];

        this.state = State.setSuccess(this.state, res, "Successfully updated your languages");

        return true;
      })
      .catch(err => {
        this.validation_errors = err?.response?.data?.errors || {};

        this.state = State.setError(this.state, err, "Failed to update languages", () => this.updateLanguages(list));
      });
  };

  removeLanguage = id => {
    return API.removeLanguage(id)
      .then(res => {
        this.profile.languages = this.profile.languages.filter(item => item.id != id);
        this.userStore.getUserStatus();

        this.state = State.setSuccess(this.state, res, "Language was deleted successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to delete language", () => this.removeLanguage(id));
      });
  };

  getCertificateOptions = () => {
    return API.getCertificateOptions()
      .then(res => {
        this.certificate_info = { ...this.certificate_info, ...res.data };
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to get certificate options", () =>
          this.getCertificateOptions()
        );
      });
  };

  getCertificates = () => {
    return API.getCertificates()
      .then(res => {
        this.certificate_info = { ...this.certificate_info, certificate_items: res.data.certificate_items };
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve certificates", () => this.getCertificates());
      });
  };

  updateCertificates = ({ certificates = [] }) => {
    if (certificates.length > 0) {
      const formCertificates = new FormData();
      certificates.forEach(item => {
        for (const key in item) {
          if (item[key] && !item[key].url) {
            formCertificates.append(`certificates[][${key}]`, item[key]);
          }
        }
      });

      this.state = State.setProcessing(this.state);
      this.validation_errors = {};

      return API.updateCertificates(formCertificates)
        .then(res => {
          this.profile.certificates = res.data?.certificate_items;
          this.userStore.getUserStatus();

          this.state = State.setSuccess(this.state, res, "Successfully updated your certificates");

          return true;
        })
        .catch(err => {
          this.validation_errors = err?.response?.data?.errors || {};

          this.state = State.setError(this.state, err, "Failed to update certificates", () =>
            this.updateCertificates({ certificates })
          );
        });
    }
    return Promise.resolve(true);
  };

  removeCertificate = id => {
    return API.removeCertificate(id)
      .then(res => {
        this.profile.certificates = this.profile.certificates.filter(item => item.id != id);
        this.userStore.getUserStatus();

        this.state = State.setSuccess(this.state, res, "Certificate was deleted successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to delete certificate", () => this.removeCertificate(id));
      });
  };

  getAssociations = () => {
    return API.getAssociations()
      .then(res => {
        this.associations = res?.data?.association_items;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve associations", () => this.getAssociations());
      });
  };

  updateAssociations = form => {
    this.state = State.setProcessing(this.state);
    this.validation_errors = {};
    return API.updateAssociations(form)
      .then(res => {
        this.profile.association_items = res?.data?.association_items;
        this.userStore.getUserStatus();

        this.state = State.setSuccess(this.state, res, "Successfully updated your associations");

        return true;
      })
      .catch(err => {
        this.validation_errors = err?.response?.data?.errors || {};

        this.state = State.setError(this.state, err, "Failed to update associations", () =>
          this.updateAssociations(form)
        );
      });
  };

  removeAssociation = id => {
    return API.removeAssociation(id)
      .then(res => {
        this.profile.association_items = this.profile.association_items.filter(item => item.id != id);
        this.userStore.getUserStatus();

        this.state = State.setSuccess(this.state, res, "Association was deleted successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to delete association", () => this.removeAssociation(id));
      });
  };

  getHobbies = () => {
    return API.getHobbies()
      .then(res => {
        this.hobbies = res?.data?.hobbies;
      })
      .catch(err => {
        this.state = {
          loading: false,
          type: "error",
          status: err?.response?.status || 500,
          action: () => this.getHobbies(),
          message: "Failed to retrieve hobbies"
        };
        this.state = State.setError(this.state, err, "Failed to retrieve hobbies", () => this.getHobbies());
      });
  };

  updateHobbies = form => {
    this.state = State.setProcessing(this.state);
    this.validation_errors = {};
    return API.updateHobbies(form)
      .then(res => {
        this.profile.hobbies = res?.data?.hobby_items;
        this.userStore.getUserStatus();

        this.state = State.setSuccess(this.state, res, "Successfully updated your hobbies");

        return true;
      })
      .catch(err => {
        this.validation_errors = err?.response?.data?.errors || {};

        this.state = State.setError(this.state, err, "Failed to update hobbies", () => this.updateHobbies(form));
      });
  };

  removeHobby = id => {
    return API.removeHobby(id)
      .then(res => {
        this.profile.hobbies = this.profile.hobbies.filter(item => item.id != id);
        this.userStore.getUserStatus();

        this.state = State.setSuccess(this.state, res, "Hobby was deleted successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to delete hobby", () => this.removeHobby(id));
      });
  };

  getGeneratedCv = () => {
    return API.getGeneratedCv()
      .then(res => {
        this.profile.generated_cv = res?.data?.generated_cv;

        return res?.data?.generated_cv?.url;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve user cv", () => this.getGeneratedCv());
      });
  };

  // Cv upload
  uploadCv = (cv, uploadOnly = true) => {
    const upload = uploadOnly ? API.uploadCv : API.uploadCvAndPopulate;
    const form = new FormData();
    form.append("cv_data", cv);
    this.state = State.setProcessing(this.state);
    this.validation_errors = {};

    return upload(form)
      .then(res => {
        this.userStore.updateUser({ cv_uploaded: true });
        this.state = State.setSuccess(this.state, res, "CV uploaded successfully");
      })
      .then(() => {
        return Promise.all([this.getGeneralInfo(), this.getPersonalInfo(), this.userStore.getUserStatus()]);
      })
      .catch(err => {
        this.state = {
          loading: false,
          type: "error",
          status: err?.response?.status || 422,
          action: () => this.getHobbies(),
          message: "Failed to upload CV"
        };

        this.state = State.setError(this.state, err, "Failed to upload CV", () => this.uploadCv(cv));
      });
  };

  // Cv upload
  addPhoneNumber = ({
    phone,
    isShowProductFree,
    isNotActiveProduct,
    handleShowProductFree,
    handleShowProductNotAvailable,
    handleCallBack
  }) => {
    this.state = State.setProcessing(this.state);

    return API.addPhoneNumber(phone)
      .then(res => {
        this.userStore.updateUser(res?.data?.user);
        this.state = State.setSuccess(this.state, res, "Phone number added successfully");

        if (isShowProductFree) {
          handleShowProductFree();
        }

        if (isNotActiveProduct) {
          handleShowProductNotAvailable();
        }

        if (handleCallBack) {
          handleCallBack();
        } else {
          window.location.reload();
        }
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve user cv", () => this.addPhoneNumber());
      });
  };

  getReferenceDetails = (id, isApplicationAttachment) => {
    return API.getReferenceDetails(id).then(res => {
      if (!isApplicationAttachment && res?.data?.reference) {
        this.reference_details = res?.data?.reference;
      }
    });
  };

  getReferenceOptions = () => {
    this.state = State.setLoading(this.state);
    return API.getReferenceOptions().then(res => {
      this.reference_options = res.data;
    });
  };

  // isApplicationAttachment
  addReference = form => {
    this.state = State.setProcessing(this.state);
    this.validation_errors = {};

    return API.addReference(form)
      .then(res => {
        this.reference_details = res?.data?.reference;
        this.profile.references = this.profile.references.concat(res?.data?.reference);
        this.userStore.getUserStatus();

        this.state = State.setSuccess(this.state, res, "Successfully added reference item");

        return true;
      })
      .catch(err => {
        this.validation_errors = err?.response?.data?.errors || {};

        this.state = State.setError(this.state, err, "Failed to add reference item", () => this.addReference(form));
      });
  };

  updateReference = (id, form) => {
    this.state = State.setProcessing(this.state);
    this.validation_errors = {};

    return API.updateReference(id, form)
      .then(res => {
        this.reference_details = res?.data?.reference;
        this.profile.references = this.profile.references.filter(item => item.id != id).concat(res?.data?.reference);
        this.userStore.getUserStatus();

        this.state = State.setSuccess(this.state, res, "Successfully updated reference item");

        return true;
      })
      .catch(err => {
        this.validation_errors = err?.response?.data?.errors || {};

        this.state = State.setError(this.state, err, "Failed to update reference item", () =>
          this.updateReference(id, form)
        );
      });
  };

  removeReference = id => {
    return API.removeReference(id)
      .then(res => {
        this.profile.references = this.profile.references.filter(item => item.id != id);
        this.userStore.getUserStatus();
        this.state = State.setSuccess(this.state, res, "Removed reference");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to remove reference", () => this.removeReference(id));
      });
  };

  setReferenceDetails = id => {
    this.reference_details = this.profile.references.find(item => String(item.id) === String(id));
  };

  getNotificationSettings = () => {
    return API.getNotificationSettings()
      .then(res => {
        this.notificationSettings = res.data.notification_settings;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve", () => this.getNotificationSettings());
      });
  };

  updateNotificationSettings = notificationSettings => {
    return API.updateNotificationSettings(notificationSettings)
      .then(res => {
        this.notificationSettings = res.data.notification_settings;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "", () => this.updateNotificationSettings(notificationSettings));
      });
  };

  getUserDevice = async () => {
    const deviceId = await generateDeviceId();

    return API.getUserDevice(deviceId).then(res => {
      this.userDevice = {
        deviceId: res.data.user_device.device_id,
        allowPushNotifications: res.data.user_device.allow_push_notifications
      };
    });
  };

  updateUserDevice = async (deviceId, userDevice) => {
    const generatedDeviceId = await generateDeviceId();
    const deviceIdParam = deviceId || generatedDeviceId;

    return API.updateUserDevice(deviceIdParam, userDevice)
      .then(res => {
        this.userDevice = {
          deviceId: res.data.user_device.device_id,
          allowPushNotifications: res.data.user_device.allow_push_notifications
        };
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "", () => this.updateUserDevice(deviceIdParam, userDevice));
      });
  };
}

export default ProfileStore;
