import { makeAutoObservable } from "mobx";
import * as API from "utils/api";

class CareerHero {
  record;

  constructor({ career_hero } = {}) {
    makeAutoObservable(this);
    this.record = career_hero;
  }

  addCareerHeroInformation = form => {
    return API.uploadCareerHeroInformation(form).then(res => res.data);
  };

  getImageByToken = token => {
    return API.loadCareerHeroByToken(token).then(res => res.data);
  };

  loadItemByToken = token => {
    if (!this.record) {
      API.loadCareerHeroByToken(token).then(res => {
        this.record = res.data.career_hero;
        return this.record;
      });
    }
  };
}

export default CareerHero;
