import styled from "styled-components";

export const ConversationSide = styled.div`
  ${() => `
        border-right: 1px solid #DEDEDE;
        width: 400px;
        display: flex;
        flex-direction: column;
        position: relative;
        @media screen and (max-width: 767px) {
            width: 100%;
            padding-bottom: 60px;
        }

        .filter-section {
            position: relative;
            display: flex;
            border-bottom: 1px solid #DEDEDE;
            padding: 0 15px 15px;

            > label {
                font-size: 14px;
                margin-left: 4px;
            }
        }

        .searchbar-section {
            position: relative;
            height: 80px;
            padding: 15px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #DEDEDE;

            input {
                border: 1px solid #BDBDBD;
                background: #fff;
                box-sizing: border-box;
                border-radius: 5px;
                width: 100%;
                outline: none;
                text-indent: 8px;
                transition: all 0.1s ease;

                &:focus + svg path {
                    fill: #BDBDBD;
                }
            }

            svg {
                width: 36px;
                position: absolute;
                right: 24px;
                top: calc(50% - 18px);

                > path {
                    transition: all 0.1s ease;
                    fill: #DEDEDE;
                }
            }
        }

        .conversation-list {
            padding: 15px;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            flex: 1 0 0;

            > .conversation-type {
                font-weight: 600;
                font-size: 12px;
                color: #656565;
                text-transform: uppercase;
                margin: 12px 0;

                @media screen and (max-width: 767px) {
                    margin-left: 8px;
                }

                &:first-child {
                    margin-top: 0;
                }
            };

            @media screen and (max-width: 767px) {
                padding: 0;
            }

            > * {
                &:not(last-child) {
                    margin-bottom: 14px;
                }
            }
        }
    `}
`;
