import React, { useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { ScreenClassContext } from "react-grid-system";

import { useOnclickOutside } from "utils/hooks";
import phoneImage from "images/b2c/phone_email.png";
import { FindJobContent, FindJobImage, FindJobInfo, Overlay } from "./styled";
import { Button, Image, Text, Title } from "../../Core";

const FindJob = ({ handleClick }) => {
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm"].includes(screen);
  const ref = useRef();

  useOnclickOutside(ref, handleClick, true);

  return (
    <>
      <Overlay />
      <FindJobContent ref={ref}>
        <FindJobImage>
          <Image src={phoneImage} height={isSm ? "120px" : "240px"} alt="Frame" margin={isSm ? "0" : "0 0 -4px 0"} />
        </FindJobImage>

        <FindJobInfo>
          <Title
            style={{ fontWeight: "600", fontSize: "24px", lineHeight: "130%", color: "#292929", marginBottom: "12px" }}
          >
            Find your next job on Fuzu
          </Title>
          <Text
            style={{
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "140%",
              color: "#292929",
              marginBottom: isSm ? "10px" : "24px"
            }}
          >
            Thousands of new job opportunities every month. Personalised job recommendations, job alerts and career
            support.
          </Text>
          <Button arrow as={Link} to="/onboarding" width="100%" margin="0 0 8px" height="40px">
            Get Started
          </Button>
          <Button as={Link} to="/login" width="100%" margin="0 0 auto" height="40px" style={{ background: "#f3f3f3" }}>
            I already have a Fuzu account
          </Button>
        </FindJobInfo>
      </FindJobContent>
    </>
  );
};

export default FindJob;
