// Import needed Rails routes for React components

export const navigateTo = path => {
  window.location.href = path;
};

export const getEditCampaignPath = campaign_id => {
  const path = "/campaigns/12345/settings";
  return path.replace("12345", campaign_id);
};

export const getDuplicateCampaignPath = campaign_id => {
  const path = "/campaigns/12345/confirm_new_from_template/12345";
  return path.replace(/12345/g, campaign_id);
};

export const getMessageCandidatesPath = (campaign_id, candidate_ids) => {
  const path = "/mailbox/messages/new/6789?ids=12345";
  return path.replace("6789", campaign_id).replace("12345", candidate_ids.join(","));
};

export const getConversationPath = conversation_id => {
  const path = "/mailbox/conversations/12345";
  return path.replace("12345", conversation_id);
};

export const getCampaignDetailsTabContentsPath = campaign_id => {
  const path = "/campaigns/12345/settings?rendered_in_tab=true";
  return path.replace(/12345/g, campaign_id);
};

export const getCampaignUpdatesTabContentsPath = campaign_id => {
  const path = "/campaigns/12345/audit_trail?rendered_in_tab=true";
  return path.replace(/12345/g, campaign_id);
};
