export const buildOptionsByIdAndTitle = array => array.map(({ id, title }) => ({ value: id, label: title }));

export const buildOptionsByIdAndName = array => array.map(({ id, name }) => ({ value: id, label: name }));

export const buildOptionsByIdCountryCodeAndName = array =>
  array.map(({ id, name, country_code }) => ({ value: id, label: name, countryCode: country_code }));

export const buildOptionsByIdAndText = array => array.map(({ id, text }) => ({ value: id, label: text }));

export const buildOptionsBySlugAndName = array => array.map(({ slug, name }) => ({ value: slug, label: name }));

export const buildOptionsByIdAndLabel = array => array.map(({ id, label }) => ({ value: id, label }));

export const sortByAlphabet = array => array.sort((a, b) => (a.label > b.label ? 1 : -1));

export const getGuestLinkUrl = path => {
  return `${process.env.PROTOCOL}://${process.env.DOMAIN}/${path === "/" ? "" : path}`;
};

export const formatDate = date => {
  return date.toLocaleString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric"
  });
};

export const formatSecondsIn360Days = (time, isNotFormat) => {
  const dateFromThreshold = new Date(new Date().getTime() + time * 1000);

  return isNotFormat ? dateFromThreshold : formatDate(dateFromThreshold);
};

export const getCurrentUserAndEmployer = (currentEmployer, currentUser, isNewTooltipText) => {
  return [
    {
      id: currentEmployer?.id,
      name: currentEmployer?.name,
      hint: "Show company name only",
      tooltip: isNewTooltipText
        ? "This message will be sent from your company. Everyone in your team can see this conversation"
        : "Candidate won’t see your name. This conversation will be visible for everyone in your team."
    },
    {
      id: currentUser.id,
      name: currentUser.name,
      hint: "Show my name + company name",
      tooltip: isNewTooltipText
        ? "This message will be sent under your name. Everyone in your team can see this conversation"
        : "Candidate will see your name. This conversation will be visible for everyone in your team."
    }
  ];
};

export const getUrlPath = url => new URL(url).pathname;
