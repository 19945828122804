import axios from "axios";

// Transactions

const trans_path = "/api/v1/browse/fuzu_points/transactions";

const invite_path = "/api/v1/browse/invite_friends";

export const getTransactions = (type = "debit", sort_by = "newest") =>
  axios.get(`${trans_path}/${type}?sort_by=${sort_by}`);

export const getFriendInvites = () => axios.get(invite_path);

export const sendInvite = email => axios.post(invite_path, { email });
export const deleteRemoveMyData = token => axios.delete(`/api/v1/browse/prospects/${token}`);
