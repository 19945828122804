import React from "react";

import Text from "../Text";
import Button from "../Button";

const PageNav = ({ isLink, href, inline, ...props }) => {
  return isLink ? (
    <Button.Link
      to={href}
      {...props}
      theme="transparent"
      style={{
        padding: "0"
      }}
    />
  ) : (
    <Text as="span" inline={inline} {...props} />
  );
};

export default PageNav;
