export const setError = (state, err, message, action) => {
  let newState = state;
  const id = new Date().getTime() + Math.random().toString(36).substr(2, 5);
  if (err.response) {
    newState = {
      ...state,
      loading: !(err.response?.status > 400),
      processing: !(err.response?.status > 400),
      type: `${id}_error`,
      error: true,
      status: err.response?.status || 500,
      message: err.response?.data?.error || err.response?.statusText || message,
      action
    };
  } else if (err.request) {
    newState = {
      ...state,
      loading: false,
      processing: false,
      type: `${id}_error`,
      error: true,
      status: err.request?.status || 0,
      message: "",
      action
    };
  }

  return newState;
};

export const setSuccess = (state, res, message, reject = false, isSuccess, successId) => {
  const id = successId || new Date().getTime() + Math.random().toString(36).substr(2, 5);

  return {
    ...state,
    loading: false,
    type: isSuccess ? "success" : `${id}_success`,
    error: false,
    processing: false,
    status: res?.status || 200,
    message: res?.data?.success || message,
    action: null,
    reject
  };
};

export const setNeutral = (state, res) => {
  const newState = {
    ...state,
    loading: false,
    processing: false,
    error: false,
    type: "",
    status: res?.status || 200,
    message: "",
    action: null
  };

  return newState;
};

export const setLoading = state => ({ ...state, status: 200, type: "", message: "", loading: true });

export const setProcessing = state => ({ ...state, status: 200, type: "", message: "", processing: true });
