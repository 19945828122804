import React, { createContext, useContext } from "react";

const InitContext = createContext();

export class ApplicationContext extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props,
      subscription: {
        ...props.subscription,
        descriptions: {
          job_matching_without_ranking: "Job matching",
          job_matching_with_ranking: "Job matching with ranking",
          job_pick_email: "Job pick email",
          candidate_list_access: "Candidate list access",
          edit_job: "Job editing",
          filtering_logic: "Filtering logic",
          campaign_stages: "Campaign stages",
          sorting: "Sorting",
          tagging: "Tagging",
          commenting: "Commenting",
          messaging: "Messaging",
          candidate_ranking: "Candidate ranking",
          psychometric_test: "Psychometric test",
          aptitude_test: "Aptitude test",
          headhunted_talent: "Headhunted talent",
          api_integration: "API integration",
          headhunting: "Headhunting",
          collaboration: "Collaboration featureset",
          custom_aptitude_test: "Custom aptitude test",
          custom_recruitment_steps: "Custom recruitment steps",
          branded_api: "Branded API",
          custom_culture_fit: "Culture fit",
          headhunting_talent_pipeline: "Headhunting talent pipeline",
          subsidiary_logic: "Subsidiary logic",
          open_applications: "Open application"
        }
      }
    };

    this.updateSubscription = this.updateSubscription.bind(this);
  }

  updateSubscription = data => this.setState({ ...this.state, subscription: { ...this.state.subscription, ...data } });

  render() {
    return (
      <InitContext.Provider value={{ ...this.state, updateSubscription: this.updateSubscription }}>
        {this.props.children}
      </InitContext.Provider>
    );
  }
}

export const isBaronaBrand = () => {
  const { global_brand } = useContext(InitContext);

  return global_brand?.name?.includes("Barona");
};

export const getCompanyName = () => {
  const { global_brand } = useContext(InitContext);

  return global_brand?.name?.includes("Barona") ? "Barona" : "Fuzu";
};

export default InitContext;
