import React, { useContext } from "react";

import stress from "images/b2c/profilePage/stress.svg";
import Layout from "b2c/components/Layout";
import { iconMap } from "b2c/constants/wizard";
import { Card, Title, Text } from "b2c/components/Core";
import { ScoreRow, ScoreBarWrapper, ScoreBar } from "./styled";
import ThemeContext from "../../../../contexts/theme";

const PersonalityScore = ({
  title = "Persistence",
  userScore = 60,
  averageScore = 30,
  topText = "",
  bottomText = "",
  description = "",
  headHuntingName
}) => {
  const theme = useContext(ThemeContext);

  const Icon = iconMap[title] || stress;

  return (
    <Layout.Section margin="0 0 20px">
      <Card bordered>
        <Card.Header>
          <Icon width={20} />
          <Title level={6} fontWeight={500} margin="0 0 0 8px">
            {title}
          </Title>
        </Card.Header>

        <Card.Content>
          <ScoreRow>
            <div className="score-titles">
              <Text fontWeight={userScore > 0 ? 400 : 500} color={userScore > 0 ? "black500" : "secondary300"}>
                {topText}
              </Text>
              <Text
                textAlign="right"
                fontWeight={userScore < 0 ? 400 : 500}
                color={userScore < 0 ? "black500" : "secondary300"}
              >
                {bottomText}
              </Text>
            </div>
            <ScoreBarWrapper>
              <ScoreBar
                theme={theme}
                userScore={Math.abs(userScore)}
                averageScore={Math.abs(averageScore)}
                userFlip={userScore < 0}
                averageFlip={averageScore < 0}
                headHuntingName={headHuntingName}
              >
                <div className="filled-bar" />
                <div className="score-indicator" name={headHuntingName || "You"} />
                <div className="average-indicator" />
              </ScoreBar>
              <div className="side-description">
                <Text fontSize={11} fontWeight={500}>
                  {userScore < 0 ? "Very Strong" : ""}
                </Text>
                <Text fontSize={11} textAlign="center" fontWeight={500}>
                  Neutral
                </Text>
                <Text fontSize={11} textAlign="right" fontWeight={500}>
                  {userScore > 0 ? "Very Strong" : ""}
                </Text>
              </div>
            </ScoreBarWrapper>
          </ScoreRow>

          <Text color="black300">{description}</Text>
        </Card.Content>
      </Card>
    </Layout.Section>
  );
};

export default PersonalityScore;
