import React, { useMemo, useState, useEffect, useRef } from "react";

import { MESSAGE_TAB_NAMES } from "constants/messages";
import { TABS } from "constants/tabs";
import { useTranslation } from "b2c/hooks/useTranslation";
import Navigation from "b2c/components/TabNavigation";
import { ConversationSide } from "./styled";
import Conversation from "../Conversation";
import Channel from "../Channel";
import { capitalize } from "../../../../utils/helpers";

const ConversationList = ({
  user: { id } = {},
  status: { unread_channels_count, unread_conversations_count },
  channels,
  conversations,
  setConversation,
  getConversations,
  retirevedConversationsCount = 12,
  selectedId,
  tabs,
  activeTab,
  setActiveTab,
  filterMessages,
  toggleMessagesFilter,
  adminAccess,
  isBarona
}) => {
  const { t } = useTranslation();

  const getTabName = tab => {
    switch (tab.id) {
      default:
        return capitalize(tab.name);
      case "channels":
      case "support":
        return `${isBarona ? "Barona" : "Fuzu"} ${t("messages.tab_support")}`;
      case "conversations":
        return adminAccess ? t("messages.tab_candidate_message") : t("messages.tab_employer_message");
    }
  };

  const [list, setList] = useState(conversations || [...new Array(8).fill({ loading: true })]);
  const [searchTerm] = useState("");
  const [lastItemIndex, setLastItemIndex] = useState(11);
  const [scrolled, setScrolled] = useState(false);

  const listRef = useRef();
  const lastItemRef = useRef();

  useEffect(() => {
    const element = listRef?.current;

    const listener = () => {
      const scrollPosition = element?.scrollTop;
      const triggerHeight = Math.max(lastItemRef.current?.offsetTop - listRef.current?.clientHeight - 78, 260);
      setScrolled(scrollPosition > triggerHeight);
    };

    element.addEventListener("scroll", listener, { passive: true });

    return () => {
      element.removeEventListener("scroll", listener, { passive: true });
    };
  }, [listRef.current, lastItemRef.current]);

  useEffect(() => {
    setList(conversations || [...new Array(8).fill({ loading: true })]);
  }, [conversations]);

  useEffect(() => {
    if (retirevedConversationsCount) setLastItemIndex(retirevedConversationsCount - 1);
  }, [retirevedConversationsCount]);

  useEffect(() => {
    const lowered = searchTerm.toLowerCase();
    if (conversations)
      setList(
        conversations.filter(({ participants }) =>
          participants.some(({ first_name, last_name }) => `${first_name} ${last_name}`.toLowerCase().includes(lowered))
        )
      );
  }, [searchTerm, conversations]);

  useEffect(() => {
    if (scrolled && list.length === retirevedConversationsCount) {
      setList(prev => prev.concat({ loading: true }));
      getConversations();
    }
  }, [scrolled]);

  const sortedChannels = useMemo(() => {
    return channels?.sort((a, b) => a.conversation?.unread_count > b.conversation?.unread_count);
  }, [channels]);

  const isListEmpty = (() => {
    switch (activeTab) {
      default:
      case MESSAGE_TAB_NAMES.conversations:
        return list.length === 0;
      case MESSAGE_TAB_NAMES.support:
        return sortedChannels?.length === 0;
    }
  })();

  return (
    <ConversationSide isEmpty={isListEmpty} tab={activeTab}>
      {/* <div className="searchbar-section">
                <input
                    type="text"
                    className="searchbar"
                    aria-label="Search messages"
                    value={searchTerm}
                    disabled
                    onChange={(e) => setSearchTerm(e.target?.value)}
                />
                <Search aria-label="Search" />
            </div> */}
      <Navigation theme="grey0">
        {tabs.map(tab => (
          <Navigation.Tab
            key={tab}
            as="div"
            selected={activeTab === tab.id}
            theme="grey0"
            onClick={() => setActiveTab(tab.id)}
            className="conversation-type"
            count={tab.id === TABS.CONVERSATIONS ? unread_conversations_count : unread_channels_count}
          >
            {getTabName(tab)}
          </Navigation.Tab>
        ))}
      </Navigation>
      <div className="conversation-list" role="tablist" ref={listRef}>
        <Navigation.Content
          loading={!sortedChannels}
          LoadingState={() =>
            [...new Array(8).fill({ loading: true })].map((conv, index) => (
              <Conversation key={`loading_${index}`} index={index + 1} {...conv} />
            ))
          }
          currentTab={activeTab}
          tab="support"
        >
          {sortedChannels?.map((channel, index) => (
            <Channel
              key={`channel_${channel.id}_${index}`}
              userId={id}
              selected={selectedId && (selectedId == channel?.conversation?.id || selectedId == channel?.id)}
              onClick={() => {
                setConversation(
                  channel?.conversation || {
                    channel_id: channel.id,
                    channel_name: channel.name,
                    channel_description: channel.description
                  }
                );
              }}
              {...channel}
            />
          ))}
        </Navigation.Content>
        {adminAccess && (
          <Navigation.Content currentTab={activeTab} tab="channels">
            <div className="filter-section">
              <input
                type="checkbox"
                id="participation"
                name="Filter by participation"
                checked={filterMessages}
                onChange={toggleMessagesFilter}
              />
              <label htmlFor="participation">{t("messages.filter_label")}</label>
            </div>
          </Navigation.Content>
        )}
        <Navigation.Content currentTab={activeTab} tab={["conversations", "channels"]}>
          {list.map((conv, index) => (
            <Conversation
              key={`conv_${conv.id || index}`}
              ref={String(index) === String(lastItemIndex) ? lastItemRef : null}
              userId={id}
              index={index + 1}
              searchTerm={searchTerm}
              selected={selectedId && String(selectedId) === String(conv.id)}
              onClick={() => setConversation(conv)}
              {...conv}
            />
          ))}
        </Navigation.Content>
      </div>
    </ConversationSide>
  );
};

export default ConversationList;
