import { autorun, makeAutoObservable, reaction } from "mobx";

import API from "b2c/utils/api";
import * as State from "utils/storeHelpers";

const normalizeNotificationsData = data => {
  const entities = {};
  const list = data.map(notification => {
    entities[notification.id] = notification;

    return notification.id;
  });

  list.sort((a, b) => entities[a].read - entities[b].read);

  return {
    list,
    entities
  };
};

class NotificationsStore {
  counter = 0;

  notifications = {
    list: [],
    entities: {}
  };

  constructor(userStore) {
    makeAutoObservable(this);

    this.userStore = userStore;

    autorun(() => {
      if (!userStore.user?.id) this.profile = {};
    });

    reaction(
      () => this.counter,
      count => {
        this.updateCounterBadge(count);
      }
    );
  }

  state = {
    processing: false,
    loading: false,
    type: "",
    message: ""
  };

  incrementNotificationsCount = () => {
    this.counter += 1;
  };

  decrementNotificationsCount = () => {
    this.counter -= 1;

    this.updateCounterBadge(this.counter);
  };

  setNotificationsCount = count => {
    this.counter = count;
  };

  updateCounterBadge = count => {
    if (!("setAppBadge" in navigator && "clearAppBadge" in navigator)) {
      return;
    }

    if (count) {
      return navigator.setAppBadge(count).catch(console.error);
    }

    navigator.clearAppBadge(count).catch(console.error);
  };

  getNotificationsList = () => {
    this.state = State.setLoading(this.state);
    return API.getNotificationsList()
      .then(res => {
        const notifications = res?.data?.notifications || [];

        this.state = State.setNeutral(this.state);
        this.counter = notifications.filter(item => !item.read).length;
        this.notifications = normalizeNotificationsData(notifications);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve notifications list", () =>
          this.getNotificationsList()
        );
      });
  };

  makeNotificationRead = notificationId => {
    API.makeNotificationRead(notificationId);

    if (this.notifications.entities[notificationId]) {
      this.notifications.entities[notificationId].read = true;
    }

    this.decrementNotificationsCount();
  };
}

export default NotificationsStore;
