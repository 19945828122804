import styled from "styled-components";

export const NotificationsContent = styled.div`
  background-color: #fff;
  padding: 0 13px 50px 13px;
  box-shadow: 0px 5px 15px 3px rgb(33 33 33 / 5%);
  border-radius: 5px;
  height: 100%;
`;

export const NotificationsItems = styled.div`
  max-width: 500px;
`;

export const Text = styled.div`
  display: block;
  font-weight: 600;
  font-size: 10px;
  line-height: 100%;
  margin: 8px -13px 0;
  color: #7b7b7b;
  padding: 15px 16px;
  border-bottom: 1px solid #f5f5f5;
  text-transform: uppercase;
`;
