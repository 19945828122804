import React, { useRef, useEffect, useMemo, useReducer, useState } from "react";
import { Row, Col } from "react-grid-system";

import { FileBlank, Send } from "@styled-icons/boxicons-solid";
import { Paperclip, Trash } from "@styled-icons/boxicons-regular";
import { useModal } from "../../../../utils/hooks";
import { Icon, Text, Button, Spinner } from "../../../../b2c/components/Core";
import { MessageSide, FileWrapper } from "../../styled";

const fileReducer = (state, { type, payload }) => {
  switch (type) {
    default:
    case "loading":
      return state.map(item => ({ ...item, state: item.name === payload.name ? "loading" : item.state }));
    case "success":
      return state.map(item => ({
        ...item,
        ...(item.name === payload.name ? { state: "success", id: payload.id } : { state: item.state })
      }));
    case "fail":
      return state.map(item => ({ ...item, state: item.name === payload.name ? "fail" : item.state }));
    case "remove":
      return state.filter(item => item.name !== payload.name);
  }
};

const initParser = files =>
  [...files].map(({ name, size }) => ({
    name,
    size: `${(size / 1024).toFixed(2)} kB`,
    loading: true
  }));

const Files = ({ uploadAttachment, files = [], submit, defaultMessageValue, close, onClose }) => {
  const textArea = useRef();
  const submitButton = useRef();
  const [messageBody, setBody] = useState("");
  const [parsedFiles, setFiles] = useReducer(fileReducer, files, initParser);
  const attachmentIds = parsedFiles.filter(file => !!file.id).map(file => file.id);

  const closeModal = () => {
    close();

    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      uploadAttachment(files.item(i))
        .then(id => {
          setFiles({ type: "success", payload: { id, name: file.name } });
        })
        .catch(() => {
          setFiles({ type: "fail", payload: { name: file.name } });
        });
    }
  }, [files]);

  useEffect(() => {
    if (parsedFiles.length === 0) closeModal();
  }, [parsedFiles]);

  useEffect(() => {
    if (defaultMessageValue) {
      setBody(defaultMessageValue);
    }
  }, [defaultMessageValue]);

  const areaHeight = useMemo(() => {
    const element = textArea.current;
    if (element) {
      element.scrollTop = element.scrollHeight;
      if (messageBody) {
        const offset = element.offsetHeight - element.clientHeight;
        return `${Math.min(element.scrollHeight + offset, 250)}px`;
      }
    }
  }, [messageBody, textArea.current]);

  const allLoaded = parsedFiles?.every(item => item.state === "success");

  const handleKeyDown = e => {
    if (e.keyCode === 13 && !e.shiftKey && allLoaded && messageBody.length > 0) {
      e.preventDefault();
      handleSubmission();
    }
  };

  const handleSubmission = () => {
    submit(messageBody || " ", attachmentIds);
    closeModal();
  };

  const FileActions = ({ state, name }) => {
    switch (state) {
      default:
      case "loading":
        return <Spinner size="28px" />;
      case "fail":
      case "success":
        return (
          <Button.Iconed
            noBackground
            onClick={() => {
              setFiles({ type: "remove", payload: { name } });
            }}
          >
            <Icon as={Trash} fill="black300" />
          </Button.Iconed>
        );
    }
  };

  return (
    <MessageSide areaHeight={areaHeight}>
      {parsedFiles.map(file => (
        <Files.Single
          key={file.name}
          fill={file.state === "fail" ? "accent300" : "secondary200"}
          {...file}
          Actions={() => <FileActions {...file} />}
        />
      ))}

      <div className="messages-textarea modal-textarea">
        <textarea
          ref={textArea}
          onChange={({ target: { value } }) => setBody(value)}
          onKeyDown={handleKeyDown}
          value={messageBody}
          placeholder="Type to add your message"
        />
        <button type="button" ref={submitButton} disabled={!allLoaded} onClick={handleSubmission}>
          <Send />
        </button>
      </div>
    </MessageSide>
  );
};

Files.Single = ({ name, size, fill = "secondary200", url, Actions }) => {
  return (
    <FileWrapper>
      <Row nowrap gutterWidth={8}>
        <Col width="content" style={{ alignSelf: "center" }}>
          <Icon as={FileBlank} fill={fill} />
        </Col>
        <Col style={{ textAlign: "left" }}>
          <Text maxLength={24}>{name}</Text>
          <Text color="black200" inline>
            {size}
          </Text>
          {url && (
            <>
              {" - "}
              <Text as="a" inline download={name} fontWeight={500} color="secondary200" href={url} target="_blank">
                Download
              </Text>
            </>
          )}
        </Col>
        {Actions && (
          <Col width="content" style={{ display: "flex" }}>
            <Actions />
          </Col>
        )}
      </Row>
    </FileWrapper>
  );
};

Files.Button = props => {
  const inputRef = useRef();

  const handleUpload = e => {
    openFiles({ files: e.target.files });
  };

  const deleteAttachedFile = () => {
    inputRef.current.value = "";
  };

  const openFiles = useModal(<Files {...props} />, "File upload", deleteAttachedFile);

  return (
    <>
      <button
        type="button"
        className="files"
        onClick={() => {
          inputRef.current.click();
        }}
      >
        <Paperclip />
      </button>
      <input
        type="file"
        accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
        multiple
        ref={inputRef}
        onChange={handleUpload}
      />
    </>
  );
};

export default Files;
