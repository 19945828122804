import React, { useContext, useState, useRef } from "react";

import styled from "styled-components";
import { onEnterPress } from "utils/helpers";
import ThemeContext from "../../../contexts/theme";

const RadioButton = ({
  id = 0,
  value,
  tabIndex = 0,
  name = "name",
  margin = "10px 0",
  baseColor = "black200",
  checkedColor = "primary",
  checkedBgColor = "primary100",
  borderColor = "grey300",
  Label,
  checked,
  boxedView = false,
  borderedView = false,
  hideIndicator,
  labelProps = {},
  ...rest
}) => {
  const control = useRef();
  const [isFocused, setFocus] = useState(false);

  const onFocusHandler = () => {
    setFocus(true);
  };

  const onBlurHandler = () => {
    setFocus(false);
  };

  const Theme = useContext(ThemeContext);

  const handleEnter = e => {
    e.stopPropagation();
    onEnterPress(e, 0, () => control.current.click());
  };

  return (
    <RadioWrapper
      role="radio"
      aria-checked={checked ? "true" : "false"}
      aria-describedby={`radio-label-${id}`}
      tabIndex={tabIndex}
      margin={margin}
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}
      onKeyDown={handleEnter}
    >
      <RadioControl id={id} ref={control} type="radio" name={name} value={value} checked={checked} {...rest} />
      <RadioLabel
        id={`radio-label-${id}`}
        htmlFor={id}
        isChecked={checked}
        isFocused={isFocused}
        boxedView={boxedView}
        borderedView={borderedView}
        baseColor={Theme[baseColor]}
        checkedColor={Theme[checkedColor]}
        checkedBgColor={Theme[checkedBgColor]}
        borderColor={Theme[borderColor]}
        hideIndicator={hideIndicator}
        {...labelProps}
      >
        <RadioLabelContent>{Label}</RadioLabelContent>
      </RadioLabel>
    </RadioWrapper>
  );
};

const RadioWrapper = styled.div`
  margin: ${({ margin }) => margin};
  position: relative;
  outline: none;
`;
const RadioControl = styled.input`
  display: none;
  outline: none;
`;
const RadioLabel = styled.label`
  ${({
    boxedView,
    borderedView,
    isChecked,
    isFocused,
    baseColor,
    checkedColor,
    checkedBgColor,
    borderColor,
    hideIndicator
  }) => `
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  outline: none;
  transition: 0.2s ease;

  ${
    boxedView
      ? `
    background: #FFFFFF;
    box-shadow: ${isChecked || isFocused ? "0px 0 0 0 rgba(0, 0, 0, 0.05)" : "0px 5px 15px 3px rgba(0, 0, 0, 0.05)"};
      height: 90px;
      border-radius: 5px;
      padding: 0 20px;
      border: 1px solid ${isChecked || isFocused ? checkedColor : "transparent"};
      &:hover {
        border-color: ${checkedColor};
        box-shadow: 0px 0 0 0 rgba(0, 0, 0, 0.05);
      }
    `
      : ""
  }

  ${
    borderedView
      ? `
            background: ${isChecked ? checkedBgColor : "#ffffff"};
            min-height: 60px;
            border-radius: 5px;
            padding: 10px 20px;
            border: 1px solid ${isChecked || isFocused ? checkedColor : borderColor};
            &:hover {
              border-color: ${checkedColor};
            }
          `
      : ""
  }
  
    &:before {
      ${hideIndicator ? "" : "content: '';"}
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
      border: 1px solid ${isChecked || (!boxedView && isFocused) ? checkedColor : baseColor};
      border-radius: 100px;
      margin-right: 12px;
      box-shadow: inset 0 0 0 ${isChecked ? "6px" : "0px"}
        ${checkedColor};
      animation: ${isChecked ? "scaleInSmall 0.4s ease forwards" : "none"};
      will-change: transform, box-shadow;
      transition: 0.2s ease;
    }
`}
`;

const RadioLabelContent = styled.div``;

export default RadioButton;
