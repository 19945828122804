import React, { useContext, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "b2c/hooks/useTranslation";
import { ScreenClassContext } from "react-grid-system";
import parse from "html-react-parser";

import ThemeContext from "../../contexts/theme";
import { getCompanyName } from "../../contexts/branded";
import { Button, Title, Text, Card } from "../Core";
import useStore from "../../contexts/store";
import { StyledDiv } from "./styled";

const Description = () => {
  const { describe = {}, highlightedRegions, resolveStep } = useStore("HomeLayout");
  const theme = useContext(ThemeContext);
  const screen = useContext(ScreenClassContext);
  const next = useRef();
  const described = [...highlightedRegions]
    .reverse()
    .find(item => item.id == describe.id || (Array.isArray(describe.id) && describe.id.some(id => item.id == id)));
  const isSm = /sm|xs/.test(screen);
  const alternative = describe.alt == described?.id && !isSm;
  const { t } = useTranslation();

  useEffect(() => {
    next.current?.focus();
  }, [next.current, highlightedRegions]);

  const positionBottom = isSm && described?.region?.y > window.innerHeight / 2;
  const positionLeft = described?.region?.x > window.innerWidth / 2 && !alternative && !isSm;
  const regionX = alternative
    ? described?.region?.x - 25
    : Math.max(
        !positionLeft ? described?.region?.x : described?.region?.x + described?.region?.width,
        0.1 * window.innerWidth
      );
  const regionY = alternative
    ? Math.min(described?.region?.y, window.innerHeight / 3)
    : described?.region?.y + described?.region?.height + 15;
  const smRegionY = positionBottom
    ? window.innerHeight - described?.region?.height - 25
    : described?.region?.y + described?.region?.height + 15;
  const maxWidth = window.innerHeight > 850 ? "356px" : "560px";
  const arrowX = described?.region?.width / 2;
  const arrowY = alternative ? described?.region?.height / 2 : -15;
  const smArrowX = described?.region?.x + described?.region?.width / 2 - 5;

  return highlightedRegions.length > 0 ? (
    <StyledDiv
      regionX={regionX}
      regionY={isSm ? smRegionY : regionY}
      arrowX={isSm ? smArrowX : arrowX}
      arrowY={arrowY}
      arrowColor={theme.primary}
      positionLeft={positionLeft}
      positionBottom={positionBottom}
      maxWidth={maxWidth}
      alternative={alternative}
    >
      <Card theme="primary" shadow>
        <Card.Header>
          <Title level={5} fontWeight={600}>
            {parse(t(describe.title, { name: getCompanyName() }))}
          </Title>
        </Card.Header>
        <Card.Content>
          {describe.paragraphs?.map((item, index) => (
            <Text key={`describe-${index}`} fontSize={16} lineHeight="24px" margin={index > 0 ? "12px 0 0" : 0}>
              {parse(t(item, { name: getCompanyName() }))}
            </Text>
          ))}
        </Card.Content>
        <Card.Footer separator>
          <Text color="black300" fontWeight={500}>
            {t("tour.count_step", { step: describe.step })}
          </Text>
          <Button ref={next} width={100} theme="white" onClick={resolveStep}>
            {describe.step < 4 ? t("global.next") : t("global.done")}
          </Button>
        </Card.Footer>
      </Card>
    </StyledDiv>
  ) : null;
};

export default observer(Description);
