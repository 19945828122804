class JobSearchUrlBuilder {
  pattern = "/<country>/job/<category>/<industry>/<seniority>/<town>";

  filters;

  appliedFilters;

  disallowKeys;

  constructor(filters, countries, params, towns = []) {
    this.filters = filters;
    this.params = params;
    this.appliedFilters = params.appliedFilters;
    this.countries = countries;
    this.towns = towns;
    this.industry = "";
    this.category = "";
    this.country = "";
    this.town = "";
    this.seniority = "";
    // country_id remove after release
    this.disallowKeys = ["category_ids", "town_id", "job_id", "seniority_ids"];
  }

  isDifferent(first, second) {
    return JSON.stringify(this._propsToCompare(first)) !== JSON.stringify(this._propsToCompare(second));
  }

  build(pathname = "/job", isHidePage, countrySelected, languageAndLanguage) {
    let allQueries;
    const { page, ...queries } = this._buildQueryParams();

    if (isHidePage) {
      allQueries = queries;
    } else {
      allQueries = this._buildQueryParams();
    }

    if (this._isDisallowSEFUrl()) {
      return { path: pathname, query: allQueries };
    }

    this._processCategory();
    this._processIndustry();
    if (countrySelected) {
      this._processCountry();
    }
    this._processSeniority();
    this._processTown(countrySelected);

    return { path: languageAndLanguage ? `${languageAndLanguage}${this._build()}` : this._build(), query: allQueries };
  }

  buildForCategory = (category, country, languageAndLanguage) => {
    this.category = this._prefix(category.slug);

    if (this.industry) {
      this.industry = "";
    }

    const result = this.build(`${languageAndLanguage}/job`, true, country, languageAndLanguage);
    this.category = "";
    return result;
  };

  buildForSeniority = (seniority, country, languageAndLanguage) => {
    this.seniority = this._prefix(seniority.slug);
    const result = this.build("/job", true, country, languageAndLanguage);
    this.seniority = "";

    return result;
  };

  buildForIndustry = (industry, country, languageAndLanguage) => {
    if (this.appliedFilters.category_ids?.length) {
      return;
    }

    this.industry = this._prefix(industry.slug);
    const result = this.build("/job", true, country, languageAndLanguage);
    this.industry = "";
    return result;
  };

  buildForCountry = country => {
    if (country.featured) {
      this.country = this._prefix(country.slug);
    }

    const result = this.build("/job", true);
    this.country = "";

    return result;
  };

  buildForTown = (town, isCheckDefaultCountryId, currentCountry, languageAndLanguage) => {
    const isDisableCountryPath = isCheckDefaultCountryId && currentCountry;

    if (town.fuzu_country_featured && isDisableCountryPath && !languageAndLanguage) {
      this.country = this._prefix(town.fuzu_country_slug);
    }

    if (languageAndLanguage) {
      this.country = languageAndLanguage;
    }

    this.town = this._prefix(town.slug);
    const result = this.build("/job", true);
    this.town = "";
    this.country = "";

    const updatedResult = !isDisableCountryPath
      ? { ...result, path: result.path.replace(/^\/(kenya|nigeria|uganda)/, "") }
      : result;

    return updatedResult;
  };

  paginationParams() {
    return { query: this._buildQueryParams(["page"]) };
  }

  _propsToCompare(obj) {
    const { job_id, page, per_page, ...rest } = obj.query || {};
    return { path: obj.path, query: rest };
  }

  _buildQueryParams(disallowKeys = []) {
    if (!Object.keys(this.appliedFilters).includes("category_ids")) disallowKeys.push("industry_ids");
    const keysToFilter = this.disallowKeys.concat(disallowKeys);
    const keys = Object.keys(this.appliedFilters).filter(key => !keysToFilter.includes(key));
    const res = keys.reduce((memo, key) => {
      if (this.appliedFilters[key]) {
        if (!memo.filters) {
          memo.filters = {};
        }
        memo.filters[key] = this.appliedFilters[key];
      }
      return memo;
    }, {});

    if (!disallowKeys.includes("page")) res.page = this.params.page;

    return res;
  }

  _build() {
    return this.pattern
      .replace("/<country>", this.country)
      .replace("/<category>", this.category)
      .replace("/<industry>", this.industry)
      .replace("/<seniority>", this.seniority)
      .replace("/<town>", this.town);
  }

  _processCategory() {
    if (!this.appliedFilters.category_ids?.length) return;
    const category = this._findItem("categories", this.appliedFilters.category_ids[0]);
    if (!category) return;
    this.category = this._prefix(category?.slug);
  }

  _processIndustry() {
    if (this.category.length) return;
    if (this.appliedFilters.category_ids?.length) return;
    if (!this.appliedFilters.industry_ids?.length) return;
    const industry = this._findItem("industries", this.appliedFilters.industry_ids[0]);
    if (!industry) return;
    this._addDisallowKey("industry_ids");
    this.industry = this._prefix(industry?.slug);
  }

  _processCountry() {
    if (!this.appliedFilters.country_id) {
      return;
    }

    const country = this.countries.find(elm => elm.id === parseInt(this.appliedFilters.country_id));
    if (country?.featured) {
      this._addDisallowKey("country_id");
      this.country = this._prefix(country?.slug);
    }
  }

  _processTown(isCountrySelected) {
    if (!this.appliedFilters.town_id) return;
    if (!this.towns.length) {
      this.towns = this.countries.filter(elm => !elm.country_code);
    }
    const town = this.towns.find(elm => elm.id === parseInt(this.appliedFilters.town_id));
    if (!town) return;
    this.town = this._prefix(town.slug);

    if (town.fuzu_country_featured && isCountrySelected) {
      this.country = this._prefix(town.fuzu_country_slug);
    }
  }

  _processSeniority() {
    if (!this.appliedFilters.seniority_ids) return;
    this.seniority = this._prefix(this.appliedFilters.seniority_ids);
  }

  _findItem(filter, id) {
    return this.filters[filter].find(elm => elm.id === parseInt(id));
  }

  _isDisallowSEFUrl() {
    if (this.appliedFilters.category_ids?.length > 1) return true;
    if (this.appliedFilters.industry_ids?.length > 1) return true;

    return false;
  }

  _addDisallowKey(key) {
    if (this.disallowKeys.indexOf(key) === -1) this.disallowKeys.push(key);
  }

  _prefix(slug) {
    return `/${slug}`;
  }
}

export default JobSearchUrlBuilder;
