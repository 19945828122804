import { makeAutoObservable } from "mobx";
import * as API from "utils/api";

class TrackingStore {
  brand;

  isBarona = false;

  constructor(brand, isBarona) {
    makeAutoObservable(this);

    this.isBarona = isBarona;
    this.brand = brand;
  }

  trackRouteChange = props => {
    return API.trackRouteChange(props).catch(err => {
      console.error(err);
    });
  };

  track = (event, params = {}) => {
    const { match_path, ...rest } = params;
    const basePayload = {
      brand_id: this.brand.id,
      brand_slug: this.brand.slug,
      url: window?.location?.href,
      match_path
    };
    window.dataLayer?.push({ event, ...basePayload, ...rest });
    return API.trackEvent({
      event_name: event,
      ...basePayload,
      params: rest
    }).catch(err => {
      console.error(err);
    });
  };

  baronaTrack = (eventName, params = {}) => {
    if (!this.isBarona) {
      return;
    }

    if (window.dataLayer) {
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: eventName,
        ecommerce: {
          ...(params.transactionId && { transaction_id: params.transactionId }), // Include only if provided
          ...(params.affiliation && { affiliation: params.affiliation }), // Instance (Baronanordic, Barona FI, Barona SE, Barona NO, )
          ...(params.value && { value: params.value }), /// Total revenue, always 0 since prices in items
          ...(params.currency && { value: params.currency }), // required variable. value always EUR
          items: [
            {
              price: 0, // required variable. value always 0
              quantity: 1, // required variable. value always 1
              currency: "EUR", // required variable. value always EUR
              index: 0, // Only if event can contain more than 1 items (starts from 0)
              item_list_id: "Careers list", // Item list event variable. Value (always Careers list) only if set previously in list events
              item_list_name: "Search", // Item list event variable. Value only if set previously in list events
              item_variant: params.itemVariant, // Positions > Job level ID > Name
              item_category: params.itemCategory, // Positions > Disciplines ID > Name
              item_name: params.itemName, // Positions > Title
              item_id: params.itemId, // Positions > ID
              affiliation: params.itemAffiliation, // Instance (Baronanordic, Barona FI, Barona SE, Barona NO, )
              item_brand: params.itemBrand // Positions > Employer ID > Name
            }
          ]
        }
      });
    }
  };
}

export default TrackingStore;
