import React from "react";
import { useTranslation } from "b2c/hooks/useTranslation";
import { Row, Col } from "react-grid-system";

import Label from "../Label";
import Text from "../Text";

const Hint = ({ style, children, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Row gutterWidth={8} nowrap style={{ marginBottom: "24px", ...style }}>
      <Col width="auto" style={{ display: "flex" }}>
        <Label rect theme="secondary200" color="white" display="inline-flex" margin="auto 0">
          {t("global.hint")}
        </Label>
      </Col>
      <Col>
        <Text {...rest}>{children}</Text>
      </Col>
    </Row>
  );
};

export default Hint;
