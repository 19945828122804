import React from "react";

import fuzuLogo from "images/new_fuzu_logo_yellow.png";
import { Image } from "b2c/components/Core";

const Logo = ({ logo = fuzuLogo, margin = "auto 16px", alt = "Main Logo", width = "73px", height = "22px" }) => {
  return (
    <Image
      src={logo}
      alt={alt}
      margin={margin}
      borderRadius="0"
      height={height}
      width={width}
      loadingType="eager"
      srcSet={`${logo} 100w`}
    />
  );
};

export default Logo;
