import { makeAutoObservable, reaction, toJS } from "mobx";

import { stringify } from "utils/qsUtil";
import * as State from "utils/storeHelpers";
import { addQueryParams } from "utils/hooks";
import API from "../../utils/api";
import CourseStore from "./CourseStore";

const defaultSections = [
  {
    title: "learn.sections_welcome_1",
    percentage: "",
    type: "welcome_to_learning",
    position: 1
  },
  {
    title: "learn.sections_welcome_2",
    percentage: "",
    type: "courses",
    position: 2,
    filter: "popular",
    expandable: false,
    count_limit: 8,
    total_limit: null
  }
];

const defaultState = {
  loading: false,
  processing: false,
  type: "",
  message: ""
};

class LearningStore {
  setAppState;

  browserHistory;

  state = defaultState;

  activity;

  searchActive = false;

  courses = null;

  totalCount = null;

  sections = defaultSections;

  categories;

  filters;

  params = {
    page: 1,
    per_page: 30,
    filters: {}
  };

  slug = "";

  isFirstLoading = true;

  title = "";

  oldStringifyParams = "";

  constructor(setAppState, context = {}) {
    makeAutoObservable(this);

    this.setAppState = setAppState;
    this.CourseStore = new CourseStore(setAppState, context);
    this.title = context.h1_title;
    this.totalCount = context.total_count;
    this.courses = context.courses;
    this.filters = context.filters;
    this.categories = context.categories;
    if (context.current_category) {
      this.slug = context.current_category.slug;
      this.params = {
        ...this.params,
        page: 1,
        filters: { ...this.params.filters, category_id: context.current_category.id }
      };
    }

    reaction(
      () => this.params || this.slug,
      () => {
        if (this.searchActive) {
          // addQueryParams(this.params, toJS(this.browserHistory), this.setUrl(this.slug, this.params));
          this.getCourses();
        }
      }
    );

    reaction(
      () => this.browserHistory || this.params || this.slug,
      () => {
        const { pathname } = this.browserHistory.location;
        const pathnameLength = pathname.length;
        const learnSearchUrl = "/learn/search/";
        const maxLearnSearchPage = learnSearchUrl.length;
        const isCategoryDetailPage = pathnameLength >= maxLearnSearchPage;
        const isEmptyFilters = Object.keys(this.params.filters).length === 0;
        if (isCategoryDetailPage && isEmptyFilters) {
          const activeCategory = this.currentCategory();
          const activeCategoryId = this.params.filters.category_id;

          if (activeCategoryId && String(activeCategoryId) !== String(activeCategory.id)) {
            this.params = {
              ...this.params,
              filters: { ...this.params.filters, category_id: String(activeCategory.id) }
            };
          }

          if (this.slug !== activeCategory.slug) {
            this.slug = activeCategory.slug;
          }
        }
      }
    );

    reaction(
      () => this.state,
      localState => {
        this.setAppState(localState);
      }
    );
  }

  currentCategory = () => {
    const { pathname } = this.browserHistory.location;
    const learnSearchUrl = "/learn/search/";
    const categoryUrl = pathname.replace(learnSearchUrl, "");

    return this.categories.find(({ slug }) => slug === categoryUrl);
  };

  get pageCount() {
    return Math.ceil(this.totalCount / this.params.per_page);
  }

  get sortedSections() {
    return this.sections ? [...this.sections].sort((a, b) => a.position - b.position) : [];
  }

  get appliedCount() {
    return this.params.filters ? Object.values(this.params.filters).length : 0;
  }

  setUrl = (slug, params) => {
    const lengthUrl = this.browserHistory.location.pathname.split("/").length;
    const path = slug && params.filters?.category_id ? `/${slug}` : "";

    if (lengthUrl >= 4 && slug) {
      return `/learn/search${path}`;
    }

    if (!params?.filters?.category_id && !slug) {
      return "/learn/search";
    }

    return `${this.browserHistory.location.pathname}${path}`;
  };

  setPage = (page = 1) => {
    this.params = {
      ...this.params,
      page: +page
    };
  };

  initializeLearning = () => {
    this.state = State.setLoading(this.state);
    return this.getLearningLayout().then(res => {
      this.state = State.setNeutral(this.state, res);
    });
  };

  initializeSearch = async (history, query) => {
    this.searchActive = true;
    this.courses = [];
    if (!this.categories) await this.getCategories();
    if (!this.browserHistory) this.browserHistory = history;
    if (Object.values(query).length > 0) {
      const currentCategory = this.currentCategory();
      this.params =
        currentCategory?.id && !query.filters?.category_id
          ? { ...query, filters: { ...query.filters, category_id: currentCategory.id } }
          : query;
    } else if (this.categories) addQueryParams(this.params, toJS(this.browserHistory));
  };

  setSlug = filters => {
    if (filters?.category_id && this.categories?.length) {
      const selectedCategory = this.categories.find(item => item.id === filters.category_id);
      this.slug = selectedCategory.slug;
    } else {
      this.slug = "";
    }
  };

  applyFilter = filter => {
    this.setSlug(filter);
    this.params = {
      ...this.params,
      page: 1,
      filters: { ...this.params.filters, ...filter }
    };
  };

  resetFilters = () => {
    this.params = {
      ...this.params,
      page: 1,
      filters: {}
    };
    this.slug = "";
  };

  disableSearch = () => {
    this.searchActive = false;
    this.state = defaultState;
  };

  getCategories = () => {
    this.state = State.setLoading(this.state);
    return API.getCourseCategories()
      .then(res => {
        this.categories = res?.data?.categories;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve course categories", () =>
          this.getCategories()
        );
      });
  };

  getStatusCourses = slug => {
    this.courses = null;
    this.state = State.setLoading(this.state);

    switch (slug) {
      default:
      case "in-progress":
        return this.getUnfinishedCourses();
      case "saved":
        return this.getSavedCourses();
      case "completed":
        return this.getFinishedCourses();
    }
  };

  getLearningLayout = () => {
    return API.getLearningLayout()
      .then(res => {
        this.sections = res?.data?.common;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve learning layout", () =>
          this.getLearningLayout()
        );
      });
  };

  getLearningActivity = () => {
    if (!this.activity) {
      return API.getLearningActivity()
        .then(res => {
          this.activity = res.data;
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Failed to retrieve learning activity", () =>
            this.getLearningActivity()
          );
        });
    }
  };

  getCourses = () => {
    this.state = State.setLoading(this.state);
    const searchQuery = stringify(this.params);

    if (this.oldStringifyParams === searchQuery) {
      return null;
    }

    this.setOldStringifyParams(searchQuery);
    return Promise.all([API.getCourseFilters(searchQuery), API.searchCourses(searchQuery)])
      .then(res => {
        this.filters = res[0]?.data?.filters;
        this.courses = res[1]?.data?.courses;
        this.totalCount = res[1]?.data?.meta?.total_count;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve courses", () => this.getCourses());
        this.setOldStringifyParams("");
      });
  };

  getFinishedCourses = () => {
    return API.getFinishedCourses()
      .then(res => {
        this.courses = res?.data?.courses;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve courses", () => this.getFinishedCourses());
      });
  };

  getUnfinishedCourses = () => {
    return API.getUnfinishedCourses()
      .then(res => {
        this.courses = res?.data?.courses;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve courses", () => this.getUnfinishedCourses());
      });
  };

  getSavedCourses = () => {
    return API.getSavedCourses()
      .then(res => {
        this.courses = res?.data?.courses;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve courses", () => this.getSavedCourses());
      });
  };

  setOldStringifyParams = oldStringifyParams => {
    this.oldStringifyParams = oldStringifyParams;
  };
}

export default LearningStore;
