import { createContext, useContext } from "react";

export const theme = {
  primary50: "#FFF7CB",
  primary100: "#FFF09F",
  primary: "#FFE140",
  primary250: "#FFC926",
  primary300: "#F3A600",
  primary800: "#BF8300",
  primary900: "#806000",
  secondary100: "#DFF1FF",
  secondary200: "#4690FF",
  secondary300: "#0E50B5",
  success100: "#E1F6E0",
  success200: "#00B55E",
  success300: "#018747",
  accent100: "#FDF4F4",
  accent200: "#FFE5E5",
  accent300: "#EB3737",
  accent400: "#C30000",
  warning50: "#FFF9E9",
  additional: "#412C4C",
  black0: "#292929",
  black100: "#BBC0CB",
  black200: "#707378",
  black300: "#494B4F",
  black350: "#3E3E3E",
  black400: "#231F20",
  black500: "#0B0A0A",
  black900: "#000000",
  grey0: "#F9FAFC",
  grey100: "#F6F7F9",
  grey200: "#E8EAEF",
  grey300: "#C5C9D1",
  grey350: "#f7f7f7",
  grey400: "#E1E1E1",
  grey500: "#808080",
  grey600: "#c5c5c5",
  grey650: "#676767",
  grey700: "#666666",
  grey750: "#333333",
  grey800: "#A4A4A4",
  grey900: "#212121",
  additional100: "#E0F5EF",
  additional200: "#049B89",
  additional300: "#12C0AB",
  white: "#fff",
  blue: "#0000ff",
  body: "#f3f3f3",
  preview: "rgba(0,0,0,0.5)",
  transparent: "transparent",
  primaryButtonBackground: "#FFE140",
  primaryButtonLabelColor: "#0B0A0A",
  primarySvgFillColor: "rgb(255, 240, 159)",
  primaryGradient: "linear-gradient(270deg, #F3A600 0%, #FFF09F 100%)",

  setColor(key, color) {
    this[key] = color;
  },

  setPalette(palette) {
    Object.keys(palette).map(key => this.setColor(key, palette[key]));
  },

  switchToNcpwd() {
    this.setPalette({
      black100: "#0B0A0A",
      black200: "#0B0A0A",
      black300: "#0B0A0A",
      black400: "#0B0A0A",
      black500: "#0B0A0A"
    });
  },

  switchColors(colors) {
    this.setPalette(colors);
  },

  getTextColor(key) {
    return Number(key.match(/\d+/g)) >= 300 || key.match(/black|additional|preview|body/) ? this.white : this.black500;
  },

  getColors(key) {
    return {
      backgroundColor: key ? this[key] : this.transparent,
      color: this.getTextColor(key)
    };
  },

  getBorderColor(key) {
    return Number(key.match(/\d+/g)) >= 300 ? this.white : this.grey300;
  },

  get Palette() {
    return Object.keys(this).filter(key => !/set|get|Palette|switch/.test(key));
  }
};

const ThemeContext = createContext(theme);

export const useTheme = () => {
  return useContext(ThemeContext);
};

export default ThemeContext;
