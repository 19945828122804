import { useTranslation as useI18nTranslation } from "react-i18next";

export const useTranslation = () => {
  const { t, i18n } = useI18nTranslation();

  return {
    t,
    changeLanguage: lng => {
      sessionStorage.setItem("language", lng);
      return i18n.changeLanguage(lng);
    },
    language: i18n.language
  };
};
