import styled from "styled-components";

export const Modal = styled.div`
  max-width: 500px;
  margin: -55px -20px -20px -20px;
`;

export const ImageContent = styled.div`
  height: 164px;
  text-align: center;
  padding: 10px 0;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: contain;

  @media screen and (min-width: 768px) {
    height: 300px;
  }
`;

export const Content = styled.div`
  padding: 20px;

  @media screen and (min-width: 768px) {
    padding: 40px 40px 10px 40px;
  }
`;
