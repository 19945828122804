import { makeAutoObservable, reaction } from "mobx";
import * as State from "utils/storeHelpers";
import API from "../../utils/api";

class CourseStore {
  setAppState;

  course;

  testResults;

  constructor(setAppState, context = {}) {
    makeAutoObservable(this);

    this.setAppState = setAppState;

    if (context.course_details) {
      this.course = {
        ...context.course_details,
        reviews: context?.reviews,
        ncpwd: context.course_details?.organizer?.name === "NCPWD"
      };
    }

    reaction(
      () => this.state,
      localState => {
        this.setAppState(localState);
      }
    );
  }

  state = {
    loading: false,
    processing: false,
    type: "",
    message: ""
  };

  module;

  getCourseDetails = id => {
    if (!this.course || (this.course?.id !== id && this.course?.slug !== id)) {
      this.state = State.setLoading(this.state);
      this.course = null;
      this.clearModule();
      return API.getCourse(id)
        .then(res => {
          this.course = res.data.course;
          this.course.ncpwd = res.data.course.organizer?.name === "NCPWD";

          return API.getCourseReviews(id);
        })
        .then(res => {
          this.course.reviews = res.data.reviews;
          return API.getCourseComments(id);
        })
        .then(res => {
          this.course.comments = res.data.comments;

          this.state = State.setNeutral(this.state, res[0]);
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Could not retrieve course details", () =>
            this.getCourseDetails(id)
          );
        });
    }
  };

  startCourse = id => {
    this.state = State.setProcessing(this.state);
    return API.startCourse(this.course?.id || id)
      .then(res => {
        this.module = res?.data?.current_module;

        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not start a course", () => this.startCourse());
      });
  };

  startAffiliate = () => {
    this.state = State.setProcessing(this.state);
    return API.startAffiliate(this.course.id)
      .then(res => {
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not start a course", () => this.startAffiliate());
      });
  };

  cancelCourse = retake => {
    this.state = State.setProcessing(this.state);
    return API.cancelCourse(this.course.id)
      .then(res => {
        if (!retake) {
          this.course = null;
          this.state = State.setSuccess(this.state, res, "Course was cancelled");
        } else {
          this.course = {
            ...this.course,
            completed_at: null
          };
        }

        return true;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not cancel a course", () => this.cancelCourse());
      });
  };

  cancelAffiliate = retake => {
    this.state = State.setProcessing(this.state);
    return API.cancelAffiliate(this.course.id)
      .then(res => {
        if (!retake) {
          this.course = null;
          this.state = State.setSuccess(this.state, res, "Course was cancelled");
        } else {
          this.course = {
            ...this.course,
            completed_at: null
          };
        }

        return true;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not cancel a course", () => this.cancelAffiliate());
      });
  };

  finishAffiliate = () => {
    this.state = State.setProcessing(this.state);
    return API.finishAffiliate(this.course.id)
      .then(res => {
        this.course = null;
        this.state = State.setSuccess(this.state, res, "Course was finished");

        return true;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not finish a course", () => this.finishAffiliate());
      });
  };

  getCourseModule = id => {
    this.state = State.setProcessing(this.state);
    return API.getModule(this.course.id, id)
      .then(res => {
        this.module = res?.data?.module;

        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not retrieve module content", () =>
          this.getCourseModule(id)
        );
      });
  };

  completeModule = () => {
    this.state = State.setProcessing(this.state);
    return API.completeModule(this.course.id, this.module.id)
      .then(res => {
        this.module = res?.data?.current_module;
        this.course.progress = {
          ...this.course.progress,
          ...res?.data?.progress,
          current_module: res?.data?.current_module
        };
        if (res?.data?.progress?.finished) this.course.completed = true;

        this.state = State.setSuccess(this.state, res, "Module completed");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not complete module", () => this.completeModule());
      });
  };

  getTestResult = id => {
    this.state = State.setProcessing(this.state);
    return API.getTestResult(id)
      .then(res => {
        this.testResults = res?.data;
        this.state = State.setSuccess(this.state, res, "Success");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not get results", () => this.completeModule());
      });
  };

  postponeModule = () => {
    this.state = State.setProcessing(this.state);
    return API.postponeModule(this.course.id, this.module.id)
      .then(res => {
        this.state = State.setSuccess(this.state, res, "Saved module progress");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not postpone module", () => this.postponeModule());
      });
  };

  addComment = (id, body, parentId) => {
    return API.addCourseComment(id, body, parentId)
      .then(res => {
        if (parentId) {
          const index = this.course.comments.findIndex(comment => {
            return comment.id === parentId;
          });
          this.course.comments[index].children.push(res?.data?.comment);
        } else {
          this.course.comments.unshift(res?.data?.comment);
        }

        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not add comment", () =>
          this.addComment(id, body, parentId)
        );
      });
  };

  updateComment = (id, body, commentId, parentId) => {
    return API.updateCourseComment(id, body, commentId).then(() => {
      if (parentId) {
        const index = this.course.comments.findIndex(comment => {
          return comment.id === parentId;
        });
        this.course.comments[index].children = this.course.comments[index].children.map(c =>
          c.id === commentId ? { ...c, body } : c
        );
      } else {
        this.course.comments = this.course.comments.map(c => (c.id === commentId ? { ...c, body } : c));
      }
    });
  };

  removeComment = (id, commentId, parentId) => {
    return API.removeCourseComment(id, commentId)
      .then(res => {
        this.state = State.setNeutral(this.state, res);
        if (parentId) {
          const index = this.course.comments.findIndex(comment => {
            return comment.id === parentId;
          });
          this.course.comments[index].children = this.course.comments[index].children.map(c =>
            c.id === commentId ? { ...c, status: "deleted" } : c
          );
        } else {
          this.course.comments = this.course.comments.map(c => (c.id === commentId ? { ...c, status: "deleted" } : c));
        }
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to remove message", () =>
          this.removeComment(id, commentId)
        );
      });
  };

  addReview = (rating, comment) => {
    this.state = State.setProcessing(this.state);
    return API.addCourseReview(this.course.id, rating, comment)
      .then(res => {
        this.course = { ...this.course, review: { ...this.course.review, grade: rating } };
        this.state = State.setSuccess(this.state, res, "Review was submitted succesfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not submit a review", () =>
          this.addReview(rating, comment)
        );
      });
  };

  likeCourse = id => {
    return API.toggleCourseLike(id).then(() => {
      this.course = {
        ...this.course,
        likes: {
          ...this.course.likes,
          liked: true,
          count: this.course.likes.count + 1
        }
      };
    });
  };

  removeCourseLike = id => {
    return API.toggleCourseLike(id).then(() => {
      this.course = {
        ...this.course,
        likes: {
          ...this.course.likes,
          liked: false,
          count: this.course.likes.count - 1
        }
      };
    });
  };

  clearModule = () => {
    this.module = null;
  };
}

export default CourseStore;
