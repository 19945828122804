import { makeAutoObservable, reaction } from "mobx";

import * as State from "utils/storeHelpers";
import AuthorizationStore from "./AuthorizationStore";
import ProfileStore from "./ProfileStore";
import AccountSettings from "./SettingsStore";
import NotificationsStore from "./NotificationsStore";
import API from "../../utils/api";

class User {
  setAppState;

  user;

  isNewRegistrationFlow = false;

  constructor(user, authenticationChannel, setAppState, track, featureToggles) {
    makeAutoObservable(this);

    this.user = user;
    this.setAppState = setAppState;
    this.Authorization = new AuthorizationStore(this, authenticationChannel, setAppState, track);
    this.Profile = new ProfileStore(this, setAppState, track);
    this.Settings = new AccountSettings(setAppState, this.Profile.getPersonalInfo, this.updateUser);
    this.Notifications = new NotificationsStore(this);
    this.isNewRegistrationFlow = featureToggles?.new_registration_flow;

    reaction(
      () => !!(this.user?.onboarding_steps_completed && !this.user?.status_retrieved),
      retrieved => {
        if (retrieved) this.getUserStatus();
      },
      { fireImmediately: true }
    );

    reaction(
      () => this.state,
      localState => {
        this.setAppState(localState);
      }
    );
  }

  stepProperties = [
    "cv_uploaded",
    "has_ten_or_more_skills",
    "has_work_experience",
    "has_education",
    "add_photo",
    "learn_and_forum"
  ];

  state = {
    loading: false,
    processing: false,
    type: "",
    message: ""
  };

  step = {
    main: "First step",
    bonus: 50,
    title: "Add a photo of yourself",
    subtitle: "It will make your profile look better"
  };

  continueItems;

  userAccountMenu = false;

  navigation = { desktop: true, mobile: true };

  transactions = {};

  invites = [];

  get profileStrength() {
    if (this.user) {
      if (Number(this.user.profile_completeness_percentage) === 100) {
        return { color: "primary100", fill: "black500", prompt: "All star", title: "home.profile_level.all_star" };
      }
      if (this.user.profile_completeness_percentage > 67) {
        return { color: "success100", fill: "success200", prompt: "Advanced", title: "home.profile_level.advanced" };
      }
      if (this.user.profile_completeness_percentage > 33) {
        return {
          color: "warning50",
          fill: "primary800",
          prompt: "Intermediate",
          title: "home.profile_level.intermediate"
        };
      }
      return { color: "accent100", fill: "accent300", prompt: "Beginner", title: "home.profile_level.beginner" };
    }
    return { color: "accent300", fill: "white", prompt: "Unknown", title: "home.profile_level.unknown" };
  }

  get updatesCount() {
    return this.user ? this.user.new_appliations || 0 + this.user.new_courses || 0 + this.user.new_saved || 0 : 0;
  }

  get currentStep() {
    return this.stepProperties.some(prop => this.user && !this.user[prop])
      ? this.stepProperties.find(prop => this.user && !this.user[prop])
      : "final";
  }

  get adminAccess() {
    return this.user?.roles?.includes("admin");
  }

  updateUser = props => {
    this.user = { ...this.user, ...props };
    return this.user;
  };

  resetUser = () => {
    this.user = undefined;

    return this.user;
  };

  updateAvatar = avatar => {
    this.user.avatar = avatar;
  };

  setState = newState => {
    this.state = { ...this.state, ...newState };
    return this.state;
  };

  toggleUserAccountMenu = () => {
    this.userAccountMenu = !this.userAccountMenu;
  };

  disableNewRegistrationFlow = () => {
    this.isNewRegistrationFlow = false;
  };

  toggleNavigation = ({ desktop, mobile }) => {
    this.navigation = { ...this.navigation, desktop, mobile };
  };

  resetState = () => {
    this.state = {
      loading: false,
      processing: false,
      status: 200,
      type: "",
      message: ""
    };

    return this.state;
  };

  updateUserStateMessage = (message = "") => {
    if (this.state.message) this.state.message = message;
  };

  changeCompletionRate = inc => {
    this.user.profile_completeness_percentage = this.user.profile_completeness_percentage + inc;

    return this.user.profile_completeness_percentage;
  };

  getUserStatus = () => {
    return API.userStatus()
      .then(res => {
        this.user = { ...this.user, ...res.data, status_retrieved: true };
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve user status", () => this.getUserStatus());
      });
  };

  setNotificationPrompt = callback => {
    return API.setNotificationPrompt()
      .then(res => {
        this.updateUser(res.data.user);

        if (callback) {
          callback();
        }
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to set notification prompt", () =>
          this.setNotificationPrompt()
        );
      });
  };

  setTutorialCompleted = () => {
    if (!this.user?.b2c_onboarding_tutorial_completed) {
      return API.setTutorialCompleted()
        .then(res => {
          this.user = { ...this.user, ...res?.data?.current_user, status_retrieved: true };
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Failed to set tutorial as completed", () =>
            this.setTutorialCompleted()
          );
        });
    }
  };

  getTransactions = (type, sortBy) => {
    this.state = State.setLoading(this.state);
    return API.getTransactions(type, sortBy)
      .then(res => {
        this.transactions[type] = res?.data?.transactions;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve user transactions", () =>
          this.getTransactions(type, sortBy)
        );
      });
  };

  getFriendInvites = () => {
    this.state = State.setLoading(this.state);
    return API.getFriendInvites()
      .then(res => {
        this.invites = res?.data?.invite_friends;

        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not retrieve friend invites", () =>
          this.getFriendInvites()
        );
      });
  };

  sendInvite = email => {
    this.state = State.setProcessing(this.state);
    return API.sendInvite(email)
      .then(res => {
        this.state = State.setSuccess(this.state, res, "Invite sent successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not send friend invite", () => this.sendInvite(email));
      });
  };

  deleteRemoveMyData = token => {
    this.state = State.setProcessing(this.state);
    return API.deleteRemoveMyData(token)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not delete remove my data", () =>
          this.deleteRemoveMyData(token)
        );
      });
  };
}

export default User;
