import React, { useContext } from "react";

import styled, { css } from "styled-components";

import ThemeContext from "../../../contexts/theme";

const Icon = ({
  as,
  margin = "0px",
  width = 24,
  maxWidth = "100%",
  padding = "0px",
  backgroundColor = "transparent",
  fill,
  customFill,
  additionalCss,
  ...rest
}) => {
  const theme = useContext(ThemeContext);

  return (
    <StyledIcon
      as={as}
      width={width}
      $fill={theme[fill]}
      $customFill={customFill}
      $margin={margin}
      $padding={padding}
      $background={theme[backgroundColor]}
      $maxWidth={maxWidth}
      $additionalcss={additionalCss}
      {...rest}
    />
  );
};

const StyledIcon = styled.svg`
  ${({ $margin, $padding, $background = "none", $fill, $maxWidth, $additionalcss = "", $customFill }) => css`
    margin: ${$margin};
    padding: ${$padding};
    background: ${$background};
    max-width: ${$maxWidth};
    transition: all 0.3s ease-out;

    ${$fill || $customFill
      ? `
            path {
                fill: ${$fill || $customFill};
                transition: all .3s ease-out;
            }
        `
      : ""}

    ${$additionalcss}
  `}
`;

export default Icon;
