import React, { useContext } from "react";

import ThemeContext from "../../../contexts/theme";

import { StyledRibbon } from "./styled";

const Ribbon = ({ theme = "additional200", backgroundColor, color, size = 150, children }) => {
  const Theme = useContext(ThemeContext);
  const computed = Theme.getColors(theme);

  return (
    <StyledRibbon
      size={size}
      backgroundColor={Theme[backgroundColor] || computed.backgroundColor}
      color={Theme[color] || computed.color}
    >
      <div>{children}</div>
    </StyledRibbon>
  );
};

export default Ribbon;
