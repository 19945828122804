import React, { useState, useContext, useRef, forwardRef } from "react";
import { MdChevronRight } from "react-icons/md";
import { Row, Col, ScreenClassContext, Visible, Hidden } from "react-grid-system";
import { LockAlt } from "@styled-icons/boxicons-solid";
import parse from "html-react-parser";

import { useTranslation } from "b2c/hooks/useTranslation";
import { onEnterPress } from "utils/helpers";
import users_promo_img from "images/users-promo.png";
import users_promo_img_2 from "images/users-promo-2.png";
import users_promo_img_3 from "images/users-promo-3.png";
import phone_course2 from "images/b2c/phone_course2.png";
import phone_course from "images/b2c/phone_course.png";
import phone_email from "images/b2c/phone_email.png";
import { useImpressionTrack, useIsBarona } from "../../../hooks";
import SignupLink from "../../Tracking";
import { GuestVisible } from "../../WithAuth";
import { WhitelabelledOrCustomHidden } from "../../../contexts/branded";
import { Button, Text, Image, Input } from "..";
import Layout from "../../Layout";
import { useModal } from "../../../../utils/hooks";
import LoginModalContent from "../../ModalContents/SignUpModalContent";
import { useTheme } from "../../../contexts/theme";
import { TextWrapper } from "./styled";

const Suggestion = forwardRef(({ children, size, style, ...rest }, ref) => {
  const isBarona = useIsBarona();

  if (isBarona) {
    return null;
  }

  const theme = useTheme();

  return (
    <WhitelabelledOrCustomHidden>
      <GuestVisible>
        <Layout.Section
          style={{
            backgroundImage: `radial-gradient(92.85% 1644.76% at 26.91% 49.28%, ${theme.primary} 0%, #F3A900 95.28%)`,
            ...style
          }}
          {...rest}
        >
          <Layout.Content ref={ref} tag="div" size={size}>
            {children}
          </Layout.Content>
        </Layout.Section>
      </GuestVisible>
    </WhitelabelledOrCustomHidden>
  );
});

Suggestion.Banner = ({ type = "job", ...rest }) => {
  const isBarona = useIsBarona();

  if (isBarona) {
    return null;
  }

  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm", "md"].includes(screen);
  const openLoginModal = useModal(<LoginModalContent type="auto" />, null, () => {}, 0);
  const { t } = useTranslation();

  const { text, buttonTitle = "suggestions.join_fuzu_for_free" } = bannerMap[type];

  return (
    <Suggestion size="md" padding="8px 0" {...rest}>
      <Row nowrap={!isSm}>
        <Col md="content" style={{ display: "flex" }}>
          <Image
            display="block"
            baseWidth={84}
            baseHeight={42}
            src={users_promo_img}
            margin={isSm ? "0 auto 12px" : "0"}
          />
        </Col>
        <Col lg="content" style={{ display: "flex" }}>
          <Text
            fontSize={16}
            textAlign={isSm ? "center" : "left"}
            width="100%"
            margin="auto 0"
            lineHeight={isSm ? "30px" : "100%"}
          >
            <TextWrapper>{parse(t(text))}</TextWrapper>
          </Text>
        </Col>
        <Col md="content" style={{ display: "flex" }}>
          <Button
            noBackground
            color="accent300"
            padding="0"
            width="100%"
            margin={isSm ? "12px 0" : "auto 0"}
            height="fit-content"
            event="promo_clicked"
            $trackParams={{ type: `guest_banner_${type}` }}
            onClick={openLoginModal}
          >
            {t(buttonTitle)}
            <MdChevronRight style={{ fontSize: "20px" }} />
          </Button>
        </Col>
      </Row>
    </Suggestion>
  );
};

Suggestion.Prompt = forwardRef(({ type = "job", fromUrl, ...rest }, ref) => {
  const isBarona = useIsBarona();

  if (isBarona) {
    return null;
  }

  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm", "md"].includes(screen);
  const openLoginModal = useModal(<LoginModalContent type="auto" />, null, () => {}, 0);
  const { t } = useTranslation();

  const { image, text, buttonTitle = "suggestions.join_fuzu_for_free" } = promptMap[type];

  return (
    <Suggestion ref={ref} style={{ borderRadius: 5 }} {...rest}>
      <Row nowrap={!isSm}>
        <Hidden sm xs md>
          <Col lg="content" style={{ display: "flex" }}>
            {type === "signUp" ? <>{image}</> : <Image display="block" height={54} src={image} margin="2px 0 0" />}
          </Col>
        </Hidden>
        <Col style={{ display: "flex" }}>
          <Text width="100%" margin={isSm ? "12px 0 0" : "auto 0"} lineHeight={isSm ? "150%" : "100%"}>
            <TextWrapper>{parse(t(text))}</TextWrapper>
          </Text>
        </Col>
        <Col lg="content" style={{ display: "flex" }}>
          <Button
            fromUrl={fromUrl}
            noBackground
            color="accent300"
            padding="0"
            width="100%"
            margin={isSm ? "12px 0" : "auto 0"}
            height="fit-content"
            event="promo_clicked"
            $trackParams={{ type: `guest_${type}` }}
            onClick={openLoginModal}
          >
            {t(buttonTitle)}
            <MdChevronRight style={{ fontSize: "20px" }} />
          </Button>
        </Col>
      </Row>
    </Suggestion>
  );
});

Suggestion.Email = ({ type = "company", subject, ...rest }) => {
  const isBarona = useIsBarona();

  if (isBarona) {
    return null;
  }

  const [email, setEmail] = useState("");
  const cta = useRef();
  const screen = useContext(ScreenClassContext);
  const isSm = ["xs", "sm", "md"].includes(screen);
  const { targetRef } = useImpressionTrack({ type: `guest_email_${type}` });
  const { t } = useTranslation();

  const handleEmailChange = ({ target: { value } }) => {
    setEmail(value);
  };

  const handleEnterPress = e => {
    onEnterPress(e, email, () => cta.current.click());
  };

  const { image, text } = (() => {
    switch (type) {
      default:
      case "company":
        return {
          image: phone_email,
          text: "suggestions.be_the_first_text"
        };
      case "learn":
        return {
          image: phone_course2,
          text: "suggestions.get_access_to_text"
        };
      case "forum":
        return {
          image: phone_course,
          text: "suggestions.dont_miss_out_text"
        };
    }
  })();

  return (
    <Suggestion ref={targetRef} style={{ borderRadius: 6 }} {...rest}>
      <div style={{ margin: isSm ? "24px -4px" : "0" }}>
        <Row>
          <Visible xxl xl lg>
            <Col width="content">
              <Image display="block" src={image} width={200} margin="28px 24px 0" />
            </Col>
          </Visible>
          <Col
            lg={7}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly"
            }}
          >
            <Text
              fontSize={isSm ? 18 : 24}
              margin={isSm ? "0 0 12px 0" : "20px 0 0"}
              textAlign={isSm ? "center" : "left"}
            >
              {parse(t(text, { subject }))}
            </Text>
            <Row>
              <Col lg={7}>
                <Input
                  onKeyDown={handleEnterPress}
                  value={email}
                  inputHeight="42px"
                  placeholder={t("suggestions.email_address_placeholder")}
                  onChange={handleEmailChange}
                />
              </Col>
              <Col lg={4}>
                <Button.Signup
                  ref={cta}
                  event="promo_clicked"
                  theme="accent300"
                  width="100%"
                  margin="0"
                  state={{ email }}
                  $trackParams={{ type: `guest_email_${type}` }}
                >
                  {t("global.sign_up_button")}
                </Button.Signup>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Suggestion>
  );
};

Suggestion.Job = forwardRef((props, ref) => {
  const isBarona = useIsBarona();

  if (isBarona) {
    return null;
  }

  const { t } = useTranslation();

  return (
    <SignupLink event="promo_clicked" $trackParams={{ type: "guest_job_list" }}>
      <Suggestion ref={ref} style={{ borderRadius: 6 }}>
        <Row>
          <Col width="content">
            <Image display="block" src={phone_email} width="90px" height="114px" margin="12px 0 0" />
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly"
            }}
          >
            <Text fontSize={16} fontWeight={600} margin="0">
              {t("suggestions.get_personalised_text")}
            </Text>
            <Button noBackground color="accent300" padding="0" height="fit-content" width="fit-content">
              {t("suggestions.join_fuzu_for_free")}
              <MdChevronRight style={{ fontSize: "20px" }} />
            </Button>
          </Col>
        </Row>
      </Suggestion>
    </SignupLink>
  );
});

const promptMap = {
  job: {
    image: users_promo_img_2,
    text: "suggestions.application_submitted_text",
    buttonTitle: "suggestions.join_fuzu_now"
  },
  course: {
    image: users_promo_img_3,
    text: "suggestions.lost_with_career_text",
    buttonTitle: "suggestions.get_started_now"
  },
  signUp: {
    text: "suggestions.sign_up_to_view",
    buttonTitle: "suggestions.join_fuzu_now",
    image: <LockAlt width="18px" height="18px" color="#000000" />
  }
};

const bannerMap = {
  job: {
    text: "suggestions.more_than_text"
  },
  company: {
    text: "suggestions.people_have_been_text",
    buttonTitle: "suggestions.create_fuzu_profile"
  },
  learn: {
    text: "suggestions.job_ready_skills_text",
    buttonTitle: "sign_up_modal.get_started_for_free"
  },
  forum: {
    text: "suggestions.join_africa_text"
  }
};

export default Suggestion;
