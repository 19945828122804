import axios from "axios";

// Messages

const messages_path = "/api/v1/messaging/conversations";

const channel_path = "/api/v1/messaging/channels";

export const getMessagesStatus = () => axios.get("/api/v1/messaging/status");

export const getConversations = (page = 0, per_page = 12) =>
  axios.get(`${messages_path}?per_page=${per_page}&page=${page}`);

export const getChannels = () => axios.get(channel_path);

export const getChannelConversations = (page = 0, per_page = 12, with_messages = false) =>
  axios.get(`${messages_path}/from_channels?per_page=${per_page}&page=${page}&with_messages=${with_messages}`);

export const addChannelConversation = (id, body, attachment_ids) =>
  axios.post(`${channel_path}/${id}/conversation`, { message: { body, attachment_ids } });

export const addConversation = (participant_ids = [], body, attachment_ids) =>
  axios.post(messages_path, {
    conversation: {
      participant_ids,
      message: { body, attachment_ids }
    }
  });

export const getConversationMessages = id => axios.get(`${messages_path}/${id}/messages`);

export const addMessage = (id, body, attachment_ids) =>
  axios.post(`${messages_path}/${id}/messages`, { message: { body, attachment_ids } });

export const uploadMessageAttachment = form =>
  axios.post("/api/v1/messaging/attachments", form, {
    headers: { "Content-Type": "multipart/form-data" }
  });

export const updateMessage = (conversation_id, message_id, body, attachment_ids) =>
  axios.patch(`${messages_path}/${conversation_id}/messages/${message_id}`, { message: { body, attachment_ids } });

export const removeMessage = (conversation_id, message_id) =>
  axios.delete(`${messages_path}/${conversation_id}/messages/${message_id}`);

export const addParticipant = (conversation_id, user_id) =>
  axios.post(`${messages_path}/${conversation_id}/participants/${user_id}/add`);

export const removeParticipant = (conversation_id, user_id) =>
  axios.delete(`${messages_path}/${conversation_id}/participants/${user_id}`);

// Employers

const employer_messages = "/api/v1/employers/messaging/conversations";

export const addCandiateConversation = (id, body, settings, employer_id, separate_conversations = true) =>
  axios.post(`${employer_messages}/candidate/${id}`, {
    conversation: {
      settings,
      message: { body },
      separate_conversations
    },
    employer_id: employer_id || ""
  });

export const addTalentConversation = (id, body, settings, employer_id, separate_conversations = true) =>
  axios.post(`${employer_messages}/talent/${id}`, {
    conversation: {
      settings,
      message: { body },
      separate_conversations
    },
    employer_id: employer_id || ""
  });

export const addBatchConversation = (candidate_ids, body, settings, employer_id, separate_conversations = true) =>
  axios.post(`${employer_messages}/candidates`, {
    conversation: {
      candidate_ids,
      settings,
      message: { body },
      separate_conversations
    },
    employer_id: employer_id || ""
  });

export const getContactDetails = (id, query) => axios.get(`api/v1/employers/users/contact_details/${id}?${query}`);

// Tracking

const events_path = "/api/v1/browse/events";

export const trackEvent = body => {
  // if (process.env.NODE_ENV !== "production") return new Promise(() => console.log("track event", body));

  return axios.post(`${events_path}/track_event`, body);
};

export const trackRouteChange = body => {
  // if (process.env.NODE_ENV !== "production") return new Promise(() => console.log("track event", body));

  return axios.post(`${events_path}/track_route_change`, body);
};

// Career hero

const career_hero_path = "/api/v1/browse/career_heros";

export const uploadCareerHeroInformation = form => axios.post(career_hero_path, form);

export const loadCareerHeroByToken = token => axios.get(`${career_hero_path}/${token}`);

export const getAppointments = countryId =>
  axios.get(`/api/v1/employers/contact_us/appointments/time_slots?fuzu_country_id=${countryId}`);

export const addTimeSlots = (body, id) => axios.put(`/api/v1/employers/contact_us/potential_employers/${id}`, body);

export const savePotentialEmployers = body => axios.post("/api/v1/employers/contact_us/potential_employers", body);

export const getDefaultCountry = () => axios.get("/api/v1/browse/countries/default");
