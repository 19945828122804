import React, { createContext, useContext } from "react";
import RootStore from "../stores";

export const storeContext = createContext({});

export const StoreProvider = ({ initialState, children }) => {
  let root = new RootStore(initialState);
  if (process.env.NODE_ENV === "development") {
    const { default: makeInspectable } = require("mobx-devtools-mst");
    root = makeInspectable(root); // Use it as necessary
  }
  const { Home, Blog, Search, ...rest } = root;

  return (
    <storeContext.Provider
      value={{
        ...Search,
        ...Home,
        ...Blog,
        ...rest
      }}
    >
      {children}
    </storeContext.Provider>
  );
};

const useStore = store => (store ? useContext(storeContext)[store] : useContext(storeContext));

export default useStore;
