import React, { cloneElement, useState, useEffect, useMemo, useContext, useRef, forwardRef, Children } from "react";
import { Visible, Hidden } from "react-grid-system";

import { useOnclickOutside, useOutsideWindow, useFocusTrap } from "utils/hooks";
import { highlightField, onEnterPress } from "utils/helpers";
import { SelectArrows } from "@styled-icons/entypo/SelectArrows";
import SearchInput from "../SearchInput";
import Text from "../Text";
import Button from "../Button";
import ThemeContext from "../../../contexts/theme";
import DropdownPortal from "../../../contexts/dropdown";
import StyledDiv, { StyledItem, StyledMenu, DropdownInactive, RequiredText } from "./styled";
import OptionAsLink from "./OptionAsLink";

const DefaultTrigger = ({ onClick, currentValue, error, dataCy, placeholder, style }) => (
  <div
    role="button"
    tabIndex={-1}
    className={`default-trigger${error === "true" ? " error" : ""}`}
    onClick={onClick}
    style={style}
    data-cy={dataCy || ""}
  >
    {currentValue || placeholder}
    <SelectArrows width={18} />
  </div>
);

const Dropdown = ({
  id = "dropdown",
  name,
  value,
  controlled = false,
  placeholder,
  triggerDataCy,
  trigger = <DefaultTrigger dataCy={triggerDataCy} />,
  label,
  labelStyles = {},
  disabled,
  errorMessage = "",
  wrapperStyle = "",
  menuStyle = "",
  $triggerStyle = "",
  defaultValue,
  onClose,
  closeOnSelect = true,
  open = false,
  borderColor,
  required,
  requiredText,
  children,
  ...rest
}) => {
  const parentRef = useRef();
  const [isOpen, setState] = useState(open);
  const [reverseMode, setReverseMode] = useState(false);
  const [currentValue, setValue] = useState(defaultValue);
  const [error, setError] = useState(!!errorMessage);
  const theme = useContext(ThemeContext);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (parentRef.current) {
      const currentY = parentRef.current?.getBoundingClientRect()?.top;
      setReverseMode(currentY > window.innerHeight / 2);
    }
  }, [isOpen, parentRef?.current]);

  useEffect(() => {
    setState(open);
  }, [open]);

  useEffect(() => {
    setError(!!errorMessage);
  }, [errorMessage]);

  const handleSelect = selected => {
    if (selected) setValue(selected);
    if (closeOnSelect && isOpen) setState(false);
  };

  const handleOpen = () => {
    setState(!isOpen);
    setError(false);
  };

  const handleClose = () => {
    if (onClose) onClose(isOpen, setState);
    setState(false);
  };

  const uid = `uid-${id}`;
  const eid = `eid-${id}`;

  const clonedChildren = children
    ? cloneElement(!controlled ? children : children({ isOpen, setState }), {
        onSelect: handleSelect,
        close: handleClose,
        currentValue,
        isOpen,
        menuStyle,
        theme,
        reverseMode,
        label,
        placeholder
      })
    : null;

  return (
    <StyledDiv
      id={uid}
      ref={parentRef}
      theme={theme}
      wrapperStyle={wrapperStyle}
      menuStyle={menuStyle}
      $triggerStyle={$triggerStyle}
      reverseMode={reverseMode}
      disabled={disabled}
      $hasError={error}
      $borderColor={borderColor}
      {...rest}
    >
      {label && (
        <div className="dropdown-label" style={{ ...labelStyles }}>
          {label}
          {required && (requiredText ? <RequiredText> {requiredText}</RequiredText> : "*")}
        </div>
      )}
      {cloneElement(trigger, {
        onClick: handleOpen,
        onKeyDown: e => onEnterPress(e, 1, () => handleOpen(e)),
        currentValue,
        error: error.toString(),
        isOpen,
        style: isOpen ? { pointerEvents: "none" } : {},
        tabIndex: 0,
        disabled,
        placeholder
      })}

      {error && (
        <Text id={eid} color="accent400" margin="4px 0 0" fontSize={12} loaded={error}>
          {errorMessage}
        </Text>
      )}

      {isOpen ? (
        <DropdownPortal>{clonedChildren}</DropdownPortal>
      ) : (
        <DropdownInactive>{clonedChildren}</DropdownInactive>
      )}

      <input id={id} name={name} value={value || currentValue || ""} readOnly />
    </StyledDiv>
  );
};

Dropdown.Menu = ({
  label,
  placeholder,
  disabled,
  disableSorting = false,
  disableSearch,
  isActiveSearch,
  onSelect,
  left = false,
  currentValue,
  footer = null,
  close,
  isFixedSm,
  triggerDataCy,
  children,
  ...rest
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [target] = useState();
  const menu = useFocusTrap(close);
  const targetChild = useRef();

  useOutsideWindow(menu, () => close());

  useOnclickOutside(menu, () => close(), true);

  useEffect(() => {
    if (targetChild.current && menu.current) {
      menu.current?.scrollTo(0, targetChild.current.offsetTop);
    }
  }, [menu.current, targetChild.current]);

  useEffect(() => {
    menu.current?.focus();
  }, [menu.current]);

  const modifiedChildren = useMemo(
    () =>
      Children.toArray(children)
        .filter(child => {
          const childes =
            typeof child.props?.children === "string"
              ? child.props?.children
              : Array.isArray(child.props?.children)
                ? child?.props?.children.join(" ")
                : "";
          if (searchTerm) {
            return (
              (typeof child.props?.value === "string" &&
                child.props?.value.toLowerCase().includes(searchTerm.toLowerCase())) ||
              childes.toLowerCase().includes(searchTerm.toLowerCase())
            );
          }
          return true;
        })
        .sort((childA, childB) =>
          !disableSorting
            ? childA.props?.value < childB.props?.value || childA.props?.children < childB.props?.children
              ? -1
              : 1
            : 0
        )
        .map(child =>
          cloneElement(child, {
            onSelect,
            currentValue,
            searchTerm,
            ...(child.props?.value === target || child.props?.children === target ? { ref: targetChild } : {})
          })
        ),
    [target, searchTerm, children]
  );

  const searchEnabled = (Children.count(children) > 12 && !disableSearch) || isActiveSearch;

  return (
    <StyledMenu
      ref={menu}
      className={left ? " left" : ""}
      searchEnabled={searchEnabled}
      {...rest}
      data-cy={triggerDataCy}
    >
      {!disabled ? (
        <>
          {searchEnabled && (
            <Hidden xs sm>
              <div className="dropdown-search">
                <SearchInput
                  autoFocus
                  value={searchTerm}
                  placeholder={placeholder}
                  width="100%"
                  onChange={e => setSearchTerm(e.target.value)}
                />
              </div>
            </Hidden>
          )}
          <Visible sm xs>
            <div className="dropdown-search">
              <Button
                theme="grey300"
                color="black500"
                noBackground
                bordered
                margin="auto 8px auto auto"
                height="48px"
                width="48px"
                onClick={close}
                aria-label="Go back"
                fontSize={20}
              >
                {"<"}
              </Button>
              {searchEnabled ? (
                <SearchInput
                  autoFocus
                  placeholder={placeholder}
                  value={searchTerm}
                  height="48px"
                  width="100%"
                  onChange={e => setSearchTerm(e.target.value)}
                />
              ) : (
                <Text fontWeight={600} width="100%">
                  {label || placeholder || currentValue}
                </Text>
              )}
            </div>
          </Visible>
          <div
            className="dropdown-list"
            role="tablist"
            id="dd-menu-id"
            style={{ marginTop: isFixedSm ? "12px" : "initial" }}
          >
            {modifiedChildren}
          </div>
          {footer && <div className="dropdown-footer">{footer}</div>}
        </>
      ) : (
        <div className="dropdown-footer">
          <i>No items</i>
        </div>
      )}
    </StyledMenu>
  );
};

Dropdown.Item = forwardRef(
  (
    {
      onClick,
      onSelect,
      currentValue,
      searchTerm,
      event = false,
      value,
      render = false,
      highlightSelected = true,
      style,
      children,
      href,
      ...rest
    },
    ref
  ) => {
    const item = useRef(ref);
    const [hovered, setHovered] = useState(false);
    const theme = useContext(ThemeContext);
    const handleSelect = values => {
      if (onSelect) {
        onSelect(values);
      }

      if (onClick) {
        onClick(values);
      }
    };

    return (
      <StyledItem
        ref={item}
        tabIndex={0}
        theme={theme}
        role="tab"
        aria-selected={(currentValue === children).toString()}
        aria-controls="dd-menu-id"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={e => {
          if (!event) e.preventDefault();
          handleSelect(value || children);
        }}
        onKeyDown={e => onEnterPress(e, 1, () => item.current.click())}
        className={`dropdown-item${highlightSelected && currentValue === children ? " selected" : ""}`}
        style={style}
        {...rest}
      >
        <OptionAsLink href={href} theme={theme}>
          {render
            ? children(currentValue === (value || children), hovered)
            : typeof children === "string"
              ? highlightField(children, searchTerm)
              : children}
        </OptionAsLink>
      </StyledItem>
    );
  }
);

export default Dropdown;
