import React, { useContext, forwardRef } from "react";
import ThemeContext from "../../../contexts/theme";
import Text from "../Text";

import { TextareaWrapper, TextareaLabel, TextareaControl } from "./styled";

const Textarea = forwardRef(
  (
    {
      id,
      inputColor = "black500",
      inputHeight = "120px",
      borderRadius = "5px",
      name,
      width,
      label,
      margin = "0 0 15px",
      labelColor = "black500",
      value,
      type = "text",
      resize = "none",
      placeholder,
      errorMessage = "",
      errorColor = "accent300",
      Icon = <></>,
      fieldRequired = false,
      ...props
    },
    ref
  ) => {
    const Theme = useContext(ThemeContext);

    const uid = id || `uid_${new Date().getTime() + Math.random().toString(36).substr(2, 5)}`;

    return (
      <TextareaWrapper ref={ref} styled={{ width, margin }}>
        {label && (
          <TextareaLabel htmlFor={uid} styled={{ color: Theme[labelColor] }}>
            {label}
            {fieldRequired && "*"}
          </TextareaLabel>
        )}
        {Icon}
        <TextareaControl
          type={type}
          styled={{
            color: Theme[inputColor],
            height: inputHeight,
            borderRadius,
            resize
          }}
          value={value}
          placeholder={placeholder}
          hasError={!!errorMessage}
          aria-invalid={!!errorMessage}
          name={name}
          id={uid}
          {...props}
        />
        {errorMessage && (
          <Text color={errorColor} fontSize="12px" margin="4px 0 0" loaded={!!errorMessage} aria-describedby={uid}>
            {errorMessage}
          </Text>
        )}
      </TextareaWrapper>
    );
  }
);

export default Textarea;
