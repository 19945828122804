import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import Iconed from "./IconedButton";
import Simple from "./SimpleButton";

const Button = React.forwardRef(
  (
    {
      className,
      overwriteClass = false,
      onClick,
      color,
      size,
      children,
      icon,
      iconAlign,
      disabled,
      style,
      tag,
      ...rest
    },
    ref
  ) => {
    const buttonClasses = !overwriteClass
      ? cn(
          "fz-btn",
          {
            [`fz-btn_${color}`]: color
          },
          {
            [`fz-btn_${size}`]: size
          },
          className
        )
      : className;

    const buttonIcon = () => {
      return (
        icon &&
        React.cloneElement(icon, {
          className: "fz-btn__icon"
        })
      );
    };

    const TagName = tag;

    return (
      <TagName ref={ref} onClick={onClick} className={buttonClasses} disabled={disabled} style={style} {...rest}>
        {icon && iconAlign === "left" && buttonIcon()}
        <div className="fz-btn__text">{children}</div>
        {icon && iconAlign === "right" && buttonIcon()}
      </TagName>
    );
  }
);

Button.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.oneOf(["yellow", "blue", "light-blue", "gray", "green", "red", "warning", "error"]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "wide"]),
  icon: PropTypes.element,
  iconAlign: PropTypes.oneOf(["left", "right"]),
  disabled: PropTypes.bool
};

Button.defaultProps = {
  iconAlign: "left",
  disabled: false,
  tag: "button"
};

Object.assign(Button, { Iconed, Simple });

export default Button;
