import { makeAutoObservable, reaction } from "mobx";

import * as State from "utils/storeHelpers";
import API from "../../utils/api";

let statusInterval;
class PaymentStore {
  setAppState;

  constructor(setAppState) {
    makeAutoObservable(this);

    this.setAppState = setAppState;

    reaction(
      () => this.transaction?.status?.pending && !this.transaction?.status?.completed,
      inProgress => {
        if (inProgress && window?.setInterval) statusInterval = setInterval(this.getTransactionStatus, 5000);
        else if (window?.clearInterval) {
          this.statusCallCount = 0;
          clearInterval(statusInterval);
        }
      }
    );

    reaction(
      () => this.state,
      localState => {
        this.setAppState(localState);
      }
    );
  }

  state = {
    loading: false,
    processing: false,
    type: "",
    message: ""
  };

  transaction = {};

  statusCallCount = 0;

  products;

  mobile_options;

  bank_options;

  provider = {};

  instructions = [];

  productDetails = {};

  errors = {};

  previousTransactionCall;

  getProductList = () => {
    if (!this.products) {
      this.state = State.setLoading(this.state);
      return API.getProductList()
        .then(res => {
          this.products = res?.data?.products;
          this.state = State.setNeutral(this.state, res);
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Could not retrieve product list", () => this.getProductList());
        });
    }
  };

  getProductDetails = slug => {
    if (!this.productDetails[slug]) {
      return API.getProductDetails(slug)
        .then(res => {
          this.productDetails[slug] = res?.data?.product;
          this.state = State.setNeutral(this.state, res);
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Could not retrieve product details", () =>
            this.getProductDetails(slug)
          );
        });
    }
  };

  getCoachingPackages = () => {
    if (!this.productDetails.career_coaching_packages) {
      return API.getCoachingPackages()
        .then(res => {
          this.productDetails.career_coaching_packages = res?.data?.packages;
          this.state = State.setNeutral(this.state, res);
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Could not retrieve product details", () =>
            this.getCoachingPackages()
          );
        });
    }
  };

  initiatePaymentFlow = (product_id, position_id, state) => {
    this.state = State.setLoading(this.state);
    this.errors = {};
    this.provider = {};
    this.mobile_options = undefined;
    this.bank_options = undefined;
    this.instructions = [];

    this.previousTransactionCall = () => this.initiateTransaction(product_id, position_id, state);
    return this.initiateTransaction(product_id, position_id, state);
  };

  initiateTransaction = (product_id, position_id, state) => {
    return API.initiateTransaction(product_id, position_id, state)
      .then(res => {
        this.product_details = res?.data?.transaction.product;
        this.transaction = res?.data?.transaction;
        if (res?.data?.payment_gateway) this.provider = res?.data?.payment_gateway;
        if (res?.data?.transaction?.payment_gateway) this.provider = res?.data?.transaction.payment_gateway;

        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not initiate payment", () =>
          this.initiateTransaction(product_id, position_id, state)
        );
      });
  };

  getMobileOptions = () => {
    this.errors = {};
    this.state = State.setLoading(this.state);
    return API.getMobileOptions(this.transaction.id)
      .then(res => {
        this.mobile_options = res?.data?.options;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not retrieve mobile payment options", () =>
          this.getMobileOptions()
        );
      });
  };

  getBankOptions = () => {
    this.errors = {};
    this.state = State.setLoading(this.state);
    return API.getBankOptions(this.transaction.id)
      .then(res => {
        this.bank_options = res?.data?.options;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not retrieve bank trnasfer options", () =>
          this.getBankOptions()
        );
      });
  };

  initiatePayment = (method, ...rest) => {
    this.state = State.setProcessing(this.state);
    this.errors = {};
    switch (method) {
      default:
      case "mobile":
        return this.initiateMobilePayment(...rest);
      case "credit_card":
        return this.initiateCardPayment(...rest);
      case "bank_transfer":
        return this.initiateBankTransfer(...rest);
    }
  };

  initiateMobilePayment = (provider, body) => {
    this.errors = {};
    this.provider = provider;
    const timer = setTimeout(() => {
      window.location.reload();
    }, 10000);
    return API.initiateMobilePayment(this.transaction.id, body)
      .then(res => {
        clearTimeout(timer);
        this.instructions = res?.data?.instructions.split("\n");
        this.transaction = res?.data?.transaction;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.errors = err?.response?.data?.errors?.errors?.mobile_transfer;
        this.state = State.setError(this.state, err, "Could not initiate mobile payment", () =>
          this.initiateMobilePayment(body)
        );
      });
  };

  initiateCardPayment = body => {
    this.errors = {};
    return API.initiateCardPayment(this.transaction.id, body)
      .then(res => {
        this.transaction = res?.data?.transaction;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.errors = err?.response?.data?.errors?.errors?.credit_card;
        this.state = State.setError(this.state, err, "Could not initiate credit card payment", () =>
          this.initiateCardPayment(body)
        );
      });
  };

  initiateBankTransfer = (provider, body) => {
    this.errors = {};
    this.provider = provider;
    return API.initiateBankTransfer(this.transaction.id, body)
      .then(res => {
        this.transaction = res?.data?.transaction;
        this.state = State.setSuccess(this.state, res, "Bank transfer initiated");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not initiate bank transfer", () =>
          this.initiateBankTransfer(body)
        );
      });
  };

  getTransactionStatus = () => {
    this.statusCallCount++;
    return API.getTransactionStatus(this.transaction.id)
      .then(res => {
        this.transaction.status = res?.data?.status;
        this.state = State.setNeutral(this.state, res);
        if (this.statusCallCount % 5 == 0) this.previousTransactionCall();
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not retrieve transaction status", () =>
          this.getTransactionStatus()
        );
      });
  };

  cancelTransaction = () => {
    if (this.transaction?.id) {
      this.state = State.setProcessing(this.state);
      this.errors = {};
      return API.cancelTransaction(this.transaction.id)
        .then(res => {
          this.transaction = {};
          this.state = State.setSuccess(this.state, res, "Transaction got cancelled");
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Could not cancel transaction", () => this.cancelTransaction());
        });
    }
    return Promise.resolve();
  };

  disableStatusCheck = () => {
    this.transaction = {};
  };
}

export default PaymentStore;
