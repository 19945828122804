import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Row, Col, ScreenClassContext } from "react-grid-system";

import infobar from "images/b2c/infobar.png";
import step_disabled from "images/b2c/step-disabled.png";
import step_success from "images/b2c/step-success.png";
import Card from "../Card";
import Image from "../Image";
import Text from "../Text";
import Spinner from "../Spinner";
import { buildImageUrlS3 } from "../../../contexts/branded";
import { nameImages } from "../../../constants/images";

const DefaultImage = props => <Image src={infobar} width="50px" height="50px" placeholder={infobar} {...props} />;

const InfoBar = ({
  BarImage = DefaultImage,
  shadow = false,
  margin = "0 0 20px",
  theme = "grey100",
  borderColor = "grey200",
  actions = <></>,
  loading = false,
  children,
  ...rest
}) => {
  const screen = useContext(ScreenClassContext);
  const isMd = ["md", "xs", "sm"].includes(screen);

  return (
    <Card bordered theme={theme} borderColor={borderColor} width="100%" shadow={shadow} style={{ margin }} {...rest}>
      <Card.Content>
        <Row style={{ minHeight: 64 }}>
          <Col width="auto" style={{ display: "flex" }}>
            {loading ? <Spinner size="50px" margin="auto" /> : <BarImage margin={isMd ? 0 : "auto"} />}
          </Col>

          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 0,
              minWidth: 200
            }}
          >
            {children}
          </Col>

          <Col
            sm={12}
            md={3}
            lg={3}
            width={!isMd ? "auto" : "100%"}
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            {actions}
          </Col>
        </Row>
      </Card.Content>
    </Card>
  );
};

const Sections = ({ sections = [] }) => {
  const screen = useContext(ScreenClassContext);
  const isMd = ["md", "xs", "sm"].includes(screen);
  const modifiedSections = isMd ? sections.slice(0, 2) : sections;

  const getAnchorProps = item => {
    return item.href
      ? {
          as: "a",
          href: item.href,
          className: "link"
        }
      : {};
  };

  return (
    <Row style={{ height: "100%" }}>
      {[...Array(Math.ceil(modifiedSections.length / 2)).keys()].map(index => (
        <Col
          key={`col${index}`}
          style={{
            padding: "10px 20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
          }}
        >
          {modifiedSections[index * 2] && (
            <div>
              <Text inline margin="0" fontWeight={600}>
                {modifiedSections[index * 2].name}:
              </Text>{" "}
              <Text inline margin="0" maxLength={32} {...getAnchorProps(modifiedSections[index * 2])}>
                {modifiedSections[index * 2].value}
              </Text>
            </div>
          )}
          {modifiedSections[index * 2 + 1] && (
            <div>
              <Text inline margin="0" fontWeight={600}>
                {modifiedSections[index * 2 + 1].name}:
              </Text>{" "}
              <Text inline margin="0" maxLength={32} {...getAnchorProps(modifiedSections[index * 2 + 1])}>
                {modifiedSections[index * 2 + 1].value}
              </Text>
            </div>
          )}
        </Col>
      ))}
    </Row>
  );
};

export const ApplicationStep = ({ status = "relevant", title = "", description = "", number, ...rest }) => {
  const barProps = (() => {
    switch (status) {
      default:
      case "relevant":
        return {
          BarImage: props => (
            <div style={{ position: "relative", margin: "auto", display: "flex" }}>
              <Text
                fontSize={18}
                fontWeight={600}
                color="primaryButtonLabelColor"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translateX(-50%) translateY(-50%)"
                }}
              >
                {number}
              </Text>

              <Image src={buildImageUrlS3(nameImages.stepCycle)} width="50px" height="50px" {...props} />
            </div>
          ),
          borderColor: "primary",
          theme: "primary50"
        };
      case "completed":
        return {
          BarImage: props => (
            <Image src={step_success} width="50px" height="50px" placeholder={step_success} {...props} />
          ),
          borderColor: "grey200",
          theme: "white"
        };
      case "disabled":
        return {
          BarImage: props => (
            <Image src={step_disabled} width="50px" height="50px" placeholder={step_disabled} {...props} />
          ),
          borderColor: "grey200",
          theme: "white"
        };
      case "loading":
        return {
          BarImage: props => (
            <Image src={step_disabled} width="50px" height="50px" placeholder={step_disabled} {...props} />
          ),
          borderColor: "grey200",
          theme: "white"
        };
    }
  })();

  return (
    <InfoBar bordered loading={status === "loading"} margin="0 0 12px" {...barProps} {...rest}>
      <Text loading={status === "loading"} margin="12px 0 2px" fontSize="16px" fontWeight="600">
        {title}
      </Text>
      <Text loading={status === "loading"} margin="auto 0 12px">
        {description}
      </Text>
    </InfoBar>
  );
};

InfoBar.Sections = Sections;

ApplicationStep.propTypes = {
  status: PropTypes.oneOf(["completed", "relevant", "disabled", "loading"])
};

export default InfoBar;
