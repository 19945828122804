import React from "react";

import styled from "styled-components";

const Video = ({ embed = false, thumbnail, src, type = "video/mp4", margin, style, ...rest }) => {
  const videoStyles = {
    ...{ margin },
    ...style
  };

  return (
    <StyledVideoWrapper $styled={videoStyles}>
      {embed ? (
        <StyledVideo as="iframe" src={src.replace("watch?v=", "embed/")} {...rest} />
      ) : (
        <StyledVideo as="video" poster={thumbnail} controls="controls" {...rest}>
          <source src={src} type={type} />
        </StyledVideo>
      )}
    </StyledVideoWrapper>
  );
};

const StyledVideoWrapper = styled.div`
  display: flex;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  ${({ $styled }) => $styled};
`;

const StyledVideo = styled.div`
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

export default Video;
