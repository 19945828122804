import styled, { css } from "styled-components";

const SelectInputWrapper = styled.div`
  margin: ${props => props.margin};
  ${props => props.styled};
`;

const SelectInputLabel = styled.label`
  display: block;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin: 0 0 8px;
  ${props => props.styled};
`;

const SelectInputControl = styled.select`
  ${({ theme, hasError, styled: styledProps }) => css`
    padding: 0 24px 0 12px;
    border: 1px solid ${hasError ? theme.accent300 : theme.grey200};
    background-color: ${hasError ? theme.accent200 : theme.white};
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M16.293 9.293L12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z'/></svg>");
    background-size: 18px auto;
    background-repeat: no-repeat;
    background-position: right 12px center;
    width: 100%;
    font-size: 14px;
    appearance: none;
    outline: none;
    transition: 0.2s ease;
    ${styledProps};

    &:focus,
    :active {
      border-color: ${hasError ? theme.accent300 : theme.primary};
      background-color: #fff;
      color: ${theme.black500};
    }

    &:invalid {
      color: ${theme.black200};
    }
  `}
`;

export { SelectInputWrapper, SelectInputLabel, SelectInputControl };
