import React, { useContext } from "react";
import ThemeContext from "../../../contexts/theme";

import { StyledProgress } from "./styled";

const ProgressBar = ({ filledColor = "primary", barColor = "grey200", currentValue = 1, maxValue = 2, style }) => {
  const Theme = useContext(ThemeContext);

  return (
    <StyledProgress
      max={maxValue}
      value={currentValue}
      $filledColor={Theme[filledColor]}
      $barColor={Theme[barColor]}
      $styled={style}
    />
  );
};

export default ProgressBar;
