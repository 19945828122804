import { makeAutoObservable } from "mobx";
import * as State from "utils/storeHelpers";
import API from "../../utils/api";

class Leaders {
  constructor(rootStore) {
    makeAutoObservable(this);

    this.rootStore = rootStore;
  }

  state = {
    loading: false,
    processing: false,
    type: "",
    message: ""
  };

  leaders;

  getSimilarUsers = (filter, limit) => {
    this.state = State.setLoading(this.state);

    return API.getSimilarUsers(filter, limit)
      .then(res => {
        this.leaders = res?.data?.users;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to get similar users", () =>
          this.getSimilarUsers(filter, limit)
        );
      });
  };
}

export default Leaders;
