import axios from "axios";

// Layout structure

export const getHomepageLayout = (slug = "home_page") => axios.get(`/api/v1/browse/layouts/${slug}`);

export const getApplicationMainLayout = id => axios.get(`/api/v1/browse/layouts/applications/main_page?job_id=${id}`);

export const getApplicationPromo = id =>
  axios.get(`/api/v1/browse/layouts/applications/profile_promo_popup?job_id=${id}`);

export const getApplicationCoverLetterLayout = id =>
  axios.get(`/api/v1/browse/layouts/applications/cover_letter?job_id=${id}`);

export const getLearningLayout = () => axios.get("/api/v1/browse/layouts/learning");
