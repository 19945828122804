import React from "react";
import { Edit, MessageSquareX } from "@styled-icons/boxicons-regular";

import { useTranslation } from "b2c/hooks/useTranslation";
import { usePrev } from "utils/hooks";
import { formatMessage } from "utils/helpers";
import Files from "../Files";
import UserMenu from "../UserMenu";
import { MessageItemContent } from "./styled";

const MessageItem = ({
  id,
  editing,
  owned,
  body,
  removable,
  sender = {},
  attachments = [],
  read,
  created_at,
  processing = false,
  removed = false,
  editMessage,
  removeMessage,
  adminAccess,
  UserCvButton,
  getContactDetails,
  deleted
}) => {
  const { t } = useTranslation();
  const prevProcessing = usePrev(processing);
  const convertedTime = created_at
    ? new Date(created_at).toLocaleTimeString([], { hour12: false, hour: "2-digit", minute: "2-digit" })
    : "";
  const onClickButton = () => (editing ? editMessage(null, "") : editMessage(id, body));
  const splittingCase = /(?:\r\n|\r|\n)/g;
  return (
    <MessageItemContent
      isOwned={owned}
      processing={processing}
      toAnimate={prevProcessing != processing || removed}
      isRemoved={removed}
      isNew={!read}
      isEditing={editing}
      aria-live="off"
    >
      <UserMenu
        user={sender}
        isOwned={owned}
        adminAccess={adminAccess}
        UserCvButton={UserCvButton}
        getContactDetails={getContactDetails}
      />
      {deleted ? (
        <div className="message-content">
          <div className="sender-name">
            {sender.first_name} {sender.last_name} {sender.system_identifier && `(${sender.system_identifier})`}
          </div>
          <div className="message-body">{t("messages.delete_message")}</div>
        </div>
      ) : (
        <div className="message-content">
          <div className="sender-name">
            {sender.first_name} {sender.last_name} {sender.system_identifier && `(${sender.system_identifier})`}
          </div>
          <div className="message-body">
            {attachments.map(({ file_name, file_size, id: fileId, download_url }) => (
              <Files.Single
                key={`${id}_attachment_${fileId}`}
                fill="secondary300"
                name={file_name}
                size={`${(file_size / 1024).toFixed(2)} kB`}
                url={download_url}
              />
            ))}

            {body
              ?.split(splittingCase)
              .map((line, index) => (line ? <p key={`${id}-${index}`}>{formatMessage(line)}</p> : <br />))}

            <div className="message-additional">
              {owned && !processing && (
                <>
                  <button type="button" className="edit" onClick={onClickButton}>
                    <Edit />
                  </button>
                  {removable && (
                    <button type="button" onClick={() => removeMessage(id)}>
                      <MessageSquareX />
                    </button>
                  )}
                </>
              )}

              {processing ? "Processing" : convertedTime}
            </div>
          </div>
        </div>
      )}
    </MessageItemContent>
  );
};

export default MessageItem;
