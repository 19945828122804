import styled from "styled-components";

export const SwitchWrapper = styled.div`
  ${props => props.styled};
  user-select: none;
  width: 44px;
`;

export const SwitchLabel = styled.label`
  display: flex;
  cursor: pointer;
  position: relative;
  user-select: none;
  transition: 0.2s ease;

  height: 24px;
  border-radius: 30px;
  background-color: ${({ checked, checkedColor, baseColor }) => (checked ? checkedColor : baseColor)};
  border: 2px solid ${({ checked, checkedColor, baseColor }) => (checked ? checkedColor : baseColor)};
  transition: 0.2s ease;
  padding: 0 20px;
  box-shadow: 0 0 0 ${({ isFocused }) => (isFocused ? "2px" : "0")} #ffd100;

  &:hover {
    opacity: 0.9;
  }

  ${props => props.styled};
`;

export const SwitchThumb = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  left: ${({ checked }) => (checked ? "100%" : "0%")};
  transform: translateX(${({ checked }) => (checked ? "-100%" : "0%")});
  top: 0;
  transition: 0.3s cubic-bezier(0.05, 0.16, 0.18, 1.5);
`;

export const SwitchControl = styled.input`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;

  ${props => props.styled};
`;
