import React, { useContext, Suspense, lazy } from "react";
import { useLocation } from "react-router-dom";
import { LeftArrowCircle, RightArrowCircle } from "@styled-icons/boxicons-solid";

import { useTranslation } from "b2c/hooks/useTranslation";
import Text from "../Text";
import ThemeContext from "../../../contexts/theme";
import { StyledRow } from "./styled";

const PageNav = lazy(() => import("./PageNav"));

const Pagination = ({
  page = 1,
  last = 100,
  disabled = false,
  padding = "8px 0",
  setPage,
  basePath,
  isLink,
  isFullUrl
}) => {
  const theme = useContext(ThemeContext);
  const pageNumber = Number(page);
  const { t } = useTranslation();

  const pages = (() => {
    const arr = [page];
    if (pageNumber === 1) arr.push(pageNumber + 1, pageNumber + 2);
    else if (pageNumber === last) arr.unshift(pageNumber - 2, pageNumber - 1);
    else {
      arr.unshift(pageNumber - 1);
      arr.push(pageNumber + 1);
    }

    return arr.filter(pageItem => pageItem <= last).filter(item => item !== 0);
  })();

  const prevDisabled = pageNumber === 1;
  const nextDisabled = pageNumber === last;

  if (last === 0 || last === 1 || pageNumber > last) return "";

  return (
    <StyledRow
      theme={theme}
      disabled={disabled}
      padding={padding}
      $prevDisabled={prevDisabled}
      $nextDisabled={nextDisabled}
      data-cy="pagination"
    >
      {!prevDisabled && (
        <Page
          className="nav-prev"
          adjP={pageNumber - 1}
          rel="prev"
          onClick={() => {
            if (setPage && pageNumber) {
              setPage(pageNumber - 1);
            }
          }}
          isLink={isLink}
          href={
            isFullUrl
              ? `${basePath}${pageNumber - 1}`
              : `${basePath}${pageNumber === 1 ? "" : `?page=${pageNumber - 1}`}`
          }
        >
          <LeftArrowCircle width={18} />
          <Text margin="auto 32px auto 0">{t("global.pagination.prev")}</Text>
        </Page>
      )}
      {pages.map(p => {
        const adjP = p > 0 && pageNumber <= last ? p : "";
        const adjPNumber = Number(adjP);
        const isPrev = adjP + 1 === pageNumber;
        const isNext = adjP - 1 === pageNumber;
        const fullPath = `${basePath}${adjPNumber}`;

        return (
          <Page
            key={`page-${adjP}`}
            adjP={adjP}
            margin="auto 8px"
            inline
            fontWeight={adjPNumber === pageNumber ? 800 : 400}
            color={adjPNumber === pageNumber ? "black500" : "black200"}
            isLink={isLink}
            href={
              isFullUrl
                ? `${adjPNumber === 1 ? `${fullPath.replace("?page=1", "")}` : `${fullPath}`}`
                : `${basePath}${adjPNumber === 1 ? "" : `?page=${adjPNumber}`}`
            }
            onClick={() => {
              if (adjPNumber && adjPNumber !== pageNumber && setPage) {
                setPage(adjPNumber);
              }
            }}
            {...(isPrev ? { rel: "prev" } : {})}
            {...(isNext ? { rel: "next" } : {})}
          >
            {adjPNumber}
          </Page>
        );
      })}
      {!nextDisabled && (
        <Page
          className="nav-next"
          disabled={nextDisabled}
          adjP={pageNumber + 1}
          rel="next"
          onClick={() => {
            if (setPage && pageNumber) {
              setPage(pageNumber + 1);
            }
          }}
          isLink={isLink}
          href={isFullUrl ? `${basePath}${page + 1}` : `${basePath}?page=${pageNumber + 1}`}
        >
          <Text margin="auto 0 auto 32px">{t("global.pagination.next")}</Text>
          <RightArrowCircle width={18} />
        </Page>
      )}
    </StyledRow>
  );
};

const Page = ({ disabled, isLink, href, adjP, ...props }) => {
  const { pathname } = useLocation();
  const path = `${pathname}?page=${adjP}`;

  return (
    <Suspense fallback={<Text as={disabled ? "div" : "a"} target="_self" href={path} {...props} />}>
      <PageNav disabled={disabled} isLink={isLink} href={href} {...props} />
    </Suspense>
  );
};

export default Pagination;
