import styled from "styled-components";

export const Job = styled.div`
  padding: 12px;
  border: 1px solid #a4a4a4;
  margin: 15px 15px 100px 15px;

  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    width: calc(100% - 42px);
    margin-top: 0;
    margin-bottom: 15px;
    margin-left: ${({ isAdmin }) => (isAdmin ? "0" : "auto")};
    margin-right: ${({ isAdmin }) => (isAdmin ? "auto" : "0")};
    pointer-events: ${({ isAdmin }) => (isAdmin ? "none" : "all")};
  }
`;

export const Cycle = styled.div`
  width: 48px;
  height: 48px;
  background: #000000;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
  margin-bottom: 16px;

  @media screen and (min-width: 768px) {
    margin-right: 16px;
    margin-bottom: 0;
  }
`;

export const linkStyles = {
  display: "block",
  marginTop: "5px",
  color: "#408BFC",
  textDecorationLine: "underline",
  fontWeight: "500",
  fontSize: "14px",
  height: "auto",
  padding: "0"
};
