import React from "react";
import { Check } from "@styled-icons/boxicons-regular";

import { list } from "../../constants/main";
import { ListItem } from "./styled";

const OfferListAddToHomeScreen = () => {
  return list.map(item => (
    <ListItem key={item}>
      <Check width="16px" height="16px" />
      {item}
    </ListItem>
  ));
};

export default OfferListAddToHomeScreen;
