import { makeAutoObservable } from "mobx";

import API from "../../utils/api";

class Articles {
  state = {};

  articles = {};

  total = {};

  h1Title = "";

  appliedQueryParams = {};

  constructor(setAppState, context, location) {
    makeAutoObservable(this);
    const {
      hero,
      popular,
      recent,
      h1_title: h1Title,
      filter,
      articles,
      similar_articles: similarArticles,
      total_amount: totalAmount
    } = context;

    this.appliedQueryParams = location.query_params;

    if (filter && articles) {
      this.articles[filter] = articles;
      this.state[filter] = {};
      this.state[filter].loading = false;
      this.state[filter].error = false;
      this.total[filter] = totalAmount;
    }

    if (similarArticles) {
      this.articles.similar_to_article = similarArticles;
    }

    if (hero) {
      this.articles.hero = hero;
      this.state.hero = {};
      this.state.hero.loading = false;
      this.state.hero.isHome = true;
    }

    if (popular) {
      this.articles.popular = popular;
      this.state.popular = {};
      this.state.popular.loading = false;
      this.state.popular.isHome = true;
    }

    if (recent) {
      this.articles.recent = recent;
      this.state.recent = {};
      this.state.recent.loading = false;
      this.state.recent.isHome = true;
    }

    if (h1Title) {
      this.h1Title = h1Title;
    }
  }

  getArticles = (filter, limit, page, article_id, countryId, authorId, isHome) => {
    if (
      (!(this.state[filter] && this.state[filter].loading) &&
        (!this.articles[filter] || this.articles[filter].length < limit)) ||
      page ||
      article_id
    ) {
      this.state[filter] = { loading: true };
      return API.getArticles(filter, limit, page, article_id, countryId, authorId)
        .then(res => {
          this.articles[filter] = res?.data?.articles;
          this.total[filter] = res?.data?.total_amount;
          this.state[filter] = {
            error: false,
            loading: false
          };

          if (isHome && ["popular", "recent"].includes(filter) && !this.state[filter].isHome) {
            this.state[filter].isHome = true;
          }
        })
        .catch(err => {
          this.state[filter] = {
            error: true,
            loading: false,
            message: err?.response?.data?.error
          };
        });
    }
  };
}

export default Articles;
