import React from "react";

import { useHistory } from "react-router-dom";

import { Button } from "../Core";
import ProductModalContent from "../ProductModalContent";

import { useModal } from "../../../utils/hooks";
import useStore from "../../contexts/store";

const ProductButton = ({ product, locationState, validate = Promise.resolve(), ...rest }) => {
  const {
    user: { fuzu_points }
  } = useStore("User");
  const {
    push,
    location: { pathname }
  } = useHistory();
  const openProductModal = useModal(<ProductModalContent />, "");

  const forwardHandler = () => {
    validate().then(() => {
      if (fuzu_points > 0) openProductModal({ product, pathname, locationState });
      else
        push({ pathname: `/product/${product.slug}`, state: { from: pathname, discounted: false, ...locationState } });
    });
  };

  return <Button onClick={forwardHandler} {...rest} />;
};

export default ProductButton;
