import React, { cloneElement } from "react";
import { observer, inject } from "mobx-react";
import { MdEmail, MdLocalPhone, MdShare, MdPublic, MdPerson } from "react-icons/md";

const ContactDetails = ({ email, phone, facebook, linkedin, twitter, website, nationalId }) => {
  return (
    <>
      {email && (
        <div className="fz-detail-string fz-detail-string_gray fz-detail-string_top-aligned mb-3">
          <MdEmail className="fz-detail-string__icon" />
          <div className="fz-detail-string__info">
            <p className="fz-detail-string__text">
              <b>Email</b>
            </p>

            <a href={`mailto:${email}`} className="fz-link">
              {email}
            </a>
          </div>
        </div>
      )}
      {phone && (
        <div className="fz-detail-string fz-detail-string_gray fz-detail-string_top-aligned mb-3">
          <MdLocalPhone className="fz-detail-string__icon" />
          <div className="fz-detail-string__info">
            <p className="fz-detail-string__text">
              <b>Phone number</b>
            </p>
            <a href={`tel:${phone}`} className="fz-link">
              {phone}
            </a>
          </div>
        </div>
      )}
      {nationalId && (
        <div className="fz-detail-string fz-detail-string_gray fz-detail-string_top-aligned mb-3">
          <MdPerson className="fz-detail-string__icon" />
          <div className="fz-detail-string__info">
            <p className="fz-detail-string__text">
              <b>National ID</b>
            </p>
            <p className="fz-detail-string__text fz-detail-string__text_indent">12345678</p>
          </div>
        </div>
      )}
      {(facebook || linkedin || twitter) && (
        <div className="fz-detail-string fz-detail-string_gray fz-detail-string_top-aligned mb-3">
          <MdShare className="fz-detail-string__icon" />
          <div className="fz-detail-string__info">
            <p className="fz-detail-string__text">
              <b>Social networks</b>
            </p>
            {facebook && (
              <a href={facebook} className="fz-link">
                {facebook} (Facebook)
              </a>
            )}
            {linkedin && (
              <a href={linkedin} className="fz-link">
                {linkedin} (LinkedIn)
              </a>
            )}
            {twitter && (
              <a href={twitter} className="fz-link">
                {twitter} (Twitter)
              </a>
            )}
          </div>
        </div>
      )}
      {website && (
        <div className="fz-detail-string fz-detail-string_gray fz-detail-string_top-aligned mb-3">
          <MdPublic className="fz-detail-string__icon" />
          <div className="fz-detail-string__info">
            <p className="fz-detail-string__text">
              <b>Websites</b>
            </p>
            <a href={website} className="fz-link">
              {website}
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export const withContactDetails = Component => {
  return inject("campaignsStore")(
    observer(({ campaignsStore: { updateTalent }, id }) => {
      const featchContactDetails = () => {
        updateTalent(id).then(details => {
          console.debug(details);
        });
      };

      return cloneElement(Component, { onClick: featchContactDetails });
    })
  );
};

export default ContactDetails;
