import stress from "images/b2c/profilePage/stress.svg";
import speech from "images/b2c/profilePage/speech.svg";
import business from "images/b2c/profilePage/business.svg";
import interpersonal from "images/b2c/profilePage/interpersonal.svg";

export const iconMap = {
  Persistence: stress,
  "Social behaviour": speech,
  "Stress tolerance": stress,
  Creativity: business,
  Empathy: interpersonal
};

export const prompts = {
  student: ["Need support with building your profile?", "Get your profile reviewed by an expert."],
  junior: [
    "Improve your profile with an expert",
    "Still unsure of your dream career?",
    "Get expert advice on how to get a promotion."
  ],
  expert: [
    "Build a profile that gets you headhunted.",
    "Want to improve your leadership skills?",
    "Need tips on personal branding and networking?",
    "Stuck in your current job? Let's plan your exit."
  ]
};

export const scrollToError = {
  gender: 340,
  date_of_birth: 440,
  first_name: 540,
  last_name: 640,
  country: 740,
  city: 840,
  phone: 940
};
