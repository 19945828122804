import styled from "styled-components";

export const NotificationWrapper = styled.div`
  display: flex;
  width: 100%;
  top: 60px;
  left: 0;
  z-index: 11;

  ${({ $styled }) => $styled}
`;

export const NotificationIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  flex: 0 0 auto;
  padding: 6px;
  border-radius: 50%;

  > svg {
    width: 100%;
  }

  ${({ $styled }) => $styled}
`;

export const NotificationContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
