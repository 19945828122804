import React, { useState } from "react";
import { Star } from "@styled-icons/boxicons-regular";
import { Star as Filled } from "@styled-icons/boxicons-solid";
import { useScreenClass } from "react-grid-system";

import { StyledWrapper, IconWrapper } from "./styled";
import Icon from "../Icon";

const Rate = ({ onRate, padding = "0" }) => {
  const [highlighted, setHighlighted] = useState(0);
  const [voted, setVoted] = useState(false);
  const isSm = /xs|sm|md|lg/.test(useScreenClass());

  const handleHover = (e, index) => {
    if (!voted && !isSm) {
      setHighlighted(index * 2);
    }
  };

  const handleRating = key => {
    onRate(!voted ? highlighted : 0);

    if (isSm && !voted) {
      setVoted(true);
      setHighlighted(key * 2);
    } else if (isSm && voted) {
      setVoted(false);
      setHighlighted(0);
    } else {
      setVoted(true);
    }
  };

  const currentOpacity = voted ? 1 : 0.55;

  return (
    <StyledWrapper
      onMouseLeave={() => {
        if (!voted && !isSm) setHighlighted(0);
      }}
      onClick={() => {
        if (voted) setVoted(false);
      }}
      currentOpacity={currentOpacity}
      padding={padding}
    >
      {[...new Array(5).keys()].map(key => {
        let CurrentStar;
        const currentIndex = (key + 1) * 2;
        const isFilled = currentIndex <= highlighted || currentIndex - 1 === highlighted;
        if (isFilled) CurrentStar = Filled;
        else CurrentStar = Star;

        return (
          <IconWrapper
            key={`rate-star-${key}`}
            onMouseOver={e => handleHover(e, key + 1)}
            onClick={() => handleRating(key + 1)}
          >
            <Icon as={CurrentStar} width="100%" padding="20%" fill={isFilled ? "primary300" : "grey300"} />
          </IconWrapper>
        );
      })}
    </StyledWrapper>
  );
};

export default Rate;
