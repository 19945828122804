import React, { useEffect, forwardRef, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { Close } from "@styled-icons/evaicons-solid";

import { useInitialFocus, useQueryParams, useQueryUtils } from "utils/hooks";

import { useTranslation } from "b2c/hooks/useTranslation";
import { StyledRoot, StyledContent, StyledOverlay, Title, Head } from "./styled";
import Button from "../Core/Button";
import ThemeContext from "../../contexts/theme";
import Notifications from "./components/Notifications";
import useStore from "../../contexts/store";

export const useNotificationsSettingsNav = () => {
  const { replace } = useHistory();
  const { setParams, unsetParams, getPathWithParams } = useQueryUtils();
  const { notificationsSettingOpened } = useQueryParams();

  const isOpened = notificationsSettingOpened !== undefined;

  const open = useCallback(() => {
    const nextQueryParams = setParams({ notificationsSettingOpened: true });

    const nextPath = getPathWithParams(nextQueryParams);
    replace(nextPath);
  }, [setParams, unsetParams, getPathWithParams]);

  const close = useCallback(() => {
    const nextQueryParams = unsetParams(["notificationsSettingOpened"]);
    const nextPath = getPathWithParams(nextQueryParams);
    replace(nextPath);
  }, [unsetParams, getPathWithParams]);

  return {
    isOpened,
    open,
    close
  };
};

const useNotificationsSettings = ref => {
  const { isOpened, close } = useNotificationsSettingsNav();

  useInitialFocus(ref);

  const closeHandler = () => {
    close();
  };

  useEffect(() => {
    const onPressEscape = event => {
      if (event.keyCode === 27) {
        closeHandler();
      }
    };

    document.addEventListener("keydown", onPressEscape);

    return () => {
      document.removeEventListener("keydown", onPressEscape);
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = isOpened ? "hidden" : "unset";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpened]);

  return {
    isOpened,
    closeHandler,
    close
  };
};

const NotificationsSettings = forwardRef((props, ref) => {
  const { isOpened, closeHandler, close } = useNotificationsSettings(ref);
  const Theme = useContext(ThemeContext);
  const { t } = useTranslation();

  const {
    User: {
      user = {},
      setNotificationPrompt,
      Profile: {
        notificationSettings,
        getNotificationSettings,
        updateNotificationSettings,
        userDevice,
        updateUserDevice,
        getUserDevice
      }
    }
  } = useStore();

  useEffect(() => {
    if (user && isOpened) {
      getNotificationSettings();
      getUserDevice();
    }
  }, [isOpened]);

  if (!isOpened) {
    return null;
  }

  return (
    <StyledRoot ref={ref} onClick={event => event.stopPropagation()} aria-modal="true" role="dialog">
      <StyledOverlay onClick={closeHandler} />

      <StyledContent>
        <Head>
          <Title>{t("notification_settings.title")}</Title>
          <Button.Iconed
            theme="transparent"
            onClick={close}
            style={{
              color: Theme.grey700
            }}
          >
            <Close width={24} />
          </Button.Iconed>
        </Head>
        <Notifications
          updateNotificationSettings={updateNotificationSettings}
          notificationSettings={notificationSettings}
          updateUserDevice={updateUserDevice}
          userDevice={userDevice}
          setNotificationPrompt={setNotificationPrompt}
        />
      </StyledContent>
    </StyledRoot>
  );
});

export default observer(NotificationsSettings);
