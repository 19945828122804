import React from "react";

import { StyledContent } from "./styled";

import { Image, Button, Text } from "../Core";
import { buildImageUrlS3 } from "../../contexts/branded";
import { nameImages } from "../../constants/images";

const ModuleQuizModalContent = ({ courseId, testId, close }) => {
  return (
    <StyledContent>
      <Image src={buildImageUrlS3(nameImages.testSuccess)} alt="Quiz" style={{ width: "160px", margin: "auto" }} />

      <Text fontWeight={500} margin="24px auto">
        It’s time to test what you’ve learned!
      </Text>
      <Text margin="auto auto 24px" lineHeight="21px">
        The following quiz consists of multiple-choice questions. To be successful with the quiz, it’s important that
        you’ve thoroughly studied the course modules. In the end of the quiz, you’ll be able to review the correct
        answers. Good luck!
      </Text>
      <Button.Link
        to={{
          pathname: `/learn/${courseId}/tests/${testId}`,
          state: { from: `/learn/${courseId}` }
        }}
        width={200}
        margin="0 auto"
        onClick={close}
        color="primaryButtonLabelColor"
      >
        Start Quiz
      </Button.Link>
    </StyledContent>
  );
};

export default ModuleQuizModalContent;
