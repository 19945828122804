import styled from "styled-components";

export const StyledRoot = styled.div`
  top: 0;
  left: 0;
  z-index: 101;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  &:focus {
    border: none;
    outline: none;
  }
  ${({ $styled }) => $styled}
`;

export const StyledContent = styled.div`
  width: 304px;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
`;

export const StyledOverlay = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  background: #1c1e2085;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #000000;
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 10px 16px;
  border-bottom: 1px solid #e1e1e1;
`;
