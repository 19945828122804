import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import avatarPlaceholder from "images/empty_avatar.png";
import baronaAvatarPlaceholder from "images/barona-avatar-empty.jpg";
import { isBaronaBrand } from "../../b2c/contexts/branded";
import useStore from "../../b2c/contexts/store";

const Wrapper = styled.div(item => item.styled);

const ProfileAvatar = ({ className = "", avatar, name, badgeCounter, ...props }) => {
  const [avatarImage, setAvatar] = useState(avatar);
  const isBarona = isBaronaBrand();
  const user = useStore("User");
  const avatarUploaded = user?.user?.avatar_uploaded;

  useEffect(() => {
    const profileAvatar = isBarona && !avatarUploaded ? baronaAvatarPlaceholder : avatar || avatarPlaceholder;
    setAvatar(profileAvatar);
  }, [avatar]);

  const counter = typeof badgeCounter !== "undefined" && !isNaN(badgeCounter) ? badgeCounter.toString() : null;

  return (
    <Wrapper className={`fz-profile-avatar ${className}`} styled={props.style}>
      {avatar ? (
        <img
          onError={() => setAvatar(isBarona ? baronaAvatarPlaceholder : avatarPlaceholder)}
          className="fz-profile-avatar__img"
          src={avatarImage}
          alt="Profile avatar"
          data-testid="image"
        />
      ) : name ? (
        <div className="fz-profile-avatar__letter">
          <span>{name[0]}</span>
        </div>
      ) : (
        <img
          className="fz-profile-avatar__img"
          src={isBarona ? baronaAvatarPlaceholder : avatarPlaceholder}
          alt="Profile avatar"
        />
      )}
      {counter && counter > 0 ? <div className="fz-badge">{counter}</div> : <></>}
    </Wrapper>
  );
};

export default observer(ProfileAvatar);
