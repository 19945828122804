import axios from "axios";

// Job search

export const getPositionFilters = query =>
  axios.get(`/api/v1/browse/jobs/filters?${query}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });

export const getApplicationFilters = () => axios.get("/api/v1/browse/applications/filters");

export const getJobSearchResult = query =>
  axios.get(`/api/v1/browse/jobs/search?${query}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });

export const getPosition = id => axios.get(`/api/v1/browse/jobs/${id}`);

export const getApplications = query =>
  axios.get(`/api/v1/browse/applications/search?${query}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });

export const getSavedJobs = query =>
  axios.get(`/api/v1/browse/jobs/saved?${query}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });

export const getCompanyDetailsByJobId = id => axios.get(`/api/v1/browse/jobs/${id}/employer`);

export const getCompanyDetailsByCompanyId = id => axios.get(`/api/v1/browse/companies/${id}`);

export const getPreferredCountries = () => axios.get("/api/v1/browse/profile/preferred_locations");

export const getCommonLocations = query =>
  axios.get(`/api/v1/common/locations/countries${query ? `?query=${query}` : ""}`);

export const getGlobalLocations = query => axios.get(`/api/v1/common/locations/global_locations?query=${query}`);

export const getCommonCities = (countryId, search) =>
  axios.get(`/api/v1/common/locations/cities?country_id=${countryId}&query=${search}&context=employer`);

export const updatePreferredCountries = location_ids =>
  axios.patch("/api/v1/browse/profile/preferred_locations", {
    location_ids
  });

export const getSenioritySettings = () => axios.get("/api/v1/browse/profile/seniority_levels");

export const udpateSenioritySettings = seniority_level_ids =>
  axios.patch("/api/v1/browse/profile/seniority_levels", {
    seniority_level_ids
  });

export const getInterestSettings = () => axios.get("/api/v1/browse/profile/interest_areas");

export const updateInterestSettings = interest_area_ids =>
  axios.patch("/api/v1/browse/profile/interest_areas", { interest_area_ids });

export const trackJobView = id => axios.post(`/api/v1/browse/jobs/${id}/viewed`);

// Universal search

export const getSearchResults = (type = "universal", term, page, filter = "") =>
  axios.get(`/api/v1/browse/search/${type}?${term ? `term=${term}` : ""}&page=${page}&filter=${filter}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
