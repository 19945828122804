import React, { useLayoutEffect, useContext } from "react";
import styled from "styled-components";

import axios from "axios";
import parse, { domToReact } from "html-react-parser";

import { ModalContext } from "contexts/modal";
import { Alert, Spinner } from "../Core";
/*
const toggleClass = (current, className) => {
  return current?.includes(className) ? current?.replace(className, "") : `${current} ${className}`;
};
*/

// const hasAccordionChild = node => node?.children?.some(child => child.attribs?.class?.includes("booster-accordion"));

const InstantFeedbackContent = ({ position_id, onClose }) => {
  const { toggle } = useContext(ModalContext);

  // const hiddenRef = useRef();

  const getModalContents = href => {
    return axios
      .get(href, { headers: { "Content-Type": "text/html", Accept: "text/html" } })
      .then(res => {
        toggle(
          <StyledContent>{React.cloneElement(replaceAnchors(res.data), { close: () => toggle() })}</StyledContent>,
          "",
          true
        );
      })
      .catch(() => {
        toggle(
          <StyledContent>
            <Alert>Unable to get Instant Feedback</Alert>
          </StyledContent>,
          "",
          true
        );
      });
  };
  /*

  const toggleHidden = e => {
    const icon = e.target.getElementsByTagName("img")[0];
    if (hiddenRef.current) hiddenRef.current.className = toggleClass(hiddenRef.current.className, "hidden");
    if (icon) icon.className = toggleClass(icon.className, "rotate-180");
  };
  */

  const replaceAnchors = parsed => {
    const options = {
      replace: domNode => {
        if (domNode.name === "a") {
          if (domNode.attribs.href !== "/jobs") {
            domNode.attribs.onClick = e => {
              e.preventDefault();
              getModalContents(domNode.attribs.href);
            };
            return domNode;
          }
          return <span className={domNode.attribs.class}>{domToReact(domNode.children)}</span>;
        }
        if (domNode.name === "html") {
          domNode.attribs.style = "width: 100%;padding: 15px;";
          return domNode;
        }
        if (domNode.attribs?.class === "col-flex-sm-9") {
          domNode.attribs.class = "";
          return domNode;
        }
      }
    };

    return parse(parsed, options);
  };

  useLayoutEffect(() => {
    getModalContents(`/legacy-features/instant-feedback/${position_id}/modal`);

    return () => {
      onClose && onClose();
    };
  }, [position_id]);

  return (
    <StyledContent>
      <Spinner size="72px" margin="72px auto" />
    </StyledContent>
  );
};

const StyledContent = styled.div`
  max-width: 100%;
  width: 80vw;
  @media screen and (max-width: 767px) {
    width: 100vw;
    margin: 0 -10px;
  }
`;

export default InstantFeedbackContent;
