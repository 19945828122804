import { makeAutoObservable, reaction } from "mobx";

import * as State from "utils/storeHelpers";
import API from "../../utils/api";

class CvBuilderStore {
  setAppState;

  constructor(setAppState) {
    makeAutoObservable(this);

    this.setAppState = setAppState;

    reaction(
      () => this.applied,
      applied => {
        if (this.options.length === 0) this.getOptions(applied.template_id);
        if (!!applied.options && !applied.disable) this.getCvPreview(applied);
      }
    );

    reaction(
      () => this.options,
      options => {
        if (options.length > 0) {
          const optionsObject = {};
          const multi = ["sections", "work_experiences", "references"];
          options.forEach(item => {
            optionsObject[item.slug] = multi.includes(item.slug)
              ? item?.values?.filter(el => el.selected).map(el => el.value)
              : item?.values?.find(el => el.selected)?.value;
          });
          this.applyOptions({ options: optionsObject });
        }
      }
    );

    reaction(
      () => this.state,
      localState => {
        this.setAppState(localState);
      }
    );
  }

  state = {
    loading: false,
    type: "",
    message: ""
  };

  templates = [];

  options = [];

  applied = {};

  pages = [];

  cvUrl;

  initialPath;

  get currentTemplate() {
    return this.templates.find(({ id }) => id == this.applied.template_id || 1) || {};
  }

  applyOptions = (newOptions = {}) => {
    this.applied = { template_id: 1, ...this.applied, ...newOptions, disable: false };
  };

  setInitialPath = path => {
    this.initialPath = path;
  };

  getTemplates = () => {
    if (this.templates.length === 0) {
      this.state = State.setLoading(this.state);
      return API.getBuilderTemplates()
        .then(res => {
          this.templates = res?.data?.templates;
          this.state = State.setNeutral(this.state, res);
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Failed to retrieve a list of templates", () =>
            this.getTemplates()
          );
        });
    }
    return Promise.resolve();
  };

  getOptions = (id = 1) => {
    this.options = [];
    this.state = State.setLoading({ ...this.state, processing: true });
    return API.getBuilderOptions(id)
      .then(res => {
        this.options = res?.data?.options;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve a list of options", () => this.getOptions(id));
      });
  };

  getCvPreview = options => {
    this.state = State.setProcessing(this.state);
    return API.applyBuilderOptions(options)
      .then(res => {
        this.pages = res?.data?.cv?.preview_urls;
        this.cvUrl = res?.data?.cv?.url;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to retrieve CV preview", () => this.getCvPreview());
      });
  };

  loadCv = () => {
    this.state = State.setProcessing(this.state);
    return API.loadCv()
      .then(res => {
        this.pages = res?.data?.cv?.preview_urls;
        this.cvUrl = res?.data?.cv?.url;
        this.applied = { ...res?.data?.cv?.generate_options, disable: true };
        this.state = State.setNeutral(this.state, res);
        this.getTemplates();

        return res?.data?.cv?.url;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to load CV", () => this.loadCv());
      });
  };
}

export default CvBuilderStore;
