import { makeAutoObservable } from "mobx";

import * as State from "utils/storeHelpers";
import API from "../../utils/api";

class Unfinished {
  constructor() {
    makeAutoObservable(this);
  }

  state = {};

  unfinished;

  unfinishedCourses;

  getUnfinishedItems = limit => {
    if (!this.unfinished) {
      this.state = State.setLoading(this.state);
      return API.getUnfinishedItems(limit)
        .then(res => {
          this.unfinished = res?.data?.unfinished;
          this.state = State.setNeutral(this.state);
        })
        .catch(err => {
          this.state = {
            loading: false,
            type: "error",
            message: err?.response?.data?.error
          };
        });
    }
  };

  getUnfinishedCourses = () => {
    this.state = State.setLoading(this.state);
    return API.getUnfinishedCourses()
      .then(res => {
        this.unfinishedCourses = res?.data?.courses;
        this.state = State.setNeutral(this.state);
      })
      .catch(err => {
        this.state = {
          loading: false,
          type: "error",
          message: err?.response?.data?.error
        };
      });
  };
}

export default Unfinished;
