import React, { Children, useContext } from "react";

import { Row, Col } from "react-grid-system";
import { RightArrowAlt } from "@styled-icons/boxicons-regular";
import ThemeContext from "../../../contexts/theme";

import Layout from "../../Layout";
import Title from "../Title";
import Icon from "../Icon";
import Tag from "../Tag";
import LinkWithFullUrl from "../../../../components/LinkWithFullUrl";
import { CategoriesWrapper } from "./styled";

const Categories = ({ title, children, contentVisibility, style }) => {
  const { body } = useContext(ThemeContext);

  const childrenArray = Children.toArray(children);
  const colCount = Math.ceil(Children.count(children) / 4);

  return (
    Children.count(children) > 0 && (
      <CategoriesWrapper backgroundColor={body} contentVisibility={contentVisibility}>
        <Layout.Content tag="div" style={style}>
          <Title loaded level={2} fontWeight={600} margin="0 0 32px">
            {title}
          </Title>
          <Row>
            {[...Array(4)].map((_, index) => (
              <Col key={`category_col_${index + 1}`} sm={3}>
                {childrenArray.slice(colCount * index, colCount * (index + 1))}
              </Col>
            ))}
          </Row>
        </Layout.Content>
      </CategoriesWrapper>
    )
  );
};

Categories.Item = ({ children, ...rest }) => {
  return (
    <Tag
      as={LinkWithFullUrl}
      name={children}
      removable={false}
      borderColor="transparent"
      color="black0"
      margin="0 0 12px"
      Icon={() => <Icon as={RightArrowAlt} height="100%" fill="primary300" />}
      flexDirection="row-reverse"
      shadow
      width="100%"
      {...rest}
    />
  );
};

export default Categories;
