import React, { createContext } from "react";
import { ServerSide } from "./ssr";

const GuestModeContext = createContext({});
export class GuestModeProvider extends React.Component {
  static contextType = ServerSide;

  setGuestMode = value => {
    this.setState({ active: value });
  };

  state = {
    active: this.context?.ServerSide?.ssr || true,
    setGuestMode: this.setGuestMode
  };

  render() {
    return <GuestModeContext.Provider value={this.state}>{this.props.children}</GuestModeContext.Provider>;
  }
}

export default GuestModeContext;
