import React, { useContext } from "react";
import styled from "styled-components";
import { ErrorCircle } from "@styled-icons/boxicons-solid";

import ThemeContext from "../../../contexts/theme";

const Alert = ({ type = "error", margin = "0", clickable, children, ...rest }) => {
  const theme = useContext(ThemeContext);

  const props = (() => {
    switch (type) {
      default:
      case "error":
        return { backgroundColor: theme.accent200, fill: theme.accent300 };
      case "success":
        return { backgroundColor: theme.success100, fill: theme.success300 };
      case "neutral":
        return { backgroundColor: theme.secondary100, fill: theme.secondary300 };
    }
  })();

  return (
    <Wrapper clickable={clickable} margin={margin} {...props} {...rest}>
      <ErrorCircle />
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${({ backgroundColor, fill, margin, clickable }) => `
        width: 100%;
        margin: ${margin};
        background: ${backgroundColor};
        color: #333;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        font-weight: 500;
        padding: 10px;
        border-radius: 8px;
        background: #FFEAE5;
        gap: 8px;
        padding: 12px;
        
        ${clickable ? "cursor: pointer;" : ""}

        > svg {
            margin: 0;
            width: 24px;
            flex: 0 0 24px;

            path {
                fill: ${fill};
            }
        }
    `}
`;

export default Alert;
