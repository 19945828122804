import React, { useContext } from "react";
import styled from "styled-components";
import ThemeContext from "../../../contexts/theme";

const StyledDivider = styled.hr(item => item.styled);

const Divider = ({ color = "grey200", margin = "0 auto", width = "100%", style, children, ...props }) => {
  const Theme = useContext(ThemeContext);

  return (
    <StyledDivider
      className="page-divider"
      styled={{
        margin,
        width,
        backgroundColor: Theme[color],
        ...style
      }}
      {...props}
    />
  );
};

Divider.Vertical = ({ color = "grey200", margin = "0 auto", height = "100%", style, children, ...props }) => {
  const Theme = useContext(ThemeContext);

  return (
    <StyledDivider
      className="page-divider vertical"
      styled={{
        margin,
        height,
        backgroundColor: Theme[color],
        ...style
      }}
      {...props}
    />
  );
};

export default Divider;
