import React, { Suspense } from "react";
import { observer } from "mobx-react";

import { Link, useLocation, useRouteMatch } from "react-router-dom";

import useStore from "../../contexts/store";

const SignupLink = ({ children, state, event = "registration_link_clicked", $trackParams, ...rest }) => {
  const { track } = useStore("Tracking");
  const { pathname } = useLocation();
  const { path } = useRouteMatch();

  const handleTracking = () => {
    track(event, { match_path: path, ...$trackParams });
  };

  return (
    <Suspense
      fallback={
        <Link {...rest} to={`/signup?return_to=${pathname}`}>
          {children}
        </Link>
      }
    >
      <Link {...rest} to={{ pathname: "/signup", state: { from: pathname, ...state } }} onClickCapture={handleTracking}>
        {children}
      </Link>
    </Suspense>
  );
};

export default observer(SignupLink);
