import styled from "styled-components";

export const StyledDiv = styled.div`
  ${({ regionX = 0, regionY = 0, arrowX, arrowY, arrowColor, positionLeft, positionBottom, maxWidth, alternative }) => `
        position: absolute;
        z-index: 101;
        top: ${regionY}px;
        max-width: ${maxWidth};
        ${!positionLeft ? `left: ${regionX}px;` : `right: calc(100% - ${regionX}px);`}
        ${alternative ? "transform: translateX(-100%);" : ""}
        ${positionBottom ? "transform: translateY(-100%);" : ""}

        &:before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 25px solid ${arrowColor};
            ${!positionBottom ? `top: ${arrowY}px` : `bottom: ${arrowY}px`};
            left: ${
              alternative ? "calc(100% - 5px)" : !positionLeft ? `${arrowX - 15}px;` : `calc(100% - ${arrowX + 15}px)`
            };
            ${alternative ? "transform: rotate(90deg);" : ""}
            ${positionBottom ? "transform: rotate(-180deg);" : ""}
        }

        @media screen and (max-width: 767px) {
            left: 1%;
            width: 98%;
            max-width: none;
		}
    `}
`;
