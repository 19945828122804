import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const HistoryListener = ({ callback, prevent = false }) => {
  const { listen, block } = useHistory();
  useEffect(() => {
    let unblock;
    if (prevent)
      unblock = block((location, action) => {
        callback(location, action);
        return false;
      });

    return prevent ? () => unblock() : listen(callback);
  }, [callback, prevent]);

  return null;
};

export default HistoryListener;
