export const jobsTypes = {
  jobs: "jobs"
};

export const jobBanner = {
  type: "jobCustomBanner"
};

export const jobCustomBanner = "jobCustomBanner";

export const jobSettings = {
  preferredCountries: "preferred_countries",
  preferredLocations: "preferred_locations"
};
