import React, { useState, useEffect, useLayoutEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { useScreenClass } from "react-grid-system";

import { useTranslation } from "b2c/hooks/useTranslation";
import Conversations from "./components/Conversations";
import Content from "./components/Content";
import { MessengerPanel } from "./styled";
import { useDocumentTitle, useOpenSupportChanel, useAddClassnameWhenOpenChat } from "../../utils/hooks";
import { getSearchParams } from "../../utils/getSearchParams";

const Messenger = ({
  Messages: {
    sortedConversations: conversations,
    channels,
    status,
    retirevedConversationsCount,
    initializeMessenger,
    markAsRead,
    groupedMessages: messages,
    draftMessages,
    setDraftMessage,
    getConversations,
    addConversation,
    addChannelConversation,
    getMessages,
    addMessage,
    updateMessage,
    removeMessage,
    clearMessages,
    disableMessenger,
    disableMessageChain,
    activeTab,
    setActiveTab,
    uploadAttachment,
    getContactDetails,
    filterMessages,
    toggleMessagesFilter
  },
  User,
  b2b = false,
  UserCvButton,
  location: { state: { user_id, message } = {}, search },
  isBarona
}) => {
  const screen = useScreenClass();
  const isMobile = /sm|xs/.test(screen);
  const [selected, setConversation] = useState({});
  const [activeConversation, setActiveConversation] = useState(false);
  const conversationId = getSearchParams(search).get("conversation");
  const { t } = useTranslation();

  useAddClassnameWhenOpenChat();

  useDocumentTitle(t("messages.meta_title", { name: isBarona ? "Barona" : "Fuzu" }));

  useOpenSupportChanel(channels, conversations, setActiveTab, search, setConversation, activeTab, User.adminAccess);

  useLayoutEffect(() => {
    initializeMessenger().then(() => {
      if (user_id) addConversation(user_id, message);
    });

    return () => {
      disableMessenger();
    };
  }, []);

  useLayoutEffect(() => {
    if (selected?.channel_id) {
      const currentChannel = channels?.find(channel => channel?.id == selected?.channel_id);
      if (currentChannel?.conversation) setConversation(currentChannel?.conversation);
    }
  }, [channels]);

  useEffect(() => {
    if (conversationId && !Object.keys(selected).length && !activeConversation) {
      const currentChannel = conversations?.find(conversation => Number(conversation?.id) === Number(conversationId));

      if (currentChannel) {
        setConversation(currentChannel);
        setActiveConversation(true);
      }
    }
  }, [conversationId, conversations]);

  useEffect(() => {
    setConversation({});
  }, [activeTab, filterMessages]);

  useEffect(() => {
    if (selected.id || selected.channel_id) {
      getMessages(selected.id || selected.channel_id);
      if (selected.unread_count > 0) markAsRead(selected.id);
    } else if (selected.channel_id) {
      disableMessageChain();
    }
  }, [selected]);

  const draftMessage = useMemo(() => {
    if (draftMessages?.some(item => Number(item.id) === Number(selected.id))) {
      return draftMessages?.find(item => Number(item.id) === Number(selected.id)).body;
    }
    return "";
  }, [draftMessages, selected]);

  const deselectConversation = () => {
    setConversation({});
    clearMessages();
  };

  return (
    <MessengerPanel b2b={b2b}>
      {(!isMobile || (!selected.id && !selected.channel_id)) && (
        <Conversations
          user={User?.user}
          channels={channels}
          conversations={conversations}
          selectedId={selected.id}
          retirevedConversationsCount={retirevedConversationsCount}
          setConversation={setConversation}
          getConversations={getConversations}
          clearMessages={clearMessages}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          status={status}
          tabs={
            User.adminAccess
              ? [
                  { id: "conversations", name: t("messages.tab_conversations") },
                  { id: "channels", name: t("messages.tab_channels") }
                ]
              : [
                  { id: "conversations", name: t("messages.tab_conversations") },
                  { id: "support", name: t("messages.tab_support") }
                ]
          }
          filterMessages={filterMessages}
          toggleMessagesFilter={toggleMessagesFilter}
          adminAccess={User.adminAccess}
          isBarona={isBarona}
        />
      )}

      {(!isMobile || selected.id || selected.channel_id) && (
        <Content
          User={User}
          isMobile={isMobile}
          deselectConversation={deselectConversation}
          messages={messages}
          draftMessage={draftMessage}
          setDraftMessage={setDraftMessage}
          addMessage={addMessage}
          addChannelConversation={addChannelConversation}
          updateMessage={updateMessage}
          removeMessage={removeMessage}
          clearMessages={clearMessages}
          uploadAttachment={uploadAttachment}
          UserCvButton={UserCvButton}
          getContactDetails={getContactDetails}
          isBarona={isBarona}
          {...selected}
        />
      )}
    </MessengerPanel>
  );
};

export default observer(Messenger);
